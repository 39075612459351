import React, { useEffect, useState } from 'react'

import { Header, Dimmer, Progress, Button, Grid } from 'semantic-ui-react'

import Data from '../../api/Data'
import { S3_BUCKET_URL } from '../../config/environments'

const VideoNotEncoded = ({ status, encodingStatus }) => {
  return (
    <Dimmer.Dimmable style={{ width: '640px', height: '360px' }}>
      <Dimmer active>
        {encodingStatus === 'error' && (
          <Header inverted as="h4">
            An error occurred during the preview of your template.
          </Header>
        )}
        {encodingStatus === 'neverEncoded' && (
          <Header inverted as="h4">
            This template has not been encoded yet. Click PREVIEW to preview your template for the first time..
          </Header>
        )}
        {encodingStatus === 'encoding' && (
          <>
            <Header inverted as="h4">
              Your preview is being generated
            </Header>

            <Header as="h5" inverted>
              <Progress size="small" color="violet" active percent={status - 100} />
            </Header>
          </>
        )}
      </Dimmer>
    </Dimmer.Dimmable>
  )
}

const VideoReady = ({ themeId }) => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        width="640"
        height="auto"
        src={`${S3_BUCKET_URL}/t/${themeId}/0/0/3?${Date.now()}`}
        poster={`${S3_BUCKET_URL}/t/${themeId}/0/0/7?${Date.now()}`}
        controls
      />
    </>
  )
}

const DesignerTabPreviewPage = ({ themeId, handlePreview }) => {
  const [themeProgress, setThemeProgress] = useState(null)

  useEffect(() => {
    let autoFetching

    const fetchTheme = async () => {
      const { data, error } = await Data.getTheme(themeId)

      if (error) return alert('An error occurred')

      const { status, encodingStatus } = data

      setThemeProgress({ status, encodingStatus })

      if (encodingStatus === 'encoding') autoFetching = setTimeout(fetchTheme, 5000)
    }
    // Fetch theme
    fetchTheme()

    // Clear timeout once navigate away from the page
    return () => {
      clearTimeout(autoFetching)
    }
  }, [themeId])

  if (!themeProgress) return null

  const { status, encodingStatus } = themeProgress

  console.log('Rendering Theme Preview', status, encodingStatus)

  return (
    <Grid>
      <Grid.Column width={1}></Grid.Column>
      <Grid.Column width={11}>
        {encodingStatus === 'encoded' ? (
          <VideoReady themeId={themeId} />
        ) : (
          <VideoNotEncoded status={status} encodingStatus={encodingStatus} />
        )}
      </Grid.Column>
      <Grid.Column width={4}>
        <Button
          secondary
          inverted
          loading={encodingStatus === 'encoding'}
          disabled={encodingStatus === 'encoding'}
          onClick={handlePreview}
        >
          Preview template
        </Button>
      </Grid.Column>
    </Grid>
  )
}

export default DesignerTabPreviewPage
