import React from 'react'

import _ from 'lodash'
import { Header } from 'semantic-ui-react'

import { ITheme } from '../../../api/Data'
import DesignerTabSection from '../../designer/DesignerTabSection'

const PlanVideoTemplatePage = ({ theme, handleChangeTheme }: IProps) => {
  console.log('Rendering Video Layout Page', theme)

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Design how your contributors&apos; video will look
      </Header>

      <DesignerTabSection
        theme={_.cloneDeep(theme)}
        sectionName="vloggi"
        section={_.cloneDeep(theme.data.vloggi)}
        defaultFonts={theme.data.playlist.defaultFonts}
        updateTheme={handleChangeTheme}
      />
    </>
  )
}

interface IProps {
  theme: ITheme
  handleChangeTheme: (arg: ITheme) => void
}

export default PlanVideoTemplatePage
