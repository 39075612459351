import React, { useContext } from 'react'

import { Grid, Header } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'
import { UserContext } from '../../../contexts/UserContext'

import ColorPicker from '../../../components/common/ColorPicker'
import FontPickerComponent from '../../../components/common/FontPickerComponent'
import UpgradeTag from '../../../components/common/UpgradeTag'

const PlanTabBrandingPage = ({ project, handleChangePrimaryFont, handleChangePrimaryColor }: IProps) => {
  console.log('Rendering Branding')

  // Contexts
  const { userProfile } = useContext(UserContext)

  const { displaySetting } = project

  const hasBrandedUploadPage = userProfile.plan.hasBrandedUploadPage

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Customize your project's branding
        {!hasBrandedUploadPage && (
          <UpgradeTag
            title="Upgrade to add your branding"
            text="Upgrade your plan to add your branding to your landing page"
          />
        )}
      </Header>

      <Grid padded="vertically">
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as="h3">
              Font
              <Header.Subheader>Your font will apply to your landing page.</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <div
              style={{
                pointerEvents: hasBrandedUploadPage ? undefined : 'none',
                opacity: hasBrandedUploadPage ? undefined : 0.5,
              }}
            >
              <FontPickerComponent activeFontFamily={displaySetting.primaryFont} onChange={handleChangePrimaryFont} />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as="h3">
              Colors
              <Header.Subheader>Your brand colors will be used to customize your landing page.</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column width={4}></Grid.Column>
          <Grid.Column width={2} textAlign="center">
            <p>Vibrant</p>
            <div
              style={{
                pointerEvents: hasBrandedUploadPage ? undefined : 'none',
                opacity: hasBrandedUploadPage ? undefined : 0.5,
              }}
            >
              <ColorPicker colorHex={displaySetting.primaryColor} onChange={handleChangePrimaryColor} />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

interface IProps {
  project: IProject
  handleChangePrimaryFont: (arg: { family: string }) => void
  handleChangePrimaryColor: (arg: { hex: string }) => void
}

export default PlanTabBrandingPage
