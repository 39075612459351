import React, { useContext, useEffect, SyntheticEvent } from 'react'

import { RouteComponentProps } from 'react-router-dom'
import { Grid, Header, Icon, Segment } from 'semantic-ui-react'

import Data from '../../api/Data'
import { UserContext } from '../../contexts/UserContext'
import Auth from '../../utils/Auth'

import SearchUserField from '../../components/common/SearchUserField'
import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'

const AdminPage = ({ history }: IAdminProps) => {
  const { dispatchUserContext } = useContext(UserContext)

  // Give focus to the search field by default
  useEffect(() => {
    const inputEl: HTMLInputElement | null = document.querySelector('.search-field-input input')
    inputEl?.focus()
  }, [])

  const fetchMyProfile = async () => {
    const { data } = await Data.getMyProfile()

    dispatchUserContext({ type: 'update user profile', payload: data })
  }

  // Redirect to user page once the user chooses a user
  const ImpersonateUser = async (e: SyntheticEvent, { result }: IResult) => {
    const { data, error } = await Data.impersonateUser(result.id)

    if (error) return alert('An error occurred')

    Auth.setToken(data.token)

    await fetchMyProfile()

    history.push('/projects')
  }

  return (
    <Section>
      <>
        <Title title="Admin" />

        <SubSection>
          <Segment placeholder style={{ marginTop: '30vh' }}>
            <Grid columns={2} stackable textAlign="center" divided>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Grid textAlign="center">
                    <Grid.Row>
                      <Header icon>
                        <Icon name="add" />
                        Admin operations
                      </Header>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>

                <Grid.Column>
                  <Header icon>
                    <Icon name="search" />
                    Type email or username to log in as user
                  </Header>
                  <SearchUserField handleSubmit={ImpersonateUser} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </SubSection>
      </>
    </Section>
  )
}

interface IResult {
  result: {
    id: number
  }
}

interface IAdminProps extends RouteComponentProps {}

export default AdminPage
