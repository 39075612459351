import React, { useState, useEffect } from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Header, Grid, Button, Table, Icon } from 'semantic-ui-react'

import Data from '../../../api/Data'
import { IEpisode } from '../../../api/Data'

import SubSection from '../../../components/common/SubSection'
import Title from '../../../components/common/Title'
import VideoEncodingCard from '../../../components/common/VideoEncodingCard'
import VideoErrorCard from '../../../components/common/VideoErrorCard'

import ExportTabEpisodeCard from './ExportTabEpisodeCard'
import ExportTabEpisodeModal from './ExportTabEpisodeModal'

let autoFetchEpisodes: ReturnType<typeof setTimeout>

type BulkDownload = {
  presignedUrl: string
  fileName: string
  date: string
}

const ExportTab = ({ history, match, projectTitle, episodes, setEpisodes, exitProject }: IProps) => {
  // States
  const [episodeModal, setEpisodeModal] = useState<IEpisode | null>(null)

  const [bulkDownloads, setBulkDownloads] = useState<BulkDownload[]>([])

  // Fetch episodes
  useEffect(() => {
    const fetchEpisodes = async () => {
      const { projectSlug } = match.params

      clearTimeout(autoFetchEpisodes)

      const { data, error } = await Data.getEpisodes(projectSlug)

      if (error) return alert('An error occurred')

      setEpisodes(data)

      const isEpisodesInProgress = data.some((episode: IEpisode) => episode.encodingStatus === 'encoding')

      // If stories are encoding, fetch stories every 5 seconds
      if (isEpisodesInProgress) autoFetchEpisodes = setTimeout(fetchEpisodes, 5000)
    }

    const fetchBulkDownloadClips = async () => {
      const projectSlug = match.params.projectSlug
      const { data, error } = await Data.getBulkDownloads(projectSlug)

      if (error) return alert('An error occurred')

      const sortedBulkDownloads = data.sort((a: BulkDownload, b: BulkDownload) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })

      try {
        setBulkDownloads(sortedBulkDownloads as BulkDownload[])
      } catch (error) {
        console.log(error)
      }
    }

    fetchEpisodes()
    fetchBulkDownloadClips()

    return () => clearTimeout(autoFetchEpisodes)
  }, [match.params, setEpisodes])

  const deleteEpisode = async (episodeId: number) => {
    const { error } = await Data.deleteEpisode(episodeId)

    if (error) {
      return alert(`An error occurred => ${error}`)
    }

    window.location.reload()
  }

  const convertDateAndTime = (date: string) => {
    const dateObj = new Date(date)
    const dateStr = dateObj.toLocaleDateString('en-AU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    const timeStr = dateObj.toLocaleTimeString('en-AU', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    return `${dateStr} - ${timeStr}`
  }

  return (
    <>
      <Title title={projectTitle}>
        <Button compact onClick={exitProject}>
          Close
        </Button>
      </Title>

      <SubSection hasGreyBackground>
        <>
          <Header as="h3">Bulk downloads</Header>
          <Header as="h4">
            Bulk downloads can take between 1 to 15 minutes to complete. Try refreshing this page if you don't see your
            download.
          </Header>
          {bulkDownloads?.length > 0 ? (
            <Table celled>
              <Table.Body>
                {bulkDownloads.map((download) => (
                  <Table.Row key={download.fileName}>
                    {/* Format the date to be more readable */}
                    <Table.Cell>
                      <Header as="h4">Bulk download created on {convertDateAndTime(download.date)}</Header>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <a href={download.presignedUrl} download={download.fileName}>
                        <Button primary>
                          <Icon name="file archive"></Icon>
                          Download archive
                        </Button>
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <Header as="h5">You don't have any bulk downloads.</Header>
          )}
          <br />
          <Header as="h3">Video stories</Header>
          {episodes?.length > 0 ? (
            <Grid stackable columns={4}>
              {episodes.map((episode) => (
                <Grid.Column key={episode.id}>
                  {episode.encodingStatus === 'encoded' && (
                    <ExportTabEpisodeCard episode={episode} setEpisodeModal={setEpisodeModal} />
                  )}
                  {episode.encodingStatus === 'error' && (
                    <VideoErrorCard videoId={episode.id} handleDelete={deleteEpisode} />
                  )}
                  {episode.encodingStatus === 'encoding' && <VideoEncodingCard status={episode.status} />}
                </Grid.Column>
              ))}
            </Grid>
          ) : (
            <Header as="h5">You have not made a story yet.</Header>
          )}
          {/* Video Playing Modal */}
          {episodeModal && (
            <ExportTabEpisodeModal
              episode={episodeModal}
              setEpisodeModal={setEpisodeModal}
              deleteEpisode={deleteEpisode}
            />
          )}
        </>
      </SubSection>
    </>
  )
}

interface IProps extends RouteComponentProps<any> {
  projectTitle: string
  episodes: IEpisode[]
  setEpisodes: (arg: IEpisode[]) => void
  exitProject: () => void
}

export default withRouter(ExportTab)
