import React, { useContext, useState } from 'react'

import { Link, RouteComponentProps } from 'react-router-dom'
import { Grid, Form, Button, Message, Image, Header, InputOnChangeData } from 'semantic-ui-react'

import vloggiLogoWhite from '../../_images/vloggi-logo-white.png'
import Data from '../../api/Data'
import { MARKETING_WEBSITE_URL } from '../../config/environments'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'
import Auth from '../../utils/Auth'

import ExternalLink from '../../components/common/ExternalLink'
import PasswordInput from '../../components/common/PasswordInput'

const LoginPage = ({ history, location }: IProps) => {
  const { state }: any = location

  const { isMobile } = useContext(GlobalContext)
  const { dispatchUserContext } = useContext(UserContext)

  // States
  const [fields, setFields] = useState<{ email: string; password: string }>({ email: '', password: '' })
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { name, value } = data
    setErrorMessage(null)
    setFields({ ...fields, [name]: value })
  }

  const fetchMyProfile = async () => {
    const { data } = await Data.getMyProfile()

    dispatchUserContext({ type: 'update user profile', payload: data })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setLoading(true)

    const { data, error } = await Data.login({ ...fields })

    if (error) {
      setLoading(false)

      const message = error.response ? error.response.data : error.message

      return setErrorMessage(message)
    }

    const { token } = data

    Auth.setToken(token)

    await fetchMyProfile()

    return history.push(`/projects`)
  }

  return (
    <Grid stretched style={{ height: '100vh', margin: 0 }}>
      <Grid.Column only="computer" width={7} style={{ backgroundImage: 'linear-gradient(to bottom, #12165b, #121360)' }}>
        <Grid centered verticalAlign="middle">
          <Grid.Column width={14}>
            <Image width="200" src={vloggiLogoWhite} alt="vloggi" />
            <Header inverted as="h2">
              Collect videos from anyone, anywhere, at any time.
              <Header.Subheader>Login to your account</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column mobile={16} computer={9} style={{ backgroundImage: isMobile ? 'linear-gradient(to bottom, #12165b, #121360)' : undefined }}>
        <Grid padded>
          <Grid.Row>
            <Grid.Column textAlign="right">
              <p style={{ color: isMobile ? 'white' : 'black' }}>
                Don't have an account? &nbsp; <Button as={Link} to="/register" primary size="mini" style={{ margin: 0 }}>Sign up</Button>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered verticalAlign="middle">
            <Grid.Column mobile={16} computer={10} widescreen={6}>
              {isMobile ? (
                <>
                  <Image src={vloggiLogoWhite} size="small" style={{ margin: 'auto' }} />
                  <Header inverted as="h4">
                    Welcome back!<br />
                    <strong>Please log in...</strong>
                  </Header>
                </>
              ) : (
                <Header as="h2">
                  Welcome back!<br />
                  <strong>Please log in...</strong>
                </Header>
              )}
              <Form success error onSubmit={handleSubmit} inverted={isMobile}>
                {!!state && !!state.successMessage && (
                  <Message success header="Success" content={state.successMessage} />
                )}

                <Form.Field>
                  <label>Email</label>
                  <Form.Input
                    fluid
                    icon="mail"
                    iconPosition="left"
                    name="email"
                    value={fields.email}
                    placeholder="Type here..."
                    type="email"
                    onChange={handleChange}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Password</label>
                  <PasswordInput
                    name="password"
                    value={fields.password}
                    placeholder="Password"
                    handleChange={handleChange}
                  />
                </Form.Field>

                {errorMessage && <Message error>{errorMessage}</Message>}

                <Button primary type="submit" loading={loading} fluid style={{ marginTop: '2rem' }}>
                  Sign in
                </Button>
              </Form>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', fontSize: isMobile ? '0.8rem' : undefined }}>
                <Link to="/forgot"> Forgot password? </Link>

                <ExternalLink url={`${MARKETING_WEBSITE_URL}/user-terms`}> Terms and conditions </ExternalLink>

                <ExternalLink url={`${MARKETING_WEBSITE_URL}/privacy`}> Privacy policy </ExternalLink>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

interface IProps extends RouteComponentProps<any> {}

export default LoginPage
