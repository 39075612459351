import React, { useContext, useEffect, useState } from 'react'

import _ from 'lodash'
import queryString from 'query-string'
import { RouteComponentProps } from 'react-router'
import { Menu, Button, Confirm, MenuItemProps } from 'semantic-ui-react'
import WebFont from 'webfontloader'

import Data from '../../api/Data'
import { ITheme } from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'
import Auth from '../../utils/Auth'
import DesignerTabAdmin from '../designer/DesignerTabAdmin'
import DesignerTabMusic from '../designer/DesignerTabMusic'
import DesignerTabPreviewPage from '../designer/DesignerTabPreview'
import DesignerTabSection from '../designer/DesignerTabSection'
import DesignerTabSettings from '../designer/DesignerTabSettings'
import DesignerTabTransitions from '../designer/DesignerTabTransition'

import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'

const ThemeEditPage = ({ history, match, location }: IProps) => {
  const { hasUserMadeChange, dispatchGlobalContext } = useContext(GlobalContext)

  // States
  const [sectionName, setSection] = useState<string>('vloggi')
  const [theme, setTheme] = useState<ITheme | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [showErrorModal, setShowErrorModal] = useState<string | null>(null)

  // // Fetch theme and load fonts
  useEffect(() => {
    const loadThemeFont = (font: string) => {
      return WebFont.load({
        google: {
          families: [`${font}:400,700,italic,bold,bolditalic`],
        },
      })
    }

    const fetchTheme = async (id: number) => {
      const { data, error } = await Data.getTheme(id)

      if (error) alert('An error occurred')

      const themeFetched = data

      // If theme does not have default fonts set them here. This function can be removed once all theme updated
      if (!themeFetched.data.playlist.defaultFonts) {
        themeFetched.data.playlist.defaultFonts = ['Open Sans', 'Open Sans']
      }

      setTheme(themeFetched)

      if (themeFetched.data.playlist.defaultFonts) {
        themeFetched.data.playlist.defaultFonts.forEach((font: string) => {
          loadThemeFont(font)
        })
      }
    }

    fetchTheme(match.params.themeId)
  }, [match.params.themeId])

  // Update URL with the current active tab
  useEffect(() => {
    const { tab } = queryString.parse(location.search)

    setSection((tab && String(tab)) || 'vloggi')
  }, [location.search])

  // Switch tabs
  const switchTab = (e: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    const { value } = data

    const { projectSlug } = theme!

    const newUrl = projectSlug
      ? `/projects/${projectSlug}/themes/${theme!.id}/edit?tab=${value}`
      : `/themes/${theme!.id}/edit?tab=${value}`

    history.push(newUrl)
  }

  // Update theme
  const handleUpdate = async () => {
    setLoading(true)

    const { data, error } = await Data.updateTheme(theme)

    if (error) {
      setLoading(false)
      return setShowErrorModal(`An error occurred while updating theme ${error.response?.data}`)
    }

    dispatchGlobalContext({ type: 'set user has made changes', payload: false })

    setTheme(data)

    setTimeout(() => setLoading(false), 1000)
  }

  // Update theme and preview
  const handlePreview = async () => {
    // Save theme
    await handleUpdate()

    // Send theme to encode
    const { error } = await Data.encodeThemePreview(theme!.id)

    if (error) return alert('An error occurred')

    window.location.reload()
  }

  // handleClose
  const handleClose = () => {
    if (hasUserMadeChange) {
      return dispatchGlobalContext({ type: 'set exit without saving modal', payload: '/themes' })
    }

    return history.push('/themes')
  }

  // Default update theme function
  const updateTheme = (newTheme: ITheme) => {
    dispatchGlobalContext({ type: 'set user has made changes', payload: true })

    setTheme(newTheme)
  }

  if (!theme) return null

  const isOpening = !!theme.data.opening
  const isClosing = !!theme.data.closing
  const isUserAdmin = Auth.getPayLoad().isAdmin

  console.log('Rendering Theme Edit Page', theme)

  return (
    <Section>
      <>
        <Title title="Edit template">
          <>
            <Button onClick={handleClose}>Close</Button>
            <Button primary disabled={!hasUserMadeChange} loading={loading} onClick={handleUpdate}>
              Save
            </Button>
          </>
        </Title>

        <SubSection>
          <>
            <Menu color="violet" pointing secondary>
              <Menu.Item name="Clip layout" value="vloggi" active={sectionName === 'vloggi'} onClick={switchTab} />
              {isOpening && (
                <Menu.Item name="Intro" value="opening" active={sectionName === 'opening'} onClick={switchTab} />
              )}
              {isClosing && (
                <Menu.Item name="Outro" value="closing" active={sectionName === 'closing'} onClick={switchTab} />
              )}
              <Menu.Item
                name="Transitions"
                value="transitions"
                active={sectionName === 'transitions'}
                onClick={switchTab}
              />
              <Menu.Item name="Audio" value="audio" active={sectionName === 'audio'} onClick={switchTab} />
              <Menu.Item name="Preview" value="preview" active={sectionName === 'preview'} onClick={switchTab} />
              <Menu.Item name="Settings" value="settings" active={sectionName === 'settings'} onClick={switchTab} />
              {isUserAdmin && (
                <Menu.Item name="Admin" value="admin" active={sectionName === 'admin'} onClick={switchTab} />
              )}
            </Menu>

            {/* theme sections */}
            {['opening', 'vloggi', 'closing'].includes(sectionName) && (
              <DesignerTabSection
                theme={_.cloneDeep(theme)}
                sectionName={sectionName}
                section={_.cloneDeep(theme.data[sectionName])}
                defaultFonts={theme.data.playlist.defaultFonts}
                updateTheme={updateTheme}
              />
            )}

            {/* transitions */}
            {['transitions'].includes(sectionName) && (
              <DesignerTabTransitions theme={theme} updateTheme={updateTheme} />
            )}

            {/* audio */}
            {['audio'].includes(sectionName) && <DesignerTabMusic theme={theme} updateTheme={updateTheme} />}

            {/* settings */}
            {['settings'].includes(sectionName) && <DesignerTabSettings theme={theme} updateTheme={updateTheme} />}

            {/* preview */}
            {['preview'].includes(sectionName) && (
              <DesignerTabPreviewPage themeId={theme.id} handlePreview={handlePreview} />
            )}

            {/* admin */}
            {['admin'].includes(sectionName) && <DesignerTabAdmin theme={theme} updateTheme={updateTheme} />}

            {/* Show error during saving */}
            <Confirm
              open={!!showErrorModal}
              header="An error occurred during saving"
              content={showErrorModal}
              confirmButton={null}
              cancelButton="Close"
              onCancel={() => setShowErrorModal(null)}
            />
          </>
        </SubSection>
      </>
    </Section>
  )
}

interface IProps extends RouteComponentProps<any> {}

export default ThemeEditPage
