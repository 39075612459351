import React from 'react'

import ReactDOM from 'react-dom'

import 'semantic-ui-less/semantic.less'
import AppWrapper from './AppWrapper'
import {
  HAS_NO_INDEX_TAG,
  HAS_HOTJAR_SCRIPT,
  HAS_GOSQUARED_ANALYTICS,
  HAS_FIRST_PROMOTER_SCRIPT,
  HAS_GETREDITUS_SCRIPT,
} from './config/environments'
import * as serviceWorker from './serviceWorker'
import {
  addNoIndexTag,
  addHotJarScript,
  addGoSquaredScript,
  addFirstPromoterScript,
  addGetReditusScript,
} from './utils/metatags'

// Add script and tags into the index.html
if (HAS_NO_INDEX_TAG) addNoIndexTag()
if (HAS_HOTJAR_SCRIPT) addHotJarScript()
if (HAS_GOSQUARED_ANALYTICS) addGoSquaredScript()
if (HAS_FIRST_PROMOTER_SCRIPT) addFirstPromoterScript()
if (HAS_GETREDITUS_SCRIPT) addGetReditusScript()

ReactDOM.render(<AppWrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
