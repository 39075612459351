import React from 'react'

import { Button, Header, CheckboxProps } from 'semantic-ui-react'

import CustomLabel from '../../components/common/custom-label/CustomLabel'

const MySettingsStudioEmails = ({ emailOptIn, handleChange }: IProps) => {
  return (
    <div>
      <Header as="h4" style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>Studio emails</div>
        
        {/*
        {emailOptIn && (
          <Button.Group compact size="tiny" style={{ margin: '-0.35rem 0 0 1rem' }}>
            <Button>Weekly</Button>
            <Button primary>Monthly</Button>
          </Button.Group>
        )}
        */}

        <CustomLabel.Checkbox
          name="emailOptIn"
          checked={emailOptIn}
          onClick={handleChange}
        />
      </Header>
    </div>
  )
}

interface IProps {
  emailOptIn: boolean
  handleChange: (e: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps) => void
}

export default MySettingsStudioEmails
