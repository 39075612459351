import React, { FC, SyntheticEvent, useState } from 'react'

import _ from 'lodash'
import { Search, SearchProps, SearchResultData } from 'semantic-ui-react'

import Data, { IUser } from '../../api/Data'

const SearchUserField: FC<IProps> = ({ handleSubmit }) => {
  const [state, setState] = useState<IState>({ isLoading: false, results: [], value: '' })

  const formatUsers = (users: IUser[]): IResult[] =>
    users.map((user) => {
      return {
        title: user.username,
        id: user.id,
        description: user.email,
      }
    })

  const searchUsers = async () => {
    const { data, error } = await Data.searchUser(state.value)

    if (error) return alert('An error occurred')

    const results: IResult[] = formatUsers(data)

    return results
  }

  const handleSearchChange = async (e: React.MouseEvent<HTMLElement, MouseEvent>, data: SearchProps) => {
    const userInput = data.value

    // Do nothing if user typed less than 3 characters
    if (userInput && userInput.length < 3) return setState((prevState) => ({ ...prevState, value: userInput }))

    await setState({ isLoading: true, results: [], value: userInput || '' })

    const results = await searchUsers()

    return setState({ isLoading: false, results: results!, value: userInput! })
  }

  const handleOnResultSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, data: SearchResultData) => {
    setState((prevState) => ({ ...prevState, value: data.result.description }))
    handleSubmit(e, { result: data.result })
  }

  if (!state) return null

  const { isLoading, value, results } = state

  console.log('Rendering Search Field', state)

  return (
    <Search
      selectFirstResult
      fluid
      minCharacters={3}
      loading={isLoading}
      onResultSelect={handleOnResultSelect}
      onSearchChange={_.debounce(handleSearchChange, 1000, {
        leading: true,
      })}
      results={results}
      className="search-field-input"
      value={value}
    />
  )
}

type IResult = {
  title: string
  id: number
  description: string
}

interface IState {
  isLoading: boolean
  results: IResult[]
  value: string
}

interface IProps {
  handleSubmit: (e: SyntheticEvent, result: any) => void
}

export default SearchUserField
