import React, { useState } from 'react'

import { Initial } from 'react-initial'
import { Grid, Button, Image, Feed, Rating, Label, Icon, Popup } from 'semantic-ui-react'

import TickIcon from '../../../_images/tick-icon.png'
import { IClip } from '../../../api/Data'
import moment from '../../../utils/Moment'

// Interface

const GalleryClipCard = (props: IProps) => {
  // Props
  const {
    userId,
    clip,
    clipsSelected,
    setShowClipModal,
    handleFavorite,
    addFilterTag,
    handleClipSelection,
    votes,
    handleVote,
  } = props

  // State
  const [showSelectBox, setShowSelectBox] = useState<boolean>(false)

  const { id, createdAt, caption1, isFavorite, contributor, imageLowResUrl } = clip

  const shortCaption = caption1?.length > 50 ? `${caption1.slice(0, 50)}...` : caption1
  const contributorName = contributor || 'unknown'

  const isClipTrimmed = clip.trim
  const isClipFeatured = clip.isFeatured
  const isClipSelected = clipsSelected?.map((el) => el.id).includes(clip.id)

  // Has user voted async arrow function
  function hasUserVoted() {
    return votes.includes(userId)
  }

  return (
    <>
      <div
        style={{ position: 'relative', cursor: 'pointer' }}
        onMouseEnter={() => setShowSelectBox(true)}
        onMouseLeave={() => setShowSelectBox(false)}
      >
        <Image rounded src={imageLowResUrl} width="100%" onClick={() => setShowClipModal(clip)} />
        <div style={{ position: 'absolute', left: '5px', top: '5px' }}>
          {isClipTrimmed && (
            <Label size="mini" circular color="black">
              <Popup trigger={<Icon name="hand scissors" fitted />} content="Clip trimmed" position="top center" />
            </Label>
          )}

          {isClipFeatured && (
            <Label size="mini" circular color="violet">
              <Popup trigger={<Icon name="star" fitted />} content="Clip featured" position="top center" />
            </Label>
          )}
        </div>
        <div style={{ position: 'absolute', left: '5px', top: '5px' }}>
          <Button
            size="mini"
            circular
            icon="heart"
            color={isFavorite ? 'yellow' : 'grey'}
            onClick={() => handleFavorite(id)}
          />
        </div>
        {(showSelectBox || isClipSelected) && (
          <div style={{ position: 'absolute', right: '5px', top: '5px' }}>
            {isClipSelected && (
              <Button
                style={{
                  margin: '0',
                  padding: '0.25rem',
                }}
                circular
                primary
                icon={<Image src={TickIcon} />}
                onClick={() => handleClipSelection(clip)}
              />
            )}
            {!isClipSelected && (
              <Button
                style={{
                  margin: '0',
                  padding: '0.25rem',
                  border: '1px solid white',
                }}
                circular
                icon="tick" // Here we need to have a icon set even we don't see it.
                onClick={() => handleClipSelection(clip)}
              />
            )}
          </div>
        )}
      </div>

      <Grid verticalAlign="middle">
        <Grid.Column width={10}>
          <Feed style={{ margin: '0.5rem 0' }} size="small">
            <Feed.Event>
              <Feed.Label>
                <Initial charCount={2} fontSize={40} useWords name={contributorName} />
              </Feed.Label>
              <Feed.Content>
                <Feed.Date>{moment(createdAt).format('ll')}</Feed.Date>
                <Feed.Summary>
                  <strong>{contributorName}</strong>
                </Feed.Summary>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          <Button
            color={hasUserVoted() ? 'green' : 'grey'}
            icon="thumbs up"
            label={{ basic: true, pointing: 'top', content: votes.length }}
            onClick={() => handleVote(!hasUserVoted())}
          />
        </Grid.Column>
      </Grid>
      <div style={{ marginBottom: '0.5rem' }}>{shortCaption}</div>
      <Label.Group size="tiny">
        {clip.tags.map((tag) => (
          <Label basic color="violet" key={tag} as="a" onClick={() => addFilterTag(tag)}>
            {tag}
          </Label>
        ))}
      </Label.Group>
    </>
  )
}

interface IProps {
  userId: number
  clip: IClip
  clipsSelected: IClip[]
  setShowClipModal: (arg: IClip | null) => void
  handleFavorite: (arg: number) => void
  addFilterTag: (arg: string) => void
  handleClipSelection: (arg: IClip) => void
  votes: number[]
  handleVote: (arg: boolean) => void
}

export default GalleryClipCard
