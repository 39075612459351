import React, { useState } from 'react'

import { Button, Form, Header, Icon, InputOnChangeData } from 'semantic-ui-react'

const MyProfileName = ({ username, isMobile, handleChange, handleSubmit }: IProps) => {
  const [formShown, setFormShown] = useState<boolean>(false)

  return (
    <div>
      {
        !formShown ? (
          <Header as="h4">
            {username} &nbsp; <Icon name="pencil" size="small" color="grey" style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => setFormShown(true)} />
          </Header>
        ) : (
          <Form>
            <Form.Field>
              <label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>Enter your name</div>
                <Icon name="close" link size="large" onClick={() => setFormShown(false)} style={{ marginTop: '-0.8rem' }} />
              </label>
              <Form.Input type="text" name="username" value={username} onChange={handleChange} />
            </Form.Field>

            <Button primary onClick={handleSubmit} fluid={isMobile}>
              Update
            </Button>
          </Form>
        )
      }
    </div>
  )
}

interface IProps {
  username: string
  isMobile: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  handleSubmit: () => void
}

export default MyProfileName
