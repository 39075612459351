import React, { useContext } from 'react'

import { Image, Grid, Header, Button, Modal, Segment, Divider } from 'semantic-ui-react'

import ProjectDuplicationImage from '../../_images/project-duplication.png'
import { GlobalContext } from '../../contexts/GlobalContext'

const ProjectStartProjectModal = ({ goToTemplatePage, setShowModal }: IProps) => {
  const { isMobile } = useContext(GlobalContext)

  console.log('Rendering share link modal')

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>Create a project</Modal.Header>

      <Modal.Content>
        <Segment basic placeholder>
          <Grid stackable relaxed="very" columns={2} textAlign="center">
            {/* Start from scratch */}
            <Grid.Column>
              <Header as="h3">
                Start from a video template
                <Header.Subheader>
                  {`Browse through Vloggi's beautiful templates and choose one to get your next project started.`}
                </Header.Subheader>
              </Header>

              <Button primary style={{ display: 'block', margin: '1rem auto 0 auto' }} onClick={goToTemplatePage}>
                Go to Vloggi template page
              </Button>
            </Grid.Column>
            {/* Duplicate an exiting template */}
            <Grid.Column>
              <Header as="h3">
                Duplicate an existing project
                <Header.Subheader>
                  Click on the bottom three dots to duplicate an existing project, update the details and create a new
                  video series.
                </Header.Subheader>
              </Header>
              <Image src={ProjectDuplicationImage} style={{ margin: '0 auto' }} />
            </Grid.Column>
          </Grid>
          {!isMobile && <Divider vertical>or</Divider>}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  goToTemplatePage: () => void
  setShowModal: (arg: boolean) => void
}

export default ProjectStartProjectModal
