import React from 'react'

import { Icon, Header, Button, Divider, Grid } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'

import PlanTabPhoneFrame from './PlanTabPhoneFrame'

const PlanTabSuccessPagePreview = ({ project }: IProps) => {
  const { successTextDescription, successCTAText } = project
  return (
    <>
      <PlanTabPhoneFrame project={project}>
        <>
          <Grid columns={1} textAlign="center" style={{ height: '100%' }}>
            <Grid.Row verticalAlign="middle"></Grid.Row>
            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <Icon size="huge" name="check circle outline" />
                {successTextDescription && <Header as="h5">{successTextDescription}</Header>}

                <Button size="mini" style={{ margin: '2rem 0 0 0' }}>
                  {successCTAText}
                </Button>
                <Divider horizontal section>
                  Or
                </Divider>

                <Button as="a" size="mini" basic style={{ margin: '0' }}>
                  Upload more clips
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign="bottom">
              <Grid.Column>
                <Header as="h6">Thanks for using the Vloggi platform</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      </PlanTabPhoneFrame>
    </>
  )
}

interface IProps {
  project: IProject
}

export default PlanTabSuccessPagePreview
