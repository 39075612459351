import React, { useContext } from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Menu, Icon, MenuItem } from 'semantic-ui-react'

import { urlsWithoutSideBar } from '../../config/constants'
import { GlobalContext } from '../../contexts/GlobalContext'

// Specifications

const NavBar = (props: INavbarProps) => {
  const { isSideBarShown, isMobile, dispatchGlobalContext } = useContext(GlobalContext)
  const { location } = props

  const toggleSideBar = () => {
    return isSideBarShown
      ? dispatchGlobalContext({ type: 'hide side bar' })
      : dispatchGlobalContext({ type: 'show side bar' })
  }

  if (urlsWithoutSideBar.some((url) => location.pathname.match(url))) return null

  if (!isMobile) return null

  return (
    <header>
      <nav>
        <Menu>
          <Menu.Menu position="right">
            {isMobile && (
              <MenuItem onClick={toggleSideBar}>
                <Icon name="bars" />
              </MenuItem>
            )}
          </Menu.Menu>
        </Menu>
      </nav>
    </header>
  )
}

interface INavbarProps extends RouteComponentProps<any> {}

export default withRouter(NavBar)
