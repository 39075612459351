import React, { useContext, useState } from 'react'

import axios from 'axios'
import { Icon, Input, Menu, Grid, Header, Feed, Image, Rating, DropdownItemProps } from 'semantic-ui-react'
import { Confirm, Modal, Form, Button, Dropdown, List, Label } from 'semantic-ui-react'
import { InputOnChangeData, CheckboxProps } from 'semantic-ui-react'

import threeDotsIcon from '../../../_images/3-dots.svg'
import Data, { IClip } from '../../../api/Data'
import { maxLength } from '../../../config/constants'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { UserContext } from '../../../contexts/UserContext'
import moment from '../../../utils/Moment'
import { downloadFileFromBrowser } from '../../../utils/sharedFunctions'

import CustomLabel from '../../../components/common/custom-label/CustomLabel'
import UpgradeTag from '../../../components/common/UpgradeTag'
import LocationSearchField from '../../../components/modals/LocationSearchField'

import GalleryClipTrimModal from './ManageClipTrimModal'

const WordCount = React.memo(({ word, limit }: { word: number; limit: number }) => {
  return (
    <span style={{ float: 'right' }}>
      {word} / {limit}
    </span>
  )
})

const GalleryClipModal = (props: IProps) => {
  const { isMobile, dispatchGlobalContext } = useContext(GlobalContext)
  const { userProfile } = useContext(UserContext)

  // Props
  const { clip: clipData, displaySetting, loading, updateClip, deleteClip, setShowModal } = props

  // States
  const [clip, setClip] = useState(clipData)
  const [tagValue, setTagValue] = useState('')
  const [activeVideoTab, setActiveVideoTab] = useState('view')
  const [activeFormTab, setActiveFormTab] = useState('contributors')

  const [isDownloading, setIsDownloading] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | null, data: InputOnChangeData) => {
    const { name, value } = data

    setClip({ ...clip, [name]: value })
  }

  const handleDelete = () => {
    setShowConfirmationModal(true)
  }

  // Update state once the user picks up a place and location
  const setLocation = (
    indexToUpdate: number,
    suggestion: { place: string; location: string; lat: number; lng: number }
  ) => {
    const { place, location, lat, lng } = suggestion
    setClip({ ...clip, place, location, lat1: lat, lon1: lng })
  }

  // Reset location
  const resetLocation = () => {
    setClip({ ...clip, place: '', location: '', lat1: null, lon1: null })
  }

  // Add tag
  // const addTag = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const addTag = (e: any) => {
    console.log(e.target)
    // Remove comma from tag value
    const valueSanitized = e.target.value?.replace(/,/g, ' ')
    if (e.key !== 'Enter' || !valueSanitized || clip.tags.includes(valueSanitized)) return

    setClip({ ...clip, tags: [...clip.tags, valueSanitized] })
    setTagValue('')
  }

  // Remove tag
  const removeTag = (value: string) => {
    const newTagArr = clip.tags.filter((tag) => tag !== value)

    setClip({ ...clip, tags: newTagArr })
  }

  // Feature clip
  const handleFeatureClip = (e: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps) => {
    const { checked } = data
    setClip((prevState) => ({ ...prevState, isFeatured: Boolean(checked) }))
  }

  const handleDownloadClip = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    dropDownData: DropdownItemProps
  ) => {
    const type = String(dropDownData.value)

    setIsDownloading(true)

    const { data, error } = await Data.downloadClip(clip.id, type)

    if (error) {
      setIsDownloading(false)

      if (error.response.status === 403) {
        return dispatchGlobalContext({ type: 'show update plan modal' })
      }

      return alert('An error occurred')
    }

    const { signedUrl, fileName } = data

    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' })

      downloadFileFromBrowser({ data: response.data, type: 'mp4', fileName })
    } catch (err) {
      alert('An error occurred')
    }

    setIsDownloading(false)
  }

  if (!clip) return null

  const { contributor, email, caption1, caption2, caption3, createdAt, isFeatured, tags, organization, question } = clip
  const { videoUrl, filmingLocalTime, rating } = clip

  const { videoResolution, cleanClip, rawClip } = userProfile.plan
  console.log(videoResolution, cleanClip, rawClip)
  console.log('Rendering Clip modal', clip)

  // Is user collaborator
  const isUserCollaborator = (): boolean => {
    return userProfile.userAccessRights === 'collaborator'
  }

  return (
    <Modal dimmer="blurring" open closeIcon onClose={() => setShowModal(null)}>
      <Grid stackable columns="equal" style={{ margin: '1rem', height: isMobile ? 'auto' : '650px' }}>
        <Grid.Column textAlign="center" style={{ backgroundColor: '#f5f5f6', borderRadius: '0.5rem' }}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Button.Group>
                  <Button primary={activeVideoTab === 'view'} onClick={() => setActiveVideoTab('view')}>
                    View
                  </Button>
                  <Button primary={activeVideoTab === 'trim'} onClick={() => setActiveVideoTab('trim')}>
                    Trim
                  </Button>
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
            {activeVideoTab === 'trim' && <GalleryClipTrimModal clip={clip} />}
            {activeVideoTab === 'view' && (
              <>
                <Grid.Row>
                  <Grid.Column>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      controls
                      autoPlay
                      tabIndex={-1}
                      style={{ width: '100%', height: 'auto', borderRadius: '0.5rem' }}
                    >
                      <source src={videoUrl} />
                    </video>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <Feed>
                      <Feed.Event>
                        <Feed.Content style={{ textAlign: 'center' }}>
                          <Feed.Date>Date video posted</Feed.Date>
                          <Feed.Summary>{moment(createdAt).format('ll')}</Feed.Summary>
                        </Feed.Content>
                      </Feed.Event>
                    </Feed>
                    <Dropdown text="Download video" button className="secondary" loading={isDownloading}>
                      <Dropdown.Menu style={{ width: '230px' }}>
                        <Dropdown.Item key="1080" value="1080" onClick={handleDownloadClip}>
                          <Header as="h6">
                            HD video (1920x1080)
                            {videoResolution < 2 && (
                              <UpgradeTag
                                text="Upgrade your plan to download this video in full HD"
                                style={{ float: 'right' }}
                              />
                            )}
                          </Header>
                        </Dropdown.Item>
                        <Dropdown.Item key="480" value="480" onClick={handleDownloadClip}>
                          <Header as="h6">SD video (854x480)</Header>
                        </Dropdown.Item>
                        {/* <Dropdown.Divider /> */}
                        <Dropdown.Item key="clean" value="clean" onClick={handleDownloadClip}>
                          <Header as="h6">
                            Clean video (1920x1080)
                            {!cleanClip && (
                              <UpgradeTag
                                text="Upgrade your plan to download this video in clean format"
                                style={{ float: 'right' }}
                              />
                            )}
                          </Header>
                        </Dropdown.Item>
                        <Dropdown.Item key="raw" value="raw" onClick={handleDownloadClip}>
                          <Header as="h6">
                            Raw video (original)
                            {!rawClip && (
                              <UpgradeTag
                                text="Upgrade your plan to download this video in raw format"
                                style={{ float: 'right' }}
                              />
                            )}
                          </Header>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </Grid>
        </Grid.Column>
        <Grid.Column stretched>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as="h2">Clip information</Header>
                <Menu pointing secondary>
                  <Menu.Item active={activeFormTab === 'contributors'} onClick={() => setActiveFormTab('contributors')}>
                    Contributors inputs
                  </Menu.Item>
                  <Menu.Item active={activeFormTab === 'additional'} onClick={() => setActiveFormTab('additional')}>
                    Additional information
                  </Menu.Item>
                </Menu>
                {activeFormTab === 'contributors' && (
                <Form>
                    {/* Contributor name field */}
                    <Form.Field>
                      <label>
                        Name
                        <WordCount word={`${contributor || ''}`.length} limit={maxLength.contributor} />
                      </label>
                      <Form.Input
                        fluid
                        inverted
                        name="contributor"
                        maxLength={maxLength.contributor}
                        value={contributor || ''}
                        onChange={handleChange}
                        disabled={isUserCollaborator()}
                      />
                    </Form.Field>
                    {/* Contributor organization  */}
                    {displaySetting.showOrganization && (
                      <Form.Field>
                        <label>
                          Organization
                          <WordCount word={`${organization || ''}`.length} limit={maxLength.organization} />
                        </label>
                        <Form.Input
                          fluid
                          inverted
                          name="organization"
                          maxLength={maxLength.organization}
                          value={organization || ''}
                          onChange={handleChange}
                          disabled={isUserCollaborator()}
                        />
                      </Form.Field>
                    )}
                    {/* Associated question */}
                    {question && (
                      <Form.Field>
                        <label>
                          Question asked
                          <WordCount word={`${question || ''}`.length} limit={maxLength.question} />
                        </label>
                        <Form.Input
                          fluid
                          inverted
                          name="question"
                          maxLength={maxLength.question}
                          value={question || ''}
                          disabled={true}
                        />
                      </Form.Field>
                    )}
                    {/* Contributor email  */}
                    {displaySetting.showEmail && (
                      <Form.Field>
                        <label>
                          Email
                          <WordCount word={`${email || ''}`.length} limit={maxLength.email} />
                        </label>
                        <Form.Input
                          fluid
                          inverted
                          name="email"
                          maxLength={maxLength.email}
                          value={email || ''}
                          onChange={handleChange}
                          disabled={isUserCollaborator()}
                        />
                      </Form.Field>
                    )}

                    {/* Caption 1 */}
                    {displaySetting.showCaption1 && (
                      <Form.Field>
                        <label>
                          {displaySetting.caption1 || 'Caption 1'}
                          <WordCount word={`${caption1 || ''}`.length} limit={maxLength.caption1} />
                        </label>
                        <Form.Input
                          disabled={!displaySetting.showCaption1 || isUserCollaborator()}
                          inverted
                          name="caption1"
                          placeholder="Caption 1"
                          maxLength={maxLength.caption1}
                          value={caption1 || ''}
                          onChange={handleChange}
                        />
                      </Form.Field>
                    )}
                    {/* Caption 2 */}
                    {displaySetting.showCaption2 && (
                      <Form.Field>
                        <label>
                          {displaySetting.caption2 || 'Caption 2'}

                          <WordCount word={`${caption2 || ''}`.length} limit={maxLength.caption2} />
                        </label>
                        <Form.Input
                          disabled={!displaySetting.showCaption2 || isUserCollaborator()}
                          inverted
                          name="caption2"
                          placeholder="Caption 2"
                          maxLength={maxLength.caption2}
                          value={caption2 || ''}
                          onChange={handleChange}
                        />
                      </Form.Field>
                    )}
                    {/* Caption 3 */}
                    {displaySetting.showCaption3 && (
                      <Form.Field>
                        <label>
                          {displaySetting.caption3 || 'Caption 3'}

                          <WordCount word={`${caption3 || ''}`.length} limit={maxLength.caption3} />
                        </label>
                        <Form.Input
                          disabled={!displaySetting.showCaption3 || isUserCollaborator()}
                          inverted
                          name="caption3"
                          placeholder="Caption 3"
                          maxLength={maxLength.caption3}
                          value={caption3 || ''}
                          onChange={handleChange}
                        />
                      </Form.Field>
                    )}
                    {/* Rating */}
                    {displaySetting.showRating && (
                      <Form.Field>
                        <label>{displaySetting.ratingPrompt}</label>
                        <div style={{ margin: '1rem 0' }}>
                          <Rating
                            icon="star"
                            defaultRating={rating}
                            maxRating={5}
                            size="massive"
                            name="rating"
                            onRate={(e, { name, rating: value }) => handleChange(null, { name, value: String(value) })}
                            disabled={isUserCollaborator()}
                          />
                        </div>
                      </Form.Field>
                    )}
                    {/* Location Field */}
                    {displaySetting.showLocation && (
                      <LocationSearchField
                        index={1}
                        upload={clip}
                        setLocation={setLocation}
                        resetLocation={resetLocation}
                      />
                    )}
                  </Form>
                )}
                {activeFormTab === 'additional' && (
                  <Form>
                    {/* Tags */}
                    <Form.Field>
                      <label>Tags</label>
                      <List horizontal>
                        {tags.map((tag) => (
                          <List.Item key={tag}>
                            <Label>
                              {tag}
                              <Icon name="delete" onClick={() => removeTag(tag)} />
                            </Label>
                          </List.Item>
                        ))}
                        <List.Item>
                          <Input
                            size="mini"
                            style={{ width: '100%' }}
                            name="tag"
                            value={tagValue}
                            onKeyDown={addTag}
                            onChange={(e, { value }) => setTagValue(value)}
                          />
                        </List.Item>
                      </List>
                    </Form.Field>
                    {/* Date shot */}
                    <Form.Field>
                      <label>Date shot</label>
                      <p>{filmingLocalTime || 'unknown'}</p>
                    </Form.Field>
                    {/* Feature clip */}
                    <Form.Field>
                      <CustomLabel>
                        <CustomLabel.Text text="Feature clip" />
                        <CustomLabel.Popup popupContent="You can feature a clip to appear in your contributor page. This would help your contributors to understand what type of video your are expecting from them."></CustomLabel.Popup>
                        <CustomLabel.Checkbox
                          name="isFeatured"
                          checked={isFeatured}
                          onClick={handleFeatureClip}
                        ></CustomLabel.Checkbox>
                      </CustomLabel>
                    </Form.Field>
                  </Form>
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row textAlign="center" verticalAlign="bottom">
              <Grid.Column width={2} floated="right"></Grid.Column>
              <Grid.Column width={12}>
                <Button onClick={() => setShowModal(null)}>Close</Button>
                <Button primary loading={loading} onClick={() => updateClip(clip)}>
                  Update
                </Button>
              </Grid.Column>
              <Grid.Column width={2}>
                <Dropdown
                  trigger={<Image style={{ width: '1.5rem' }} src={threeDotsIcon} />}
                  pointing="top left"
                  icon={null}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item text="Delete clip" onClick={() => handleDelete()} />
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>

      <Confirm
        open={showConfirmationModal}
        header="Clip deletion"
        content="Are you sure you want to delete this clip?"
        confirmButton="Yes"
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => deleteClip(clip.id)}
      />
    </Modal>
  )
}

interface IProps {
  clip: IClip
  displaySetting: any
  loading: boolean
  updateClip: (arg: IClip) => void
  deleteClip: (arg: number) => void
  setShowModal: (arg: IClip | null) => void
}

export default GalleryClipModal
