import React from 'react'

import QRCode from 'qrcode.react'
import { Grid, Header, Button, Segment } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'

const InviteTab = ({ project }: IProps) => {
  const downloadQR = () => {
    const canvas: any = document.getElementById('qr-code')

    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `${project.title.replace(/ /g, '-')}-qr-code.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const { projectUrl } = project

  const projectLinkForQRCode = `${projectUrl}?utm_source=qrcode` // utm is used to differentiate direct form qrcode scan

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Physical event
      </Header>
      <Segment textAlign="center">
        <Grid columns={2} verticalAlign="middle" stackable style={{ height: '200px' }}>
          <Grid.Column>
            <QRCode value={projectLinkForQRCode} />
            {/* TODO: This hidden QR code is used to download a higher resolution version of the QR code above, there is probably another method to use to avoid having to render 2 QRCODEs.  */}
            <QRCode id="qr-code" size={512} value={projectLinkForQRCode} style={{ display: 'none' }} />
          </Grid.Column>
          <Grid.Column>
            <Button compact size="mini" secondary onClick={downloadQR}>
              Download QR code
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  )
}

interface IProps {
  project: IProject
}

export default InviteTab
