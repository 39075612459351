import React, { useContext, useState, useEffect } from 'react'

import { Button, Message, Confirm, Grid, InputOnChangeData, Header } from 'semantic-ui-react'

import Data, { ITeam } from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'

import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'

import MyTeamDetails from './MyTeamDetails'
import MyTeamIntegrations from './MyTeamIntegrations'
import MyTeamManageMembers from './MyTeamManageMembers'
import MyTeamTabAddUserModal from './MyTeamTabAddUserModal'
import MyTeamTabUpdateUserModal from './MyTeamTabUpdateUserModal'
import TeamMember from './TeamMember'

const MyTeamPage = () => {
  const { isMobile, dispatchGlobalContext } = useContext(GlobalContext)
  const { dispatchUserContext } = useContext(UserContext)
  const { userProfile } = useContext(UserContext)

  // States
  const [team, setTeam] = useState<ITeam | null>(null)

  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState<boolean>(false)
  const [showUpdateTeamMemberModal, setShowUpdateTeamMemberModal] = useState<boolean>(false)
  const [updateTeamMember, setUpdateTeamMember] = useState<TeamMember | null>(null)

  const [showConfirmUserDeletion, setShowConfirmDeletion] = useState<number | null>(null)

  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [errorMessageModal, setErrorMessageModal] = useState<string | null>(null)

  // Fetch team
  useEffect(() => {
    const fetchMyTeam = async () => {
      const { data, error } = await Data.getMyTeam()

      if (error) return alert('An error occurred')

      return setTeam(data)
    }

    fetchMyTeam()
  }, [])

  // Reset form
  const resetForm = () => {
    if (successMessage) setSuccessMessage(null)
    if (errorMessage) setErrorMessage(null)
  }

  // Handle team name change
  const handleChangeTeamName = (event: React.ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    resetForm()

    // TODO
    setTeam((prevState) => {
      return prevState && { ...prevState, [name]: value }
    })
  }

  // Submit team name change
  const handleSubmitChangeTeamName = async () => {
    if (team!.teamName.trim() === '') {
      return setErrorMessage('Team name not set')
    }

    resetForm()

    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.updateTeam(team)

    dispatchGlobalContext({ type: 'set loading', payload: false })

    if (error) {
      return setErrorMessage(error.response.data)
    }
    const { teamName } = data

    dispatchUserContext({ type: 'update user profile', payload: { teamName } })

    return setSuccessMessage('Your team has been updated successfully')
  }

  // Add team member
  const handleAddTeamMember = async (newUser: TeamMember) => {
    resetForm()

    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.addUser(newUser)

    dispatchGlobalContext({ type: 'set loading', payload: false })

    if (error) {
      if (error.response.status === 403) {
        return dispatchGlobalContext({ type: 'show update plan modal' })
      }

      return setErrorMessageModal(error.response.data)
    }

    setTeam(data)

    setShowAddTeamMemberModal(false)

    return setSuccessMessage(`An email has been sent to ${newUser.email} with their credentials`)
  }

  // Update team member
  const handleUpdateTeamMember = async (user: TeamMember) => {
    resetForm()

    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.updateUser(user)

    dispatchGlobalContext({ type: 'set loading', payload: false })

    if (error) {
      return setErrorMessage(error.response.data)
    }

    setUpdateTeamMember(null)
    setShowUpdateTeamMemberModal(false)
    setTeam(data)

    return setSuccessMessage('User has been updated')
  }

  const handleRemoveTeamMember = async (userId: number) => {
    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.revokeUserAccess(userId)

    dispatchGlobalContext({ type: 'set loading', payload: false })

    if (error) {
      return setErrorMessage(error.response.data)
    }

    setShowConfirmDeletion(null)

    setSuccessMessage('User has been removed from the team')

    return setTeam(data)
  }

  const handleReactivateUser = async (userId: number) => {
    resetForm()

    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.reactivateUser(userId)

    dispatchGlobalContext({ type: 'set loading', payload: false })

    if (error) {
      if (error.response.status === 403) {
        return dispatchGlobalContext({ type: 'show update plan modal' })
      }
      return alert('An error occurred')
    }

    setSuccessMessage('User has been reactivated')

    return setTeam(data)
  }

  // Is user collaborator
  const isUserCollaborator = (): boolean => {
    return userProfile.userAccessRights === 'collaborator'
  }

  // Wait until data are fetched
  if (!team) return null

  console.log('Rendering Team page', team)

  const { teamName } = team

  return (
    <Section>
      <>
        <Title title="My Team" />
        <>
          {isUserCollaborator() ? (
            <SubSection>
              <Header as="h2">Only admin member can manage team account.</Header>
            </SubSection>
          ) : (
            <SubSection>
              <>
                <Grid>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <MyTeamDetails
                        teamName={teamName}
                        handleChange={handleChangeTeamName}
                        handleSubmit={handleSubmitChangeTeamName}
                        isMobile={isMobile}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <MyTeamManageMembers
                        team={team}
                        setShowAddTeamMemberModal={setShowAddTeamMemberModal}
                        setShowConfirmDeletion={setShowConfirmDeletion}
                        handleReactivateUser={handleReactivateUser}
                        setShowUpdateTeamMemberModal={setShowUpdateTeamMemberModal}
                        setUpdateTeamMember={setUpdateTeamMember}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <MyTeamIntegrations />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                {successMessage && <Message success header="Success" content={successMessage} />}

                {errorMessage && <Message error header="Fail" content={errorMessage} />}

                {/* Modals */}
                {showAddTeamMemberModal && (
                  <MyTeamTabAddUserModal
                    projects={team.projects}
                    setShowModal={setShowAddTeamMemberModal}
                    handleAddTeamMember={handleAddTeamMember}
                    errorMessage={errorMessageModal}
                  />
                )}

                {showUpdateTeamMemberModal && updateTeamMember && (
                  <MyTeamTabUpdateUserModal
                    teamMember={updateTeamMember}
                    projects={team.projects}
                    setShowModal={setShowUpdateTeamMemberModal}
                    handleUpdateTeamMember={handleUpdateTeamMember}
                    errorMessage={errorMessageModal}
                  />
                )}

                {showConfirmUserDeletion && (
                  <Confirm
                    open={!!showConfirmUserDeletion}
                    header="Member deletion"
                    content="Are you sure you want to remove this member from the team?"
                    confirmButton="Yes"
                    onCancel={() => setShowConfirmDeletion(null)}
                    onConfirm={() => handleRemoveTeamMember(showConfirmUserDeletion)}
                  />
                )}
              </>
            </SubSection>
          )}
        </>
      </>
    </Section>
  )
}

export default MyTeamPage
