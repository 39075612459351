import React from 'react'

import { Header, Grid } from 'semantic-ui-react'

import ColorPicker from '../../components/common/ColorPicker'
import FontPickerComponent from '../../components/common/FontPickerComponent'

const MyBrandingPage = (props: IMyBrandingStyleProps) => {
  // Props
  const { primaryFont, primaryColor, secondaryColor } = props
  const { handleChangeFont, handleChangePrimaryColor, handleChangeSecondaryColor } = props

  return (
    <>
      <Grid.Row>
        {/* primary font */}
        <Grid.Column width={10}>
          <Header as="h3">
            Primary font
            <Header.Subheader>
              Your primary font will apply to your landing pages and by default, will be used for any text overlays on
              your video stories.
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <FontPickerComponent activeFontFamily={primaryFont} onChange={handleChangeFont} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {/* primary color */}
        <Grid.Column width={10}>
          <Header as="h3">
            Brand colors
            <Header.Subheader>
              Your brand colors will be used to customise your landing pages and your video templates.
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={2} textAlign="center">
          <p>Vibrant</p>
          <ColorPicker colorHex={primaryColor} onChange={handleChangePrimaryColor} />
        </Grid.Column>
        <Grid.Column width={2} textAlign="center">
          <p>Dark</p>
          <ColorPicker colorHex={secondaryColor} onChange={handleChangeSecondaryColor} />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

interface IMyBrandingStyleProps {
  primaryFont: string
  primaryColor: string
  secondaryColor: string
  handleChangeFont: (arg: { family: string }) => void
  handleChangePrimaryColor: (arg: { hex: string }) => void
  handleChangeSecondaryColor: (arg: { hex: string }) => void
}

export default MyBrandingPage
