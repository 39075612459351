import React from 'react'

import { Button, Divider, Header, Progress } from 'semantic-ui-react'

const TrialProgress = ({ daysLeft = 0, goToPage }: ITrialProgressProps) => {
  const progressPercent = ((14 - daysLeft) / 14) * 100

  return (
    <div style={{ textAlign: 'center' }}>
      <Header as="h6" inverted>
        Trial expires in {daysLeft} days
      </Header>
      <Progress inverted percent={progressPercent} size="tiny" color="orange" style={{ margin: '0 1rem 1rem 1rem' }} />

      <Button
        inverted
        color="orange"
        compact
        size="mini"
        style={{ margin: '0 auto' }}
        onClick={() => goToPage('my-account')}
      >
        Upgrade Now
      </Button>
      <Divider inverted />
    </div>
  )
}

interface ITrialProgressProps {
  daysLeft: number
  goToPage: (arg: string) => void
}

export default TrialProgress
