import React, { useContext, useEffect, useState } from 'react'

import { Button, Segment, Grid, Confirm } from 'semantic-ui-react'

import Data from '../../api/Data'
import { ITeam } from '../../api/Data'
import { teamAvatarSize, teamWatermarkSize } from '../../config/constants'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'

import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'
import ImageUploader from '../../components/modals/ImageUploader'

import BrandingLogos from './BrandingLogos'
import BrandingStyle from './BrandingStyle'

var tinycolor = require('tinycolor2')

const MyBrandingPage = () => {
  const { hasUserMadeChange, dispatchGlobalContext } = useContext(GlobalContext)
  const { dispatchUserContext } = useContext(UserContext)

  // States
  const [team, setTeam] = useState<ITeam | null>(null)

  const [loading, setIsLoading] = useState<boolean>(false)

  const [showLogoUploadModal, setShowLogoUploadModal] = useState(false)
  const [showWatermarkUploadModal, setShowWatermarkUploadModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useEffect(() => {
    const fetchTeam = async () => {
      const { data } = await Data.getMyTeam()

      setTeam(data)
    }
    fetchTeam()
  }, [])

  const handleCancelAvatarUpload = () => {
    setShowLogoUploadModal(false)
  }

  const handleCancelWatermarkUpload = () => {
    setShowWatermarkUploadModal(false)
  }

  // Change team avatar
  const handleSaveTeamAvatar = async (imageBlob: Blob) => {
    const formData = new FormData()

    formData.append('imageBlob', imageBlob)

    setIsLoading(true)

    const { error, data } = await Data.updateTeamAvatar(formData)

    if (error) {
      setIsLoading(false)
      return alert('An error occurred when during image upload')
    }

    const { logoAvatarUrl } = data

    setTeam(data)

    dispatchUserContext({ type: 'update user profile', payload: { logoAvatarUrl } })

    setIsLoading(false)

    return setShowLogoUploadModal(false)
  }

  // Change team watermark
  const handleSaveTeamWatermark = async (imageBlob: Blob) => {
    const formData = new FormData()

    formData.append('imageBlob', imageBlob)

    setIsLoading(true)

    const { error, data } = await Data.updateTeamWatermark(formData)

    if (error) {
      setIsLoading(false)
      return alert('An error occurred when during image upload')
    }

    const { logoWatermarkUrl } = data

    setTeam(data)

    dispatchUserContext({ type: 'update user profile', payload: { logoWatermarkUrl } })

    setIsLoading(false)

    return setShowWatermarkUploadModal(false)
  }

  const handleChangeFont = (newFont: { family: string }) => {
    if (!hasUserMadeChange) dispatchGlobalContext({ type: 'set user has made changes', payload: true })

    const newFontFamily = newFont.family

    setTeam((preState: any) => ({ ...preState, primaryFont: newFontFamily }))
  }

  const handleChangePrimaryColor = (newColor: { hex: string }) => {
    if (!hasUserMadeChange) dispatchGlobalContext({ type: 'set user has made changes', payload: true })

    const hexColor = newColor.hex

    setTeam((preState: any) => ({ ...preState, ...{ primaryColor: hexColor, buttonTextColor: tinycolor(hexColor).getBrightness() >= 200 ? '#000000' : '#FFFFFF' } }))
  }

  const handleChangeSecondaryColor = (newColor: { hex: string }) => {
    if (!hasUserMadeChange) dispatchGlobalContext({ type: 'set user has made changes', payload: true })

    const hexColor = newColor.hex

    setTeam((preState: any) => ({ ...preState, secondaryColor: hexColor }))
  }
  
  const handleSubmitStyle = async () => {
    setIsLoading(true)

    const { data, error } = await Data.updateTeam(team)

    if (error) {
      setIsLoading(false)
      return alert('An error occurred during the update')
    }

    const { primaryFont, primaryColor, secondaryColor, buttonTextColor } = data

    setTeam(data)

    dispatchUserContext({
      type: 'update user profile',
      payload: { primaryFont, primaryColor, secondaryColor, buttonTextColor },
    })

    dispatchGlobalContext({ type: 'set user has made changes', payload: false })

    setIsLoading(false)

    return setShowConfirmModal(true)
  }

  if (!team) return null

  const { logoAvatarUrl, logoWatermarkUrl, primaryFont, primaryColor, secondaryColor } = team

  return (
    <Section>
      <>
        {/* title */}
        <Title title="My Branding">
          <Button compact primary loading={loading} disabled={!hasUserMadeChange} onClick={handleSubmitStyle}>
            Save
          </Button>
        </Title>

        <SubSection>
          <Segment basic style={{ maxWidth: '800px', margin: '0 auto' }}>
            <Grid divided="vertically" verticalAlign="middle" stackable>
              {/* Logos */}
              <BrandingLogos
                logoAvatarUrl={logoAvatarUrl}
                logoWatermarkUrl={logoWatermarkUrl}
                setShowLogoUploadModal={setShowLogoUploadModal}
                setShowWatermarkUploadModal={setShowWatermarkUploadModal}
              />

              {/* Style */}
              <BrandingStyle
                primaryFont={primaryFont}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                handleChangeFont={handleChangeFont}
                handleChangePrimaryColor={handleChangePrimaryColor}
                handleChangeSecondaryColor={handleChangeSecondaryColor}
              />
            </Grid>
          </Segment>
        </SubSection>
        {/* Team avatar */}
        {showLogoUploadModal && (
          <ImageUploader
            inputImageType={teamAvatarSize.inputImageType}
            outputImageType={teamAvatarSize.outputImageType}
            imageIsAvatar={teamAvatarSize.imageIsAvatar}
            imageSize={{ width: teamAvatarSize.width, height: teamAvatarSize.height }}
            loading={loading}
            handleSave={handleSaveTeamAvatar}
            handleCancel={handleCancelAvatarUpload}
          />
        )}

        {/* Team watermark */}
        {showWatermarkUploadModal && (
          <ImageUploader
            inputImageType={teamWatermarkSize.inputImageType}
            outputImageType={teamWatermarkSize.outputImageType}
            imageIsAvatar={teamWatermarkSize.imageIsAvatar}
            imageSize={{ width: teamWatermarkSize.width, height: teamWatermarkSize.height }}
            loading={loading}
            handleSave={handleSaveTeamWatermark}
            handleCancel={handleCancelWatermarkUpload}
          />
        )}
        {/* confirmation modal */}
        {showConfirmModal && (
          <Confirm
            open={showConfirmModal}
            header="Branding Updated"
            content="Note: Your changes will only apply to new projects, existing projects will not be a affected by the changes."
            confirmButton="Ok"
            cancelButton={null}
            onConfirm={() => setShowConfirmModal(false)}
          />
        )}
      </>
    </Section>
  )
}

export default MyBrandingPage
