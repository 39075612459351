import React from 'react'

import { CheckboxProps, Form, Header, Segment } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'

import CustomLabel from '../../../components/common/custom-label/CustomLabel'

const PlanTabFormatsPage = (props: IProps) => {
  // Props
  const { project, handleChange } = props

  const handleCheckboxChange = (e: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps) => {
    const { name, checked } = data
    
    handleChange(e, {
      name: String(name),
      checked:  Boolean(checked),
    })
  }
  
  const { allowRecordVideo, allowUploadFile, allowQrCode } = project

  console.log('Rendering Formats Form', project)

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Video Inputs
      </Header>
      
      <Form>
        <Header as="h5">
          Accept contributor videos from:
        </Header>
        <Segment>
          <Form.Field>
            <CustomLabel>
              <CustomLabel.Text text="Webcam" />
              <CustomLabel.Popup popupContent="Allow contributors to record videos from their webcams" />
              <CustomLabel.Checkbox
                name="allowRecordVideo"
                checked={allowRecordVideo}
                onClick={handleCheckboxChange}
              />
            </CustomLabel>
          </Form.Field>
          <Form.Field>
            <CustomLabel>
              <CustomLabel.Text text="Upload Files" />
              <CustomLabel.Popup popupContent="Allow contributors to upload prerecorded videos from their devices" />
              <CustomLabel.Checkbox
                name="allowUploadFile"
                checked={allowUploadFile}
                onClick={handleCheckboxChange}
              />
            </CustomLabel>
          </Form.Field>
          <Form.Field>
            <CustomLabel>
              <CustomLabel.Text text="Display QR Code" />
              <CustomLabel.Popup popupContent="Allow contributors to upload from another device via a QR code" />
              <CustomLabel.Checkbox
                name="allowQrCode"
                checked={allowQrCode}
                onClick={handleCheckboxChange}
              />
            </CustomLabel>
          </Form.Field>
        </Segment>
      </Form>
    </>
  )
}

interface IProps {
  project: IProject
  handleChange: (
    arg: React.SyntheticEvent,
    { name, checked }: { name: string; checked: boolean }
  ) => void
}

export default PlanTabFormatsPage
