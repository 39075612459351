import React, { FC } from 'react'

import { Dimmer, Header, Button, Image } from 'semantic-ui-react'

import VideoImagePlaceholder from '../../_images/video-upload-placeholder.jpg'

const ClipInError: FC<IProps> = ({ videoId, handleDelete }) => (
  <Dimmer.Dimmable blurring>
    <Image src={VideoImagePlaceholder} rounded style={{ border: '1px solid #e8e8e9' }} />
    <Dimmer active>
      <Header inverted as="h4">
        An error occurred during the processing of this video
      </Header>
      <Header as="h5" inverted>
        <Button onClick={() => handleDelete(videoId)}>Delete Video</Button>
      </Header>
    </Dimmer>
  </Dimmer.Dimmable>
)

interface IProps {
  videoId: number
  handleDelete: (arg: number) => void
}

export default ClipInError
