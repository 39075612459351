import React, { useContext, useEffect, useState } from 'react'

import queryString from 'query-string'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button, Divider, Form, Segment } from 'semantic-ui-react'

import Data from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'
import Moment from '../../utils/Moment'

import StripePricingTableComponent from './MyAccountDetailsStripePricing'
import MyAccountPaymentSuccessModal from './MyAccountPaymentSuccessModal'

const MyAccountDetails = (props: IProps) => {
  const { isMobile } = useContext(GlobalContext)
  const { userProfile } = useContext(UserContext)

  const { location, teamId } = props

  const [showSubSuccessModal, setShowSubSuccessModal] = useState<boolean>(false)

  useEffect(() => {
    const { success } = queryString.parse(location.search)

    if (success) setShowSubSuccessModal(true)
  }, [location.search])

  const submitManageBillingForm = async () => {
    const { data, error } = await Data.createStripeCustomerPortalSession()

    if (error) alert('Oops something went wrong')

    window.location.href = data!.url
  }

  const { subscriptionExpiryDate, plan: currentPlan, isCustomer } = userProfile

  const expirationDateFormatted = subscriptionExpiryDate ? Moment(subscriptionExpiryDate).format('ll') : 'None'

  console.log('Rendering Account Details Page')

  return (
    <>
      {isCustomer ? (
        <Segment>
          <Form>
            <Form.Field>
              <label>My current plan:</label>
              <p>{currentPlan.title}</p>
            </Form.Field>

            <Button primary fluid={isMobile} onClick={submitManageBillingForm}>
              Manage my billing
            </Button>
          </Form>
        </Segment>
      ) : (
        <>
          <p>My current plan: {currentPlan.title}</p>
          <p>Expiration date: {expirationDateFormatted}</p>

          <StripePricingTableComponent clientReferenceId={teamId} />
        </>
      )}

      <Divider></Divider>

      {showSubSuccessModal && (
        <MyAccountPaymentSuccessModal setShowModal={setShowSubSuccessModal}></MyAccountPaymentSuccessModal>
      )}
    </>
  )
}

interface IProps extends RouteComponentProps<any> {
  teamId: number
}

export default withRouter(MyAccountDetails)
