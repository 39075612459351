import React from 'react'

import Checkbox from './CustomLabelCheckbox'
import Popup from './CustomLabelPopup'
import Text from './CustomLabelText'
import WordCount from './CustomLabelWordCount'

const CustomLabel = ({ children }: { children: JSX.Element[] }): JSX.Element => (
  <label style={{ cursor: 'pointer' }}>{children}</label>
)

CustomLabel.Text = Text
CustomLabel.Popup = Popup
CustomLabel.WordCount = WordCount
CustomLabel.Checkbox = Checkbox

export default CustomLabel
