/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState } from 'react'

import { Range, getTrackBackground } from 'react-range'
import 'video-react/dist/video-react.css'
import { Button, Grid, Label } from 'semantic-ui-react'

import Data from '../../../api/Data'
import { IClip } from '../../../api/Data'

const getTrackComponent = (
  props: any,
  children: React.ReactNode,
  startTime: number,
  playerCurrentTime: number,
  duration: number
) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
    <div
      // eslint-disable-next-line react/destructuring-assignment
      onMouseDown={props.onMouseDown}
      // eslint-disable-next-line react/destructuring-assignment
      onTouchStart={props.onTouchStart}
      style={{
        // eslint-disable-next-line react/destructuring-assignment
        ...props.style,
        height: '36px',
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        // eslint-disable-next-line react/destructuring-assignment
        ref={props.ref}
        style={{
          height: '6px',
          width: '100%',
          borderRadius: '3px',
          background: getTrackBackground({
            values: [startTime, playerCurrentTime],
            colors: ['#ccc', '#181667', '#ccc'],
            min: 0,
            max: duration,
          }),
          alignSelf: 'center',
        }}
      >
        {children}
      </div>
    </div>
  </>
)

const getThumbComponents = (index: number, props: any, isDragged: boolean, rangeValues: number[]) => (
  <>
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={{
        // eslint-disable-next-line react/destructuring-assignment
        ...props.style,
        height: '30px',
        width: '30px',
        borderRadius: '4px',
        backgroundColor: '#181667',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 6px #AAA',
      }}
    >
      <Label
        basic
        pointing="above"
        style={{
          position: 'absolute',
          top: '26px',
          fontSize: '14px',
          padding: '4px',
          borderRadius: '4px',
          backgroundColor: 'white',
        }}
      >
        {rangeValues[index].toFixed(1)}
      </Label>

      <div
        style={{
          height: '16px',
          width: '4px',
          backgroundColor: isDragged ? 'white' : 'white',
        }}
      />
    </div>
  </>
)

const ClipTrim = ({ clip }: IProps) => {
  // Refs
  const playerRef = useRef<HTMLVideoElement | null>(null)

  // States
  const [rangeValues, setRangeValues] = useState<number[] | null>(null)
  const [playerCurrentTime, setPlayerCurrentTime] = useState<number>(0)
  const [alreadyMounted, setAlreadyMounted] = useState<boolean>(false)

  const handlePlay = () => {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const [startTime, endTime] = rangeValues!
    playerRef.current!.currentTime = startTime
    playerRef.current!.play()
  }

  const handleStopPlayer = () => {
    return playerRef.current!.pause()
  }

  const handleTrim = async () => {
    const [startTime, endTime] = rangeValues!

    await Data.updateClip({ ...clip, trim: [startTime, endTime] })
    window.location.reload()
  }

  const handleRecoverOriginal = async () => {
    await Data.updateClip({ ...clip, trim: null })
    window.location.reload()
  }

  // Stop the player at end time selected by the user
  useEffect(() => {
    if (playerCurrentTime > (rangeValues! && rangeValues[1])) playerRef.current!.pause()
  }, [playerCurrentTime, playerRef, rangeValues])

  // Set event listeners
  useEffect(() => {
    // Set initial range values (video start and video end)
    const addLoadEvent = () => {
      playerRef.current!.addEventListener(
        'loadeddata',
        function () {
          console.log('video is loaded')
          setRangeValues(clip.trim || [0, playerRef.current!.duration])
        },
        false
      )
    }

    // Add listener to update player current time as it plays
    const addTimeUpdateEvent = () => {
      playerRef.current!.addEventListener(
        'timeupdate',
        function checkVideoEnd() {
          setPlayerCurrentTime(playerRef.current!.currentTime)
        },
        false
      )
    }

    if (!alreadyMounted) {
      addLoadEvent()
      addTimeUpdateEvent()
      setAlreadyMounted(true) // Trick to only run this function once
    }
  }, [alreadyMounted, playerRef])

  // Update range values and player current time based on user range change
  const handleRangeChange = (values: number[]) => {
    const [startTime, endTime] = rangeValues!
    const [newStartTime, newEndTime] = values

    if (newStartTime !== startTime) playerRef.current!.currentTime = newStartTime
    else if (newEndTime !== endTime) playerRef.current!.currentTime = newEndTime

    setRangeValues(values)
  }

  console.log('Rendering Clip Trim Modal', clip, playerRef, rangeValues)

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            poster={clip.imageLowResUrl}
            style={{ width: '100%', height: 'auto', borderRadius: '0.5rem' }}
            ref={playerRef}
          >
            <source src={clip.videoCleanUrl} type="video/mp4" />
          </video>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {rangeValues && (
            <Grid textAlign="center" padded="horizontally">
              <Grid.Column width={3} textAlign="left">
                {playerRef.current!.paused ? (
                  <Button circular icon="play" onClick={handlePlay} />
                ) : (
                  <Button circular icon="stop" onClick={handleStopPlayer} />
                )}
              </Grid.Column>

              <Grid.Column width={13}>
                <Range
                  values={rangeValues}
                  step={0.1}
                  min={0}
                  max={playerRef.current!.duration}
                  onChange={handleRangeChange}
                  renderTrack={({ props, children }) =>
                    getTrackComponent(props, children, rangeValues[0], playerCurrentTime, playerRef.current!.duration)
                  }
                  renderThumb={({ index, props, isDragged }) =>
                    getThumbComponents(index, props, isDragged, rangeValues)
                  }
                />
              </Grid.Column>
            </Grid>
          )}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row textAlign="center">
        <Grid.Column>
          <Button primary onClick={handleTrim}>
            Trim
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row textAlign="center">
        <Grid.Column>
          <Button secondary disabled={clip.trim === null} onClick={handleRecoverOriginal}>
            Recover Original
          </Button>
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

interface IProps {
  clip: IClip
}
export default ClipTrim
