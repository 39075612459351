import React, { useState } from 'react'

import { Button, Modal, Form, Message } from 'semantic-ui-react'

import { ITheme } from '../../api/Data'
import { maxLength } from '../../config/constants'
import Moment from '../../utils/Moment'

import CustomLabel from '../../components/common/custom-label/CustomLabel'

const ThemeProjectCreationModal = ({ theme, setShowModal, handleCreateProject }: IProps) => {
  const [projectName, setProjectName] = useState(`${theme.title} - ${Moment().format('ll')}`)

  return (
    <Modal dimmer="blurring" open size="tiny" onClose={() => setShowModal(null)}>
      <Modal.Header>Name your video project</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <CustomLabel>
              <CustomLabel.Text text="Project name" />
              <CustomLabel.WordCount word={projectName} limit={maxLength.name}></CustomLabel.WordCount>
            </CustomLabel>

            <Form.Input
              maxLength={maxLength.title}
              placeholder="Type your project name here..."
              value={projectName}
              onChange={(e, { value }) => setProjectName(value)}
            ></Form.Input>
          </Form.Field>
          <Message success header="Contact request submitted" content="A member of our team will be in touch soon" />
          <Message
            error
            header="Error during submission"
            content="Something went wrong your contact request submission, please try again"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(null)}>Close</Button>

        <Button primary onClick={() => handleCreateProject(projectName, theme.id)}>
          Create project
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  theme: ITheme
  setShowModal: (args: ITheme | null) => void
  handleCreateProject: (arg1: string, arg2: number) => void
}

export default ThemeProjectCreationModal
