import React from 'react'

import { Menu, Image, Popup } from 'semantic-ui-react'

const MenuItem = ({ isSmallSideBar, activeItem, page, name, icon, goToPage }: IProps) => {
  const isActive = activeItem === page

  const menuItemColor = isActive ? 'violet' : 'blue'

  return isSmallSideBar ? (
    <Popup
      position="right center"
      content={name}
      trigger={
        <Menu.Item as="a" active={isActive} onClick={() => goToPage(`/${page}`)} color={menuItemColor}>
          <Image width="25px" src={icon} wrapped />
        </Menu.Item>
      }
    />
  ) : (
    <Menu.Item as="a" active={isActive} onClick={() => goToPage(`/${page}`)} color={menuItemColor}>
      <Image width="25px" src={icon} wrapped /> <span style={{ marginLeft: '2rem' }}>{name}</span>
    </Menu.Item>
  )
}

interface IProps {
  isSmallSideBar: boolean
  activeItem: string
  page: string
  name: string
  icon: string
  goToPage(arg: string): void
}

export default MenuItem
