import React from 'react'

import { Rnd } from 'react-rnd'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import {
  renderOpeningClosingBackgroundHTML,
  renderTextElementHTML,
  renderImageElementHTML,
  renderClipBackgroundImageHTML,
  renderRatingElementHTML,
} from '../../components/common/ThemeElement'

// Handle style
const handleClass = { width: '10px', height: '10px', backgroundColor: 'white', borderRadius: '50%' }
const HandleStyles = {
  bottomLeft: { ...handleClass, left: '-5px', bottom: '-5px' },
  bottomRight: { ...handleClass, right: '-5px', bottom: '-5px' },
  topLeft: { ...handleClass, left: '-5px', top: '-5px' },
  topRight: { ...handleClass, right: '-5px', top: '-5px' },
  top: { ...handleClass, left: '50%', top: '-5px' },
  bottom: { ...handleClass, left: '50%', bottom: '-5px' },
  left: { ...handleClass, marginTop: '-5px', left: '-5px', top: '50%' },
  right: { ...handleClass, marginTop: '-5px', right: '-5px', top: '50%' },
}

const DesignerTabSectionCanva = (props) => {
  // Props
  const { section, sectionName, selectedIndex, setSelectedIndex, updateThemeSection } = props
  const { setActiveToolBox, gridSize, canvaWidth, canvaHeight, canvaRatio } = props

  // Move elements
  const updateElementPosition = (e, data) => {
    const index = Number(data.node.id)

    const { lastX, lastY } = data

    const { x, y } = section.elements[index]

    // Do not update element position if has not moved enough
    if (
      Math.ceil(Math.abs(x * canvaRatio - lastX)) < gridSize * canvaRatio &&
      Math.ceil(Math.abs(y * canvaRatio - lastY)) < gridSize * canvaRatio
    )
      return

    const newThemeSection = section

    newThemeSection.elements[index].x = Math.floor(lastX / canvaRatio)
    newThemeSection.elements[index].y = Math.floor(lastY / canvaRatio)

    updateThemeSection(newThemeSection)
  }

  // Resize element
  const resizeElement = (e, direction, ref, delta, position) => {
    const index = Number(ref.id)
    const { width, height } = ref.style

    const newWidth = Number(width.replace('px', ''))
    const newHeight = Number(height.replace('px', ''))

    const newThemeSection = { ...section }

    newThemeSection.elements[index].width = Math.floor(newWidth / canvaRatio)
    newThemeSection.elements[index].height = Math.floor(newHeight / canvaRatio)

    newThemeSection.elements[index].x = Math.floor(position.x / canvaRatio)
    newThemeSection.elements[index].y = Math.floor(position.y / canvaRatio)

    updateThemeSection(newThemeSection)
  }

  // Select element
  const selectElement = (e) => {
    e.stopPropagation()

    const newElementIndex = Number(e.target?.id)

    // Return if element is already selected
    if (selectedIndex === newElementIndex) return
    // Return if newElementIndex is undefined
    if (!newElementIndex && newElementIndex !== 0) return

    const newElementType = section.elements[newElementIndex].type

    setSelectedIndex(newElementIndex)
    setActiveToolBox(newElementType === 'text' ? 'font' : 'opacity')
  }

  // Necessary otherwise the selectElement function does not work
  const handleClick = (e) => {
    e.stopPropagation()
  }

  if (!section) return false

  console.log('Rendering Designer Canva', section, selectedIndex)

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        style={{
          width: `${canvaWidth}px`,
          height: `${canvaHeight}px`,
          position: 'relative',
          padding: '0',
          margin: '0',
        }}
        onClick={() => {
          setSelectedIndex(null)
        }}
      >
        {['opening', 'closing'].includes(sectionName) && (
          <>{renderOpeningClosingBackgroundHTML(section, canvaHeight, canvaWidth)}</>
        )}

        {['vloggi'].includes(sectionName) && <>{renderClipBackgroundImageHTML()}</>}

        {section.elements.map((element, index) => (
          <Rnd
            key={index}
            id={index}
            dragGrid={[gridSize * canvaRatio, gridSize * canvaRatio]}
            resizeGrid={[gridSize * canvaRatio, gridSize * canvaRatio]}
            resizeHandleStyles={HandleStyles}
            bounds="parent"
            lockAspectRatio={element.type === 'image' ? element.width / element.height : false}
            enableResizing={selectedIndex === index}
            style={{ display: 'table', outline: `${selectedIndex === index ? '1px dashed black' : ''}` }}
            size={{ width: element.width * canvaRatio, height: element.height * canvaRatio }}
            position={{ x: element.x * canvaRatio, y: element.y * canvaRatio }}
            onDragStop={updateElementPosition}
            onResizeStop={resizeElement}
            onMouseDown={selectElement}
            onClick={handleClick}
          >
            {element.type === 'text' && renderTextElementHTML(sectionName, element, index, canvaRatio)}

            {element.type === 'image' && renderImageElementHTML(element, index, canvaRatio)}

            {element.type === 'rating' && renderRatingElementHTML(element, index, canvaRatio)}
          </Rnd>
        ))}
      </div>
    </>
  )
}

export default DesignerTabSectionCanva
