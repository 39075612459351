import React, { FC, useState } from 'react'

import { SketchPicker } from 'react-color'
import { Button } from 'semantic-ui-react'

const ColorPicker: FC<IProps> = ({ colorHex, onChange }) => {
  const [showColorPicker, setShowColorPicker] = useState(false)

  return (
    <>
      <Button
        fluid
        style={{
          display: 'inline',
          maxWidth: '4rem',
          height: '3rem',
          margin: '0',
          padding: '1rem 1rem 1rem',
          backgroundColor: `${colorHex}`,
        }}
        role="button"
        onClick={() => setShowColorPicker(true)}
      />
      {showColorPicker && (
        <div
          style={{
            position: 'absolute',
            top: '-155px',
            left: '-230px',
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            role="button"
            onClick={() => setShowColorPicker(false)}
          />
          <SketchPicker onChange={onChange} color={colorHex} disableAlpha />
        </div>
      )}
    </>
  )
}

interface IProps {
  colorHex: string
  onChange: (arg: { hex: string }) => void
}

export default ColorPicker
