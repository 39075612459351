import React, { useContext, useState } from 'react'

import queryString from 'query-string'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Grid, Form, Button, Message, Image } from 'semantic-ui-react'
import { Header, List, Icon, InputOnChangeData } from 'semantic-ui-react'

import vloggiLogoWhite from '../../_images/vloggi-logo-white.png'
import Data from '../../api/Data'
import { MARKETING_WEBSITE_URL } from '../../config/environments'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'
import Auth from '../../utils/Auth'

import ExternalLink from '../../components/common/ExternalLink'
import PasswordInput from '../../components/common/PasswordInput'

const formInitialState: IForm = {
  username: '',
  email: '',
  password: '',
  couponCode: null,
}

const RegisterPage = ({ history, location }: IProps) => {
  const { isMobile } = useContext(GlobalContext)
  const { dispatchUserContext } = useContext(UserContext)

  const { stripeSessionId } = queryString.parse(location.search)

  const [fields, setFields] = useState<IForm>(formInitialState)
  const [missingFields, setMissingFields] = useState<string[]>([])
  const [showPromoCodeField, setShowPromoCodeField] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setErrorMessage(null)
    setMissingFields([])

    const { name, value } = data

    setFields((prevState) => ({ ...prevState, [name]: value }))
  }

  const fetchUserAndTeam = async () => {
    const { data } = await Data.getMyProfile()

    dispatchUserContext({ type: 'update user profile', payload: data })
  }

  const validateForm = () => {
    const missingFieldsArr = []
    if (!fields.username) missingFieldsArr.push('username')
    if (!fields.email) missingFieldsArr.push('email')
    if (!fields.password) missingFieldsArr.push('password')

    return missingFieldsArr
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const missingFields = validateForm()

    if (missingFields.length > 0) {
      setMissingFields(missingFields)
      return setErrorMessage('Missing fields')
    }

    setLoading(true)

    const { data, error } = await Data.register({ ...fields, ...{ stripeSessionId: stripeSessionId } })

    setLoading(false)

    if (error) {
      if (error.response.data === 'Password too weak') {
        return setErrorMessage('Password too weak')
      }

      const message = error.response ? error.response.data : error.message

      return setErrorMessage(message)
    }

    sendEmailToReferralPlatform(fields.email)

    const { token } = data

    Auth.setToken(token)

    await fetchUserAndTeam()

    history.push(`/projects?newUser=true`)
  }

  // Send FirstPromoter email of new sign-up (if referred)
  const sendEmailToReferralPlatform = (email: string) => {
    try {
      window.fpr('referral', { email })
    } catch (err) {
      console.error('Could not send email to referral platform', err)
    }
  }

  const togglePromoCodeField = (): void => {
    setShowPromoCodeField((prevState) => !prevState)
  }

  return (
    <Grid stretched style={{ height: '100vh', margin: 0 }}>
      <Grid.Column
        only="computer"
        width={7}
        style={{ backgroundImage: 'linear-gradient(to bottom, #12165b, #121360)' }}
      >
        <Grid centered verticalAlign="middle">
          <Grid.Column width={14}>
            <Image width="200" src={vloggiLogoWhite} alt="vloggi" />
            <Header inverted as="h2">
              Collect videos from anyone, anywhere, at any time.
            </Header>
            {!stripeSessionId && (
              <List relaxed="very" size="large" style={{ margin: '4rem 1rem', color: 'white' }}>
                <List.Item>
                  <List.Icon name="play" size="small" style={{ marginRight: '2rem' }} verticalAlign="middle" />
                  No credit card required
                </List.Item>
                <List.Item>
                  <List.Icon name="play" size="small" style={{ marginRight: '2rem' }} verticalAlign="middle" />
                  Start with our free plan
                </List.Item>
                <List.Item>
                  <List.Icon name="play" size="small" style={{ marginRight: '2rem' }} verticalAlign="middle" />
                  Easy setup
                </List.Item>
              </List>
            )}
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column mobile={16} computer={9} style={{ backgroundColor: isMobile ? '#12165b' : 'white' }}>
        <Grid padded>
          <Grid.Row>
            <Grid.Column textAlign="right">
              {!stripeSessionId && (
                <p style={{ color: isMobile ? 'white' : 'black' }}>
                  Already have an account? &nbsp;{' '}
                  <Button as={Link} to="/login" primary size="mini" style={{ margin: 0 }}>
                    Log in
                  </Button>
                </p>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered verticalAlign="middle">
            <Grid.Column mobile={16} computer={10} widescreen={6}>
              {!!stripeSessionId && (
                <div style={{ marginBottom: '4rem', textAlign: 'center' }}>
                  <Icon name="check circle outline" size="huge" color="orange" />
                  <Header inverted={isMobile} as="h2">
                    Thanks for subscribing
                  </Header>
                  <p style={{ color: isMobile ? 'white' : 'black' }}>
                    A payment to VLOGGI SUBSCRIPTION will appear on your statement
                  </p>
                </div>
              )}
              {isMobile ? (
                <>
                  <Image src={vloggiLogoWhite} size="small" style={{ margin: 'auto' }} />
                  {!!stripeSessionId ? (
                    <Header inverted as="h4">
                      <strong>Complete your account setup</strong>
                    </Header>
                  ) : (
                    <Header inverted as="h4">
                      Hello.
                      <br />
                      <strong>To start collecting videos, please sign up...</strong>
                    </Header>
                  )}
                </>
              ) : (
                <>
                  {!!stripeSessionId ? (
                    <Header as="h2">
                      <strong>Complete your account setup</strong>
                    </Header>
                  ) : (
                    <Header as="h2">
                      Hello.
                      <br />
                      <strong>To start collecting videos, please sign up...</strong>
                    </Header>
                  )}
                </>
              )}
              <Form error onSubmit={handleSubmit} inverted={isMobile}>
                <Form.Group widths="equal">
                  {/* name */}
                  <Form.Field required>
                    <label>Full Name</label>
                    <Form.Input
                      fluid
                      icon="user"
                      iconPosition="left"
                      type="text"
                      name="username"
                      value={fields.username}
                      placeholder="e.g. John Smith"
                      onChange={handleChange}
                      autoComplete="off"
                      error={missingFields.includes('username')}
                    />
                  </Form.Field>

                  {/* email */}
                  <Form.Field required>
                    <label>Email</label>
                    <Form.Input
                      fluid
                      icon="mail"
                      iconPosition="left"
                      type="email"
                      name="email"
                      value={fields.email}
                      placeholder="e.g. johnsmith@js.com"
                      onChange={handleChange}
                      autoComplete="off"
                      error={missingFields.includes('email')}
                    />
                  </Form.Field>
                </Form.Group>

                {/* password */}
                <Form.Field required>
                  <label>Password</label>
                  <PasswordInput
                    name="password"
                    value={fields.password}
                    placeholder="10+ characters strong password"
                    handleChange={handleChange}
                    error={missingFields.includes('password')}
                  />
                </Form.Field>
                {fields.password.length > 0 && (
                  <Form.Field>
                    <label>
                      <p style={{ margin: '0.5rem 0' }}>
                        {fields.password.length >= 10 ? (
                          <span style={{ color: '#9d9d9d' }}>
                            <Icon name="check" />
                            At least 10 characters
                          </span>
                        ) : (
                          <span>
                            <Icon name="exclamation circle" style={{ color: 'red' }} />
                            At least 10 characters
                          </span>
                        )}
                      </p>
                      <p style={{ margin: '0.5rem 0' }}>
                        {/[A-Z]/.test(fields.password) ? (
                          <span style={{ color: '#9d9d9d' }}>
                            <Icon name="check" />
                            At least 1 uppercase letter
                          </span>
                        ) : (
                          <span>
                            <Icon name="exclamation circle" style={{ color: 'red' }} />
                            At least 1 uppercase letter
                          </span>
                        )}
                      </p>
                      <p style={{ margin: '0.5rem 0' }}>
                        {/[!@#$%^&]/.test(fields.password) ? (
                          <span style={{ color: '#9d9d9d' }}>
                            <Icon name="check" />
                            At least 1 of the symbols !@#$%^&amp;
                          </span>
                        ) : (
                          <span>
                            <Icon name="exclamation circle" style={{ color: 'red' }} />
                            At least 1 of the symbols !@#$%^&amp;
                          </span>
                        )}
                      </p>
                      <p style={{ margin: '0.5rem 0' }}>
                        {/[0-9]/.test(fields.password) ? (
                          <span style={{ color: '#9d9d9d' }}>
                            <Icon name="check" />
                            At least 1 number
                          </span>
                        ) : (
                          <span>
                            <Icon name="exclamation circle" style={{ color: 'red' }} />
                            At least 1 number
                          </span>
                        )}
                      </p>
                    </label>
                  </Form.Field>
                )}
                {errorMessage === 'Password too weak' && (
                  <Message error content="Password does not meet requirements" style={{ marginBottom: '1.5rem' }} />
                )}
                <Form.Field>
                  <label>
                    By creating an account you agree to our
                    <ExternalLink url={`${MARKETING_WEBSITE_URL}/user-terms`}> User Terms </ExternalLink>
                    and understand our
                    <ExternalLink url={`${MARKETING_WEBSITE_URL}/privacy`}> Privacy Policy.</ExternalLink>
                  </label>
                </Form.Field>

                {/* promo code */}
                <Form.Field style={{ margin: '2rem 0' }}>
                  <Form.Checkbox
                    label="I have a promo code"
                    checked={showPromoCodeField}
                    onChange={togglePromoCodeField}
                  />
                  {showPromoCodeField && (
                    <Form.Input
                      fluid
                      icon="fire"
                      iconPosition="left"
                      type="text"
                      name="couponCode"
                      value={fields.couponCode}
                      onChange={handleChange}
                      placeholder="Type here... "
                      autoComplete="off"
                    />
                  )}
                </Form.Field>

                {errorMessage !== 'Password too weak' && <Message error style={{ marginBottom: '1.5rem' }}>{errorMessage}</Message>}

                <Button loading={loading} type="submit" fluid color="orange" style={{ margin: '1rem 0' }}>
                  Get started!
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

interface IForm {
  username: string
  email: string
  password: string
  couponCode: string | null
}

interface IProps extends RouteComponentProps<any> {}

export default RegisterPage
