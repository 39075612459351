import React, { useContext, useState, useEffect } from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { InputOnChangeData } from 'semantic-ui-react'

// Specs
import Data from '../../api/Data'
import { IUser, ITeam, IBrand, ITheme, IProject } from '../../api/Data'
import { teamAvatarSize, teamWatermarkSize } from '../../config/constants'
import { UserContext } from '../../contexts/UserContext'
import { extractDomain, getAvailableFonts, wrapBrand } from '../../utils/sharedFunctions'

import ImageUploader from '../../components/modals/ImageUploader'

import BrandingModal from './BrandingModal'
import ProjectLinkModal from './ProjectLinkModal'
import ProjectNameModal from './ProjectNameModal'
import ProjectThemeModal from './ProjectThemeModal'
import StyleModal from './StyleModal'
import SubscriptionConfirmationModal from './SubscriptionConfirmationModal'
import WelcomeModal from './WelcomeModal'

var tinycolor = require('tinycolor2')

const OnboardingPage = ({ history }: IProps) => {
  const { userProfile, dispatchUserContext } = useContext(UserContext)

  // States
  const [step, setStep] = useState(userProfile.isCustomer ? 0 : 1)
  const [user] = useState<IUser>(userProfile)
  const [team, setTeam] = useState<ITeam | null>(null)
  const [brand, setBrand] = useState<IBrand | null>(null)
  const [themes, setThemes] = useState<ITheme[] | null>(null)
  const [projectName, setProjectName] = useState<string>('')
  const [projectTheme, setProjectTheme] = useState<ITheme | null>(null)
  const [firstProject, setFirstProject] = useState<IProject | null>(null)
  const [loading, setIsLoading] = useState(false)
  const [showLogoUploadModal, setShowLogoUploadModal] = useState(false)
  const [showWatermarkUploadModal, setShowWatermarkUploadModal] = useState(false)

  useEffect(() => {
    const fetchTeam = async () => {
      const { data } = await Data.getMyTeam()

      setTeam(data)
    }
    fetchTeam()
  }, [])

  useEffect(() => {
    const fetchThemes = async () => {
      const { data } = await Data.getThemes()

      setThemes(data)
    }
    fetchThemes()
  }, [])

  // Change team logo
  const handleSaveTeamAvatar = async (imageBlob: Blob) => {
    const formData = new FormData()

    formData.append('imageBlob', imageBlob)

    setIsLoading(true)

    const { error, data } = await Data.updateTeamAvatar(formData)

    if (error) {
      setIsLoading(false)
      return alert('An error occurred')
    }

    const { logoAvatarUrl } = data

    // Necessary to display the new team logo
    setTeam((preState: any) => ({ ...preState, logoAvatarUrl: logoAvatarUrl }))

    dispatchUserContext({ type: 'update user profile', payload: { logoAvatarUrl } })

    setIsLoading(false)

    setShowLogoUploadModal(false)

    return true
  }

  // Change team watermark
  const handleSaveTeamWatermark = async (imageBlob: Blob) => {
    const formData = new FormData()

    formData.append('imageBlob', imageBlob)

    setIsLoading(true)

    const { error, data } = await Data.updateTeamWatermark(formData)

    if (error) {
      setIsLoading(false)
      return alert('An error occurred')
    }

    const { logoWatermarkUrl } = data

    setTeam((preState: any) => ({ ...preState, logoWatermarkUrl: logoWatermarkUrl }))

    dispatchUserContext({ type: 'update user profile', payload: { logoWatermarkUrl } })

    setIsLoading(false)

    setShowWatermarkUploadModal(false)

    return true
  }

  const handleChangeTeamName = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value } = data

    setTeam((preState: any) => ({ ...preState, teamName: value }))
  }

  const handleChangeOrganisationWebsite = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value } = data

    setTeam((preState: any) => ({ ...preState, organisationWebsite: value.trim() }))
  }

  const handleChangeFont = (newFont: { family: string }) => {
    const newFontFamily = newFont.family

    setTeam((preState: any) => ({ ...preState, primaryFont: newFontFamily }))
  }

  const handleChangePrimaryColor = (newColor: { hex: string }) => {
    const hexColor = newColor.hex

    setTeam((preState: any) => ({
      ...preState,
      ...{
        primaryColor: hexColor,
        buttonTextColor: tinycolor(hexColor).getBrightness() >= 200 ? '#000000' : '#FFFFFF',
      },
    }))
  }

  const handleChangeSecondaryColor = (newColor: { hex: string }) => {
    const hexColor = newColor.hex

    setTeam((preState: any) => ({ ...preState, secondaryColor: hexColor }))
  }

  const handleCancelAvatarUpload = () => {
    setShowLogoUploadModal(false)
  }

  const handleCancelWatermarkUpload = () => {
    setShowWatermarkUploadModal(false)
  }

  const handleChangeProjectName = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value } = data

    setProjectName(value)
  }

  const handleChangeProjectTheme = (t: ITheme) => {
    setProjectTheme(t)
  }

  const stepBack = () => {
    setStep((prevState) => prevState - 1)
  }

  const stepForward = () => {
    setStep((prevState) => prevState + 1)
  }

  const handleSaveBranding = async () => {
    setIsLoading(true)

    let newTeamData: any = {}
    let brandData: any = null

    // Brandfetch integration
    if (!!team!.organisationWebsite) {
      const domain = extractDomain(team!.organisationWebsite)

      if (!!domain) {
        const { data, error } = await Data.getBrand(domain)

        if (!error) {
          const availableFonts = await getAvailableFonts()

          brandData = wrapBrand(data)

          newTeamData = {
            ...newTeamData,
            ...{
              organisationWebsite: brandData.organisationWebsite || team!.organisationWebsite,
              organisationDescription: brandData.organisationDescription || team!.organisationDescription,
              primaryFont: !!availableFonts.find((f: any) => f.family === brandData.primaryFont)
                ? brandData.primaryFont
                : team!.primaryFont,
              primaryColor: brandData.primaryColor || team!.primaryColor,
              secondaryColor: brandData.secondaryColor || team!.secondaryColor,
              buttonTextColor:
                tinycolor(brandData.primaryColor || team!.primaryColor).getBrightness() >= 200 ? '#000000' : '#FFFFFF',
            },
          }

          if (!!brandData.logoAvatarUrl) {
            const blob = await fetch(brandData.logoAvatarUrl).then((r) => r.blob())

            if (!!blob) {
              handleSaveTeamAvatar(blob)
            }
          }

          if (!!brandData.logoWatermarkUrl) {
            const blob = await fetch(brandData.logoWatermarkUrl).then((r) => r.blob())

            if (!!blob) {
              handleSaveTeamWatermark(blob)
            }
          }
        }
      }
    }

    setTeam({ ...team, ...newTeamData })
    setBrand(brandData)

    const { data, error } = await Data.updateTeam({ ...team, ...newTeamData })

    if (error) {
      setIsLoading(false)
      return alert('An error occurred during the update')
    }

    const {
      teamName,
      organisationWebsite,
      organisationDescription,
      primaryFont,
      primaryColor,
      secondaryColor,
      buttonTextColor,
      logoAvatarUrl,
      logoWatermarkUrl,
    } = data

    dispatchUserContext({
      type: 'update user profile',
      payload: {
        teamName,
        organisationWebsite,
        organisationDescription,
        primaryFont,
        primaryColor,
        secondaryColor,
        buttonTextColor,
        logoAvatarUrl,
        logoWatermarkUrl,
      },
    })

    setIsLoading(false)

    stepForward()
  }

  const handleSaveStyle = async () => {
    setIsLoading(true)

    const { data, error } = await Data.updateTeam(team)

    if (error) {
      setIsLoading(false)
      return alert('An error occurred during the update')
    }

    const { primaryFont, primaryColor, secondaryColor, buttonTextColor } = data

    dispatchUserContext({
      type: 'update user profile',
      payload: { primaryFont, primaryColor, secondaryColor, buttonTextColor },
    })

    setIsLoading(false)

    stepForward()
  }

  const handleSaveProject = async () => {
    setIsLoading(true)

    const { data, error } = await Data.createProject({
      name: projectName,
      description: `Dear ${userProfile.teamName} Community, We want to showcase your video content. Use this video form to upload clips from your device or record responses to our questions.\n\nWhen you're ready, hit GET STARTED to contribute to the project "${projectName}".`,
      rules: `By uploading your video content, you are granting ${userProfile.teamName} and its affiliates royalty-free, irrevocable, non-exclusive rights and license to re-use this content.\n\nWe encourage creativity, but please ensure that your content respects ethical standards and does not contain any form of inappropriate material. Your commitment to these guidelines ensures an inclusive environment for all our community. Thank you.`,
      tips: `- Let your personality shine\n- Prop up your phone for a stable shot\n- If filming a scene, experiment with framing to create better visual impact\n- If using a webcam, position yourself in well-lit surroundings for a vibrant and captivating video`,
      displaySettings: {
        showLocation: projectTheme!.data.vloggi.elements.find((element: any) =>
          ['location', 'title'].includes(element.name)
        ),
        showCaption1: !!projectTheme!.data.vloggi.elements.find((element: any) => element.name === 'caption1'),
        showCaption2: !!projectTheme!.data.vloggi.elements.find((element: any) => element.name === 'caption2'),
        showCaption3: !!projectTheme!.data.vloggi.elements.find((element: any) => element.name === 'caption3'),
        caption1: 'Instagram handle (editable field)',
        caption2: 'Product purchased (editable field)',
        caption3: 'Branch number (editable field)',
      },
      themeId: projectTheme!.id,
    })

    if (error) {
      setIsLoading(false)
      return alert('An error occurred during the create')
    }

    setFirstProject(data.project)

    setIsLoading(false)

    stepForward()
  }

  const handleEndOnboarding = async () => {
    history.push('/projects')

    window.location.reload()
  }

  if (!team || !themes) return null

  return (
    <>
      {step === 0 && <SubscriptionConfirmationModal user={user} team={team} stepForward={stepForward} />}
      {step === 1 && <WelcomeModal stepForward={stepForward} />}
      {step === 2 && (
        <BrandingModal
          loading={loading}
          user={user}
          team={team}
          handleChangeTeamName={handleChangeTeamName}
          handleChangeOrganisationWebsite={handleChangeOrganisationWebsite}
          stepBack={stepBack}
          handleSaveBranding={handleSaveBranding}
        />
      )}
      {step === 3 && (
        <StyleModal
          loading={loading}
          team={team}
          brand={brand}
          setShowLogoUploadModal={setShowLogoUploadModal}
          setShowWatermarkUploadModal={setShowWatermarkUploadModal}
          handleChangeFont={handleChangeFont}
          handleChangePrimaryColor={handleChangePrimaryColor}
          handleChangeSecondaryColor={handleChangeSecondaryColor}
          stepBack={stepBack}
          handleSaveStyle={handleSaveStyle}
          handleEndOnboarding={handleEndOnboarding}
        />
      )}
      {step === 4 && (
        <ProjectThemeModal
          themes={themes}
          projectTheme={projectTheme}
          handleChangeProjectTheme={handleChangeProjectTheme}
          stepBack={stepBack}
          stepForward={stepForward}
          handleEndOnboarding={handleEndOnboarding}
        />
      )}
      {step === 5 && (
        <ProjectNameModal
          loading={loading}
          projectName={projectName}
          handleChangeProjectName={handleChangeProjectName}
          stepBack={stepBack}
          handleSaveProject={handleSaveProject}
          handleEndOnboarding={handleEndOnboarding}
        />
      )}
      {step === 6 && firstProject !== null && (
        <ProjectLinkModal team={team} project={firstProject} handleEndOnboarding={handleEndOnboarding} />
      )}
      {showLogoUploadModal && (
        <ImageUploader
          inputImageType={teamAvatarSize.inputImageType}
          outputImageType={teamAvatarSize.outputImageType}
          imageIsAvatar={teamAvatarSize.imageIsAvatar}
          imageSize={{ width: teamAvatarSize.width, height: teamAvatarSize.height }}
          loading={loading}
          handleSave={handleSaveTeamAvatar}
          handleCancel={handleCancelAvatarUpload}
        />
      )}
      {showWatermarkUploadModal && (
        <ImageUploader
          inputImageType={teamWatermarkSize.inputImageType}
          outputImageType={teamWatermarkSize.outputImageType}
          imageIsAvatar={teamWatermarkSize.imageIsAvatar}
          imageSize={{ width: teamWatermarkSize.width, height: teamWatermarkSize.height }}
          loading={loading}
          handleSave={handleSaveTeamWatermark}
          handleCancel={handleCancelWatermarkUpload}
        />
      )}
    </>
  )
}

interface IProps extends RouteComponentProps<any> {}

export default withRouter(OnboardingPage)
