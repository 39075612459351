import React from 'react'

import { Header } from 'semantic-ui-react'

const GetStartedVideoThumbnail = ({ video }: IProps) => {
  const { header, videoLink } = video

  return (
    <>
      <Header as="h4">{header}</Header>
      <div style={{ position: 'relative', paddingBottom: '66.01466992665037%', height: '0' }}>
        <iframe
          title={header}
          src={videoLink}
          frameBorder="0"
          allowFullScreen
          style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
        ></iframe>
      </div>
    </>
  )
}

interface IProps {
  video: {
    header: string
    videoLink: string
  }
}

export default GetStartedVideoThumbnail
