import React from 'react'

import { Segment } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'

import './PlanTabPhoneFrame.scss'

const Overlay = () => (
  <div
    className="hide-scroll-bar"
    style={{
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '150%',
      zIndex: 1,
      overflowY: 'auto',
    }}
  />
)

const PlanTabPhoneFrame = ({ children }: IProps) => {
  return (
    <>
      {/* Overlay that prevent user to interact with the control below */}
      <Segment
        id="phone-frame"
        style={{
          maxWidth: '270px',
          height: '530px',
          margin: 0,
          border: '4px solid black',
          borderRadius: '12px',
          overflowY: 'auto',
        }}
      >
        <Overlay />
        {children}
      </Segment>
    </>
  )
}

interface IProps {
  project: IProject
  children: JSX.Element
}

export default PlanTabPhoneFrame
