import React from 'react'

import App from './App'
import './common.scss'
import { GlobalProvider } from './contexts/GlobalContext'
import { UserProvider } from './contexts/UserContext'
import ErrorBoundary from './utils/ErrorBoundary'

const AppWrapper = () => {
  return (
    <ErrorBoundary>
      <GlobalProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </GlobalProvider>
    </ErrorBoundary>
  )
}

export default AppWrapper
