import React from 'react'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import LoadingOverlay from './components/common/LoadingOverlay'
import SmallScreenOverlay from './components/common/SmallScreenOverlay'
import ExitWithoutSavingChangeModal from './components/modals/ExitWithoutSavingModal'
import UpdatePlanModal from './components/modals/UpdatePlanModal'
import NavBar from './components/navigation/NavBar'
import SideBar from './components/navigation/SideBar'

import { SecureUserRoute, SecureAdminRoute } from './hoc/WithSecureRoute'
import AdminPage from './pages/admin/AdminPage'
import MyBrandingPage from './pages/branding/BrandingPage'
import HelpCentrePage from './pages/help-centre/HelpCentrePage'
import LoginPage from './pages/login/LoginPage'
import MyAccountPage from './pages/my-account/MyAccountPage'
import MyProfilePage from './pages/my-profile/MyProfilePage'
import MySettingsPage from './pages/my-settings/MySettingsPage'
import MyTeamPage from './pages/my-team/MyTeamPage'
import Callback from './pages/oauth2/Callback'
import PageNotFound from './pages/PageNotFound'
import ForgotPage from './pages/password-recovery/ForgotPage'
import RecoverPage from './pages/password-recovery/RecoverPage'
import ProjectEditPage from './pages/project-edit/ProjectEditPage'
import ProjectIndexPage from './pages/projects/ProjectIndexPage'
import RegisterPage from './pages/register/RegisterPage'
import StyleGuide from './pages/StyleGuide'
import ThemeEditPage from './pages/theme-edit/ThemeEditPage'
import ThemeIndexPage from './pages/themes-index/ThemeIndexPage'
import GoogleAnalytics from './utils/GoogleAnalytics'
import GoSquaredAnalytics from './utils/GoSquaredAnalytics'

const App = () => (
  <BrowserRouter>
    <SmallScreenOverlay>
      <GoogleAnalytics />
      <GoSquaredAnalytics />
      <header>
        <NavBar />
      </header>
      <main>
        <SideBar>
          <LoadingOverlay>
            <Switch>
              <SecureUserRoute exact path="/my-branding" component={MyBrandingPage} />
              <SecureUserRoute exact path="/projects/:projectSlug/:section" component={ProjectEditPage} />
              <SecureUserRoute exact path="/projects" component={ProjectIndexPage} />
              <SecureAdminRoute exact path="/themes/:themeId/edit" component={ThemeEditPage} />
              <SecureUserRoute exact path="/themes" component={ThemeIndexPage} />
              <SecureUserRoute exact path="/help-centre" component={HelpCentrePage} />
              <SecureAdminRoute exact path="/admin" component={AdminPage} />
              <SecureUserRoute exact path="/me" component={MyProfilePage} />
              <SecureUserRoute exact path="/my-team" component={MyTeamPage} />
              <SecureUserRoute exact path="/my-account" component={MyAccountPage} />
              <SecureUserRoute exact path="/my-settings" component={MySettingsPage} />
              <SecureUserRoute exact path="/style" component={StyleGuide} />
              <SecureUserRoute exact path="/oauth2/callback" component={Callback} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/register" component={RegisterPage} />
              <Route exact path="/forgot" component={ForgotPage} />
              <Route exact path="/recover/:recoveryCode(\d+r.{10})" component={RecoverPage} />
              <Route exact path="/404" component={PageNotFound} />
              {/* Here send the user to /projects if he is logged in otherwise the "SecureUserRoute" will redirect him to"/login" */}
              <SecureUserRoute exact path="/" render={() => <Redirect to="/projects" />} />
              <Route path="/" render={() => <Redirect to="/404" />} />
            </Switch>
            <UpdatePlanModal />
          </LoadingOverlay>
        </SideBar>
        <ExitWithoutSavingChangeModal />
      </main>
    </SmallScreenOverlay>
  </BrowserRouter>
)

export default App
