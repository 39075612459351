import React from 'react'

import { Button, Form, Grid, Header, Image, Modal, InputOnChangeData } from 'semantic-ui-react'

import { IUser, ITeam } from '../../api/Data'

import CustomLabel from '../../components/common/custom-label/CustomLabel'

import imageOnboardingWelcome from '../../_images/onboarding-welcome.png'

const BrandingModal = (props: IBrandingModalProps) => {
  // Props
  const { loading, user, team, handleChangeTeamName, handleChangeOrganisationWebsite, stepBack, handleSaveBranding } = props

  const { username } = user
  
  const { teamName, organisationWebsite } = team

  return (
    <Modal open style={{ width: '100%', height: '100%', marginTop: '-14px' }}>
      <Modal.Content style={{ padding: 0 }}>
        <Grid style={{ height: '100vh', minHeight: '700px', margin: 0 }}>
          <Grid.Column only="computer" width={7} style={{ backgroundImage: 'linear-gradient(to bottom, #12165b, #121360)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Header inverted as="h1">
                Welcome to Vloggi!
              </Header>
              <Image src={imageOnboardingWelcome} width={350} alt="vloggi" />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} computer={9}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
              <div style={{ height: '30px' }}>
                
              </div>
              <div style={{ padding: '0 100px' }}>
                <Header as="h2">
                  Hello, {username} 👋
                </Header>
                <Form style={{ margin: '20px 0 10px 0' }}>
                  <Form.Field>
                    <CustomLabel>
                      <CustomLabel.Text text="What organization are you collecting videos for?" />
                      <CustomLabel.WordCount word={teamName} limit={50}></CustomLabel.WordCount>
                    </CustomLabel>
                    <Form.Input type="text" name="teamName" maxLength={50} value={teamName} placeholder="Enter business or organization name" onChange={handleChangeTeamName} />
                  </Form.Field>
                  <Form.Field>
                    <CustomLabel>
                      <CustomLabel.Text text="What is your organization's website?" />
                      <></>
                    </CustomLabel>
                    <Form.Input type="text" name="organisationWebsite" value={organisationWebsite} placeholder="Enter website" onChange={handleChangeOrganisationWebsite} />
                  </Form.Field>
                </Form>
                <Button primary disabled={teamName.trim() === '' || loading} loading={loading} style={{ marginTop: '20px' }} onClick={handleSaveBranding}>
                  Next
                </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ margin: '12px 20px 0 0' }}>
                  2 / 6
                </div>
                <Button disabled={loading} onClick={stepBack}>
                  Back
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

interface IBrandingModalProps {
  loading: boolean
  user: IUser
  team: ITeam
  handleChangeTeamName: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  handleChangeOrganisationWebsite: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  stepBack: () => void
  handleSaveBranding: () => void
}

export default BrandingModal
