import React, { useEffect, useState, useCallback } from 'react'

import { Segment, Header, Button, Grid, Modal, Pagination } from 'semantic-ui-react'

import Data from '../../../api/Data'
import { S3_BUCKET_URL } from '../../../config/environments'

import UploaderModal from '../../../components/modals/UploaderModal'

const DesignerImageSelectModal = ({ handleSelect, setShowModal }) => {
  const [items, setItems] = useState()
  const [itemSelected, setItemSelected] = useState(null)
  const [page, setPage] = useState(1)
  const [itemCount, setItemCount] = useState(null)
  const [itemPerPage] = useState(40)
  const [showUploadModal, setShowUploadModal] = useState(false)

  const fetchItems = useCallback(async () => {
    const offset = (page - 1) * itemPerPage
    const limit = itemPerPage

    const { data, error } = await Data.getThemeAssets('image', offset, limit)

    if (error) return alert('An error occurred')

    const { rows, count } = data

    setItems(rows || [])

    setItemCount(count)
  }, [itemPerPage, page])

  useEffect(() => {
    console.log('fetching')

    fetchItems()
  }, [fetchItems])

  const handleDelete = async () => {
    const { error } = await Data.deleteThemeAsset(itemSelected.id)

    if (error) return alert('An error occurred')

    return fetchItems()
  }

  const handleClose = () => {
    setShowUploadModal(false)
  }

  const handleUploadComplete = () => {
    setShowUploadModal(false)
    fetchItems()
  }

  if (!items) return false

  console.log('Rendering modal', items, itemSelected)

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>
        <Grid columns="equal">
          <Grid.Column>Add an image</Grid.Column>
          <Grid.Column>
            <Button floated="right" inverted secondary onClick={() => setShowUploadModal(true)}>
              Upload my image
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Grid padded>
        <Grid.Column width={14} textAlign="right">
          <Pagination
            size="mini"
            boundaryRange={0}
            defaultActivePage={page}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            onPageChange={(e, data) => setPage(data.activePage)}
            totalPages={Math.ceil(itemCount / itemPerPage)}
          />
        </Grid.Column>

        <Grid.Column width={2} textAlign="left">
          <Header as="h5">
            <Header.Subheader>
              {page * 40 - 40}-{Math.min(page * 40, itemCount)} of {itemCount}
            </Header.Subheader>
          </Header>
        </Grid.Column>

        <Grid.Column width={8} textAlign="right"></Grid.Column>
      </Grid>
      <Modal.Content scrolling>
        <Modal.Description>
          <Grid.Row>
            <Grid columns={4}>
              {items.map((item) => (
                <Grid.Column key={item.id} onClick={() => setItemSelected(item)}>
                  <Segment color={itemSelected?.id === item.id && 'red'}>
                    <div
                      style={{
                        height: '107px',
                        backgroundImage: `url(${S3_BUCKET_URL}/themes/assets/${item.id})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                      }}
                    />
                    <p>{item.title}</p>
                  </Segment>
                </Grid.Column>
              ))}
            </Grid>
          </Grid.Row>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>Close</Button>
        <Button color="red" inverted onClick={() => handleDelete(itemSelected)}>
          delete
        </Button>
        <Button primary onClick={() => handleSelect(itemSelected)}>
          Select
        </Button>
      </Modal.Actions>
      {/* Video Uploader */}
      {showUploadModal && (
        <UploaderModal
          title="Upload an image"
          endpoint="themeAssets?type=image"
          requirements={{
            maxFileSize: '80mb',
            maxFiles: 1,
            minDuration: null,
            acceptedFileTypes: ['jpeg', 'png'],
            acceptedFileMimeTypes: ['image/jpeg', 'image/png'],
          }}
          handleUploadSuccess={handleUploadComplete}
          handleClose={handleClose}
        />
      )}
    </Modal>
  )
}

export default DesignerImageSelectModal
