import React, { useCallback, useState, useEffect } from 'react'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import _ from 'lodash'
import { Segment } from 'semantic-ui-react'

import DesignerTabSectionAddItem from './DesignerTabSectionAddItem'
import DesignerCanva from './DesignerTabSectionCanva'
import DesignerToolBox from './DesignerTabSectionToolBox'

// const canvaWidth = 854
// const canvaHeight = 480
// const canvaRatio = 854 / 1920
const canvaWidth = 640
const canvaHeight = 360
const canvaRatio = 640 / 1920
const gridSize = 20

const DesignerTabSection = ({ theme, section, sectionName, defaultFonts, updateTheme }) => {
  // States
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [activeToolBox, setActiveToolBox] = useState(null)
  const [themeHistory, setThemeHistory] = useState([])
  const [copiedElement, setCopiedElement] = useState(null)

  // Update theme, used only for sections 'opening, vloggi, closing'
  const updateThemeSection = useCallback(
    (newThemeSection) => {
      const prevTheme = theme

      // We only keep 10 theme version
      const last9ThemeVersions = themeHistory.slice(0, 8)
      const newThemeHistory = [prevTheme, ...last9ThemeVersions]

      setThemeHistory(newThemeHistory)
      updateTheme({ ...theme, data: { ...theme.data, [sectionName]: newThemeSection } })
    },
    [sectionName, theme, themeHistory, updateTheme]
  )

  // Revert change
  const revertChange = useCallback(() => {
    if (!themeHistory.length) return
    const newThemeHistory = _.cloneDeep(themeHistory)

    const lastThemeVersion = newThemeHistory[0]

    // Here we remove the last theme version form the history
    setThemeHistory((prevState) => prevState.slice(1))
    setSelectedIndex(null)
    updateTheme(lastThemeVersion)
  }, [themeHistory, updateTheme])

  // Delete element
  const deleteThemeElement = useCallback(() => {
    const newThemeSection = { ...section }

    newThemeSection.elements.splice(selectedIndex, 1)

    updateThemeSection(newThemeSection)

    setSelectedIndex(null)
  }, [section, selectedIndex, updateThemeSection])

  // Copy element
  const copyThemeElement = useCallback(() => {
    if (!selectedIndex) return
    const elementToCopy = { ...section.elements[selectedIndex] }
    setCopiedElement(elementToCopy)
  })

  // Paste element
  const pasteThemeElement = useCallback(() => {
    if (!copiedElement) return

    const newThemeSection = { ...section }

    const elementToCopy = { ...copiedElement, x: 0, y: 0 } // To avoid copying the element on top of each others

    newThemeSection.elements.push(elementToCopy) // Pushed at the end of the array to have the element on top of all others

    const elementToCopyIndex = newThemeSection.elements.length - 1

    updateThemeSection(newThemeSection)

    setCopiedElement(null)

    setSelectedIndex(elementToCopyIndex)
  })

  // Allow the user to move/delete the elements using the keyboard
  useEffect(() => {
    const keyPressHandler = (e) => {
      // Do nothing if already processed
      if (e.defaultPrevented) return
      // Early return if no key are attached to an event handler
      if (!['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'z', 'c', 'v'].includes(e.key))
        return

      // Handle control + z event
      if (e.key === 'z' && e.metaKey) {
        revertChange()
        return
      }

      // Handle control + c event
      if (e.key === 'c' && e.metaKey) {
        copyThemeElement()
        return
      }

      // Handle control + v event
      if (e.key === 'v' && e.metaKey) {
        pasteThemeElement()
        return
      }

      // To avoid conflic when the user uses arrow in the toolbox
      if (activeToolBox) return
      // Do nothing is no element are selected
      if (selectedIndex === null) return

      // ------------------------------------------------PROBLEM: the events below cannot work since the tool box is always active when an element is selected. We need to find a way for the control elements in the tool box to not being affected/conflicted by the below event handlers ------------------------------------------------

      // let newValue
      // const newThemeSection = section

      // const incrementUnit = gridSize * canvaRatio
      // const { x: currentX, y: currentY, width: elementWidth, height: elementHeight } = section.elements[selectedIndex]

      // switch (
      //   e.key
      // case 'ArrowDown':
      //   if (currentY >= canvaHeight / canvaRatio - incrementUnit - elementHeight)
      //     newValue = canvaHeight / canvaRatio - elementHeight
      //   else newValue = currentY + incrementUnit

      //   newThemeSection.elements[selectedIndex].y = newValue

      //   updateThemeSection(newThemeSection)
      //   break
      // case 'ArrowUp':
      //   if (currentY <= incrementUnit) newValue = 0
      //   else newValue = currentY - incrementUnit

      //   newThemeSection.elements[selectedIndex].y = newValue

      //   updateThemeSection(newThemeSection)
      //   break
      // case 'ArrowLeft':
      //   if (currentX <= incrementUnit) newValue = 0
      //   else newValue = currentX - incrementUnit

      //   newThemeSection.elements[selectedIndex].x = newValue

      //   updateThemeSection(newThemeSection)
      //   break
      // case 'ArrowRight':
      //   if (currentX >= canvaWidth / canvaRatio - incrementUnit - elementWidth)
      //     newValue = canvaWidth / canvaRatio - elementWidth
      //   else newValue = currentX + incrementUnit

      //   newThemeSection.elements[selectedIndex].x = newValue

      //   updateThemeSection(newThemeSection)
      //   break

      // case 'Backspace':
      // case 'Delete':
      //   // Remove current element
      //   deleteThemeElement()
      //   break
      // default:
      // Quit when this doesn't handle the key key
      // ) {
      // }
    }

    document.addEventListener('keydown', keyPressHandler)
    return () => {
      document.removeEventListener('keydown', keyPressHandler)
    }
  }, [
    activeToolBox,
    copyThemeElement,
    deleteThemeElement,
    pasteThemeElement,
    revertChange,
    section,
    selectedIndex,
    updateThemeSection,
  ])

  console.log('Rendering section page', selectedIndex)

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <DesignerTabSectionAddItem
          section={section}
          sectionName={sectionName}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          defaultFonts={defaultFonts}
          updateThemeSection={updateThemeSection}
          themeHistory={themeHistory}
          revertChange={revertChange}
          copiedElement={copiedElement}
          copyThemeElement={copyThemeElement}
          pasteThemeElement={pasteThemeElement}
        />
      </div>
      <div style={{ width: 'calc(640px + 2rem)', padding: '0 1rem' }}>
        <DesignerCanva
          section={section}
          sectionName={sectionName}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          updateThemeSection={updateThemeSection}
          setActiveToolBox={setActiveToolBox}
          gridSize={gridSize}
          canvaWidth={canvaWidth}
          canvaHeight={canvaHeight}
          canvaRatio={canvaRatio}
        />
      </div>

      <Segment secondary style={{ width: '300px', height: 'inherit', margin: '0' }}>
        {selectedIndex !== null && (
          <DesignerToolBox
            section={section}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            defaultFonts={defaultFonts}
            updateThemeSection={updateThemeSection}
            activeToolBox={activeToolBox}
            setActiveToolBox={setActiveToolBox}
          />
        )}
      </Segment>
    </div>
  )
}

export default DesignerTabSection
