/* eslint-disable react/no-unused-state */

import Auth from '../utils/Auth'

const userReducer = (state: IUserContext, action: IAction) => {
  switch (action.type) {
    case 'update user profile': {
      return { ...state, ...action.payload }
    }

    case 'update user plan': {
      const { plan, subscriptionExpiryDate } = action.payload

      return { ...state, plan, subscriptionExpiryDate }
    }

    case 'log out': {
      Auth.removeToken()
      return null
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

interface IPlan {
  title: string
  description: string
  contentOwnership: boolean
  maxNbOfUserPerTeam: number
  maxNbOfClips: number
  videoResolution: number
  downloadReport: boolean
  cleanClip: boolean
  hasMadeWithVloggiOutro: boolean
  projectHeroVideo: boolean
}

export interface IUserContext {
  userProfile: {
    username: string
    email: string
    userAccessRights: 'admin' | 'member' | 'revoke'
    teamName: string
    logoWatermarkUrl: string
    logoAvatarUrl: string
    watermarkId: number
    subscriptionExpiryDate: Date
    subscriptionDaysLeft: number
    isCustomer: boolean
    plan: IPlan
  } | null
  dispatchUserContext: (args: IAction) => void
}

export interface IAction {
  type: 'update user profile' | 'update user plan' | 'log out'
  payload?: any
}

export default userReducer
