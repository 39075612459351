import React from 'react'

import { RouteComponentProps, withRouter } from 'react-router'
import { Label, Popup } from 'semantic-ui-react'

const UpgradeTag = (props: IProps) => (
  <Popup
    popper={{ style: { filter: 'none' } }} // Fix a semantic ui problem where popup will be blurred if triggered from a modal with blurred background.
    trigger={
      <Label
        as="a"
        on="hover"
        positionFixed
        color="orange"
        size="tiny"
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation()
          props.history.push('/my-account')
        }}
        style={props.style ? props.style : {}}
      >
        {props.title ? props.title : 'Upgrade'}
      </Label>
    }
    content={props.text}
  ></Popup>
)

interface IProps extends RouteComponentProps<any> {
  title?: string
  text: string
  style?: React.CSSProperties
}

export default withRouter(UpgradeTag)
