import React, { useContext, useState } from 'react'

import _ from 'lodash'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button, Confirm, Segment, Menu, Header } from 'semantic-ui-react'
import { Checkbox, CheckboxProps, InputOnChangeData } from 'semantic-ui-react'

import Data, { ITheme, IClip } from '../../../api/Data'
import { maxLength } from '../../../config/constants'
import { GlobalContext } from '../../../contexts/GlobalContext'
import DesignerTabMusic from '../../designer/DesignerTabMusic'
import DesignerTabSection from '../../designer/DesignerTabSection'
import DesignerTabTransition from '../../designer/DesignerTabTransition'

import SubSection from '../../../components/common/SubSection'
import { getNewOpeningElement, getNewClosingElement } from '../../../components/common/ThemeElement'
import Title from '../../../components/common/Title'

import MergeTabStoryBuilder from './MergeTabStoryBuilder'

const MergeTab = ({ history, match, projectTitle, theme, clipsSelected, setTheme, setClipsSelected }: IProps) => {
  const { dispatchGlobalContext } = useContext(GlobalContext)

  // States
  const [activeTab, setActiveTab] = useState<number>(1)
  const [episode, setEpisode] = useState({ title: '', description: '' })
  const [validationError, setValidationError] = useState<string | null>(null)
  const [showAlertModal, setShowAlertModal] = useState<string | null>(null)
  const [showConfirmSubmissionModal, setShowConfirmSubmissionModal] = useState<boolean>(false)

  const removeClip = (clip: IClip) => {
    const newClipSelected = clipsSelected.filter((clipSelected) => clipSelected.id !== clip.id)

    setClipsSelected(newClipSelected)
  }

  const handleChangeEpisode = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { name, value } = data

    setValidationError(null)

    setEpisode((prevState) => ({ ...prevState!, [name]: value }))
  }

  // Update theme
  const handleSubmitTheme = async () => {
    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.updateTheme(theme)

    if (error) {
      dispatchGlobalContext({ type: 'set user has made changes', payload: false })
      return dispatchGlobalContext({ type: 'set loading', payload: false })
    }

    setTheme(data)

    dispatchGlobalContext({ type: 'set user has made changes', payload: false })

    setTimeout(() => dispatchGlobalContext({ type: 'set loading', payload: false }), 1000)
  }

  const submitEpisode = async () => {
    // Save Theme
    handleSubmitTheme()

    // Create Episode
    const clipIdsArr = clipsSelected.map((clip) => {
      return { clipId: clip.id }
    })

    const { error } = await Data.createEpisode({ ...episode, projectSlug: match.params.projectSlug, clips: clipIdsArr })

    if (error) {
      if (error.response.status === 403) {
        return dispatchGlobalContext({ type: 'show update plan modal' })
      } else {
        alert('An error occurred')
      }

      dispatchGlobalContext({ type: 'set user has made changes', payload: false })

      return setShowConfirmSubmissionModal(false)
    }

    setClipsSelected([])

    dispatchGlobalContext({ type: 'set user has made changes', payload: false })

    setShowConfirmSubmissionModal(false)

    return history.push(`/projects/${match.params.projectSlug}/publish`)
  }

  const handleSubmit = () => {
    const { title, description } = episode

    let invalidField: string | null = null

    // Validate form
    if (!title || title.length > maxLength.title) invalidField = 'title'
    if (description?.length > maxLength.description) invalidField = 'description'
    if (invalidField) {
      setValidationError(invalidField)
      return setActiveTab(1)
    }

    return setShowConfirmSubmissionModal(true)
  }

  // ------------------------------------------------Theme handles------------------------------------------------

  // Default update theme function
  const handleUpdateTheme = (newTheme: ITheme) => {
    dispatchGlobalContext({ type: 'set user has made changes', payload: true })

    setTheme(newTheme)
  }

  // Toggle Intro
  const toggleIntro = (e: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps) => {
    const { checked } = data

    const newTheme = { ...theme }

    if (checked) {
      // add Intro
      const newIntro = getNewOpeningElement()
      newTheme.data.opening = newIntro
    } else {
      // remove intro
      delete newTheme.data.opening
    }
    handleUpdateTheme(newTheme)
  }

  // Toggle Outro
  const toggleOutro = (e: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps) => {
    const { checked } = data

    const newTheme = { ...theme }
    if (checked) {
      // add Intro
      const newIntro = getNewClosingElement()
      newTheme.data.closing = newIntro
    } else {
      // remove intro
      delete newTheme.data.closing
    }
    handleUpdateTheme(newTheme)
  }

  const goToManageTab = () => {
    return history.push(`/projects/${match.params.projectSlug}/manage`)
  }

  const hasOpening = !!theme.data.opening
  const hasClosing = !!theme.data.closing

  console.log('Rendering Merge tab', clipsSelected)

  return (
    <>
      <Title title={projectTitle}>
        <>
          <Button onClick={goToManageTab}>Back</Button>

          <Button secondary onClick={handleSubmitTheme}>
            Save
          </Button>

          <Button primary onClick={handleSubmit}>
            Save & Create Story
          </Button>
        </>
      </Title>

      <SubSection hasGreyBackground>
        <>
          <Menu color="violet" pointing secondary style={{ marginBottom: '2rem' }}>
            <Menu.Item active={activeTab === 1} onClick={() => setActiveTab(1)}>
              Build Story
            </Menu.Item>
            <Menu.Item>{'>'}</Menu.Item>
            <Menu.Item active={activeTab === 2} onClick={() => setActiveTab(2)}>
              Add Intro
            </Menu.Item>
            <Menu.Item>{'>'}</Menu.Item>
            <Menu.Item active={activeTab === 3} onClick={() => setActiveTab(3)}>
              Add Outro
            </Menu.Item>
            <Menu.Item>{'>'}</Menu.Item>
            <Menu.Item active={activeTab === 4} onClick={() => setActiveTab(4)}>
              Change Transition
            </Menu.Item>
            <Menu.Item>{'>'}</Menu.Item>
            <Menu.Item active={activeTab === 5} onClick={() => setActiveTab(5)}>
              Manage Audio
            </Menu.Item>
          </Menu>

          {activeTab === 1 && (
            <MergeTabStoryBuilder
              validationError={validationError}
              maxLength={maxLength}
              episode={episode}
              clipsSelected={clipsSelected}
              setClipsSelected={setClipsSelected}
              removeClip={removeClip}
              handleChange={handleChangeEpisode}
            />
          )}
          {activeTab === 2 && (
            <>
              <Header as="h3">
                Design your story intro
                <span style={{ float: 'right' }}>
                  <Checkbox toggle label="Add / Remove" checked={hasOpening} onClick={toggleIntro} />
                </span>
              </Header>

              {hasOpening && (
                <DesignerTabSection
                  theme={_.cloneDeep(theme)}
                  sectionName="opening"
                  section={_.cloneDeep(theme.data.opening)}
                  defaultFonts={theme.data.playlist.defaultFonts}
                  updateTheme={handleUpdateTheme}
                />
              )}
            </>
          )}
          {activeTab === 3 && (
            <>
              <Header as="h3">
                Design your story outro
                <span style={{ float: 'right' }}>
                  <Checkbox toggle label="Add / Remove" checked={hasClosing} onClick={toggleOutro} />
                </span>
              </Header>
              {hasClosing && (
                <DesignerTabSection
                  theme={_.cloneDeep(theme)}
                  sectionName="closing"
                  section={_.cloneDeep(theme.data.closing)}
                  defaultFonts={theme.data.playlist.defaultFonts}
                  updateTheme={handleUpdateTheme}
                />
              )}
            </>
          )}
          {activeTab === 4 && (
            <Segment secondary>
              <DesignerTabTransition theme={theme} updateTheme={handleUpdateTheme} />
            </Segment>
          )}
          {activeTab === 5 && (
            <Segment secondary>
              <DesignerTabMusic theme={_.cloneDeep(theme)} updateTheme={handleUpdateTheme} />
            </Segment>
          )}
          {/* confirm modals */}
          <Confirm
            open={!!showAlertModal}
            header="Validation alert"
            content={showAlertModal}
            cancelButton={null}
            confirmButton="Ok"
            onConfirm={() => setShowAlertModal(null)}
          />
          <Confirm
            open={showConfirmSubmissionModal}
            header="Confirm Story Creation"
            content='Note: It might take few minutes for Vloggi to produce your story. You can follow its progress from the "Publish" section under the "Stories Produced".'
            cancelButton="Cancel"
            confirmButton="Create story"
            onCancel={() => setShowConfirmSubmissionModal(false)}
            onConfirm={submitEpisode}
          />
        </>
      </SubSection>
    </>
  )
}

interface IProps extends RouteComponentProps<any> {
  projectTitle: string
  theme: ITheme
  clipsSelected: IClip[]
  setClipsSelected: (arg: IClip[]) => void
  setTheme: (arg: ITheme) => void
}
export default withRouter(MergeTab)
