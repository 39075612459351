import React, { useContext, useState } from 'react'

import axios from 'axios'
import { Confirm, Modal, Button, Dropdown, Image, DropdownItemProps } from 'semantic-ui-react'

import threeDotsIcon from '../../../_images/3-dots.svg'
import Data from '../../../api/Data'
import { IEpisode } from '../../../api/Data'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { downloadFileFromBrowser } from '../../../utils/sharedFunctions'

const EpisodeModal = ({ episode, setEpisodeModal, deleteEpisode }: IProps) => {
  const { isMobile, dispatchGlobalContext } = useContext(GlobalContext)

  // States
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownloadEpisode = async (e: any, { value }: DropdownItemProps) => {
    const type = String(value)

    setIsDownloading(true)

    const { data, error } = await Data.downloadEpisode(episode.id, type)

    if (error) {
      setIsDownloading(false)

      if (error.response.status === 403) {
        return dispatchGlobalContext({ type: 'show update plan modal' })
      }

      return alert('An error occurred')
    }

    const { signedUrl, fileName } = data

    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' })

      downloadFileFromBrowser({ data: response.data, type: 'mp4', fileName })
    } catch (err) {
      alert('An error occurred')
    }

    return setIsDownloading(false)
  }

  const handleDelete = () => setShowConfirmationModal(true)

  return (
    <Modal dimmer="blurring" size={isMobile ? 'fullscreen' : 'small'} open onClose={() => setEpisodeModal(null)}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video controls autoPlay tabIndex={-1} style={{ width: '100%', height: 'auto' }}>
        <source src={episode.videoUrl} />
      </video>
      <Modal.Actions>
        <Button onClick={() => setEpisodeModal(null)}>Close</Button>

        <Dropdown text="Download video" button className="secondary" loading={isDownloading}>
          <Dropdown.Menu>
            <Dropdown.Item key="HQ" text="HD video (1920x1080)" value="1080" onClick={handleDownloadEpisode} />
            <Dropdown.Item key="MQ" text="SD video (854x480)" value="480" onClick={handleDownloadEpisode} />
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          style={{ display: '-webkit-inline-box' }} // Needed to align correctly
          pointing="bottom right"
          icon={<Image style={{ width: '1.5rem' }} src={threeDotsIcon} />}
        >
          <Dropdown.Menu>
            <Dropdown.Item text="Delete story" onClick={handleDelete} />
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Actions>
      <Confirm
        open={showConfirmationModal}
        header="Stories deletion"
        content="Are you sure you want to delete this episode?"
        confirmButton="Yes"
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => deleteEpisode(episode.id)}
      />
    </Modal>
  )
}

interface IProps {
  episode: IEpisode
  setEpisodeModal: (arg: IEpisode | null) => void
  deleteEpisode: (arg: number) => void
}

export default EpisodeModal
