import React, { useContext, useRef } from 'react'

import { UserContext } from '../../contexts/UserContext'

import UploaderModal from '../../components/modals/UploaderModal'

const UploadVideoSelection = ({ setClipIds, projectSlug, setStep, setShowModal }: IProps) => {
  // Refs
  const pondRef = useRef<any | null>(null)
  const { userProfile } = useContext(UserContext)

  const handleClose = () => {
    setShowModal(false)
  }

  const handleUploadSuccess = () => {
    if (!pondRef) return
    const clipIds = pondRef.current.getFiles().map((fileUploaded: { serverId: number }) => fileUploaded.serverId)
    setClipIds(clipIds)
    setStep(2)
  }

  return (
    <UploaderModal
      pondRef={pondRef}
      title="From your computer"
      endpoint={`clips?projectSlug=${projectSlug}`}
      requirements={{
        maxFileSize: `${userProfile.maxSizeOfClipInMb}mb`,
        maxFiles: 3,
        minDuration: 2,
        acceptedFileTypes: ['mp4', 'avi', 'mov'],
        acceptedFileMimeTypes: ['video/mp4', 'video/avi', 'video/quicktime'],
      }}
      handleClose={handleClose}
      handleUploadSuccess={handleUploadSuccess}
    />
  )
}

interface IProps {
  projectSlug: string
  setClipIds: (arg: number[]) => void
  setStep: (arg: number) => void
  setShowModal: (arg: boolean) => void
}

export default UploadVideoSelection
