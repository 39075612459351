import React, { useContext, useState } from 'react'

import { Message, Grid, Header, Image, InputOnChangeData, Icon } from 'semantic-ui-react'

import Data, { IUser } from '../../api/Data'
import { MARKETING_WEBSITE_URL } from '../../config/environments'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'

import ExternalLink from '../../components/common/ExternalLink'
import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'

import MyProfileName from './MyProfileName'
import MyProfileBio from './MyProfileBio'

import avatarImage from '../../_images/avatar-default.jpg'

const MyProfilePage = () => {
  const { isMobile, dispatchGlobalContext } = useContext(GlobalContext)
  const { dispatchUserContext } = useContext(UserContext)

  const { userProfile } = useContext(UserContext)

  // States
  const [user, setUser] = useState<IUser>(userProfile)
  
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // Reset form
  const resetForm = () => {
    if (successMessage) setSuccessMessage(null)
    if (errorMessage) setErrorMessage(null)
  }

  // Handle change username
  const handleChangeProfileName = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    resetForm()

    const { name, value } = data

    setUser((prevState) => ({ ...prevState, [name]: value }))
  }

  // Submit username change
  const handleSubmitProfileName = async () => {
    if (user.username.trim() === '') {
      return setErrorMessage('Name not set')
    }

    resetForm()

    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.updateMyProfile(user)

    dispatchGlobalContext({ type: 'set loading', payload: false })

    if (error) {
      return setErrorMessage(error.response.data)
    }

    const { username } = data

    dispatchUserContext({ type: 'update user profile', payload: { username } })

    setSuccessMessage('Your name has been updated successfully')
  }

  // Handle change bio
  const handleChangeProfileBio = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    resetForm()

    const { name, value } = data

    setUser((prevState) => ({ ...prevState, [name]: value }))
  }

  // Submit bio change
  const handleSubmitProfileBio = async () => {
    resetForm()

    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.updateMyProfile(user)

    dispatchGlobalContext({ type: 'set loading', payload: false })

    if (error) {
      return setErrorMessage(error.response.data)
    }

    const { bio } = data

    dispatchUserContext({ type: 'update user profile', payload: { bio } })

    setSuccessMessage('Your bio has been updated successfully')
  }
  
  if (!user) return null

  console.log('Rendering Profile page', user)

  return (
    <Section>
      <>
        {/* title */}
        <Title title="My Profile" />

        <SubSection>
          <>
            <Grid columns={2} divided="vertically">
              {/*
              <Grid.Row stretched>
                <Grid.Column width={3}>
                  
                </Grid.Column>
                <Grid.Column width={13}>
                  <div style={{ position: 'relative', width: 250, height: 250 }}>
                    <Image src={avatarImage} avatar style={{ width: '100%', height: '100%' }} />
                    <Icon name="camera" circular bordered size="big" style={{ position: 'absolute', top: 8, right: 8, backgroundColor: '#ffffff', color: '#6b50ff', boxShadow: '0em 0em 0em 0.1em #6b50ff inset' }} />
                  </div>
                </Grid.Column>
              </Grid.Row>
              */}
              <Grid.Row stretched>
                <Grid.Column width={3}>
                  <Header as="h4">
                    Name
                  </Header>
                </Grid.Column>
                <Grid.Column width={13}>
                  <MyProfileName
                    username={user.username}
                    handleChange={handleChangeProfileName}
                    handleSubmit={handleSubmitProfileName}
                    isMobile={isMobile}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column width={3}>
                  <Header as="h4">
                    Email
                  </Header>
                </Grid.Column>
                <Grid.Column width={13}>
                  <Header as="h4">
                    <span style={{ color: '#6b50ff' }}>{user.email}</span>
                    <Header.Subheader as="h6">
                      Account email addresses cannot be changed
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column width={3}>
                  <Header as="h4">
                    Bio
                  </Header>
                </Grid.Column>
                <Grid.Column width={13}>
                  <MyProfileBio
                    bio={user.bio}
                    handleChange={handleChangeProfileBio}
                    handleSubmit={handleSubmitProfileBio}
                    isMobile={isMobile}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {/* User terms */}
                  <div>
                    <ExternalLink url={`${MARKETING_WEBSITE_URL}/user-terms`}>User terms</ExternalLink>
                  </div>
                  {/* Privacy policy */}
                  <div>
                    <ExternalLink url={`${MARKETING_WEBSITE_URL}/privacy`}>Privacy policy</ExternalLink>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {successMessage && <Message success header="Success" content={successMessage} />}
            {errorMessage && <Message error header="Fail" content={errorMessage} />}
            {/* Build number */}
            {/* <div style={{ color: 'white' }}>Build number:{APP_BUILD || 'no build number'}</div> */}
          </>
        </SubSection>
      </>
    </Section>
  )
}

export default MyProfilePage
