import React from 'react'

import { Grid, Segment, Icon, Divider } from 'semantic-ui-react'

import ExternalLink from '../../components/common/ExternalLink'
import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'

import HelpCentreVideoThumbnail from './HelpCentreVideoThumbnail'

const helpVideos = [
  {
    header: 'Vloggi Studio Quick Overview',
    videoLink: 'https://www.loom.com/embed/cabd637e4806472eb5f59da655f6b8fe',
  },
  {
    header: 'Video Templates - which one is for me',
    videoLink: 'https://www.loom.com/embed/c23ba67efe7f4b14b212844206249a59',
  },
  {
    header: 'Managing your video library',
    videoLink: 'https://www.loom.com/embed/fe4ce416bcde4d5eacfd4bcf505f2a80',
  },
]

const helpArticles = [
  {
    name: 'Vloggi quick start guide',
    link: 'https://vloggi.helpdocs.io/article/v329toinb7-vloggi-quick-start-guide',
  },
  {
    name: 'Which template is for me',
    link: 'https://vloggi.helpdocs.io/article/4b7wdk3ibd-which-template-is-for-me',
  },
  {
    name: 'How to write a successful landing page',
    link: 'https://vloggi.helpdocs.io/article/23c16xf1in-how-to-write-a-successful-landing-page',
  },
  {
    name: 'Why you need a project incentive',
    link: 'https://vloggi.helpdocs.io/article/1oikcpvy9v-why-you-need-a-project-incentive',
  },
  {
    name: '6 tips to making a welcome video',
    link: 'https://vloggi.helpdocs.io/article/yitaao1v3l-6-tips-to-making-a-welcome-video',
  },
  {
    name: 'Guide to video clip terminology',
    link: 'https://vloggi.helpdocs.io/article/ie6gghu4j3-video-clip-layout-dictionary',
  },
  {
    name: '8 tips to higher response rates',
    link: 'https://vloggi.helpdocs.io/article/f5o878xtp8-8-tips-to-higher-response-rates',
  },
  {
    name: 'Ways to manage your video library',
    link: 'https://vloggi.helpdocs.io/article/5bifrm0dee-ways-to-manage-your-video-library',
  },
  {
    name: 'How to delete videos',
    link: 'https://vloggi.helpdocs.io/article/3woale4iqr-how-to-delete-videos',
  },
  {
    name: 'How to download videos',
    link: 'https://vloggi.helpdocs.io/article/jg8p2a31zv-how-to-download-videos',
  },
]

const GetStarted = () => {
  return (
    <Section>
      <>
        <Title title="Help Center" />

        <SubSection>
          <>
            <Grid columns={3} stackable>
              {helpVideos.map((video) => (
                <Grid.Column key={video.videoLink}>
                  <HelpCentreVideoThumbnail video={video}></HelpCentreVideoThumbnail>
                </Grid.Column>
              ))}
            </Grid>

            <Segment secondary style={{ marginTop: '2rem' }}>
              <Grid columns={2} stackable>
                <Grid.Column>
                  {helpArticles.slice(0, 4).map((article) => (
                    <div style={{ margin: '1.5rem 0' }}>
                      <Icon name="external alternate" />
                      <ExternalLink url={article.link}>{article.name}</ExternalLink>
                    </div>
                  ))}
                </Grid.Column>
                <Grid.Column>
                  {helpArticles.slice(4, 8).map((article) => (
                    <div style={{ margin: '1.5rem 0' }}>
                      <Icon name="external alternate" />
                      <ExternalLink url={article.link}>{article.name}</ExternalLink>
                    </div>
                  ))}
                </Grid.Column>
              </Grid>
              <Divider></Divider>
              <ExternalLink url="https://vloggi.helpdocs.io/">Or check out or knowledge base</ExternalLink>
            </Segment>
          </>
        </SubSection>
      </>
    </Section>
  )
}

export default GetStarted
