import React from 'react'

import { Header, Button, Segment, Card, Image, Item, Divider } from 'semantic-ui-react'

import LaurenHudsonAvatar from '../../../_images/lauren-hudson-avatar.jpg'
import { HELPDOCS_URL } from '../../../config/environments'

import ExternalLink from '../../../components/common/ExternalLink'

const InviteTab = () => {
  const goToBookingCalendar = () => {
    window.open('https://calendly.com/jwastnage/vloggi-demo', '_blank')
  }

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Need some help?
      </Header>
      <Segment>
        <Card>
          <Card.Content>
            <Image floated="right" size="tiny" src={LaurenHudsonAvatar} style={{ borderRadius: '50%' }} />
            <Card.Header>Lauren Hudson</Card.Header>
            <Card.Meta>Customer Success Manager</Card.Meta>
            <Card.Description>
              Book a <strong>15 mins Onboarding session</strong> with me to learn more about the Vloggi platform and to
              maximize the value of your campaigns.
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className="ui two buttons">
              <Button basic color="orange" onClick={goToBookingCalendar}>
                Book Now
              </Button>
            </div>
          </Card.Content>
        </Card>
        <Header as="h4">Help center</Header>
        <Item.Group divided>
          <Item>
            <Item.Image
              size="tiny"
              src="https://files.helpdocs.io/ld5a319ifs/articles/t29pnqjn3c/1626160802692/website-testimonial-page.png"
            />

            <Item.Content verticalAlign="middle">
              <Item.Header>
                <small>
                  <ExternalLink url="https://vloggi.helpdocs.io/article/t29pnqjn3c-where-to-share-your-video-project">
                    Where to share your video project
                  </ExternalLink>
                </small>
              </Item.Header>
            </Item.Content>
          </Item>

          <Item>
            <Item.Image
              size="tiny"
              src="https://files.helpdocs.io/ld5a319ifs/articles/f5o878xtp8/1626158169452/incentives-and-reminders.png"
            />

            <Item.Content verticalAlign="middle">
              <Item.Header>
                <small>
                  <ExternalLink url="https://vloggi.helpdocs.io/article/f5o878xtp8-8-tips-to-higher-response-rates">
                    8 tips to higher response rates
                  </ExternalLink>
                </small>
              </Item.Header>
            </Item.Content>
          </Item>
        </Item.Group>
        <Divider />
        <div style={{ textAlign: 'center' }}>
          <ExternalLink url={HELPDOCS_URL}>Read more</ExternalLink>
        </div>
      </Segment>
    </>
  )
}

export default InviteTab
