import React, { useState, useEffect, useContext } from 'react'

import ThemeSwitcher from 'react-css-vars'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Button, Grid } from 'semantic-ui-react'

import { IProject, IClip, ITheme } from '../../../api/Data'
import Data from '../../../api/Data'
import { projectImageSize } from '../../../config/constants'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { UserContext } from '../../../contexts/UserContext'

import SubSection from '../../../components/common/SubSection'
import Title from '../../../components/common/Title'
import ImageUploader from '../../../components/modals/ImageUploader'
import UploaderModal from '../../../components/modals/UploaderModal'

import PlanTabBrandingPage from './PlanTabBrandingPage'
import PlanTabCollectPage from './PlanTabCollectPage'
import PlanTabCollectPagePreview from './PlanTabCollectPagePreview'
import PlanTabFormatsPage from './PlanTabFormatsPage'
import PlanTabLandingPage from './PlanTabLandingPage'
import PlanTabLandingPagePreview from './PlanTabLandingPagePreview'
import PlanTabOverviewPage from './PlanTabOverviewPage'
import PlanTabQuestionsPage from './PlanTabQuestionsPage'
import PlanTabSuccessPage from './PlanTabSuccessPage'
import PlanTabSuccessPagePreview from './PlanTabSuccessPagePreview'
import PlanTabTemplateAdmin from './PlanTabTemplateAdmin'
import PlanVideoTemplatePage from './PlanVideoTemplatePage'

var tinycolor = require('tinycolor2')

let autoFetchProject: any

const PlanTab = (props: IProps) => {
  const { hasUserMadeChange, dispatchGlobalContext } = useContext(GlobalContext)
  const { userProfile } = useContext(UserContext)

  // Props
  const { projectTitle, history, location, match, project, clips, theme, setTheme, setProject, exitProject } = props

  const { hasBrandedUploadPage } = userProfile.plan

  // States
  const [activeTab, setActiveTab] = useState<string>('overview')
  const [validationError, setValidationError] = useState<string | null>(null)
  const [heroVideoEncodingStatus, setHeroVideoEncodingStatus] = useState(project.heroVideoEncodingStatus)
  const [showProjectPictureModal, setShowProjectPictureModal] = useState<boolean>(false)
  const [showProjectVideoModal, setShowProjectVideoModal] = useState(false)

  // Url search query navigation
  useEffect(() => {
    if (location.search.startsWith('?tab=overview')) setActiveTab('Overview')
    else if (location.search.startsWith('?tab=branding')) setActiveTab('Branding')
    else if (location.search.startsWith('?tab=landingPage')) setActiveTab('Landing Page')
    else if (location.search.startsWith('?tab=questions')) setActiveTab('Questions')
    else if (location.search.startsWith('?tab=formats')) setActiveTab('Formats')
    else if (location.search.startsWith('?tab=dataCollection')) setActiveTab('Data Collection')
    else if (location.search.startsWith('?tab=successPage')) setActiveTab('Success Page')
    else if (location.search.startsWith('?tab=videoLayout')) setActiveTab('Video Layout')
    else if (location.search.startsWith('?tab=templateAdmin')) setActiveTab('Template Admin')
    else history.push(`/projects/${match.params.projectSlug}/plan?tab=overview`)
  }, [history, location.search, match.params.projectSlug])

  // Fetch project if the hero video is encoding
  useEffect(() => {
    const fetchProject = async () => {
      const slug = match.params.projectSlug

      clearTimeout(autoFetchProject)

      const { data: projectData, error } = await Data.getProject(slug)

      if (error) return alert('An error occurred')

      setHeroVideoEncodingStatus(projectData.heroVideoEncodingStatus)

      const videoHeroInProgress = ![0, 199, 200].includes(projectData.heroVideoEncodingStatus)

      // If clips are encoding, fetch clips every 5 seconds
      if (videoHeroInProgress) autoFetchProject = setTimeout(() => fetchProject(), 5000)
    }

    fetchProject()

    return () => clearTimeout(autoFetchProject)
  }, [match.params.projectSlug, setProject])

  useEffect(() => {
    setProject({ ...project, heroVideoEncodingStatus: heroVideoEncodingStatus })
  }, [heroVideoEncodingStatus])

  // ------------------------------------------------Project handles------------------------------------------------
  const handleSaveNewProjectPicture = async (imageBlob: Blob) => {
    const data = new FormData()

    data.append('imageBlob', imageBlob)

    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { error } = await Data.updateProjectImage(project.slug, data)

    if (error) {
      dispatchGlobalContext({ type: 'set loading', payload: false })

      return alert('An error occurred')
    }

    window.location.href = `/projects/${match.params.projectSlug}/plan?tab=landingPage`
  }

  const handleCancelPictureUpload = () => {
    setShowProjectPictureModal(false)
  }

  const handleUploadSuccess = () => {
    setShowProjectVideoModal(false)
    window.location.href = `/projects/${match.params.projectSlug}/plan?tab=landingPage`
  }

  const handleCancelVideoUpload = () => {
    setShowProjectVideoModal(false)
  }

  const handleProjectChange = (e: any, data: any) => {
    const { name, value, checked, values } = data

    // Record any change made on the form
    if (!hasUserMadeChange) dispatchGlobalContext({ type: 'set user has made changes', payload: true })

    // Reset errors
    if (validationError === name) setValidationError(null)

    let newValue

    // if value is a boolean
    if (['shared', 'showRecentContributions', 'allowRecordVideo', 'allowUploadFile', 'allowQrCode'].includes(name)) newValue = checked
    // If value is an array
    else if (name === 'questions') newValue = values
    // If no deadline, set it to null
    else if (name === 'deadline' && value === '') newValue = null
    // Else set new value
    else newValue = value

    setProject({ ...project, [name]: newValue })
  }

  const handleChangePrimaryFont = (newFont: { family: string }) => {
    if (!hasBrandedUploadPage) return

    if (!hasUserMadeChange) dispatchGlobalContext({ type: 'set user has made changes', payload: true })

    const newFontFamily = newFont.family

    setProject({ ...project, ...{ displaySetting: { ...project.displaySetting, ...{ primaryFont: newFontFamily } } } })
  }

  const handleChangePrimaryColor = (newColor: { hex: string }) => {
    if (!hasBrandedUploadPage) return

    if (!hasUserMadeChange) dispatchGlobalContext({ type: 'set user has made changes', payload: true })

    const hexColor = newColor.hex

    setProject({
      ...project,
      ...{
        displaySetting: {
          ...project.displaySetting,
          ...{
            primaryColor: hexColor,
            buttonTextColor: tinycolor(hexColor).getBrightness() >= 200 ? '#000000' : '#FFFFFF',
          },
        },
      },
    })
  }

  const handleSubmitProject = async () => {
    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.updateProject(project)

    if (error) {
      dispatchGlobalContext({ type: 'set loading', payload: false })
      alert('Oops, something went wrong')
    }

    setTimeout(() => {
      setProject(data)
      dispatchGlobalContext({ type: 'set loading', payload: false })
    }, 1000)
  }

  // ------------------------------------------------Theme handles------------------------------------------------
  const handleChangeTheme = (newTheme: ITheme) => {
    dispatchGlobalContext({ type: 'set user has made changes', payload: true })

    setTheme(newTheme)
  }

  const handleSubmitTheme = async () => {
    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.updateTheme(theme)

    dispatchGlobalContext({ type: 'set user has made changes', payload: false })

    if (error) {
      dispatchGlobalContext({ type: 'set loading', payload: false })

      dispatchGlobalContext({ type: 'set user has made changes', payload: false })

      return alert(`An error occurred while updating your theme => ${error.response?.data}`)
    }

    setTheme(data)

    setTimeout(() => dispatchGlobalContext({ type: 'set loading', payload: false }), 1000)
  }

  // TODO: here we save both the project and the theme every time the user clicks "save". We should separate that.
  const hasFormError = () => {
    // Use to test if a field is a absolute url
    const UrlRegex = new RegExp(
      /(http|https):\/\/www\.[a-zA-Z]+\.[a-zA-Z]+|www\.[a-zA-Z]+\.[a-zA-Z]+|[a-zA-Z]+\.[a-z]+/,
      'i'
    )

    const { name, title, description, teamTermsUrl, successTextDescription, successCTAText, successCTALink } = project
    console.log(UrlRegex.test(successCTALink))
    console.log(successCTALink)

    if (!name) {
      setValidationError('name')
      history.push(`/projects/${match.params.projectSlug}/plan?tab=overview`)
      return true
    }

    if (!title) {
      setValidationError('title')
      history.push(`/projects/${match.params.projectSlug}/plan?tab=landingPage`)
      return true
    }

    if (!description) {
      setValidationError('description')
      history.push(`/projects/${match.params.projectSlug}/plan?tab=landingPage`)
      return true
    }

    if (teamTermsUrl && !UrlRegex.test(teamTermsUrl)) {
      setValidationError('teamTermsUrl')
      history.push(`/projects/${match.params.projectSlug}/plan?tab=landingPage`)
      return true
    }

    if (!successTextDescription) {
      setValidationError('successTextDescription')
      history.push(`/projects/${match.params.projectSlug}/plan?tab=successPage`)
      return true
    }

    if (!successCTAText) {
      setValidationError('successCTAText')
      history.push(`/projects/${match.params.projectSlug}/plan?tab=successPage`)
      return true
    }

    if (!UrlRegex.test(successCTALink)) {
      setValidationError('successCTALink')
      history.push(`/projects/${match.params.projectSlug}/plan?tab=successPage`)
      return true
    }
  }

  const goToNextStep = () => {
    if (location.search.startsWith('?tab=overview'))
      history.push(`/projects/${match.params.projectSlug}/plan?tab=branding`)
    if (location.search.startsWith('?tab=branding'))
      history.push(`/projects/${match.params.projectSlug}/plan?tab=landingPage`)
    if (location.search.startsWith('?tab=landingPage'))
      history.push(`/projects/${match.params.projectSlug}/plan?tab=questions`)
    if (location.search.startsWith('?tab=questions'))
      history.push(`/projects/${match.params.projectSlug}/plan?tab=formats`)
    if (location.search.startsWith('?tab=formats'))
      history.push(`/projects/${match.params.projectSlug}/plan?tab=dataCollection`)
    if (location.search.startsWith('?tab=dataCollection'))
      history.push(`/projects/${match.params.projectSlug}/plan?tab=successPage`)
    if (location.search.startsWith('?tab=successPage'))
      history.push(`/projects/${match.params.projectSlug}/plan?tab=videoLayout`)
    if (location.search.startsWith('?tab=videoLayout'))
      history.push(`/projects/${match.params.projectSlug}/invite`)
  }

  const handleSave = () => {
    if (hasFormError()) return
    handleSubmitProject()
    handleSubmitTheme()
  }

  const handleNext = () => {
    if (hasFormError()) return
    handleSubmitProject()
    handleSubmitTheme()
    goToNextStep()
  }

  const handleFinish = () => {
    if (hasFormError()) return
    handleSubmitProject()
    handleSubmitTheme()
    history.push(`/projects/${match.params.projectSlug}/invite`)
  }

  const handleVideoHeroClick = () => {
    if (!userProfile!.plan.projectHeroVideo) {
      return dispatchGlobalContext({ type: 'show update plan modal', payload: true })
    }

    return setShowProjectVideoModal(true)
  }

  const { slug, displaySetting } = project
  const { primaryFont, primaryColor, buttonTextColor } = displaySetting

  const uploadPageTheme = {
    primaryFont: hasBrandedUploadPage ? primaryFont : 'Roboto',
    primaryColor: hasBrandedUploadPage ? primaryColor : '#6b50ff',
    buttonTextColor: hasBrandedUploadPage ? buttonTextColor : '#FFFFFF',
  }
  console.log('Rendering Plan tab Container', activeTab)

  return (
    <ThemeSwitcher theme={uploadPageTheme}>
      {/* title */}
      <Title title={projectTitle}>
        <>
          <Button compact secondary disabled={!hasUserMadeChange} onClick={handleSave}>
            Save
          </Button>
          {activeTab === 'Video Layout' ? (
            <Button compact color="orange" onClick={handleFinish}>
              Finish Setup
            </Button>
          ) : (
            <Button compact primary onClick={handleNext}>
              Next
            </Button>
          )}
          <Button compact onClick={exitProject}>
            Close
          </Button>
        </>
      </Title>

      <SubSection hasGreyBackground>
        <>
          {activeTab === 'Overview' && (
            <PlanTabOverviewPage
              project={project}
              clips={clips}
              handleChange={handleProjectChange}
              validationError={validationError}
            />
          )}

          {activeTab === 'Branding' && (
            <PlanTabBrandingPage
              project={project}
              handleChangePrimaryFont={handleChangePrimaryFont}
              handleChangePrimaryColor={handleChangePrimaryColor}
            />
          )}

          {activeTab === 'Questions' && (
            <Grid stackable>
              <Grid.Column width={11}>
                <PlanTabQuestionsPage
                  project={project}
                  plan={userProfile.plan}
                  handleChange={handleProjectChange}
                />
              </Grid.Column>

              <Grid.Column width={5}>
                <PlanTabLandingPagePreview project={project} />
              </Grid.Column>
            </Grid>
          )}

          {activeTab === 'Formats' && (
            <Grid stackable>
              <Grid.Column width={11}>
                <PlanTabFormatsPage
                  project={project}
                  handleChange={handleProjectChange}
                />
              </Grid.Column>

              <Grid.Column width={5}>
                <PlanTabLandingPagePreview project={project} />
              </Grid.Column>
            </Grid>
          )}

          {activeTab === 'Landing Page' && (
            <Grid stackable>
              <Grid.Column width={11}>
                <PlanTabLandingPage
                  project={project}
                  handleChange={handleProjectChange}
                  setShowProjectPictureModal={setShowProjectPictureModal}
                  handleVideoHeroClick={handleVideoHeroClick}
                  validationError={validationError}
                />
              </Grid.Column>

              <Grid.Column width={5}>
                <PlanTabLandingPagePreview project={project} />
              </Grid.Column>
            </Grid>
          )}

          {activeTab === 'Data Collection' && (
            <Grid stackable>
              <Grid.Column width={11}>
                <PlanTabCollectPage
                  project={project}
                  handleChange={handleProjectChange}
                />
              </Grid.Column>

              <Grid.Column width={5}>
                <PlanTabCollectPagePreview project={project} />
              </Grid.Column>
            </Grid>
          )}

          {activeTab === 'Success Page' && (
            <Grid stackable>
              <Grid.Column width={11}>
                <PlanTabSuccessPage
                  project={project}
                  handleChange={handleProjectChange}
                  validationError={validationError}
                />
              </Grid.Column>

              <Grid.Column width={5}>
                <PlanTabSuccessPagePreview project={project} />
              </Grid.Column>
            </Grid>
          )}

          {activeTab === 'Video Layout' && (
            <PlanVideoTemplatePage
              theme={theme}
              handleChangeTheme={handleChangeTheme}
            />
          )}

          {activeTab === 'Template Admin' && (
            <PlanTabTemplateAdmin
              theme={theme}
              updateTheme={handleChangeTheme}
            />
          )}

          {showProjectVideoModal && (
            <UploaderModal
              title="Upload a video for your landing page"
              endpoint={`/projects/${slug}/updateVideo`}
              requirements={{
                maxFileSize: '160mb',
                maxFiles: 1,
                minDuration: 2,
                acceptedFileTypes: ['mp4', 'mov'],
                acceptedFileMimeTypes: ['video/mp4', 'video/quicktime'],
              }}
              handleUploadSuccess={handleUploadSuccess}
              handleClose={handleCancelVideoUpload}
            />
          )}

          {showProjectPictureModal && (
            <ImageUploader
              inputImageType={projectImageSize.inputImageType}
              outputImageType={projectImageSize.outputImageType}
              imageSize={{ width: projectImageSize.width, height: projectImageSize.height }}
              imageIsAvatar={projectImageSize.imageIsAvatar}
              handleSave={handleSaveNewProjectPicture}
              handleCancel={handleCancelPictureUpload}
            />
          )}
        </>
      </SubSection>
    </ThemeSwitcher>
  )
}

interface IProps extends RouteComponentProps<any> {
  projectTitle: string
  project: IProject
  clips: IClip[]
  theme: ITheme
  setProject: (arg: IProject) => void
  setTheme: (arg: ITheme) => void
  exitProject: () => void
}

export default withRouter(PlanTab)
