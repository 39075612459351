import React, { useState } from 'react'

import { Modal, Form, Message, Button, InputOnChangeData, DropdownProps } from 'semantic-ui-react'

import TeamMember from './TeamMember'

const MyTeamTabUpdateUserModal = ({
  teamMember,
  projects,
  errorMessage,
  setShowModal,
  handleUpdateTeamMember: handleUpdateTeamMember,
}: IProps) => {
  // States
  const [user, setUser] = useState<TeamMember>(teamMember)

  const handleRoleChange = (e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setUser({ ...user, role: value as string })
  }

  const handleProjectsChange = (e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setUser({ ...user, projectIds: value as number[] })
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    handleUpdateTeamMember(user)
  }

  const projectOptions = projects.map((project) => ({
    key: project.id,
    text: project.name,
    value: project.id,
  }))

  console.log('Update team user form')

  return (
    <Modal dimmer="blurring" open size="mini" onClose={() => setShowModal(false)}>
      <Modal.Header>Update team member </Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ width: '100%' }}>
          <Form id="update-user-form" error={!!errorMessage} onSubmit={handleSubmit}>
            <Form.Input
              fluid
              disabled // User should not be able to change members username
              icon="user"
              iconPosition="left"
              type="text"
              name="username"
              value={user.username}
              autoComplete="off"
            />
            <Form.Input
              fluid
              disabled // User should not be able to change members email
              icon="mail"
              iconPosition="left"
              type="email"
              name="email"
              value={user.email}
              placeholder="E-mail address"
              autoComplete="off"
            />
            <Form.Select
              fluid
              label="Role"
              name="role"
              options={[
                { key: 'admin', text: 'Admin', value: 'admin' },
                { key: 'member', text: 'Member', value: 'member' },
                { key: 'collaborator', text: 'Collaborator', value: 'collaborator' },
              ]}
              value={user.role}
              placeholder="Role"
              onChange={handleRoleChange}
            />
            {user.role === 'collaborator' && (
              <Form.Select
                fluid
                label="Projects"
                multiple
                name="projectIds"
                options={[...projectOptions]}
                value={user.projectIds}
                placeholder="Select projects"
                onChange={handleProjectsChange}
              />
            )}

            {errorMessage && <Message error>{errorMessage}</Message>}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>Close</Button>
        <Button primary type="submit" form="update-user-form">
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  teamMember: TeamMember
  projects: any[]
  errorMessage: string | null
  setShowModal: (arg: boolean) => void
  handleUpdateTeamMember: (arg: TeamMember) => void
}

export default MyTeamTabUpdateUserModal
