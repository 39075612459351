import React, { useState, useRef, FC } from 'react'

import Slider from 'rc-slider'
import AvatarEditor from 'react-avatar-editor'
import { Button, Modal, Grid } from 'semantic-ui-react'

import 'rc-slider/assets/index.css'

const ImageUploader: FC<IProps> = (props) => {
  // Props
  const { handleSave, handleCancel } = props
  const { imageSize, imageIsAvatar, loading = false, inputImageType, outputImageType } = props

  // Refs
  const avatarEditorRef = useRef<AvatarEditor>(null)

  // States
  const [imageScale, setImageScale] = useState(1.2)
  const [inputImage, setImageToUpload] = useState<any>(null)

  const dataURItoBlob = (dataURI: string) => {
    const binary = atob(dataURI.split(',')[1])
    const array = []
    for (let i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i))

    const newBlob = new Blob([new Uint8Array(array)], { type: outputImageType })
    console.log('newBlob', newBlob)
    return newBlob
  }

  const submitNewPicture = async () => {
    if (avatarEditorRef.current) {
      // Get the resized canvas
      const canvasResized = avatarEditorRef.current.getImageScaledToCanvas()
      // Transform the canvas into a dataURL
      const dataUrl = canvasResized.toDataURL(outputImageType)
      // Transform the dataURL into a Blob
      const imageBlob = dataURItoBlob(dataUrl)

      handleSave(imageBlob)
    }
  }

  const triggerFilePicker = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = inputImageType

    input.onchange = (e: any) => {
      // getting a hold of the file reference
      console.log(e.target)
      const file = e.target!.files[0]

      const imageToUploadUrl = URL.createObjectURL(file)

      setImageToUpload({ type: file.type, imageToUploadUrl })
    }

    input.click()
  }

  console.log('Rendering Image Uploader', inputImage, outputImageType)

  return (
    <Modal dimmer="blurring" open style={{ position: 'relative', margin: 'auto' }}>
      <Modal.Header>Upload Image</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Column textAlign="center">
            {!inputImage ? (
              <Button secondary inverted onClick={triggerFilePicker}>
                Select an image
              </Button>
            ) : (
              <>
                <AvatarEditor
                  ref={avatarEditorRef}
                  image={inputImage.imageToUploadUrl}
                  width={imageSize.width}
                  height={imageSize.height}
                  border={50}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={imageScale}
                  rotate={0}
                  // The 2 attributes below have been disabled because they do not exist as a type in the react-avatar-editor
                  // If the image selected by the user is a png we tell Avatar editor that the image is transparent
                  // isTransparent={inputImage.type === 'image/png' && outputImageType === 'image/jpeg'}
                  // If input image is transparent and we want a jpeg as output we tell react avatar editor to draw a white background behind the png
                  // backgroundColor={
                  //   inputImage.type === 'image/png' && outputImageType === 'image/jpeg' ? '#FFF' : 'transparent'
                  // }
                  crossOrigin="anonymous"
                  borderRadius={imageIsAvatar ? imageSize.width / 2 : 0}
                  style={{ backgroundColor: outputImageType === 'image/png' ? '#E6E6E6' : '#FFF' }}
                />
                <div style={{ width: '350px', height: '20px', margin: '0 auto' }}>
                  <Slider min={0.5} max={3} step={0.1} defaultValue={1.2} onChange={(value) => setImageScale(value)} />
                </div>
              </>
            )}
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel}>Close</Button>

        <Button primary disabled={!inputImage} loading={loading} onClick={submitNewPicture}>
          Save image
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  imageSize: { width: number; height: number }
  imageIsAvatar: boolean
  loading?: boolean
  inputImageType: string
  outputImageType: string
  handleSave: (arg: Blob) => void
  handleCancel: () => void
}

export default ImageUploader
