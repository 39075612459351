import React from 'react'

import { Icon, Image, Header, Button, Dimmer, Form, Rating } from 'semantic-ui-react'

import blackBackground from '../../../_images/blackBackground.jpg'
import { IProject } from '../../../api/Data'

import PlanTabPhoneFrame from './PlanTabPhoneFrame'

const PlanTabCollectPagePreview = ({ project }: { project: IProject }) => {
  const { displaySetting } = project
  const { showEmail, showLocation, showCaption1, showCaption2, showCaption3, showRating, showOrganization } =
    displaySetting
  const { caption1, caption2, caption3, ratingPrompt } = displaySetting

  return (
    <>
      <PlanTabPhoneFrame project={project}>
        <>
          <Header as="h3" textAlign="center">
            Submit your video
            <Header.Subheader>Clip 1</Header.Subheader>
          </Header>
          {/* image */}
          <Dimmer.Dimmable>
            <Image src={blackBackground} rounded />
            <Dimmer active>
              <Icon name="play circle outline" size="huge" />
            </Dimmer>
          </Dimmer.Dimmable>
          <Form size="mini" style={{ marginTop: '1rem' }}>
            {/* show name */}
            <Form.Field required>
              <label style={{ textAlign: 'center' }}>Your name</label>
              <Form.Input placeholder="Type here..." />
            </Form.Field>

            {/* show organization */}
            {showOrganization && (
              <Form.Field required>
                <label style={{ textAlign: 'center' }}>Your organization's name</label>
                <Form.Input placeholder="Type here..." />
              </Form.Field>
            )}

            {/* show email */}
            {showEmail && (
              <Form.Field required>
                <label style={{ textAlign: 'center' }}>Your email</label>
                <Form.Input placeholder="Type here..." />
              </Form.Field>
            )}

            {/* show caption1 */}
            {showCaption1 && (
              <Form.Field required>
                <label style={{ textAlign: 'center' }}>{caption1}</label>
                <Form.Input placeholder="Type here..." />
              </Form.Field>
            )}

            {/* show caption2 */}
            {showCaption2 && (
              <Form.Field required>
                <label style={{ textAlign: 'center' }}>{caption2}</label>
                <Form.Input placeholder="Type here..." />
              </Form.Field>
            )}

            {/* show caption3 */}
            {showCaption3 && (
              <Form.Field required>
                <label style={{ textAlign: 'center' }}>{caption3}</label>
                <Form.Input placeholder="Type here..." />
              </Form.Field>
            )}

            {/* show location */}
            {showLocation && (
              <Form.Field required>
                <label style={{ textAlign: 'center' }}>Your location</label>
                <Form.Input placeholder="Type here..." />
              </Form.Field>
            )}

            {/* show rating */}
            {showRating && (
              <Form.Field required>
                <label style={{ textAlign: 'center' }}>{ratingPrompt}</label>
                <div style={{ margin: '1rem 0', textAlign: 'center' }}>
                  <Rating icon="star" defaultRating={4} maxRating={5} size="large" />
                </div>
              </Form.Field>
            )}

            {/* cta */}
            <Button fluid compact size="mini" primary style={{ margin: '3rem auto' }}>
              Complete submission
            </Button>
          </Form>
        </>
      </PlanTabPhoneFrame>
    </>
  )
}

export default PlanTabCollectPagePreview
