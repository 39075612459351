import React, { useState } from 'react'

import { Modal, Form, Button } from 'semantic-ui-react'

// Interface
import { ITheme } from '../../api/Data'

const DesignerDuplicateModal = ({ theme, handleDuplicate, setShowModal }: IProps) => {
  // States
  const [title, setTitle] = useState(`Duplicate of "${theme.title}"`)

  return (
    <Modal dimmer="blurring" open size="mini" onClose={() => setShowModal(null)}>
      <Modal.Header>Duplicate template</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Input
              autoFocus
              type="text"
              label="Name your new template"
              value={title}
              onChange={(e, { value }) => setTitle(value)}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(null)}>Close</Button>
        <Button primary onClick={() => handleDuplicate(title, theme.id)}>
          Duplicate
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  theme: ITheme
  handleDuplicate: (arg1: string, arg2: number) => void
  setShowModal: (arg: ITheme | null) => void
}

export default DesignerDuplicateModal
