import React, { FC, Ref, useContext, useState } from 'react'

import { FilePondErrorDescription, FilePondFile } from 'filepond'
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import filepondPluginImageSizeMetadata from 'filepond-plugin-image-size-metadata'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import { Message, Dimmer, Button, Modal, Loader, Grid, List } from 'semantic-ui-react'

import { API_URL } from '../../config/environments'
import { GlobalContext } from '../../contexts/GlobalContext'
import Auth from '../../utils/Auth'

// Plugin registration
registerPlugin(
  filepondPluginImageSizeMetadata,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFileMetadata
)

const UploaderModal: FC<IProps> = (props) => {
  // Global
  const { isMobile, dispatchGlobalContext } = useContext(GlobalContext)

  // Props
  const { pondRef = null, title, requirements, endpoint, handleUploadSuccess, handleClose } = props

  // State
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  // Use handle warning to display a message in case the user selects too many videos
  const handleWarning = (error: any, file?: FilePondFile | undefined, status?: any) => {
    if (error.body === 'Max files') setError(`Too many clips selected (max ${requirements.maxFiles})`)
    else setError(error.body)
  }

  const handleStartProcessingFiles = () => {
    setLoading(true)
  }

  const handleError = (error: FilePondErrorDescription, file?: FilePondFile | undefined, status?: any) => {
    if (error) {
      setLoading(false)
      if (error.code === 403) return dispatchGlobalContext({ type: 'show update plan modal' })
      else return alert(error)
    }
  }

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Dimmer active={loading}>
          <Loader>Upload in progress...</Loader>
        </Dimmer>
        <Grid textAlign="center" padded>
          <Grid.Row centered>
            <div className="filepond-wrapper" style={{ width: `${isMobile ? '100%' : '50%'}` }}>
              <FilePond
                ref={pondRef}
                labelIdle='<span class="filepond--label-action"><strong>Tap here</strong> to select from my library</span> '
                allowMultiple={requirements.maxFiles > 1}
                maxFiles={requirements.maxFiles}
                maxFileSize={requirements.maxFileSize}
                acceptedFileTypes={requirements.acceptedFileMimeTypes}
                instantUpload={true}
                onerror={handleError}
                onprocessfilestart={handleStartProcessingFiles}
                onwarning={handleWarning}
                onprocessfiles={handleUploadSuccess}
                server={{
                  url: `${API_URL}/rest/${endpoint}`,
                  timeout: 4 * 60 * 1000 + 55 * 1000, // timeout on the server is 5 mins so just want to have a lesser timeout of 4min and 55 seconds on the front end.
                  headers: { Authorization: `Bearer ${Auth.getToken()}` },
                }}
              />
              {error && <Message error={!!error}>{error}</Message>}
            </div>
          </Grid.Row>
          <Grid.Column width={16}>
            {/* Video Requirements List */}
            <List bulleted horizontal>
              <List.Item>
                Max file size:
                <strong> {requirements.maxFileSize}</strong>
              </List.Item>
              <List.Item>
                Max number of files:
                <strong> {requirements.maxFiles}</strong>
              </List.Item>
              <List.Item>
                Accepted formats:
                <strong> {requirements.acceptedFileTypes.join(', ')}</strong>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  pondRef?: Ref<FilePond>
  title: string
  requirements: any
  endpoint: string
  handleUploadSuccess: () => void
  handleClose: () => void
}

export default UploaderModal
