import React, { useContext } from 'react'

import { Button, Segment, Header, Image, List, Grid } from 'semantic-ui-react'

import avatarImage from '../../_images/avatar-default.jpg'
import { ITeam, IUser } from '../../api/Data'
import { UserContext } from '../../contexts/UserContext'

import UpgradeTag from '../../components/common/UpgradeTag'

import TeamMember from './TeamMember'

const MyTeamManageMembers = (props: IProps) => {
  const { userProfile } = useContext(UserContext)
  const { email, userAccessRights, plan } = userProfile!

  // Props
  const { team } = props
  const {
    handleReactivateUser,
    setShowConfirmDeletion,
    setShowAddTeamMemberModal,
    setShowUpdateTeamMemberModal,
    setUpdateTeamMember,
  } = props
  const { users } = team

  const currentUserIsTeamAdmin = userAccessRights === 'admin'
  const nbOfActiveUser = users.filter((member) =>
    ['member', 'admin', 'collaborator'].includes(member.userAccessRights)
  ).length

  const { maxNbOfUserPerTeam } = plan
  const isNbMembersMax = nbOfActiveUser >= plan.maxNbOfUserPerTeam

  const handleShowUpdateTeamMember = (user: IUser) => {
    const teamMember: TeamMember = {
      username: user.username,
      email: user.email,
      role: user.userAccessRights,
      projectIds: user.collaboratorProjects,
    }
    setUpdateTeamMember(teamMember)
    setShowUpdateTeamMemberModal(true)
  }

  return (
    <>
      <Header as="h3">Manage my team</Header>

      <Segment>
        <Header as="h6">
          {`Team members: ${nbOfActiveUser} / ${maxNbOfUserPerTeam}`}
          {isNbMembersMax && <UpgradeTag text="Upgrade your plan to add more team member to your team." />}
        </Header>
        <List divided verticalAlign="middle">
          {users.map((user) => (
            <List.Item key={user.id}>
              <List.Content floated="right">
                <Button size="mini" primary basic compact onClick={() => handleShowUpdateTeamMember(user)}>
                  Edit
                </Button>
                {currentUserIsTeamAdmin && user.email !== email && user.userAccessRights !== 'revoke' && (
                  <Button size="mini" color="red" basic compact onClick={() => setShowConfirmDeletion(user.id)}>
                    Remove from team
                  </Button>
                )}
                {user.userAccessRights === 'revoke' && (
                  <Button size="mini" primary basic compact onClick={() => handleReactivateUser(user.id)}>
                    Reactivate user
                  </Button>
                )}
              </List.Content>
              <Image avatar src={avatarImage} />
              <List.Content style={{ width: '70%', opacity: `${user.userAccessRights === 'revoke' ? 0.4 : 1}` }}>
                <Grid>
                  <Grid.Column width={4}>{user.username}</Grid.Column>
                  <Grid.Column width={7}>{user.email}</Grid.Column>
                  <Grid.Column width={5}>
                    {user.userAccessRights === 'collaborator'
                      ? user.userAccessRights + ' on ' + user.collaboratorProjects.length + ' projects'
                      : user.userAccessRights}
                  </Grid.Column>
                </Grid>
              </List.Content>
            </List.Item>
          ))}
        </List>
        {currentUserIsTeamAdmin && (
          <Button primary onClick={() => setShowAddTeamMemberModal(true)}>
            Add team member
          </Button>
        )}
      </Segment>
    </>
  )
}

interface IProps {
  team: ITeam
  handleReactivateUser: (arg: number) => void
  setShowConfirmDeletion: (arg: number) => void
  setShowAddTeamMemberModal: (arg: boolean) => void
  setShowUpdateTeamMemberModal: (arg: boolean) => void
  setUpdateTeamMember: (arg: TeamMember) => void
}

export default MyTeamManageMembers
