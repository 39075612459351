import React, { Fragment, useContext } from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Sidebar } from 'semantic-ui-react'

import { urlsWithoutSideBar, urlsWithSecondarySideBar } from '../../config/constants'
import { GlobalContext } from '../../contexts/GlobalContext'

import SideBarMain from './SideBarMain'
import SideBarSecondary from './SideBarSecondary'

const SideBar = ({ children, history, location }: IProps) => {
  const { isSideBarShown, isMobile, hasUserMadeChange, dispatchGlobalContext } = useContext(GlobalContext)

  const hideSideBarOnMobile = () => {
    if (isMobile) {
      dispatchGlobalContext({ type: 'hide side bar' })
    }
  }

  const goToPage = (pageUrl: string) => {
    hideSideBarOnMobile()

    if (hasUserMadeChange) {
      return dispatchGlobalContext({ type: 'set exit without saving modal', payload: pageUrl })
    }

    history.push(pageUrl)
  }

  // Do not display side bar for public pages
  if (urlsWithoutSideBar.some((url) => location.pathname.match(url))) return <Fragment>{children}</Fragment>

  // Define what type of sidebar to display
  const sideBarType = urlsWithSecondarySideBar.some((url) => location.pathname.match(url)) ? 'secondary' : 'main'

  return (
    <Sidebar.Pushable>
      <Sidebar
        size="large"
        animation="overlay"
        visible={isSideBarShown}
        onHide={hideSideBarOnMobile}
        inverted="true"
        style={{ height: isMobile ? 'calc(100vh - 42px)' : '100vh', overflowX: 'hidden', backgroundColor: '#FFF' }}
      >
        {sideBarType === 'main' && (
          <div style={{ width: '260px', height: '100%' }}>
            <SideBarMain goToPage={goToPage} />
          </div>
        )}

        {sideBarType === 'secondary' && (
          <div style={{ display: 'flex', width: '260px', height: '100%' }}>
            <div style={{ width: '60px' }}>
              <SideBarMain isSmallSideBar goToPage={goToPage}></SideBarMain>
            </div>
            <div style={{ width: '200px' }}>
              <SideBarSecondary goToPage={goToPage}></SideBarSecondary>
            </div>
          </div>
        )}
      </Sidebar>
      <Sidebar.Pusher>{children}</Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

interface IProps extends RouteComponentProps<any> {
  children: React.ReactNode
}

export default withRouter(SideBar)
