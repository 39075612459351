import React, { useState } from 'react'

import { Button, Grid, Header, Image, Modal, Label } from 'semantic-ui-react'

import { ITeam, IProject } from '../../api/Data'

import CopyToClipBoard from 'react-copy-to-clipboard'

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share'

import imageOnboardingGetStarted from '../../_images/onboarding-get-started.png'

const ProjectLinkModal = (props: IProjectLinkModalProps) => {
  //States
  const [copied, setCopied] = useState(false)

  // Props
  const { team, project, handleEndOnboarding } = props

  const { teamName } = team
  
  const { title, description, projectUrl } = project

  const socialMediaTitle = `Vloggi is a crowd sourcing video platform. You have been invited to contribute video clips to the Vloggi project "${title}" posted by ${teamName} `
  const socialMediaDescription = `Upload your video clips here`
  
  return (
    <Modal open style={{ width: '100%', height: '100%', marginTop: '-14px' }}>
      <Modal.Content style={{ padding: 0 }}>
        <Grid style={{ height: '100vh', minHeight: '700px', margin: 0 }}>
          <Grid.Column only="computer" width={7} style={{ backgroundImage: 'linear-gradient(to bottom, #f1eff6, #f2f0f6)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Header as="h1">
                Last steps - you're nearly all set up
              </Header>
              <Image src={imageOnboardingGetStarted} width={350} alt="vloggi" />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} computer={9}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
              <div style={{ height: '30px' }}>
                
              </div>
              <div style={{ padding: '0 100px' }}>
                <Header as="h2">
                  Finally - for the magic!
                </Header>
                <Header as="h4">
                  Share your project's magic link to start collecting videos from anyone, anywhere
                </Header>
                <div style={{ margin: '20px 0 10px 0' }}>
                  <CopyToClipBoard text={projectUrl} options={{ format: 'text/plain' }} onCopy={() => setCopied(true)}>
                    <Button as="div" labelPosition="left">
                      <Label as="a" basic>
                        {projectUrl.split('//')[1]}
                      </Label>
                      <Button icon primary>
                        {copied ? 'Copied' : 'Copy'}
                      </Button>
                    </Button>
                  </CopyToClipBoard>
                </div>

                {/* Share Social Media */}
                <Grid padded stackable style={{ width: '400px' }}>
                  <Grid.Column width={3}>
                    <EmailShareButton url={projectUrl} subject={socialMediaTitle} body={description}>
                      <EmailIcon size={44} round />
                    </EmailShareButton>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <FacebookShareButton url={projectUrl} quote={`${socialMediaTitle} \n ${socialMediaDescription}:`}>
                      <FacebookIcon size={44} round />
                    </FacebookShareButton>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <WhatsappShareButton url={projectUrl} title={`${socialMediaTitle} \n ${socialMediaDescription}: \n`}>
                      <WhatsappIcon size={44} round />
                    </WhatsappShareButton>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <LinkedinShareButton
                      url={projectUrl}
                      title={socialMediaTitle}
                      summary={socialMediaDescription}
                      source="www.vloggi.com"
                    >
                      <LinkedinIcon size={44} round />
                    </LinkedinShareButton>
                  </Grid.Column>
                </Grid>

                <Button color="orange" style={{ marginTop: '20px' }} onClick={handleEndOnboarding}>
                  Let's do this
                </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ height: '30px', margin: '12px 20px 0 0' }}>
                  6 / 6
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

interface IProjectLinkModalProps {
  team: ITeam
  project: IProject
  handleEndOnboarding: () => void
}

export default ProjectLinkModal
