import React, { useRef, useEffect, useState, useCallback } from 'react'

import { Segment, Button, Dimmer, Grid, Image, Label, Modal } from 'semantic-ui-react'

import Data from '../../../api/Data'
import { S3_BUCKET_URL } from '../../../config/environments'

const TransitionCard = ({ item }) => {
  const [dimmed, setDimmed] = useState(false)
  const videoPlayer = useRef(null)

  const playVideo = () => videoPlayer.current.play()

  const pauseVideo = () => videoPlayer.current.pause()

  const transitionS3URL = `${S3_BUCKET_URL}/themes/transitions`

  return (
    <Dimmer.Dimmable blurring onMouseEnter={() => setDimmed(true)} onMouseLeave={() => setDimmed(false)}>
      {item && <Image width="100%" height="107px" rounded src={`${transitionS3URL}/${item.title}.jpg`} />}
      <Label basic attached="top left">
        {item.title}
      </Label>
      <Dimmer active={dimmed} style={{ borderRadius: '4px' }}>
        {item && (
          /* eslint-disable-next-line */
          <video
            ref={videoPlayer}
            loop
            width="100%"
            height="auto"
            src={`${transitionS3URL}/${item.title}.mp4`}
            onMouseOver={playVideo}
            onMouseLeave={pauseVideo}
          />
        )}
      </Dimmer>
    </Dimmer.Dimmable>
  )
}

const DesignerTransitionSelectModal = ({ type, theme, updateTheme, setShowModal }) => {
  const [items, setItems] = useState(null)
  const [itemSelected, setItemSelected] = useState(theme.data.playlist[type]?.split(',')[0] || null)

  const fetchItems = useCallback(async () => {
    const { data, error } = await Data.getThemeAssets('transition')

    if (error) return alert('An error occurred')

    setItems(data.rows || [])
  }, [])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const handleCancel = () => setShowModal(null)

  const handleSelect = () => {
    const newTheme = { ...theme }

    const transitionName = itemSelected

    if (transitionName === 'None') newTheme.data.playlist[type] = null
    else newTheme.data.playlist[type] = `${transitionName},1`

    updateTheme(newTheme)
    setShowModal(null)
  }

  const selectItem = (id) => setItemSelected(id)

  if (!items) return false

  console.log('Rendering modal', items, itemSelected)

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>Change {type}</Modal.Header>

      <Modal.Content scrolling>
        <Modal.Description>
          <Grid columns={4}>
            {items.map((item) => (
              <Grid.Column key={item.id} onClick={() => selectItem(item.title)}>
                <Segment color={itemSelected === item.title && 'red'}>
                  {item.title && <TransitionCard item={item} />}
                </Segment>
              </Grid.Column>
            ))}
          </Grid>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleCancel}>Close</Button>

        <Button primary onClick={handleSelect}>
          Select
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DesignerTransitionSelectModal
