import React from 'react'

import { Button, Container, Divider, Form, Grid, Header, Image, Menu, Segment } from 'semantic-ui-react'

import ExternalLink from '../components/common/ExternalLink'

const StyleGuide = () => (
  <Container style={{ marginTop: '3em', backgroundColor: 'white' }}>
    <Header as="h1">Style sheet</Header>
    <Header as="h2" dividing>
      Typography
    </Header>
    {/* ------------------------------------------------Header------------------------------------------------ */}
    <Grid columns={2} stackable>
      <Grid.Column>
        <Header as="h2">Example headers</Header>
        <Header as="h1">
          Heading 1<Header.Subheader>SubHeading 1</Header.Subheader>
        </Header>
        <Header as="h2">
          Heading 2<Header.Subheader>SubHeading 2</Header.Subheader>
        </Header>
        <Header as="h3">
          Heading 3<Header.Subheader>SubHeading 3</Header.Subheader>
        </Header>
        <Header as="h4">
          Heading 4<Header.Subheader>SubHeading 4</Header.Subheader>
        </Header>
        <Header as="h5">
          Heading 5<Header.Subheader>SubHeading 5</Header.Subheader>
        </Header>
      </Grid.Column>

      {/* ------------------------------------------------Text------------------------------------------------ */}
      <Grid.Column>
        <Header as="h2">Example body text</Header>
        <p>
          Nullam quis risus eget <ExternalLink url="/">urna mollis ornare</ExternalLink> vel eu leo. Cum sociis natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula.
        </p>
        <p>
          <small>This line of text is meant to be treated as fine print.</small>
        </p>
        <p>
          The following snippet of text is <strong>rendered as bold text</strong>.
        </p>
        <p>
          The following snippet of text is <em>rendered as italicized text</em>.
        </p>
        <p>
          An abbreviation of the word attribute is <abbr title="attribute">attr</abbr>.
        </p>
        <p style={{ color: '#000000' }}> the folowwing text is full black</p>
        <p style={{ color: '#f9fafb' }}> the folowwing text is off white</p>
        <p style={{ color: '#f3f4f5' }}> the folowwing text is darkwhite</p>
        <p style={{ color: '#dcddde' }}> the folowwing text is mid White</p>
        <p style={{ color: '#ffffff' }}> the folowwing text is white</p>
      </Grid.Column>
    </Grid>

    <Header as="h2" dividing>
      Form elements
    </Header>

    <Grid columns={2}>
      {/* ------------------------------------------------Forms------------------------------------------------ */}
      <Grid.Column>
        <Form>
          <Form.Field>
            <label>Label goes here</label>
            <p>Value goes here</p>
          </Form.Field>
          <Form.Field>
            <Form.Input label="Label goes here" placeholder="Placeholder goes here" />
          </Form.Field>
          <Form.Field>
            <Form.TextArea label="Label goes here" placeholder="Placeholder goes here" />
          </Form.Field>
        </Form>
      </Grid.Column>
      <Grid.Column>
        <Segment placeholder>Primary segment</Segment>
        <Segment placeholder secondary>
          Secondary segment
        </Segment>
        <Segment placeholder tertiary>
          Tertiary segment
        </Segment>
        <Segment placeholder basic>
          Basic segment
        </Segment>
      </Grid.Column>
    </Grid>

    {/* ------------------------------------------------Menu------------------------------------------------ */}

    <Header as="h2" dividing>
      Menu
    </Header>
    <Grid columns={3} doubling>
      <Grid.Column>
        <Menu
          items={[
            { key: '1', name: 'link-1', content: 'Link' },
            { key: '2', name: 'link-2', content: 'Link' },
            { key: '3', name: 'link-3', content: 'Link' },
          ]}
          pointing
          secondary
        />
      </Grid.Column>

      <Grid.Column>
        <Menu
          items={[
            { key: '1', name: 'link-1', content: 'Link' },
            { key: '2', name: 'link-2', content: 'Link' },
            { key: '3', name: 'link-3', content: 'Link' },
          ]}
          pointing
          tabular
        />
      </Grid.Column>

      <Grid.Column>
        <Menu
          items={[
            { key: 'l1', name: 'link-1', content: 'Link' },
            { key: 'l2', name: 'link-2', content: 'Link' },
            { key: 't1', name: 'text-1', content: 'Right text', position: 'right' },
          ]}
          pointing
        />
      </Grid.Column>
    </Grid>
    <Header as="h2" dividing>
      Buttons
    </Header>
    <Grid columns="equal">
      <Grid.Column>
        <Button primary>Primary</Button>
        <Button secondary>Secondary</Button>
        <Button secondary inverted>
          Secondary minor
        </Button>
        <Button color="red" inverted>
          Negative
        </Button>
        <Button>Default</Button>
        <Button basic>Basic</Button>
        <Button compact>Compact</Button>

        <Divider />

        <Button icon="heart" />
        <Button content="Labeled" icon="heart" labelPosition="left" />
        <Button content="Labeled" icon="heart" labelPosition="right" />

        <Divider />

        <Button.Group>
          <Button>Combo</Button>
        </Button.Group>

        <Divider />

        <Button animated>
          <Button.Content visible>Horizontal</Button.Content>
          <Button.Content hidden>Hidden</Button.Content>
        </Button>
        <Button animated="vertical">
          <Button.Content visible>Vertical</Button.Content>
          <Button.Content hidden>Hidden</Button.Content>
        </Button>
        <Button animated="fade">
          <Button.Content visible>Fade In</Button.Content>
          <Button.Content hidden>Hidden</Button.Content>
        </Button>

        <Divider />

        <Button disabled>Disabled</Button>
        <Button loading>Loading</Button>

        <Divider />

        <Button.Group>
          <Button>1</Button>
          <Button>2</Button>
          <Button>3</Button>
        </Button.Group>

        <Button.Group>
          <Button icon="align left" />
          <Button icon="align center" />
          <Button icon="align right" />
          <Button icon="align justify" />
        </Button.Group>

        <Button.Group>
          <Button>1</Button>
          <Button.Or />
          <Button>2</Button>
        </Button.Group>

        <Divider />

        <Button.Group attached="top" widths={2}>
          <Button>One</Button>
          <Button>Two</Button>
        </Button.Group>
        <Segment attached>
          <Image src="/images/wireframe/paragraph.png" />
        </Segment>
        <Button.Group attached="bottom" widths={2}>
          <Button>One</Button>
          <Button>Two</Button>
        </Button.Group>
      </Grid.Column>

      <Grid.Column>
        <Button size="mini">Mini</Button>
        <Button size="tiny">Tiny</Button>
        <Button size="small">Small</Button>
        <Button size="large">Large</Button>
        <Button size="big">Big</Button>
        <Button size="huge">Huge</Button>
        <Button size="massive">Massive</Button>

        <Divider />

        <Button color="violet" style={{ marginBottom: '1em' }}>
          Violet
        </Button>
        <Button color="yellow" style={{ marginBottom: '1em' }}>
          Yellow
        </Button>
        <Button color="orange" style={{ marginBottom: '1em' }}>
          Orange
        </Button>
        <Button color="green" style={{ marginBottom: '1em' }}>
          Green
        </Button>
        <Button color="teal" style={{ marginBottom: '1em' }}>
          Teal
        </Button>
        <Button color="blue" style={{ marginBottom: '1em' }}>
          Blue
        </Button>
        <Button color="purple" style={{ marginBottom: '1em' }}>
          Purple
        </Button>
        <Button color="pink" style={{ marginBottom: '1em' }}>
          Pink
        </Button>
        <Button color="red" style={{ marginBottom: '1em' }}>
          Red
        </Button>
        <Button color="black" style={{ marginBottom: '1em' }}>
          Black
        </Button>

        <Divider />

        <Segment inverted>
          <Button inverted>Inverted</Button>
          <Button basic inverted>
            Basic
          </Button>
          <Button color="blue" inverted>
            Colored
          </Button>
          <Button basic color="blue" inverted>
            Basic Colored
          </Button>
        </Segment>
      </Grid.Column>
    </Grid>
  </Container>
)

export default StyleGuide
