import React, { useEffect, useState, useCallback } from 'react'

import { Button, Dimmer, Grid, Header, Image, Modal, Segment } from 'semantic-ui-react'

import musicPlaceholderImg from '../../../_images/music-placeholder.jpeg'
import Data from '../../../api/Data'
import { S3_BUCKET_URL } from '../../../config/environments'

import UploaderModal from '../../../components/modals/UploaderModal'

const DesignerMusicSelectModal = ({ type, theme, updateTheme, setShowModal }) => {
  const [items, setItems] = useState()
  const [itemSelected, setItemSelected] = useState(theme.data.playlist.music)
  const [showUploadModal, setShowUploadModal] = useState(false)

  const fetchItems = useCallback(async () => {
    const { data, error } = await Data.getThemeAssets('music')

    if (error) return alert('An error occurred')

    const musics = data
    setItems(musics.rows || [])
  }, [])

  useEffect(() => {
    console.log('fetching')

    fetchItems()
  }, [fetchItems])

  const handleCancel = () => setShowModal(null)

  const handleDelete = async () => {
    const { error } = await Data.deleteThemeAsset(itemSelected)

    if (error) return alert('An error occurred')

    return fetchItems()
  }

  const handleSelect = (itemId) => {
    const newTheme = { ...theme }
    newTheme.data.playlist.music = itemId
    updateTheme(newTheme)
    setShowModal(null)
  }

  const handleClose = () => {
    setShowUploadModal(false)
  }

  const handleUploadComplete = () => {
    setShowUploadModal(false)
    fetchItems()
  }

  const selectItem = (id) => setItemSelected(id)

  if (!items) return false

  const fullMusicList = [{ id: null, title: 'None' }, ...items]

  console.log('Rendering modal', items, itemSelected)

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>
        <Grid columns="equal">
          <Grid.Column>Change {type}</Grid.Column>

          <Grid.Column>
            <Button floated="right" inverted secondary onClick={() => setShowUploadModal(true)}>
              {`Upload my ${type}`}
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Header>

      <Modal.Content scrolling>
        <Modal.Description>
          <Grid columns={5}>
            {fullMusicList.map((item) => (
              <Grid.Column key={item.id} onClick={() => selectItem(item.id)}>
                <Segment color={itemSelected === item.id && 'red'}>
                  <Dimmer.Dimmable blurring dimmed>
                    <Image src={musicPlaceholderImg} />
                    <Dimmer active style={{ cursor: 'pointer', borderRadius: '4px' }}>
                      <Header inverted as="h3">
                        {item.title}
                      </Header>
                    </Dimmer>
                  </Dimmer.Dimmable>
                </Segment>
              </Grid.Column>
            ))}
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Grid centered padded>
        <Grid.Column width={14}>
          {itemSelected && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <audio style={{ width: '100%' }} autoPlay controls src={`${S3_BUCKET_URL}/themes/assets/${itemSelected}`} />
          )}
        </Grid.Column>
      </Grid>

      <Modal.Actions>
        <Button onClick={handleCancel}>Close</Button>
        <Button inverted color="red" onClick={() => handleDelete(itemSelected)}>
          Delete
        </Button>
        <Button primary onClick={() => handleSelect(itemSelected)}>
          Select
        </Button>
      </Modal.Actions>
      {/* music uploader */}
      {showUploadModal && (
        <UploaderModal
          title="Upload your own music"
          endpoint="themeAssets?type=music"
          requirements={{
            maxFileSize: '80mb',
            maxFiles: 1,
            minDuration: null,
            acceptedFileTypes: ['mp3'],
            acceptedFileMimeTypes: ['audio/mpeg'],
          }}
          handleUploadSuccess={handleUploadComplete}
          handleClose={handleClose}
        />
      )}
    </Modal>
  )
}

export default DesignerMusicSelectModal
