import React, { useState, useEffect, useContext } from 'react'

import { Button, Grid, Header, Image, Modal, Segment } from 'semantic-ui-react'

import UploadFromComputerImage from '../../_images/upload-form-computer.png'
import UploadFromPhoneImage from '../../_images/upload-from-phone.png'
import UploadFromPexelsImage from '../../_images/upload-from-sample-library.png'
import { IClip, IProject } from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'

import UploadForm from './UploadForm'
import UploadFromComputerModal from './UploadFromComputerModal'
import PexelsLibraryModal from './UploadFromPexelsModal'
import UploadFromPhoneModal from './UploadFromPhoneModal'

const UploadModal = ({ project, setShowModal }: IProps) => {
  // Global
  const { isMobile } = useContext(GlobalContext)

  // States
  const [step, setStep] = useState(1)
  const [projectSlug] = useState(project.slug)
  const [clipIds, setClipIds] = useState<number[]>([])
  const [uploads, setUploads] = useState<IClip[]>([])

  const [showFromComputerModal, setShowFromComputerModal] = useState(false)
  const [showFromPhoneModal, setShowFromPhoneModal] = useState(false)
  const [showFromPexelsModal, setShowFromPexelsModal] = useState(false)

  // Scroll up after each step
  useEffect(() => window.scrollTo(0, 0), [step])

  const { projectUrl, displaySetting } = project

  console.log('Rendering upload Modal', step, clipIds, uploads)

  return (
    <>
      {step === 1 && (
        <Modal dimmer="blurring" open>
          <Modal.Header>Upload Clips</Modal.Header>
          <Modal.Content>
            <Grid columns={3} textAlign="center" verticalAlign="middle" style={{ height: '300px' }}>
              <Grid.Column stretched>
                <Segment onClick={() => setShowFromComputerModal(true)}>
                  <Image src={UploadFromComputerImage} size="small" style={{ margin: '0 auto', cursor: 'pointer' }} />
                  <Header as="h3">From Your Computer</Header>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment onClick={() => setShowFromPhoneModal(true)}>
                  <Image src={UploadFromPhoneImage} size="small" style={{ margin: '0 auto', cursor: 'pointer' }} />
                  <Header as="h3">From Your Phone</Header>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment onClick={() => setShowFromPexelsModal(true)}>
                  <Image src={UploadFromPexelsImage} size="small" style={{ margin: '0 auto', cursor: 'pointer' }} />
                  <Header as="h3">From Our Sample Library</Header>
                </Segment>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button fluid={isMobile} onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {step === 2 && (
        <UploadForm
          clipIds={clipIds}
          displaySetting={displaySetting}
          uploads={uploads}
          setUploads={setUploads}
          setShowModal={setShowModal}
        />
      )}
      {/* From computer */}
      {showFromComputerModal && (
        <UploadFromComputerModal
          setClipIds={setClipIds}
          projectSlug={projectSlug}
          setStep={setStep}
          setShowModal={setShowFromComputerModal}
        />
      )}

      {/* From phone */}
      {showFromPhoneModal && <UploadFromPhoneModal projectUrl={projectUrl} setShowModal={setShowFromPhoneModal} />}

      {/* From Pexels  */}
      {showFromPexelsModal && (
        <PexelsLibraryModal
          projectSlug={projectSlug}
          setStep={setStep}
          setClipIds={setClipIds}
          setShowModal={setShowFromPexelsModal}
        />
      )}
    </>
  )
}

interface IProps {
  project: IProject
  setShowModal: (arg: boolean) => void
}

export default UploadModal
