import React from 'react'

import { Button, Divider, Grid, Header, Image, Item, Segment } from 'semantic-ui-react'

import closeIcon from '../../../_images/close-icon.svg'
import pinIcon from '../../../_images/pin-icon.svg'
import { IClip } from '../../../api/Data'
import moment from '../../../utils/Moment'

const MergeTabSelectedClipCard = ({ clip, removeClip }: IProps) => {
  // Props
  const { imageLowResUrl, contributor, place, location, createdAt } = clip
  const { caption1, caption2, caption3 } = clip

  const shortPlace = place?.length > 20 ? `${place.slice(0, 20)}...` : place
  const shortLocation = location?.length > 20 ? `${location.slice(0, 20)}...` : location
  const shortCaptions = [caption1, caption2, caption3].filter(Boolean).map((caption) => {
    return caption.length > 40 ? `${caption.slice(0, 40)}...` : caption
  })
  return (
    <>
      <Segment raised style={{ borderRadius: '0.2rem' }}>
        <Item.Group>
          <Item>
            <div style={{ position: 'relative', marginRight: '1rem', width: '212px' }}>
              <Image src={imageLowResUrl} rounded width="100%" />
              <Button
                style={{ position: 'absolute', right: '5px', top: '5px', padding: '0.25rem', margin: '0' }}
                negative
                circular
                onClick={() => removeClip(clip)}
                icon={<Image src={closeIcon} />}
              />
            </div>
            <Item.Content>
              <Grid columns={2}>
                <Grid.Column>
                  <Header as="h4">{contributor}</Header>

                  <Item.Meta>
                    <span>
                      <small>{moment(createdAt).format('ll')}</small>
                    </span>
                  </Item.Meta>
                </Grid.Column>

                <Grid.Column>
                  <Header as="h5" color="blue">
                    <Image src={pinIcon} width="20px" />
                    <Header.Content>
                      <strong>{shortPlace || 'Place unknown'}</strong>
                      <br />
                      {shortLocation}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid>
              <Item.Description>
                {shortCaptions.map((shortCaption) => (
                  <div key={shortCaption}>
                    <Divider fitted />
                    <p style={{ color: 'black' }}>
                      <small>{shortCaption}</small>
                    </p>
                  </div>
                ))}
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
    </>
  )
}

interface IProps {
  clip: IClip
  removeClip: (args: IClip) => void
}

export default MergeTabSelectedClipCard
