import React, { useState } from 'react'

import { RouteComponentProps } from 'react-router'
import { Grid, Form, Header, Message, Button, Image, Icon, InputOnChangeData, FormProps } from 'semantic-ui-react'

import vloggiLogo from '../../_images/vloggi-logo-blue.png'
import Data from '../../api/Data'

import PasswordInput from '../../components/common/PasswordInput'

const RecoverForm = ({ history, match }: IProps) => {
  const [fields, setFields] = useState<{ newPassword: string; passwordConfirmation: string }>({
    newPassword: '',
    passwordConfirmation: '',
  })
  const [success, setSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { name, value } = data

    setErrorMessage(null)
    setFields({ ...fields, [name]: value })
  }

  const validateForm = ({
    newPassword,
    passwordConfirmation,
  }: {
    newPassword: string
    passwordConfirmation: string
  }) => {
    if (!newPassword || !passwordConfirmation) {
      setErrorMessage('Missing fields')
      return false
    }
    if (newPassword !== passwordConfirmation) {
      setErrorMessage("Passwords don't match")
      return false
    }
    return true
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, data: FormProps) => {
    e.preventDefault()

    const isFormValid = validateForm(fields)

    if (!isFormValid) return

    setLoading(true)

    const { recoveryCode } = match.params

    const { error } = await Data.recoverPassword(recoveryCode, fields.newPassword)

    if (error) {
      setLoading(false)
      setErrorMessage(error.response.data)
      return
    }
    setSuccess(true)
  }

  const goToLoginPage = () => history.push(`/login`)

  return (
    <Grid container verticalAlign="middle" centered style={{ height: '100vh' }}>
      <Grid.Column mobile={16} computer={6}>
        <Image width="200" src={vloggiLogo} alt="vloggi" style={{ margin: '0 auto 2rem auto' }} />

        <Form error={!!errorMessage} success={success} onSubmit={handleSubmit}>
          <Header as="h1" style={{ marginBottom: '2rem' }}>
            <strong>Choose a new password</strong>
          </Header>
          <Form.Field>
            <label>New Password</label>

            <PasswordInput
              name="newPassword"
              value={fields.newPassword}
              placeholder="New password"
              handleChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Password Confirmation</label>
            <PasswordInput
              name="passwordConfirmation"
              value={fields.passwordConfirmation}
              placeholder="Confirm newPassword"
              handleChange={handleChange}
            />
          </Form.Field>
          {fields.newPassword.length > 0 && (
            <Form.Field>
              <label>
                <p style={{ margin: '0.5rem 0' }}>
                  {fields.newPassword.length >= 10 ? (
                    <span style={{ color: '#9d9d9d' }}>
                      <Icon name="check" />
                      At least 10 characters
                    </span>
                  ) : (
                    <span>
                      <Icon name="exclamation circle" style={{ color: 'red' }} />
                      At least 10 characters
                    </span>
                  )}
                </p>
                <p style={{ margin: '0.5rem 0' }}>
                  {/[A-Z]/.test(fields.newPassword) ? (
                    <span style={{ color: '#9d9d9d' }}>
                      <Icon name="check" />
                      At least 1 uppercase letter
                    </span>
                  ) : (
                    <span>
                      <Icon name="exclamation circle" style={{ color: 'red' }} />
                      At least 1 uppercase letter
                    </span>
                  )}
                </p>
                <p style={{ margin: '0.5rem 0' }}>
                  {/[!@#$%^&]/.test(fields.newPassword) ? (
                    <span style={{ color: '#9d9d9d' }}>
                      <Icon name="check" />
                      At least 1 of the symbols !@#$%^&amp;
                    </span>
                  ) : (
                    <span>
                      <Icon name="exclamation circle" style={{ color: 'red' }} />
                      At least 1 of the symbols !@#$%^&amp;
                    </span>
                  )}
                </p>
                <p style={{ margin: '0.5rem 0' }}>
                  {/[0-9]/.test(fields.newPassword) ? (
                    <span style={{ color: '#9d9d9d' }}>
                      <Icon name="check" />
                      At least 1 number
                    </span>
                  ) : (
                    <span>
                      <Icon name="exclamation circle" style={{ color: 'red' }} />
                      At least 1 number
                    </span>
                  )}
                </p>
              </label>
            </Form.Field>
          )}
          <Message success header="Success" content="Your password has been updated successfully" />

          <Message error header="Fail" content={errorMessage} />

          {success ? (
            <Button primary onClick={goToLoginPage} style={{ marginTop: '0.5rem' }}>
              Go to login page
            </Button>
          ) : (
            <Button primary type="submit" loading={loading} style={{ marginTop: '0.5rem' }}>
              Submit
            </Button>
          )}
        </Form>
      </Grid.Column>
    </Grid>
  )
}

interface IProps extends RouteComponentProps<any> {}

export default RecoverForm
