import React, { useEffect, useState } from 'react'

import { Button, Segment, Header, List, Grid, Image } from 'semantic-ui-react'

import avatarImage from '../../_images/avatar-default.jpg'
import googleDriveImage from '../../_images/google-drive.png'
import microsoftOneDriveImage from '../../_images/microsoft-onedrive.png'
import Data, { IIntegration, IntegrationProvider } from '../../api/Data'

const MyTeamIntegrations = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [integrations, setIntegrations] = useState<IIntegration[]>([])

  useEffect(() => {
    const fetchIntegrations = async () => {
      const { data, error } = await Data.getIntegrations()

      if (error) {
        console.error('Error getting integrations', error)
        return
      }

      setIntegrations(data || [])
    }

    fetchIntegrations()
  }, [])

  const openGoogleOAuth2Flow = async () => {
    // Set loading to true
    setIsLoading(true)

    const { data, error } = await Data.getIntegrationUrl(IntegrationProvider.GOOGLE)

    if (error) {
      console.error('Error getting Google OAuth2 URL', error)
      setIsLoading(false)
      return
    }

    // Generated auth url
    const url = data.url

    // Open the url in the current window
    window.open(url)

    // Set loading to false
    setIsLoading(false)
  }

  const deleteIntegration = (id: number) => async () => {
    const { error } = await Data.deleteIntegration(id)

    if (error) {
      console.error('Error deleting integration', error)
      return
    }

    // Remove the integration from the list
    setIntegrations(integrations.filter((integration) => integration.id !== id))
  }

  const integrationName = (providerName: string) => {
    switch (providerName) {
      case IntegrationProvider.GOOGLE:
        return 'Google Drive'
      case IntegrationProvider.MICROSOFT:
        return 'Microsoft OneDrive'
      default:
        return providerName
    }
  }

  const integrationImage = (providerName: string) => {
    switch (providerName) {
      case IntegrationProvider.GOOGLE:
        return googleDriveImage
      case IntegrationProvider.MICROSOFT:
        return microsoftOneDriveImage
      default:
        return avatarImage
    }
  }

  const createdDate = (createdDate: Date) => {
    return new Date(createdDate).toLocaleDateString()
  }

  return (
    <>
      <Header as="h3">Integrations</Header>

      <Segment>
        <Header as="h6">{`Integrations: ${integrations.length}`}</Header>
        <br />
        {integrations.length === 0 && <div>No integrations</div>}
        <List divided verticalAlign="middle">
          {integrations.map((integration) => (
            <List.Item key={integration.id}>
              <List.Content floated="right">
                <Button size="mini" color="red" basic compact onClick={deleteIntegration(integration.id)}>
                  Delete
                </Button>
              </List.Content>
              <Image avatar src={integrationImage(integration.provider)} />
              <List.Content style={{ width: '70%' }}>
                <Grid>
                  <Grid.Column width={6}>{integrationName(integration.provider)}</Grid.Column>
                  <Grid.Column width={5}>Created on {createdDate(integration.createdAt)} </Grid.Column>
                  <Grid.Column width={5}>Is active</Grid.Column>
                </Grid>
              </List.Content>
            </List.Item>
          ))}
        </List>
        <br />
        <Button primary onClick={openGoogleOAuth2Flow} loading={isLoading} disabled={isLoading}>
          Connect Google Drive
        </Button>
        {/* <Button primary onClick={openGoogleOAuth2Flow} loading={isLoading} disabled={isLoading}>
          Connect Microsoft OneDrive
        </Button> */}
      </Segment>
    </>
  )
}

export default MyTeamIntegrations
