import React from 'react'

import { Button, Grid, Header, Image, Modal } from 'semantic-ui-react'

import { onboardingThemes } from '../../config/constants'
import { ITheme } from '../../api/Data'

import ThemeCard from './ThemeCard'

import imageOnboardingBranding from '../../_images/onboarding-branding.png'

const ProjectThemeModal = (props: IProjectThemeModalProps) => {
  // Props
  const { themes, projectTheme, handleChangeProjectTheme, stepBack, stepForward, handleEndOnboarding } = props
  
  return (
    <Modal open style={{ width: '100%', height: '100%', marginTop: '-14px' }}>
      <Modal.Content style={{ padding: 0 }}>
        <Grid style={{ height: '100vh', minHeight: '700px', margin: 0 }}>
          <Grid.Column only="computer" width={7} style={{ backgroundImage: 'linear-gradient(to bottom, #3121a0, #1f248e)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Header inverted as="h1">
                Almost done - let's tailor Vloggi to your needs
              </Header>
              <Image src={imageOnboardingBranding} width={350} alt="vloggi" />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} computer={9}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleEndOnboarding}>
                  Skip
                </Button>
              </div>
              <div style={{ padding: '0 100px' }}>
                <Header as="h2">
                  Choose a project template to use to <strong>collect videos</strong> from <strong>your audience</strong>
                </Header>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '450px', overflow: 'auto', gap: '5px', margin: '20px 0 5px -4px' }}>
                  {themes.filter((theme) => onboardingThemes.includes(theme.id)).map((theme, i) => (
                    <ThemeCard key={i} theme={theme} selected={projectTheme !== null && theme.id === projectTheme.id} onClickSelect={handleChangeProjectTheme} />
                  ))}
                </div>
                <Button primary disabled={projectTheme === null} style={{ marginTop: '20px' }} onClick={stepForward}>
                  Next
                </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ margin: '12px 20px 0 0' }}>
                  4 / 6
                </div>
                <Button onClick={stepBack}>
                  Back
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

interface IProjectThemeModalProps {
  themes: ITheme[]
  projectTheme: ITheme | null
  handleChangeProjectTheme: (t: ITheme) => void
  stepBack: () => void
  stepForward: () => void
  handleEndOnboarding: () => void
}

export default ProjectThemeModal
