import { FC } from 'react'
/* eslint-disable no-undef */
import { useContext, useEffect } from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'

import { HAS_GOSQUARED_ANALYTICS } from '../config/environments'
import { UserContext } from '../contexts/UserContext'
import Auth from '../utils/Auth'

const GoSquaredAnalytics: FC<IProps> = ({ history, location }) => {
  const { userProfile } = useContext(UserContext)

  const identifyUser = (userEmail: string) => {
    console.log(`identifying user ${userEmail}`)
    window._gs('identify', { email: userEmail })
  }

  const unidentifyUser = () => {
    console.log(`unidentifying user`)
    window._gs('unidentify')
  }

  // Trigger when the user profile change (login, register, logout...)
  useEffect(() => {
    if (HAS_GOSQUARED_ANALYTICS) {
      const isUserLoggedIn = Auth.isAuthenticated()
      const isUserAdmin = Auth.getPayLoad().isAdmin

      if (!isUserLoggedIn) unidentifyUser()
      else if (!isUserAdmin) identifyUser(userProfile.email)
    }
  }, [userProfile])

  // Trigger at the start to make sure all pages will be tracked
  useEffect(() => {
    const logPageView = () => {
      // Logging page view for each history change
      console.log('GoSquared: logging page view', location.pathname + location.search)

      window._gs('track', location.pathname + location.search)
    }

    if (HAS_GOSQUARED_ANALYTICS) {
      // Necessary to log the initial page the user lands on
      console.log('GoSquared: initial page view', window.location.pathname + window.location.search)
      window._gs('track', window.location.pathname + window.location.search)

      const unListen = history.listen(logPageView)

      // Remove listener from history
      return () => {
        console.log('GoSquared: remove history listener', unListen)
        unListen()
      }
    }
  }, [history, location.pathname, location.search])

  return null
}

interface IProps extends RouteComponentProps<any> {}

export default withRouter(GoSquaredAnalytics)
