import React from 'react'

import { Checkbox, CheckboxProps } from 'semantic-ui-react'

const CustomLabelCheckbox = ({ name, checked, onClick }: IProps): JSX.Element => (
  <span style={{ float: 'right', marginLeft: '1rem' }}>
    <Checkbox toggle name={name} checked={checked} onClick={onClick} />
  </span>
)

interface IProps {
  name: string
  checked: boolean
  onClick: (event: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps) => void
}

export default CustomLabelCheckbox
