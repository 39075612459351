import React, { useContext, useEffect } from 'react'

import { Dropdown, Image, Grid, Header, Modal, Button, List } from 'semantic-ui-react'
import WebFont from 'webfontloader'

import threeDotsIconWhite from '../../_images/3-dots-white.svg'
import { ITheme } from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'
import Auth from '../../utils/Auth'
import rollbar from '../../utils/Rollbar'

import ThemePreview from './ThemePreview'

const ThemePreviewModal = (props: IProps) => {
  const { isMobile } = useContext(GlobalContext)

  // Props
  const { theme, handleEditTheme, handlePublishTheme, setShowModal, setShowPrjModal } = props
  const { setShowConfirmationModal, setShowDuplicateModal } = props

  // Send an event to rollbar each time a template is viewed
  useEffect(() => {
    rollbar.info(`Template view: ${theme.title}`)
  }, [theme.title])

  useEffect(() => {
    if (theme.data.playlist.defaultFonts) {
      theme.data.playlist.defaultFonts.forEach((font: string) => {
        return WebFont.load({
          google: {
            families: [`${font}`, 'Web:400,700,italic,normal'],
          },
        })
      })
    }
  }, [theme.data.playlist.defaultFonts])

  if (!theme) return null

  const { isAdmin } = Auth.getPayLoad()

  const { id, description, isPublished, data, videoSampleUrl, placeholderText } = theme

  const applicableDescription = description.indexOf('1.') !== -1 && description.indexOf('2.') !== -1 ? description.substr(description.indexOf('2.') + 2).trim() : description

  const placeholderTextObj = {
    creator: placeholderText.split(';')[0],
    place: placeholderText.split(';')[1],
    location: placeholderText.split(';')[2],
    caption1: placeholderText.split(';')[3],
    count: placeholderText.split(';')[4],
  }

  const ratio = 640 / 1920
  return (
    <Modal basic dimmer="blurring" open onClose={() => setShowModal(null)}>
      {isMobile ? (
        <Modal.Content>
          <ThemePreview section="vloggi" themeData={data} ratio={ratio} />
        </Modal.Content>
      ) : (
        <Modal.Content>
          <Grid>
            <Grid.Column width={4}>
              <Header inverted as="h2">
                Perfect for:
              </Header>
              <List bulleted>
                {applicableDescription.split(',').map((item) => (
                  <List.Item key={item}>{item}</List.Item>
                ))}
              </List>
            </Grid.Column>
            <Grid.Column stretched width={10}>
              <ThemePreview
                placeholderTextObj={placeholderTextObj}
                section="vloggi"
                themeData={data}
                ratio={ratio}
                backgroundAsset={videoSampleUrl}
                isBackgroundVideo
              />
            </Grid.Column>
          </Grid>
        </Modal.Content>
      )}

      <Modal.Actions>
        <Button inverted onClick={() => setShowModal(null)}>
          Close
        </Button>
        <Button primary onClick={() => setShowPrjModal(theme)}>
          Select
        </Button>
        {isAdmin && (
          <Dropdown
            style={{ display: '-webkit-inline-box' }} // Needed to align correctly
            pointing="bottom right"
            icon={<Image style={{ width: '1.5rem' }} src={threeDotsIconWhite} />}
          >
            <Dropdown.Menu>
              <Dropdown.Item text="Edit template" onClick={() => handleEditTheme(id)} />
              {isPublished && (
                <Dropdown.Item text="Un-publish template" onClick={() => handlePublishTheme(theme, false)} />
              )}
              {!isPublished && (
                <Dropdown.Item text="Publish template" onClick={() => handlePublishTheme(theme, true)} />
              )}
              <Dropdown.Item text="Delete template" onClick={() => setShowConfirmationModal(id)} />
              <Dropdown.Item text="Duplicate template" onClick={() => setShowDuplicateModal(theme)} />
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  theme: ITheme
  handleEditTheme: (arg: number) => void
  handlePublishTheme: (arg1: ITheme, arg2: boolean) => void
  setShowModal: (args: ITheme | null) => void
  setShowPrjModal: (args: ITheme) => void
  setShowConfirmationModal: (args: number) => void
  setShowDuplicateModal: (args: ITheme) => void
}

export default ThemePreviewModal
