import React, { useContext, useState, useEffect } from 'react'

import { RouteComponentProps } from 'react-router-dom'
import WebFont from 'webfontloader'

import Data from '../../api/Data'
import { IProject, IClip, IEpisode } from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'

import Section from '../../components/common/Section'

import ExportTab from './export/ExportTab'
import InviteTab from './invite/InviteTab'
import ManageTab from './manage/ManageTab'
import MergeTab from './merge/MergeTab'
import PlanTab from './plan/PlanTab'

const ProjectShowPage = ({ history, match, location }: IProps) => {
  const { hasUserMadeChange, dispatchGlobalContext } = useContext(GlobalContext)

  // States
  const [projectTitle, setProjectTitle] = useState<string>('')
  const [project, setProject] = useState<IProject | null>(null)
  const [theme, setTheme] = useState<any>(null)
  const [clips, setClips] = useState<IClip[] | null>(null)
  const [episodes, setEpisodes] = useState<IEpisode[] | null>(null)
  const [clipsSelected, setClipsSelected] = useState<IClip[]>([])

  // Fetch data
  useEffect(() => {
    const fetchProject = async () => {
      const slug = match.params.projectSlug

      const { data, error } = await Data.getProject(slug)

      if (error) return alert('Oops something went wrong')

      const { clips: projectClips, episodes: projectEpisodes, theme: projectTheme, ...projectData } = data

      setProjectTitle(projectData.name)
      setProject(projectData)
      setTheme(projectTheme)
      setClips(projectClips)
      setEpisodes(projectEpisodes)
    }
    fetchProject()
  }, [match.params.projectSlug, location.search])

  // Fetch project font
  useEffect(() => {
    const fetchProjectFont = () => {
      const fontToFetch = project?.displaySetting.primaryFont

      return WebFont.load({
        google: {
          families: [`${fontToFetch}:400,700,italic,bold,bolditalic`],
        },
      })
    }

    if (project) fetchProjectFont()
  }, [project])

  // Exit the project
  const exitProject = () => {
    if (hasUserMadeChange) {
      return dispatchGlobalContext({ type: 'set exit without saving modal', payload: `/projects` })
    }

    return history.push('/projects')
  }

  if (!project || !clips || !theme || !episodes) return null

  const { section } = match.params

  console.log('Rendering project Show Page', project, clips, episodes, theme, section)

  return (
    <Section>
      <>
        {section === 'plan' && (
          <PlanTab
            projectTitle={projectTitle}
            project={project}
            setProject={setProject}
            clips={clips}
            theme={theme}
            setTheme={setTheme}
            exitProject={exitProject}
          />
        )}
        {section === 'invite' && <InviteTab projectTitle={projectTitle} project={project} exitProject={exitProject} />}
        {section === 'manage' && (
          <ManageTab
            projectTitle={projectTitle}
            project={project}
            clips={clips}
            setClips={setClips}
            clipsSelected={clipsSelected}
            setClipsSelected={setClipsSelected}
            exitProject={exitProject}
          />
        )}

        {section === 'merge' && (
          <MergeTab
            projectTitle={projectTitle}
            theme={theme}
            setTheme={setTheme}
            clipsSelected={clipsSelected}
            setClipsSelected={setClipsSelected}
          />
        )}
        {section === 'export' && (
          <ExportTab
            projectTitle={projectTitle}
            episodes={episodes}
            setEpisodes={setEpisodes}
            exitProject={exitProject}
          />
        )}
      </>
    </Section>
  )
}

interface IProps extends RouteComponentProps<any> {}

export default ProjectShowPage
