import React, { FC, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { Dimmer, Header, Icon } from 'semantic-ui-react'

import { GlobalContext } from '../../contexts/GlobalContext'

const exemptedPages = ['login', 'forgot', 'register']

const SmallScreenOverlay: FC<{ children: JSX.Element[] }> = ({ children }) => {
  const { isMobile } = useContext(GlobalContext)

  const location = useLocation()

  return (
    <Dimmer.Dimmable blurring style={{ minHeight: '100vh' }}>
      <Dimmer active={!!isMobile && !exemptedPages.includes(location.pathname.replace(/^\/|\/$/g, ''))}>
        <Icon name="expand arrows alternate" size="huge" style={{ color: '#6b50ff' }} />
        <Header as="h2" inverted>
          Your browser is too small
        </Header>
        <Header as="h5" inverted>
          Resize your browser to be at least 770px wide to access the Vloggi platform.
        </Header>
      </Dimmer>
      {children}
    </Dimmer.Dimmable>
  )
}

export default SmallScreenOverlay
