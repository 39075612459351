import React, { useContext, useState, useEffect } from 'react'

import { Form, Grid, Segment, Header, Image } from 'semantic-ui-react'

import Data from '../../api/Data'
import { themeThumbnailSize } from '../../config/constants'
import { GlobalContext } from '../../contexts/GlobalContext'

import ImageUploader from '../../components/modals/ImageUploader'
import UploaderModal from '../../components/modals/UploaderModal'

import 'filepond/dist/filepond.min.css'

const DesignerTabAdmin = ({ theme, updateTheme }) => {
  const { dispatchGlobalContext } = useContext(GlobalContext)

  // States
  const [themeJSONData, setThemeJSONData] = useState('')
  const [showSelectThumbnailModal, setShowSelectThumbnailModal] = useState(false)
  const [showSelectPreviewVideoModal, setShowSelectPreviewVideoModal] = useState(false)

  // Update JSON data each time theme gets saved
  useEffect(() => {
    setThemeJSONData(JSON.stringify(theme.data, undefined, 4))
  }, [theme])

  // Change team logo
  const handleSaveNewThumbnail = async (imageBlob) => {
    const data = new FormData()

    data.append('imageBlob', imageBlob)

    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { error } = await Data.updateThemeThumbnail(theme.id, data)

    if (error) {
      dispatchGlobalContext({ type: 'set loading', payload: false })
      return alert('An error occurred')
    }

    // Necessary to display the new team image in the side bar
    return window.location.reload()
  }

  // Handle change
  const handleChange = ({ target: { value, name } }) => {
    if (value === '') value = null
    if (name === 'data') value = JSON.parse(value)

    updateTheme({ ...theme, [name]: value })
  }

  // Change value of theme data
  const handleJSONChange = ({ target: { value } }) => setThemeJSONData(value)

  if (!theme) return null

  const { id, title, description, category, placeholderText, imageUrl, videoSampleUrl } = theme

  console.log('Rendering admin modal')

  return (
    <Segment>
      <Header as="h3">Template admin</Header>
      <Form>
        <Grid columns="equal">
          <Grid.Column>
            <Form.Input type="text" label="Id" name="id" value={id} disabled />
            <Form.Group>
              <Form.Field>
                <label>Thumbnail</label>
                <Image width="200px" src={imageUrl} rounded onClick={() => setShowSelectThumbnailModal(true)} />
              </Form.Field>
              <Form.Field>
                <label>Sample video</label>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  width="200px"
                  src={videoSampleUrl}
                  rounded
                  onClick={() => setShowSelectPreviewVideoModal(true)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Input type="text" label="Title" name="title" value={title} onChange={handleChange} />
            <Form.TextArea
              type="text"
              style={{ resize: 'none' }}
              label="Description"
              name="description"
              value={description}
              onChange={handleChange}
            />

            <Form.Input type="text" label="Category" name="category" value={category} onChange={handleChange} />
            <Form.Input
              type="text"
              label="Placeholder text (contributor;place;location;caption1,count)"
              name="placeholderText"
              value={placeholderText}
              onChange={handleChange}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.TextArea
              label="data"
              rows="27"
              name="data"
              value={themeJSONData}
              onChange={handleJSONChange}
              onBlur={handleChange}
            />
          </Grid.Column>
        </Grid>
      </Form>
      {/* theme thumbnail selection modal */}
      {showSelectThumbnailModal && (
        <ImageUploader
          inputImageType="image/*"
          imageSize={{ width: themeThumbnailSize.width, height: themeThumbnailSize.height }}
          imageIsAvatar={themeThumbnailSize.imageIsAvatar}
          handleSave={handleSaveNewThumbnail}
          handleCancel={() => setShowSelectThumbnailModal(false)}
        />
      )}
      {/* Video uploader */}
      {showSelectPreviewVideoModal && (
        <UploaderModal
          title="Upload an sample video"
          endpoint={`themes/${theme.id}/updateSampleVideo`}
          requirements={{
            maxFileSize: '80mb',
            maxFiles: 1,
            minDuration: 2,
            acceptedFileTypes: ['mp4', 'mov'],
            acceptedFileMimeTypes: ['video/mp4', 'video/quicktime'],
          }}
          handleUploadSuccess={() => setShowSelectPreviewVideoModal(false)}
          handleClose={() => setShowSelectPreviewVideoModal(false)}
        />
      )}
    </Segment>
  )
}
export default DesignerTabAdmin
