import React, { useContext } from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Menu, Image } from 'semantic-ui-react'

import AdminIcon from '../../_images/menu-admin-icon.png'
import BrandingIcon from '../../_images/menu-branding-icon.png'
import HelpCentreIcon from '../../_images/menu-help-centre-icon.png'
import ProjectsIcon from '../../_images/menu-projects-icon.png'
import ThemesIcon from '../../_images/menu-templates-icon.png'
import VloggiLogoWhite from '../../_images/vloggi-logo-white.png'
import VloggiSmallLogoWhite from '../../_images/vloggi-small-logo-white.png'
import { UserContext } from '../../contexts/UserContext'
import Auth from '../../utils/Auth'

import MenuItem from './MenuItem'
import TrialProgressBar from './TrialProgressBar'
import UserMenu from './UserMenu'

const userMenuItems = [
  { name: 'My Branding', page: 'my-branding', icon: BrandingIcon },
  { name: 'My Projects', page: 'projects', icon: ProjectsIcon },
  { name: 'Video Templates', page: 'themes', icon: ThemesIcon },
  { name: 'Help Center', page: 'help-centre', icon: HelpCentreIcon },
]

const adminMenuItems = [{ name: 'Admin (admin only)', page: 'admin', icon: AdminIcon }]

const SideBarMain = ({ history, location, isSmallSideBar = false, goToPage }: IProps) => {
  const { userProfile, dispatchUserContext } = useContext(UserContext)

  const logout = () => {
    dispatchUserContext({ type: 'log out' })
    history.push('/login')
  }

  // in case the userProfile could not be fetched, log out the user
  if (!userProfile) {
    logout()
    return null
  }

  const { username, logoAvatarUrl, plan, subscriptionDaysLeft } = userProfile

  const isUserAdmin = Auth.getPayLoad().isAdmin

  const menuItems = isUserAdmin ? [...userMenuItems, ...adminMenuItems] : [...userMenuItems]

  const isUserOnTrial = plan.title === '14 day Trial (on Community plan)'

  const isTrialProgressDisplayed = isUserOnTrial && !isSmallSideBar

  const activeItem = location.pathname.split('/')[1]

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        backgroundColor: '#13165a',
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <Menu borderless vertical inverted fluid style={{ backgroundColor: 'inherit' }}>
          {/* Logo */}
          <Menu.Item style={{ height: '76px' }} onClick={() => goToPage('/projects')}>
            {isSmallSideBar ? (
              <Image src={VloggiSmallLogoWhite} style={{ margin: '5px auto' }} />
            ) : (
              <Image src={VloggiLogoWhite} width="130px" style={{ margin: '5px auto' }} />
            )}
          </Menu.Item>

          {/* Menu items */}
          {menuItems.map((menuItem) => (
            <MenuItem
              key={menuItem.name}
              activeItem={activeItem}
              goToPage={goToPage}
              name={menuItem.name}
              page={menuItem.page}
              icon={menuItem.icon}
              isSmallSideBar={isSmallSideBar}
            />
          ))}
        </Menu>
      </div>

      <div>
        {isTrialProgressDisplayed && <TrialProgressBar daysLeft={subscriptionDaysLeft} goToPage={goToPage} />}

        <UserMenu
          username={username}
          logoAvatarUrl={logoAvatarUrl}
          goToPage={goToPage}
          logout={logout}
          isSmallSideBar={isSmallSideBar}
        />
      </div>
    </div>
  )
}

interface IProps extends RouteComponentProps<any> {
  isSmallSideBar?: boolean
  goToPage: (args: string) => void
}

export default withRouter(SideBarMain)
