import React, { FC, useContext } from 'react'

import { Modal } from 'semantic-ui-react'

import { GlobalContext } from '../../contexts/GlobalContext'

import './SearchFieldModal.scss'

const SearchFieldModal: FC<IProps> = ({ isModalShown, setIsModalShown, children }) => {
  const { isMobile } = useContext(GlobalContext)

  return (
    <Modal
      id="search-field-modal"
      centered={!isMobile}
      size={isMobile ? 'fullscreen' : 'tiny'}
      open={isModalShown}
      onClose={() => setIsModalShown(null)}
    >
      {children}
    </Modal>
  )
}

interface IProps {
  isModalShown: boolean
  setIsModalShown: (arg: number | null) => void
}

export default SearchFieldModal
