import React, { FC, useState } from 'react'

import { List, Segment, Icon, Button, Form, Grid, Header, Image } from 'semantic-ui-react'

import pinIcon from '../../_images/pin-icon.svg'
import { MAPBOX_API_KEY } from '../../config/environments'

import LocationSearchMapBox from './LocationSearchMapBox'
import SearchFieldModal from './SearchFieldModal'

const LocationSearchField: FC<IProps> = (props) => {
  const [isLocationSearchShown, setIsLocationSearchShown] = useState<number | null>(null)

  const { upload, placeSuggestions, index, setLocation, resetLocation } = props

  const { place, location } = upload

  console.log('Rendering Location Field', upload)

  return (
    <Form.Field>
      <label>Filming location</label>

      {!location ? (
        <>
          <input readOnly placeholder="Select a location..." onClick={() => setIsLocationSearchShown(index)} />
          {/* Place Suggestions */}
          {placeSuggestions && (
            <List horizontal>
              {placeSuggestions.map((suggestion) => (
                <List.Item key={suggestion.place}>
                  <Button
                    primary
                    compact
                    inverted
                    size="mini"
                    onClick={() => setLocation(index, suggestion)}
                    style={{ margin: 0 }}
                  >
                    {suggestion.place}
                  </Button>
                </List.Item>
              ))}
            </List>
          )}
        </>
      ) : (
        <>
          {/* Display location if is set */}
          <Segment>
            <Grid verticalAlign="middle">
              <Grid.Column width="14">
                <Header as="h6" color="blue">
                  <Image src={pinIcon} width="1rem" />
                  <Header.Content>
                    <strong>{place || 'Place unknown'}</strong>
                    <br />
                    {location}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width="2">
                <Icon link color="blue" name="close" onClick={() => resetLocation(index)} />
              </Grid.Column>
            </Grid>
          </Segment>
        </>
      )}
      {/* Modal */}
      <SearchFieldModal isModalShown={isLocationSearchShown !== null} setIsModalShown={setIsLocationSearchShown}>
        <Form>
          <Form.Field>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <LocationSearchMapBox
              publicKey={MAPBOX_API_KEY!}
              inputClass="form-control search"
              onSuggestionSelect={(suggestion: Suggestion) => {
                const indexToUpdate = isLocationSearchShown
                setIsLocationSearchShown(null)
                return setLocation(indexToUpdate!, suggestion)
              }}
              resetSearch={false}
            />
          </Form.Field>
        </Form>

        <Button onClick={() => setIsLocationSearchShown(null)}>Close</Button>
      </SearchFieldModal>
    </Form.Field>
  )
}

type Suggestion = {
  place: string
  location: string
  lat: number
  lng: number
}

interface IProps {
  index: number
  upload: { place: string; location: string }
  placeSuggestions?: Suggestion[]
  setLocation: (arg1: number, arg2: Suggestion) => void
  resetLocation: (arg: number) => void
}

export default LocationSearchField
