import React, { useEffect, useState, useCallback } from 'react'

import { Segment, Button, Header, Divider, Grid, Image, Label, Modal, Pagination } from 'semantic-ui-react'

import blackBackground from '../../../_images/designer-black-background.jpg'
import ClipBackground from '../../../_images/designer-clip-background.jpeg'
import sixShotsBackground from '../../../_images/designer-opening-sixshots.jpeg'
import Data from '../../../api/Data'
import { S3_BUCKET_URL } from '../../../config/environments'

import UploaderModal from '../../../components/modals/UploaderModal'

const standardIntros = {
  opening: [
    { name: 'blackBackground', text: 'black background', src: blackBackground },
    { name: 'vloggiss', text: 'Freeze frame', src: ClipBackground },
    { name: 'six', text: 'Six shots', src: sixShotsBackground },
  ],
  closing: [{ name: 'blackBackground', text: 'black', src: blackBackground }],
}

const DesignerIntroOutroSelectModal = ({ section, sectionName, handleSelect, setShowModal }) => {
  const [items, setItems] = useState()
  const [itemSelected, setItemSelected] = useState(section.input)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [page, setPage] = useState(1)
  const [itemCount, setItemCount] = useState(null)
  const [itemPerPage] = useState(40)

  const fetchItems = useCallback(async () => {
    const offset = (page - 1) * itemPerPage
    const limit = itemPerPage

    const { data, error } = await Data.getThemeAssets('video', offset, limit)

    if (error) return alert('An error occurred')

    const { rows, count } = data

    setItems(rows || [])

    setItemCount(count + standardIntros.opening.length)
  }, [itemPerPage, page])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const handleCancel = () => setShowModal(null)

  const handleDelete = async () => {
    // Do nothing if item is not a custom video
    if (!itemSelected.startsWith('asset:')) return false

    const itemId = itemSelected.replace('asset:', '')

    const { error } = await Data.deleteThemeAsset(itemId)

    if (error) return alert('An error occurred')

    return fetchItems()
  }

  const handleClose = () => {
    setShowUploadModal(false)
  }

  const handleUploadComplete = () => {
    setShowUploadModal(false)
    fetchItems()
  }

  if (!items) return false

  const videoPreviewS3URL = `${S3_BUCKET_URL}/themes/assets/previews`
  const sectionType = sectionName === 'opening' ? 'intro' : 'outro'

  console.log('Rendering modal', section, items, itemSelected)

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>
        <Grid columns="equal">
          <Grid.Column>Change {sectionType}</Grid.Column>
          <Grid.Column>
            <Button floated="right" inverted secondary onClick={() => setShowUploadModal(true)}>
              {`Upload my ${sectionType}`}
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Grid padded>
        <Grid.Column width={14} textAlign="right">
          <Pagination
            size="mini"
            boundaryRange={0}
            defaultActivePage={page}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            onPageChange={(e, data) => setPage(data.activePage)}
            totalPages={Math.ceil(itemCount / itemPerPage)}
          />
        </Grid.Column>

        <Grid.Column width={2} textAlign="left">
          <Header as="h5">
            <Header.Subheader>
              {page * 40 - 40}-{Math.min(page * 40, itemCount)} of {itemCount}
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid>
      <Modal.Content scrolling>
        <Modal.Description>
          <Divider horizontal>Standard {sectionType}</Divider>
          <Grid columns={4} padded>
            {standardIntros[sectionName].map((intro) => (
              <Grid.Column key={intro.name} onClick={() => setItemSelected(intro.name)}>
                <Segment color={itemSelected === intro.name && 'red'}>
                  <Image width="100%" height="100%" rounded src={intro.src} />
                  <Label size="small" basic attached="top left">
                    {intro.text}
                  </Label>
                </Segment>
              </Grid.Column>
            ))}
          </Grid>

          <Divider horizontal>Your {sectionType}</Divider>

          <Grid columns={4}>
            {items.map((item) => (
              <Grid.Column key={item.id} onClick={() => setItemSelected(`asset:${item.id}`)}>
                <Segment color={itemSelected === `asset:${item.id}` && 'red'}>
                  <div
                    style={{
                      height: '107px',
                      backgroundImage: `url(${videoPreviewS3URL}/${item.id})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                    }}
                  />
                  {/* Add space on title to avoid line break */}
                  <p>{item.title}</p>
                </Segment>
              </Grid.Column>
            ))}
          </Grid>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleCancel}>Close</Button>
        <Button color="red" inverted onClick={() => handleDelete(itemSelected)}>
          Delete
        </Button>
        <Button primary onClick={() => handleSelect(itemSelected)}>
          Select
        </Button>
      </Modal.Actions>
      {/* Video Uploader */}
      {showUploadModal && (
        <UploaderModal
          title={`Upload an ${sectionType} video`}
          endpoint="themeAssets?type=video"
          requirements={{
            maxFileSize: '80mb',
            maxFiles: 1,
            minDuration: 2,
            acceptedFileTypes: ['mp4', 'mov'],
            acceptedFileMimeTypes: ['video/mp4', 'video/quicktime'],
          }}
          handleUploadSuccess={handleUploadComplete}
          handleClose={handleClose}
        />
      )}
    </Modal>
  )
}

export default DesignerIntroOutroSelectModal
