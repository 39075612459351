/* eslint-disable react/no-unused-state */
import React, { useReducer, useEffect, useState } from 'react'

import Data from '../api/Data'
import userContextReducer from '../reducers/UserReducer'
import Auth from '../utils/Auth'

export const UserContext = React.createContext<any>(null)

export const UserProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(userContextReducer, null)

  const [stateInitialized, setStateInitialized] = useState(false)

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (Auth.isAuthenticated()) {
        const { data, error } = await Data.getMyProfile()

        if (error) {
          // When we restart the backend, all users token get unrecognized so we push them back to the login page to log back in
          Auth.removeToken()
          return window.location.pathname === '/login'
        }

        dispatch({ type: 'update user profile', payload: { ...data } })
      }

      return setStateInitialized(true)
    }

    fetchUserProfile()
  }, [])

  if (!stateInitialized) return null

  console.log('User Context State', state)

  const value = { userProfile: state, dispatchUserContext: dispatch }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

interface Props {
  children: JSX.Element
}
