import React, { useState } from 'react'

import { Button, Dimmer, Header } from 'semantic-ui-react'

import { ITheme } from '../../api/Data'

import ThemePreview from './ThemePreview'

const ThemeCard = ({ theme, setShowThemePreviewModal }: IProps) => {
  const [dimmed, setDimmed] = useState(false)
  const { imageUrl, title, data } = theme

  const [cardWidth, cardHeight] = [293, 164]
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [videoWith, videoHeight] = [1920, 1080]

  const ratio = cardWidth / videoWith

  return (
    <>
      <Header as="h3">{title}</Header>

      <Dimmer.Dimmable
        style={{ width: `${cardWidth}px`, height: `${cardHeight}px` }}
        onMouseEnter={() => setDimmed(true)}
        onMouseLeave={() => setDimmed(false)}
      >
        <ThemePreview section="vloggi" themeData={data} ratio={ratio} backgroundAsset={imageUrl} />
        <Dimmer active={dimmed} onClick={() => setShowThemePreviewModal(theme)}>
          <Button inverted>Preview</Button>
        </Dimmer>
      </Dimmer.Dimmable>
    </>
  )
}

interface IProps {
  theme: ITheme
  setShowThemePreviewModal: (arg: ITheme) => void
}

export default ThemeCard
