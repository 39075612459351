import React, { useState, useEffect, useContext } from 'react'

import { RouteComponentProps } from 'react-router'
import { Confirm, Divider, Grid, Icon, Menu } from 'semantic-ui-react'

import Data from '../../api/Data'
import { ITheme } from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'
import Auth from '../../utils/Auth'

import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'

import ThemeCard from './ThemeCard'
import ThemeCustomisationModal from './ThemeCustomisationModal'
import DesignerDuplicateModal from './ThemeDuplicateModal'
import ThemePreviewModal from './ThemePreviewModal'
import ThemeProjectCreationModal from './ThemeProjectCreationModal'

const categories = ['most popular', 'testimonial', 'listicle', 'grabs', 'all']

const ThemeIndexPage = ({ history }: IProps) => {
  const { dispatchGlobalContext } = useContext(GlobalContext)
  const { userProfile } = useContext(UserContext)

  // States
  const [themes, setThemes] = useState<ITheme[] | null>(null)
  const [activeCategory, setActiveCategory] = useState<string>('most popular')

  const [showConfirmationModal, setShowConfirmationModal] = useState<number | null>(null)
  const [showDuplicateModal, setShowDuplicateModal] = useState<ITheme | null>(null)
  const [showPrjModal, setShowPrjModal] = useState<ITheme | null>(null)
  const [showThemePreviewModal, setShowThemePreviewModal] = useState<ITheme | null>(null)
  const [showThemeCustomisationModal, setShowThemeCustomisationModal] = useState<boolean>(false)

  // Initial fetching
  useEffect(() => {
    // Fetch published themes
    const fetchThemes = async () => {
      dispatchGlobalContext({ type: 'set loading', payload: true })

      const { data, error } = await Data.getThemes()

      dispatchGlobalContext({ type: 'set loading', payload: false })

      if (error) {
        return
      }

      setThemes(data)
    }
    fetchThemes()
  }, [dispatchGlobalContext])

  // Handle create project
  const handleCreateProject = async (projectName: string, themeId: number) => {
    const themeSelected = themes?.find((theme) => theme.id === themeId)

    // Define default project data
    const description = `Dear ${userProfile.teamName} Community, We want to showcase your video content. Use this video form to upload clips from your device or record responses to our questions.\n\nWhen you're ready, hit GET STARTED to contribute to the project "${projectName}".`
    const rules = `By uploading your video content, you are granting ${userProfile.teamName} and its affiliates royalty-free, irrevocable, non-exclusive rights and license to re-use this content.\n\nWe encourage creativity, but please ensure that your content respects ethical standards and does not contain any form of inappropriate material. Your commitment to these guidelines ensures an inclusive environment for all our community. Thank you.`
    const tips = `- Let your personality shine\n- Prop up your phone for a stable shot\n- If filming a scene, experiment with framing to create better visual impact\n- If using a webcam, position yourself in well-lit surroundings for a vibrant and captivating video`
    const displaySetting = {
      showLocation: themeSelected!.data.vloggi.elements.find((element: any) =>
        ['location', 'title'].includes(element.name)
      ),
      showCaption1: !!themeSelected!.data.vloggi.elements.find((element: any) => element.name === 'caption1'),
      showCaption2: !!themeSelected!.data.vloggi.elements.find((element: any) => element.name === 'caption2'),
      showCaption3: !!themeSelected!.data.vloggi.elements.find((element: any) => element.name === 'caption3'),
      caption1: 'Instagram handle (editable field)',
      caption2: 'Product purchased (editable field)',
      caption3: 'Branch number (editable field)',
    }

    const { data, error } = await Data.createProject({
      name: projectName,
      description,
      rules,
      tips,
      displaySetting,
      themeId,
    })

    if (error) {
      if (error.response.status === 403) {
        return dispatchGlobalContext({ type: 'show update plan modal' })
      } else return alert(error)
    }

    const { project, isFirstProject } = data
    const newProjectSlug = project.slug

    history.push(`/projects/${newProjectSlug}/plan?tab=landingPage${isFirstProject ? '&firstProject=true' : ''}`)
  }

  // Edit theme
  const handleEditTheme = (themeId: number) => {
    history.push(`/themes/${themeId}/edit`)
  }

  // Delete theme
  const handleDelete = async (themeId: number) => {
    const { error } = await Data.deleteTheme(themeId)

    if (error) {
      return alert('An error occurred')
    }

    return window.location.reload()
  }

  // Duplicate theme
  const handleDuplicate = async (title: string, themeId: number) => {
    const { error } = await Data.duplicateTheme(themeId, title)

    if (error) {
      return alert('An error occurred')
    }

    return window.location.reload()
  }

  // Set template as published or drafts
  const handlePublishTheme = async (theme: ITheme, isPublished: boolean) => {
    const newTheme = { ...theme, isPublished }
    const { error } = await Data.updateTheme(newTheme)

    if (error) {
      return alert('An error occurred')
    }

    return window.location.reload()
  }

  const getPublicThemes = (themesArr: ITheme[]) => {
    return themesArr.filter((theme) => theme.isPublished === true)
  }

  const getDraftThemes = (themesArr: ITheme[]) => {
    return themesArr.filter((theme) => theme.isPublished === false)
  }

  const getThemesFilterByCategory = (publishedThemes: ITheme[]) => {
    return publishedThemes.filter((theme) => {
      return activeCategory === 'all' || theme.category.split(',').includes(activeCategory)
    })
  }

  if (!themes) return null

  const { isAdmin } = Auth.getPayLoad()

  const publishedThemes = getPublicThemes(themes)

  const draftThemes = getDraftThemes(themes)

  const themesFilteredByCategory = getThemesFilterByCategory(publishedThemes)

  console.log('Rendering themes page', themes)

  return (
    <Section>
      <>
        {/* title */}
        <Title title="What type of videos would you like to collect from your contributors?" />

        <SubSection>
          <>
            {/* Category */}
            <Menu secondary widths={8} stackable>
              {categories.map((category) =>
                category === 'most popular' ? (
                  <Menu.Item
                    key={category}
                    active={activeCategory === category}
                    onClick={() => setActiveCategory(category)}
                  >
                    Most Popular
                    <Icon name="star" color="yellow" style={{ marginLeft: '0.25rem' }} />
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    key={category}
                    name={category}
                    active={activeCategory === category}
                    onClick={() => setActiveCategory(category)}
                  />
                )
              )}
            </Menu>

            {/* Templates */}
            <Grid columns={3} stackable relaxed="very" style={{ minHeight: '40vh' }}>
              <>
                {themesFilteredByCategory.map((theme) => (
                  <Grid.Column key={theme.id}>
                    <ThemeCard theme={theme} setShowThemePreviewModal={setShowThemePreviewModal} />
                  </Grid.Column>
                ))}
              </>
            </Grid>

            {/* Request a design form */}
            {/* <Segment textAlign="center">
              <Header as="h3">
                Looking for something different?
                <Header.Subheader>
                  Request a specific template design based on your campaign needs form our video designer team.
                </Header.Subheader>
                <Button compact primary onClick={() => setShowThemeCustomisationModal(true)}>
                  Send a request
                </Button>
              </Header>
            </Segment> */}
            {/* Draft themes, only for admin */}
            {isAdmin && (
              <>
                <Divider horizontal>Draft themes (only seen by admin)</Divider>
                <Grid columns={3} stackable padded>
                  {draftThemes.map((theme) => (
                    <Grid.Column key={theme.id}>
                      <ThemeCard theme={theme} setShowThemePreviewModal={setShowThemePreviewModal} />
                    </Grid.Column>
                  ))}
                </Grid>
              </>
            )}
          </>
        </SubSection>

        {/* Theme modal */}
        {showThemePreviewModal && (
          <ThemePreviewModal
            theme={showThemePreviewModal}
            handleEditTheme={handleEditTheme}
            handlePublishTheme={handlePublishTheme}
            setShowModal={setShowThemePreviewModal}
            setShowPrjModal={setShowPrjModal}
            setShowDuplicateModal={setShowDuplicateModal}
            setShowConfirmationModal={setShowConfirmationModal}
          />
        )}
        {/* Theme creation modal */}
        {showPrjModal && (
          <ThemeProjectCreationModal
            theme={showPrjModal}
            setShowModal={setShowPrjModal}
            handleCreateProject={handleCreateProject}
          />
        )}
        {/* Theme duplication modal */}
        {showDuplicateModal && (
          <DesignerDuplicateModal
            theme={showDuplicateModal}
            setShowModal={setShowDuplicateModal}
            handleDuplicate={handleDuplicate}
          />
        )}

        {/* Theme customisation request modal */}
        {showThemeCustomisationModal && <ThemeCustomisationModal setShowModal={setShowThemeCustomisationModal} />}

        {/* Theme Deletion confirmation modal */}
        <Confirm
          open={!!showConfirmationModal}
          header="Template deletion"
          content="Are you sure you want to delete this theme?"
          confirmButton="Yes"
          onCancel={() => setShowConfirmationModal(null)}
          onConfirm={() => handleDelete(showConfirmationModal!)}
        />
      </>
    </Section>
  )
}

interface IProps extends RouteComponentProps<any> {}

export default ThemeIndexPage
