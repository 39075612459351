import React from 'react'

import { Button, Grid, Header, Icon, Image, Modal } from 'semantic-ui-react'

import { IUser, ITeam } from '../../api/Data'
import { vloggiSuccessCallUrl } from '../../config/constants'
import ImageVloggiLogoWhite from '../../_images/vloggi-logo-white.png'

const SubscriptionConfirmationModal = (props: ISubscriptionConfirmationModalProps) => {
  // Props
  const { user, team, stepForward } = props
  
  const { username, plan } = user

  const { description, maxNbOfUserPerTeam } = plan

  const { users } = team

  return (
    <Modal open style={{ width: '100%', height: '100%', marginTop: '-14px' }}>
      <Modal.Content style={{ padding: 0 }}>
        <Grid style={{ height: '100vh', minHeight: '700px', margin: 0 }}>
          <Grid.Column only="computer" width={7} style={{ backgroundImage: 'linear-gradient(to bottom, #12165b, #121360)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', padding: '0 100px' }}>
              <Image src={ImageVloggiLogoWhite} width={200} alt="vloggi" />
              <Header inverted as="h4">
                You have just subscribed to {description}
              </Header>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} computer={9}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
              <div style={{ padding: '0 100px', textAlign: 'center' }}>
                <Icon name="check circle outline" size="huge" color="orange" />
                <Header as="h2">
                  You’re all set-up and ready to go, {username}!
                </Header>
                <Header as="h4">
                  To ensure that you get the most out of your new subscription, add team members or book a free success call
                </Header>
                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', padding: '10px 0 0 0' }}>
                  <div style={{ width: '220px' }}>
                    <div style={{ backgroundColor: '#25359E', borderRadius: '10px', padding: '10px 5px' }}>
                      <Header as="h3" style={{ color: '#FFFFFF' }}>
                        Team members
                      </Header>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '140px', justifyContent: 'center', backgroundColor: '#F6F5FE', borderTop: '5px solid #FFFFFF', padding: '5px', color: '#6054DB' }}>
                      <div>You have used</div>
                      <div style={{ backgroundColor: '#FFFFFF', borderRadius: '10px', margin: '10px', fontSize: '24px' }}>
                        <strong>{users.length}</strong> of {maxNbOfUserPerTeam}
                      </div>
                      <div style={{ fontSize: '18px' }}>team members</div>
                      <div>on this plan</div>
                    </div>
                  </div>
                  <a href={vloggiSuccessCallUrl} target="_blank" rel="noopener noreferrer">
                    <div style={{ width: '220px' }}>
                      <div style={{ backgroundColor: '#6C5FF7', borderRadius: '10px', padding: '10px 5px' }}>
                        <Header as="h3" style={{ color: '#FFFFFF' }}>
                          Book success call
                        </Header>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', height: '140px', justifyContent: 'center', backgroundColor: '#F6F5FE', borderTop: '5px solid #FFFFFF', padding: '5px', color: '#6054DB' }}>
                        <div>Click here to book your success call</div>
                      </div>
                    </div>
                  </a>
                </div>
                <Header as="h4">
                  Or log back into your account to get started
                </Header>
                <Button color="orange" style={{ marginTop: '20px' }} onClick={stepForward}>
                  Get started
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

interface ISubscriptionConfirmationModalProps {
  user: IUser
  team: ITeam
  stepForward: () => void
}

export default SubscriptionConfirmationModal
