import React, { useState } from 'react'

import { Button, Form, Header, Icon, InputOnChangeData } from 'semantic-ui-react'

const MyProfileBio = ({ bio, isMobile, handleChange, handleSubmit }: IProps) => {
  const [formShown, setFormShown] = useState<boolean>(false)

  return (
    <div>
      {
        !formShown ? (
          <Header as="h4" color={!bio ? 'grey' : undefined}>
            {!bio ? 'Introduce yourself to your team' : bio} &nbsp; <Icon name="pencil" size="small" color="grey" style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => setFormShown(true)} />
          </Header>
        ) : (
          <Form>
            <Form.Field>
              <label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>Enter your short bio ({(bio || '').length} / 150)</div>
                <Icon name="close" link size="large" onClick={() => setFormShown(false)} style={{ marginTop: '-0.8rem' }} />
              </label>
              <Form.Input type="text" name="bio" value={bio} maxLength={150} onChange={handleChange} />
            </Form.Field>

            <Button primary onClick={handleSubmit} fluid={isMobile}>
              Update
            </Button>
          </Form>
        )
      }
    </div>
  )
}

interface IProps {
  bio: null | string
  isMobile: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  handleSubmit: () => void
}

export default MyProfileBio
