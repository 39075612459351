import React from 'react'

import hexToRgba from 'hex-to-rgba'

import blackBackground from '../../_images/designer-black-background.jpg'
import ClipBackground from '../../_images/designer-clip-background.jpeg'
import sixShotsBackground from '../../_images/designer-opening-sixshots.jpeg'
import { S3_BUCKET_URL } from '../../config/environments'
import { getFontCSSFromFontFile } from '../../utils/sharedFunctions'

export const renderOpeningClosingBackgroundHTML = (sectionData) => {
  const [openingType, openingAssetId] = sectionData.input.split(':')

  let backgroundImageSrc

  switch (openingType) {
    // screenshot of first clip of the story
    case 'vloggiss':
      backgroundImageSrc = ClipBackground
      break
    // screenshot of the first six
    case 'six':
      backgroundImageSrc = sixShotsBackground
      break
    // custom video
    case 'asset':
      backgroundImageSrc = `${S3_BUCKET_URL}/themes/assets/previews/${openingAssetId}`
      break
    case 'blackBackground':
      backgroundImageSrc = blackBackground
      break
    default:
      break
  }

  return (
    <img
      style={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, width: `100%`, height: `100%` }}
      src={backgroundImageSrc}
      alt=""
    />
  )
}

export const renderClipBackgroundVideoHTML = (backgroundVideo) => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      controls={false}
      autoPlay
      loop
      tabIndex={-1}
      style={{
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '640px',
        height: '360px',
      }}
    >
      <source src={backgroundVideo} />
    </video>
  )
}

export const renderClipBackgroundImageHTML = (backgroundImage) => {
  return (
    <img
      style={{
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        width: `100%`,
        height: `100%`,
      }}
      src={backgroundImage || ClipBackground}
      alt=""
    />
  )
}

export const renderTextElementHTML = (section, element, index, canvaRatio, placeholderText = {}) => {
  const { fontcolor, uppercase, align, fontsize, background, padding, fontfile } = element
  const { text, name, valign } = element

  const [colorHex, opacity] = background?.split('@') || ['#FFF', '0']

  const paddingY = Number(padding?.split(',')[0] || 0)
  const paddingX = Number(padding?.split(',')[1] || 0)

  const { fontFamily, fontWeight, fontStyle } = getFontCSSFromFontFile(fontfile)

  let textToOverlay
  if (name === 'title') textToOverlay = '{{your story name will appear here}}'
  else if (name === 'credits') textToOverlay = '{{list of contributors}}'
  else if (name === 'creator')
    textToOverlay = section === 'vloggi' ? placeholderText[name] || '{{contributor name}}' : '{{producer name}}'
  else if (name === 'place') textToOverlay = placeholderText[name] || '{{place name}}'
  else if (name === 'location') textToOverlay = placeholderText[name] || '{{location}}'
  else if (name === 'count') textToOverlay = placeholderText[name] || '{{X}}'
  else if (name === 'caption1') textToOverlay = placeholderText[name] || '{{Prompt 1 answer will appear here}}'
  else if (name === 'caption2') textToOverlay = '{{Prompt 2 answer will appear here}}'
  else if (name === 'caption3') textToOverlay = '{{Prompt 3 answer will appear here}}'
  else if (name === 'date') textToOverlay = '{{DD MMM YYYY HH:MM}}'
  else textToOverlay = text

  const style = {
    display: 'table-cell',
    padding: `${paddingY * canvaRatio}px ${paddingX * canvaRatio}px`,
    cursor: 'move',
    color: `${fontcolor?.split('@')[0]}`,
    height: '100%',
    width: `100%`,
    fontFamily: fontFamily.replace(/_/g, ' '),
    fontWeight,
    fontStyle,
    fontSize: `${fontsize * canvaRatio}px`,
    textTransform: `${uppercase ? 'uppercase' : ''}`,
    textAlign: `${align || 'left'}`,
    lineHeight: `${fontsize * canvaRatio}px`,
    background: `${hexToRgba(colorHex, opacity)}`,
    verticalAlign: valign,
  }

  return (
    <div id={index} className="handle" style={style}>
      {textToOverlay}
    </div>
  )
}

export const renderImageElementHTML = (element, index, canvaRatio) => {
  const { width, height, src, opacity, background } = element
  const [colorHex, backgroundOpacity] = background?.split('@') || ['#FFF', '0']

  return (
    <img
      id={index}
      src={`${S3_BUCKET_URL}/themes/assets/${src}`}
      style={{
        pointerEvents: 'none',
        width: `${width * canvaRatio}px`,
        height: `${height * canvaRatio}px`,
        opacity: `${opacity || 1}`,
        background: `${hexToRgba(colorHex, backgroundOpacity)}`,
      }}
      alt="asset"
    />
  )
}

export const renderRatingElementHTML = (element, index, canvaRatio) => {
  const { height, opacity, background } = element
  const [colorHex, backgroundOpacity] = background?.split('@') || ['#FFF', '0']

  const ratingIconHeight = `${height * canvaRatio}px`
  const ratingIconWidth = ratingIconHeight // icon are square

  return (
    <div
      id={index}
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        pointerEvents: 'none',
        opacity: `${opacity || 1}`,
        background: `${hexToRgba(colorHex, backgroundOpacity)}`,
      }}
      alt="rating"
    >
      {new Array(5).fill('').map(() => (
        <img
          src={`${S3_BUCKET_URL}/themes/assets/rating-star-full`}
          style={{ width: ratingIconWidth, height: ratingIconHeight }}
          alt="rating"
        />
      ))}
    </div>
  )
}

export const getNewOpeningElement = () => {
  return {
    input: 'blackBackground',
    duration: 3,
    elements: [],
  }
}

export const getNewClosingElement = () => {
  return {
    input: 'blackBackground',
    duration: 3,
    elements: [],
  }
}

export const getNewTextElement = (name, fontfile, text) => {
  const textElement = {
    name,
    type: 'text',
    x: 0,
    y: 0,
    width: 400,
    height: 100,
    background: null,
    fontcolor: '#FFFFFF@1',
    fontsize: 48,
    fontfile,
    uppercase: false,
    padding: '0,0',
    align: 'center',
    valign: 'middle',
    fadeIn: null,
    fadeOut: null,
  }

  if (name === 'static') textElement.text = text

  return textElement
}

export const getNewImageElement = (src, width, height) => {
  return {
    type: 'image',
    src,
    x: 0,
    y: 0,
    width: 200,
    height: `${(200 * height) / width}`,
    fadeIn: null,
    fadeOut: null,
    opacity: 1,
  }
}

export const getNewLogoElement = (src) => {
  const [logoWidth, logoHeight] = [160, 90]
  const logoPadding = 30

  return {
    type: 'image',
    name: 'logo',
    src,
    x: 1920 - logoWidth - logoPadding,
    y: logoPadding,
    width: logoWidth,
    height: logoHeight,
    fadeIn: null,
    fadeOut: null,
    opacity: 1,
  }
}

export const getNewRatingElement = () => {
  return {
    name: 'rating',
    type: 'rating',
    x: 0,
    y: 0,
    width: 600,
    height: 100,
    fadeIn: null,
    fadeOut: null,
    opacity: 1,
  }
}
