import React, { useState, useEffect, useContext } from 'react'

import queryString from 'query-string'
import { RouteComponentProps } from 'react-router'
import { Grid, Header, Confirm, Button, Image, Label } from 'semantic-ui-react'

import ImageVloggiOctopus from '../../_images/vloggi-octopus.jpeg'
import Data, { IProject } from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'
import OnboardingPage from '../onboarding/OnboardingPage'

import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'

import ProjectCard from './ProjectCard'
import ProjectStartProjectModal from './ProjectStartProjectModal'

const ProjectIndexPage = ({ history, location }: IProps) => {
  const { dispatchGlobalContext } = useContext(GlobalContext)
  const { userProfile } = useContext(UserContext)

  // States
  const [projects, setProjects] = useState<IProject[] | null>(null)

  const [showOnboardingModal, setShowOnboardingModal] = useState<boolean>(false)
  const [showStartProjectModal, setShowStartProjectModal] = useState<boolean>(false)
  const [showDeletionConfModal, setShowDeletionConfModal] = useState<string | null>(null)

  // Show welcome modal if new user
  useEffect(() => {
    const { newUser } = queryString.parse(location.search)
    if (newUser) setShowOnboardingModal(true)
  }, [location.search])

  // Fetch projects
  useEffect(() => {
    const fetchProjects = async () => {
      const { data, error } = await Data.getProjects()

      if (error) return

      setProjects(data)
    }

    fetchProjects()
  }, [location.search, setProjects])

  const handleDuplicateProject = async (slug: string) => {
    const { data, error } = await Data.duplicateProject(slug)

    if (error) {
      if (error.response.status === 403) {
        return dispatchGlobalContext({ type: 'show update plan modal' })
      } else return alert(error)
    }

    // Add project to project gallery
    const newProject = data
    setProjects((prevState) => [newProject, ...prevState!])
  }

  const deleteProject = async (slug: string) => {
    const { error } = await Data.deleteProject(slug)

    if (error) {
      setShowDeletionConfModal(null)
      return alert('An error occurred')
    }

    // Remove deleted project from the array
    setProjects((prevState) => [...prevState!.filter((el) => el.slug !== slug)])

    setShowDeletionConfModal(null)
  }

  const goToProjectShowPage = (slug: string) => {
    history.push(`/projects/${slug}/plan?tab=overview`)
  }

  const goToTemplatePage = () => {
    history.push(`/themes`)
  }

  // Is user collaborator
  const isUserCollaborator = (): boolean => {
    return userProfile.userAccessRights === 'collaborator'
  }

  if (!projects) return null

  console.log('Rendering projects page', projects)

  return (
    <Section>
      <>
        <Title title="My Video Projects">
          <>
            {projects.length !== 0 && !isUserCollaborator() && (
              <Label color={projects[0].totalNumberOfTeamClips >= projects[0].clipMax ? 'red' : 'grey'}>
                Video submissions received: {projects[0].totalNumberOfTeamClips} / {projects[0].clipMax}
              </Label>
            )}

            {projects.length !== 0 && !isUserCollaborator() && (
              <Button primary compact onClick={() => setShowStartProjectModal(true)}>
                Create a new project
              </Button>
            )}
          </>
        </Title>

        <SubSection>
          <>
            {projects.length !== 0 && (
              <Grid padded="vertically" stackable columns={4}>
                {projects.map((project) => (
                  <Grid.Column key={project.id}>
                    <ProjectCard
                      key={project.id}
                      project={project}
                      goToProjectShowPage={goToProjectShowPage}
                      handleDuplicateProject={handleDuplicateProject}
                      setShowDeletionConfModal={setShowDeletionConfModal}
                    />
                  </Grid.Column>
                ))}
              </Grid>
            )}
            {projects.length === 0 && (
              <>
                <Header as="h1" textAlign="center">
                  <Header.Subheader>
                    Plan your projects, manage your contributions and create video stories all from one place. Browse
                    each video project to see the most recent video clips contributed by your community, colleagues or
                    customers.
                  </Header.Subheader>
                </Header>
                <Image src={ImageVloggiOctopus} style={{ margin: '0 auto' }} />
                <Button
                  color="orange"
                  content="Create my first project"
                  onClick={goToTemplatePage}
                  style={{ display: 'block', margin: '2rem auto' }}
                />
              </>
            )}
          </>
        </SubSection>

        <Confirm
          open={!!showDeletionConfModal}
          header="Project deletion"
          content="Are you sure you want to delete this project? All clips will be lost."
          confirmButton="Yes"
          onCancel={() => setShowDeletionConfModal(null)}
          onConfirm={() => deleteProject(showDeletionConfModal!)}
        />
        {showStartProjectModal && (
          <ProjectStartProjectModal setShowModal={setShowStartProjectModal} goToTemplatePage={goToTemplatePage} />
        )}
        {showOnboardingModal && <OnboardingPage />}
      </>
    </Section>
  )
}

interface IProps extends RouteComponentProps<any> {}

export default ProjectIndexPage
