import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import QRCode from 'qrcode.react'
import { Modal, Button, Grid, Header } from 'semantic-ui-react'

const PexelsLibraryModal = ({ history, projectUrl, setShowModal }: IProps) => {
  // Global
  const projectLinkForQRcode = `${projectUrl}?utm_source=qrcode` // utm is used to differentiate direct form qrcode scan

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>From Your Phone</Modal.Header>
      <Modal.Content>
        <Grid columns={2} centered divided verticalAlign="middle" style={{ height: '100%' }}>
          <Grid.Column>
            <Grid>
              <Grid.Column width={4}></Grid.Column>
              <Grid.Column width={12}>
                <Header as="h3">Use your smartphone to scan this QR code and upload a clip from your phone.</Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <QRCode id="qr-code" value={projectLinkForQRcode} />
          </Grid.Column>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>Cancel</Button>

        <Button primary onClick={() => history.go(0)}>
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps extends RouteComponentProps<any> {
  projectUrl: string
  setShowModal: (arg: boolean) => void
}

export default withRouter(PexelsLibraryModal)
