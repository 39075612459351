import React from 'react'

import { Checkbox, Grid, Header } from 'semantic-ui-react'

import { getFontCSSFromFontFile, getFontFileFromFontCSS } from '../../utils/sharedFunctions'

import FontPickerComponent from '../../components/common/FontPickerComponent'
import { getNewOpeningElement, getNewClosingElement } from '../../components/common/ThemeElement'

const DesignerTabSettings = ({ theme, updateTheme }) => {
  // This function update all fontfile of all elements if the default font is changed
  const updateFontFiles = (oldFontFamily, newFontFamily) => {
    const newTheme = { ...theme }

    Object.keys(theme.data).forEach((section) => {
      if (['opening', 'vloggi', 'closing'].includes(section)) {
        theme.data[section].elements.forEach((element, index) => {
          Object.keys(element).forEach((elementKey) => {
            if (elementKey === 'fontfile') {
              const { fontFamily, fontWeight, fontStyle } = getFontCSSFromFontFile(element[elementKey])

              // Update only the font needed (primary or secondary)
              if (fontFamily === oldFontFamily) {
                const newFontFile = getFontFileFromFontCSS(newFontFamily, fontWeight, fontStyle)
                newTheme.data[section].elements[index][elementKey] = newFontFile
              }
            }
          })
        })
      }
    })
    return newTheme
  }

  // Toggle Intro
  const toggleIntro = (e, { checked }) => {
    const newTheme = { ...theme }
    if (checked) {
      // add Intro
      const newIntro = getNewOpeningElement()
      newTheme.data.opening = newIntro
    } else {
      // remove intro
      delete newTheme.data.opening
    }
    updateTheme(newTheme)
  }

  // Toggle Outro
  const toggleOutro = (e, { checked }) => {
    const newTheme = { ...theme }
    if (checked) {
      // add Intro
      const newIntro = getNewClosingElement()
      newTheme.data.closing = newIntro
    } else {
      // remove intro
      delete newTheme.data.closing
    }
    updateTheme(newTheme)
  }

  // Change primaryFont
  const changePrimaryFont = (nextFont) => {
    const oldFontFamily = theme.data.playlist.defaultFonts[0]
    const newFontFamily = nextFont.family

    const newTheme = updateFontFiles(theme, oldFontFamily, newFontFamily)

    newTheme.data.playlist.defaultFonts[0] = newFontFamily

    updateTheme(newTheme)
  }

  // Change secondary font
  const changeSecondaryFont = (nextFont) => {
    const oldFontFamily = theme.data.playlist.defaultFonts[1]
    const newFontFamily = nextFont.family

    const newTheme = updateFontFiles(theme, oldFontFamily, newFontFamily)

    newTheme.data.playlist.defaultFonts[1] = newFontFamily

    updateTheme(newTheme)
  }

  const { opening, closing } = theme.data

  const { defaultFonts } = theme.data.playlist

  const [primaryFont, secondaryFont] = defaultFonts

  console.log('Rendering section page')

  return (
    <Grid>
      <Grid.Column width={1}></Grid.Column>
      <Grid.Column width={11}>
        <Grid divided="vertically" verticalAlign="middle">
          {/* Add Intro */}
          <Grid.Row>
            <Grid.Column width={10}>
              <Header as="h5" color="violet">
                <label>Intro sequence</label>
                <Header.Subheader>
                  Add an intro sequence to your template to give your stories a more consistent look. You can customise
                  your intro by clicking on the &quot;Intro&quot; tab above.
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              <Checkbox toggle checked={!!opening} onClick={toggleIntro} />
            </Grid.Column>
          </Grid.Row>

          {/* add outro  */}
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h5" color="violet">
                <label>Outro sequence</label>
                <Header.Subheader>
                  {`Add an outro sequence to close your story with credits to your contributors, links to you or your
                  partner's website and more You can customise your outro by clicking on the "Outro" tab
                  above.`}
                </Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column width={8} textAlign="right">
              <Checkbox toggle checked={!!closing} onClick={toggleOutro} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {/* primary font */}
            <Grid.Column width={10}>
              <Header as="h5" color="violet">
                <label>Primary font</label>
                <Header.Subheader>Choose your main font here.</Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column width={6} textAlign="right">
              <FontPickerComponent pickerId="primary" activeFontFamily={primaryFont} onChange={changePrimaryFont} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {/* secondary font */}
            <Grid.Column width={10}>
              <Header as="h5" color="violet">
                <label>Secondary font</label>
                <Header.Subheader>Choose your secondary font here.</Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              <FontPickerComponent
                pickerId="secondary"
                activeFontFamily={secondaryFont}
                onChange={changeSecondaryFont}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

export default DesignerTabSettings
