import React, { useContext } from 'react'

import { Container } from 'semantic-ui-react'

import { GlobalContext } from '../../contexts/GlobalContext'

const SubSection = ({ children, hasGreyBackground = false }: IProps) => {
  const { isMobile } = useContext(GlobalContext)
  return (
    <section
      style={{
        height: isMobile ? 'auto' : 'calc(100vh - 62px)',
        borderTop: hasGreyBackground ? 'none' : '1px solid #f3f4f5',
        background: hasGreyBackground ? '#f3f4f5' : '#FFF',
        overflowX: 'hidden',
      }}
    >
      <Container>
        {isMobile ? (
          <div style={{ padding: '2rem 0rem ' }}>{children}</div>
        ) : (
          <div style={{ padding: '2rem 3rem ' }}>{children}</div>
        )}
      </Container>
    </section>
  )
}

interface IProps {
  children: JSX.Element
  hasGreyBackground?: boolean
}

export default SubSection
