import React, { useContext } from 'react'

import { GlobalContext } from '../../contexts/GlobalContext'

const Section = ({ children }: { children: JSX.Element }) => {
  const { isMobile } = useContext(GlobalContext)
  return (
    <section
      style={{
        height: isMobile ? 'calc(100vh - 42px)' : '100vh',
        marginLeft: isMobile ? '0' : '260px',
        overflowX: 'hidden',
        overflowY: isMobile ? 'scroll' : 'hidden',
      }}
    >
      {children}
    </section>
  )
}

export default Section
