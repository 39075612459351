import React, { SyntheticEvent, useContext, useState } from 'react'

import ReactDatePicker from 'react-datepicker'
import { Accordion, Button, Dimmer, Form, Header, Icon, Image } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'
import { maxLength } from '../../../config/constants'
import { UserContext } from '../../../contexts/UserContext'
import moment from '../../../utils/Moment'

import CustomLabel from '../../../components/common/custom-label/CustomLabel'
import ExternalLink from '../../../components/common/ExternalLink'
import UpgradeTag from '../../../components/common/UpgradeTag'
import VideoEncodingCard from '../../../components/common/VideoEncodingCard'

import 'react-datepicker/dist/react-datepicker.css'

const PlanTabLandingPage = (props: IProps) => {
  // Contexts
  const { userProfile } = useContext(UserContext)

  // Props
  const { project, validationError } = props
  const { setShowProjectPictureModal, handleVideoHeroClick, handleChange } = props

  // States
  const [showMoreCustomisation, setShowMoreCustomisation] = useState(false)

  // Set filming date
  const onSetDate = (date: Date) => {
    // Here we set the time to 23:59:59 to make sure that the project stays open until the end of the deadline day.
    const newDeadline = date ? moment(date).set('hour', 23).set('minute', 59).set('second', 59).format() : null
    handleChange(date, { name: 'deadline', value: newDeadline })
  }
  const getDeadlineFormatted = (deadline: Date) => {
    return deadline ? moment(deadline).toDate() : ''
  }

  const { title, description, incentive, rules, tips, teamTermsUrl, imageUrl } = project
  const { deadline, hasVideo, heroVideoEncodingStatus } = project

  const deadlineFormatted: Date = getDeadlineFormatted(deadline)

  const canUploadWelcomeVideo = userProfile.plan.projectHeroVideo

  console.log('Rendering Contributor Settings Form', project)

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Brief your contributors
      </Header>
      <Form>
        <Form.Field width="10" style={{ margin: '0 auto 1.5rem auto' }}>
          <CustomLabel>
            <CustomLabel.Text text="Hero video / image" />
            <CustomLabel.Popup popupContent="Add a video to your landing page to engage and help your contributors. Alternatively, upload an image."></CustomLabel.Popup>
          </CustomLabel>
          {!canUploadWelcomeVideo && (
            <UpgradeTag text="Upgrade your plan to upload a welcome video on your landing page" />
          )}

          {hasVideo && ![200, 199].includes(heroVideoEncodingStatus) ? (
            <VideoEncodingCard status={heroVideoEncodingStatus} />
          ) : (
            <>
              {/* Picture */}
              <Dimmer.Dimmable style={{ width: '400px' }}>
                <Image src={imageUrl}></Image>
                <Dimmer active>
                  <Button.Group>
                    <Button inverted size="small" icon="video" onClick={handleVideoHeroClick} />
                    <Button inverted size="small" icon="image" onClick={() => setShowProjectPictureModal(true)} />
                  </Button.Group>
                </Dimmer>
              </Dimmer.Dimmable>
            </>
          )}
        </Form.Field>
        <Form.Group>
          {/* title  */}
          <Form.Field required width="10">
            <CustomLabel>
              <CustomLabel.Text text=" Give your page a title" />
              <CustomLabel.Popup popupContent="Make it short and powerful."></CustomLabel.Popup>
              <CustomLabel.WordCount word={title} limit={maxLength.title}></CustomLabel.WordCount>
            </CustomLabel>

            <Form.Input
              placeholder="Type here..."
              name="title"
              maxLength={maxLength.title}
              value={title || ''}
              onChange={handleChange}
              error={
                validationError === 'title' && {
                  content: `Please give a title to your project (max ${maxLength.title} characters)`,
                  pointing: 'above',
                }
              }
            />
          </Form.Field>
          {/* expiry date */}
          <Form.Field width="6">
            <label>Expiry date</label>
            <ReactDatePicker
              autoComplete="off"
              selected={deadlineFormatted}
              placeholderText="No expiry date"
              onChange={onSetDate}
              dateFormat="MMMM d, yyyy"
              isClearable
            />
          </Form.Field>
        </Form.Group>
        {/* Incentive  */}
        <Form.Field>
          <CustomLabel>
            <CustomLabel.Text text="Add an incentive to encourage your contributors" />
            <CustomLabel.Popup
              popupContent={
                <p>
                  Boost your video contributions by adding a prize to your project (
                  <ExternalLink url="https://vloggi.helpdocs.io/article/1oikcpvy9v-why-you-need-a-project-incentive">
                    read more
                  </ExternalLink>
                  )
                </p>
              }
            ></CustomLabel.Popup>
            <CustomLabel.WordCount word={incentive} limit={maxLength.incentive}></CustomLabel.WordCount>
          </CustomLabel>

          <Form.Input
            placeholder="Type here..."
            name="incentive"
            maxLength={maxLength.incentive}
            value={incentive || ''}
            onChange={handleChange}
          />
        </Form.Field>

        {/* description  */}
        <Form.Field required>
          <CustomLabel>
            <CustomLabel.Text text="Tell your contributors how to participate" />
            <CustomLabel.Popup popupContent="Tell your audience what type of video you expect them to share with you"></CustomLabel.Popup>
            <CustomLabel.WordCount word={description} limit={maxLength.description}></CustomLabel.WordCount>
          </CustomLabel>

          <Form.TextArea
            placeholder="Type here..."
            name="description"
            rows="6"
            maxLength={maxLength.description}
            value={description || ''}
            onChange={handleChange}
            error={
              validationError === 'description' && {
                content: `Please enter a project brief to your project (max ${maxLength.description} characters)`,
                pointing: 'above',
              }
            }
          />
        </Form.Field>

        <Accordion>
          <Accordion.Title
            active={showMoreCustomisation}
            onClick={() => setShowMoreCustomisation((prevState) => !prevState)}
          >
            <Icon name="dropdown" />
            Show more
          </Accordion.Title>
          <Accordion.Content active={showMoreCustomisation}>
            {/* rules  */}
            <Form.Field>
              <CustomLabel>
                <CustomLabel.Text text="Set your project rules" />
                <CustomLabel.Popup popupContent="Set your project rules here (i.e. competition rules...)"></CustomLabel.Popup>
                <CustomLabel.WordCount word={rules} limit={maxLength.rules}></CustomLabel.WordCount>
              </CustomLabel>

              <Form.TextArea
                placeholder="Type here..."
                name="rules"
                maxLength={maxLength.rules}
                value={rules || ''}
                onChange={handleChange}
                error={
                  validationError === 'rules' && {
                    content: `Please enter a project brief to your project (max ${maxLength.rules} characters)`,
                    pointing: 'above',
                  }
                }
              />
            </Form.Field>
            {/* tips  */}
            <Form.Field>
              <CustomLabel>
                <CustomLabel.Text text="Share some tips to get the better video contributions" />
                <CustomLabel.Popup popupContent="Set some guidelines to help your audience to produce the highest video quality (i.e. horizontal video, proper lighting...)"></CustomLabel.Popup>
                <CustomLabel.WordCount word={tips} limit={maxLength.tips}></CustomLabel.WordCount>
              </CustomLabel>

              <Form.TextArea
                placeholder="Type here..."
                name="tips"
                maxLength={maxLength.tips}
                value={tips || ''}
                onChange={handleChange}
                error={
                  validationError === 'tips' && {
                    content: `Please enter a project brief to your project (max ${maxLength.tips} characters)`,
                    pointing: 'above',
                  }
                }
              />
            </Form.Field>
            {/* T&C  */}
            <Form.Field>
              <CustomLabel>
                <CustomLabel.Text text="Link to your own terms and conditions" />
                <CustomLabel.Popup popupContent="Link to your own terms and conditions"></CustomLabel.Popup>
                <CustomLabel.WordCount word={teamTermsUrl} limit={maxLength.teamTermsUrl}></CustomLabel.WordCount>
              </CustomLabel>

              <Form.Input
                placeholder="https://www.mydomain.com/my-terms-and-conditions"
                name="teamTermsUrl"
                maxLength={maxLength.teamTermsUrl}
                value={teamTermsUrl || ''}
                onChange={handleChange}
                error={
                  validationError === 'teamTermsUrl' && {
                    content: `A valid link needs to be entered`,
                    pointing: 'above',
                  }
                }
              />
            </Form.Field>
          </Accordion.Content>
        </Accordion>
      </Form>
    </>
  )
}

interface IProps {
  project: IProject
  validationError: string | null
  handleChange: (arg: any, data: any) => void
  setShowProjectPictureModal: (arg: boolean) => void
  handleVideoHeroClick: (arg: SyntheticEvent) => void
}
export default PlanTabLandingPage
