import React, { useState, useEffect } from 'react'

import { Form, Grid, Segment } from 'semantic-ui-react'

// Interface
import { ITheme } from '../../../api/Data'

const DesignerTabAdmin = ({ theme, updateTheme }: IProps) => {
  // States
  const [themeJSONData, setThemeJSONData] = useState('')

  // Update JSON data each time theme gets saved
  useEffect(() => {
    setThemeJSONData(JSON.stringify(theme.data, undefined, 4))
  }, [theme])

  // Handle change
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target

    try {
      const parsedValue = JSON.parse(value)

      updateTheme({ ...theme, data: parsedValue })
    } catch (err) {
      alert(err)
    }
  }

  // Change value of theme data
  const handleJSONChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target

    setThemeJSONData(value)
  }

  if (!theme) return null

  console.log('Rendering template admin page')

  return (
    <Segment>
      <p>
        Note: In order to be able to save your changes, don't forget to click outside of the "data" box once you have
        modified the template
      </p>
      <Form>
        <Grid columns={1}>
          <Grid.Column>
            <Form.TextArea
              label="data"
              rows="27"
              name="data"
              value={themeJSONData}
              onChange={handleJSONChange}
              onBlur={handleChange}
            />
          </Grid.Column>
        </Grid>
      </Form>
    </Segment>
  )
}

interface IProps {
  theme: ITheme
  updateTheme: (arg: ITheme) => void
}
export default DesignerTabAdmin
