import React from 'react'

import { Header, Image } from 'semantic-ui-react'

import { IEpisode } from '../../../api/Data'
import moment from '../../../utils/Moment'

// Episode won't re-render if its status has not changed
const hasStatusChanged = (prevProps: { episode: IEpisode }, currentProps: { episode: IEpisode }) =>
  prevProps.episode.status === currentProps.episode.status

const ExportTabEpisodeCard = React.memo(({ episode, setEpisodeModal }: IProps) => {
  const { imageLowResUrl, createdAt, title } = episode
  return (
    <div style={{ cursor: 'pointer' }}>
      <Image rounded src={imageLowResUrl} onClick={() => setEpisodeModal(episode)} />
      <Header as="h4">
        {title}
        <Header.Subheader>{moment(createdAt).format('ll')}</Header.Subheader>
      </Header>
    </div>
  )
}, hasStatusChanged)

interface IProps {
  episode: IEpisode
  setEpisodeModal: (arg: IEpisode) => void
}

export default ExportTabEpisodeCard
