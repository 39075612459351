import React, { useContext, useState } from 'react'

import { Button, Dropdown, Popup } from 'semantic-ui-react'

import { UserContext } from '../../contexts/UserContext'
import { getFontFileFromFontCSS } from '../../utils/sharedFunctions'

import {
  getNewRatingElement,
  getNewTextElement,
  getNewImageElement,
  getNewLogoElement,
} from '../../components/common/ThemeElement'

import DesignerImageSelectModal from './modals/DesignerImageSelectModal'
import DesignerIntroOutroSelectionModal from './modals/DesignerIntroOutroSelectionModal'
import DesignerStaticTextPromptModal from './modals/DesignerStaticTextPromptModal'

// List of elements for a clip
const clipTextElements = [
  { text: 'Place name', value: 'place', icon: 'map pin' },
  { text: 'Location', value: 'location', icon: 'location arrow' },
  { text: 'Contributor name', value: 'creator', icon: 'user' },
  { text: 'Clip date & time', value: 'date', icon: 'calendar alternate outline' },
  { text: 'List count', value: 'count', icon: 'list ol' },
  { text: 'Prompt 1 answer', value: 'caption1', icon: 'closed captioning' },
  { text: 'Prompt 2 answer', value: 'caption2', icon: 'closed captioning' },
  { text: 'Prompt 3 answer', value: 'caption3', icon: 'closed captioning' },
]

// List of elements for an opening or closing
const openingClosingTextElements = [
  { text: 'Story name', value: 'title', icon: 'heading' },
  { text: 'Producer name', value: 'creator', icon: 'user' },
  { text: 'Contributor list', value: 'credits', icon: 'users' },
]

const DropDownOpeningAndClosingElements = ({
  sectionName,
  setIntroOutroSelectModal,
  setImageSelectionModal,
  setShowStaticTextPromptModal,
  addLogoElement,
  addThemeElement,
}) => {
  return (
    <Dropdown
      className="primary icon"
      button
      icon="add"
      pointing="left"
      upward={false}
      style={{ marginRight: '0', marginBottom: '1rem' }}
    >
      <Dropdown.Menu>
        <Dropdown.Header>Change {sectionName} video</Dropdown.Header>
        <Dropdown.Item icon="film" text="Video" onClick={() => setIntroOutroSelectModal(true)} />
        <Dropdown.Divider />

        <Dropdown.Header>Static elements</Dropdown.Header>
        <Dropdown.Item icon="at" text="Logo" value="logo" key="logo" onClick={addLogoElement} />
        <Dropdown.Item icon="font" text="Text box" value="static" onClick={() => setShowStaticTextPromptModal(true)} />
        <Dropdown.Item icon="object group outline" text="Image" onClick={() => setImageSelectionModal(true)} />

        <Dropdown.Header>
          <>{sectionName} smart fields ⚡️</>
        </Dropdown.Header>

        {openingClosingTextElements.map((element) => (
          <Dropdown.Item
            key={element.value}
            value={element.value}
            onClick={addThemeElement}
            icon={element.icon}
            text={element.text}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const DropDownVloggiElements = ({
  setImageSelectionModal,
  addThemeElement,
  addLogoElement,
  addRatingElement,
  setShowStaticTextPromptModal,
}) => {
  return (
    <Dropdown
      className="primary icon"
      button
      icon="add"
      pointing="left"
      upward={false}
      style={{ marginRight: '0', marginBottom: '1rem' }}
    >
      <Dropdown.Menu>
        <Dropdown.Header>Static elements</Dropdown.Header>
        <Dropdown.Item icon="at" text="Logo" value="logo" key="logo" onClick={addLogoElement} />
        <Dropdown.Item icon="font" text="Text box" value="static" onClick={() => setShowStaticTextPromptModal(true)} />
        <Dropdown.Item icon="object group outline" text="Image" onClick={() => setImageSelectionModal(true)} />

        <Dropdown.Header>
          <>Clip smart fields ⚡️</>
        </Dropdown.Header>
        {clipTextElements.map((element) => (
          <Dropdown.Item
            key={element.value}
            value={element.value}
            onClick={addThemeElement}
            icon={element.icon}
            text={element.text}
          />
        ))}
        <Dropdown.Item key="rating" value="rating" onClick={addRatingElement} icon="star" text="Rating" />
      </Dropdown.Menu>
    </Dropdown>
  )
}

const DesignerTabSectionAddItem = (props) => {
  const { userProfile } = useContext(UserContext)

  // Props
  const { section, sectionName, selectedIndex, setSelectedIndex, defaultFonts, themeHistory, copiedElement } = props
  const { updateThemeSection, revertChange, copyThemeElement, pasteThemeElement } = props

  // States
  const [introOutroSelectModal, setIntroOutroSelectModal] = useState(false)
  const [imageSelectionModal, setImageSelectionModal] = useState(false)
  const [showStaticTextPromptModal, setShowStaticTextPromptModal] = useState(false)

  // Add element
  const addThemeElement = (e, { value }, text = null) => {
    setShowStaticTextPromptModal(false)

    const defaultFontFamily = defaultFonts[0]
    const fontfile = getFontFileFromFontCSS(defaultFontFamily, 'normal', 'normal')
    const newElement = getNewTextElement(value, fontfile, text)

    const newThemeSection = { ...section }

    newThemeSection.elements.push(newElement)

    updateThemeSection(newThemeSection)

    const indexNewElement = newThemeSection.elements.length - 1
    setSelectedIndex(indexNewElement)
  }

  // Delete an element from the theme
  const deleteThemeElement = () => {
    const newThemeSection = { ...section }

    newThemeSection.elements.splice(selectedIndex, 1)

    setSelectedIndex(null)
    updateThemeSection(newThemeSection)
  }

  // Add Logo element
  const addLogoElement = () => {
    setShowStaticTextPromptModal(false)

    const { watermarkId } = userProfile

    const watermarkSource = watermarkId || 'default-logo-watermark'

    const newElement = getNewLogoElement(watermarkSource)

    const newThemeSection = { ...section }
    newThemeSection.elements.push(newElement)

    updateThemeSection(newThemeSection)

    const indexNewElement = newThemeSection.elements.length - 1

    setSelectedIndex(indexNewElement)
  }

  // Add rating element
  const addRatingElement = () => {
    setShowStaticTextPromptModal(false)

    const newElement = getNewRatingElement()

    const newThemeSection = { ...section }
    newThemeSection.elements.push(newElement)

    updateThemeSection(newThemeSection)

    const indexNewElement = newThemeSection.elements.length - 1

    setSelectedIndex(indexNewElement)
  }

  // Change intro outro
  const handleSelectIntroOutro = (itemSelected) => {
    const newThemeSection = { ...section }
    newThemeSection.input = itemSelected

    updateThemeSection(newThemeSection)
    setIntroOutroSelectModal(false)
  }

  // Add image
  const handleSelectImage = (imageSelected) => {
    const { id: src, width, height } = imageSelected
    const newImageElement = getNewImageElement(src, width, height)

    const newThemeSection = { ...section }
    newThemeSection.elements.push(newImageElement)

    updateThemeSection(newThemeSection)
    setImageSelectionModal(false)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {['opening', 'closing'].includes(sectionName) && (
        <DropDownOpeningAndClosingElements
          sectionName={sectionName}
          setIntroOutroSelectModal={setIntroOutroSelectModal}
          setImageSelectionModal={setImageSelectionModal}
          setShowStaticTextPromptModal={setShowStaticTextPromptModal}
          addLogoElement={addLogoElement}
          addThemeElement={addThemeElement}
        />
      )}

      {['vloggi'].includes(sectionName) && (
        <DropDownVloggiElements
          setIntroOutroSelectModal={setIntroOutroSelectModal}
          setImageSelectionModal={setImageSelectionModal}
          setShowStaticTextPromptModal={setShowStaticTextPromptModal}
          addThemeElement={addThemeElement}
          addLogoElement={addLogoElement}
          addRatingElement={addRatingElement}
        />
      )}

      <Popup
        trigger={
          <Button
            style={{ margin: '1rem 0' }}
            inverted
            secondary
            icon="undo"
            disabled={!themeHistory.length}
            onClick={revertChange}
          />
        }
        content="Undo change"
        position="top left"
      />

      <Popup
        trigger={
          <Button
            style={{ margin: '1rem 0' }}
            inverted
            secondary
            icon="copy"
            disabled={selectedIndex === null}
            onClick={() => copyThemeElement(selectedIndex)}
          />
        }
        content="Copy element"
        position="top left"
      />

      <Popup
        trigger={
          <Button
            style={{ margin: '1rem 0' }}
            inverted
            secondary
            icon="clipboard"
            disabled={!copiedElement}
            onClick={() => pasteThemeElement(selectedIndex)}
          />
        }
        content="Paste element"
        position="top left"
      />
      {/* </Grid.Row> */}

      <Popup
        trigger={
          <Button
            style={{ margin: '1rem 0' }}
            inverted
            color="red"
            icon="trash"
            disabled={selectedIndex === null}
            onClick={() => deleteThemeElement(selectedIndex)}
          />
        }
        content="Delete element"
        position="top left"
      />
      {introOutroSelectModal && (
        <DesignerIntroOutroSelectionModal
          section={section}
          sectionName={sectionName}
          updateThemeSection={updateThemeSection}
          handleSelect={handleSelectIntroOutro}
          setShowModal={setIntroOutroSelectModal}
        />
      )}
      {imageSelectionModal && (
        <DesignerImageSelectModal
          updateThemeSection={updateThemeSection}
          handleSelect={handleSelectImage}
          setShowModal={setImageSelectionModal}
        />
      )}

      {showStaticTextPromptModal && (
        <DesignerStaticTextPromptModal handleSubmit={addThemeElement} setShowModal={setShowStaticTextPromptModal} />
      )}
    </div>
  )
}

export default DesignerTabSectionAddItem
