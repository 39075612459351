import React, { FC, useContext } from 'react'

import { Dimmer, Loader } from 'semantic-ui-react'

import { GlobalContext } from '../../contexts/GlobalContext'

const LoadingOverlay: FC<{ children: JSX.Element[] }> = ({ children }) => {
  const { isLoading } = useContext(GlobalContext)
  return (
    <Dimmer.Dimmable>
      <Dimmer active={isLoading} inverted>
        <Loader size="medium">Loading</Loader>
      </Dimmer>
      {children}
    </Dimmer.Dimmable>
  )
}

export default LoadingOverlay
