import React, { Fragment, useContext } from 'react'

import queryString from 'query-string'
import { withRouter, RouteComponentProps } from 'react-router'
import { Divider, Header, Menu, Label } from 'semantic-ui-react'

import stepperDesignActiveIcon from '../../_images/stepper-invite-icon-active.svg'
import stepperDesignIcon from '../../_images/stepper-invite-icon.svg'
import stepperInvitesActiveIcon from '../../_images/stepper-manage-icon-active.svg'
import stepperInvitesIcon from '../../_images/stepper-manage-icon.svg'
import stepperPlanActiveIcon from '../../_images/stepper-plan-icon-active.svg'
import stepperPlanIcon from '../../_images/stepper-plan-icon.svg'
import stepperPublishActiveIcon from '../../_images/stepper-publish-icon-active.svg'
import stepperPublishIcon from '../../_images/stepper-publish-icon.svg'
import { UserContext } from '../../contexts/UserContext'
import Auth from '../../utils/Auth'

const adminMemberSectionOptions: ISectionOption[] = [
  {
    section: 'plan',
    sectionName: 'Plan',
    icon: stepperPlanIcon,
    activeIcon: stepperPlanActiveIcon,
    tabs: [
      {
        tab: 'overview',
        tabName: 'Overview',
      },
      {
        tab: 'branding',
        tabName: 'Branding',
      },
      {
        tab: 'landingPage',
        tabName: 'Landing Page',
      },
      {
        tab: 'questions',
        tabName: 'Questions',
        isNew: true,
      },
      {
        tab: 'formats',
        tabName: 'Formats',
        isNew: true,
        shouldShowOnlyAdmin: true,
      },
      {
        tab: 'dataCollection',
        tabName: 'Data Collection',
      },
      {
        tab: 'successPage',
        tabName: 'Success Page',
      },
      {
        tab: 'videoLayout',
        tabName: 'Video Layout',
      },
    ],
  },
  {
    section: 'invite',
    sectionName: 'Invite',
    icon: stepperDesignIcon,
    activeIcon: stepperDesignActiveIcon,
    tabs: [
      {
        tab: null,
        tabName: 'Invite contributors',
      },
    ],
  },
  {
    section: 'manage',
    sectionName: 'Manage',
    icon: stepperInvitesIcon,
    activeIcon: stepperInvitesActiveIcon,
    tabs: [
      {
        tab: null,
        tabName: 'Video clips',
      },
    ],
  },
  {
    section: 'export',
    sectionName: 'Exports',
    icon: stepperPublishIcon,
    activeIcon: stepperPublishActiveIcon,
    tabs: [
      {
        tab: null,
        tabName: 'Stories and downloads',
      },
    ],
  },
]

const sectionOptionsCollaborator: ISectionOption[] = [
  {
    section: 'plan',
    sectionName: 'Plan',
    icon: stepperPlanIcon,
    activeIcon: stepperPlanActiveIcon,
    tabs: [
      {
        tab: 'overview',
        tabName: 'Overview',
      },
    ],
  },
  {
    section: 'manage',
    sectionName: 'Manage',
    icon: stepperInvitesIcon,
    activeIcon: stepperInvitesActiveIcon,
    tabs: [
      {
        tab: null,
        tabName: 'Video clips',
      },
    ],
  },
]

const SideBarSecondary = ({ history, location, goToPage }: IProps) => {
  const { isAdmin } = Auth.getPayLoad()
  const { userProfile } = useContext(UserContext)

  const { pathname, search } = location

  // e.g. https://studio.vloggi.com/projects/MBN-000/plan?tab=langingPage
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [domain, resource, projectSlug, currentSection] = pathname.split('/')

  const { tab } = queryString.parse(search)

  const currentTab = tab ? String(tab) : null

  console.log('Rendering Secondary Side Bar')

  // Is user collaborator
  const isUserCollaborator = (): boolean => {
    return userProfile.userAccessRights === 'collaborator'
  }

  const sectionOptions = isUserCollaborator() ? sectionOptionsCollaborator : adminMemberSectionOptions

  return (
    <div style={{ padding: '1rem' }}>
      <Divider fitted style={{ marginTop: '48px' }}></Divider>

      {sectionOptions.map(({ section, sectionName, activeIcon, icon, tabs }) => (
        <Fragment key={section}>
          <Header
            as="h5"
            style={{ fontWeight: '700', margin: '0.5rem 0 0 0' }}
            image={currentSection === section ? activeIcon : icon}
            content={sectionName}
          />

          <Menu compact text vertical style={{ marginTop: '0.5rem ' }}>
            {tabs.map(({ tab, tabName, isNew, shouldShowOnlyAdmin }) =>
              shouldShowOnlyAdmin && !isAdmin ? null : (
                <Menu.Item
                  key={tabName}
                  name={tabName}
                  color="violet"
                  active={currentSection === section && currentTab === tab}
                  style={{
                    borderRadius: '0',
                    borderLeft: '2px solid #e2e2e2',
                    padding: '0.75rem',
                    margin: '0 0 0 9px',
                  }}
                  onClick={() => goToPage(`/projects/${projectSlug}/${section}${tab ? `?tab=${tab}` : ''}`)}
                >
                  {isNew && <Label color="red" size="mini" horizontal content="New" />}
                  {tabName}
                </Menu.Item>
              )
            )}
            {/* Add video template management if user is an admin */}
            {isAdmin && section === 'plan' && (
              <Menu.Item
                key="Template Admin"
                name="Template Admin"
                color="violet"
                active={currentSection === section && currentTab === 'templateAdmin'}
                style={{
                  borderRadius: '0',
                  borderLeft: '2px solid #e2e2e2',
                  padding: '0.5rem',
                  margin: '0 0 0 9px',
                }}
                onClick={() => goToPage(`/projects/${projectSlug}/plan?tab=templateAdmin`)}
              />
            )}
          </Menu>
        </Fragment>
      ))}
    </div>
  )
}

interface ISectionOption {
  section: string
  sectionName: string
  icon: string
  activeIcon: string
  tabs: {
    tab: string | null
    tabName: string
    isNew?: boolean
    shouldShowOnlyAdmin?: boolean
  }[]
}

interface IProps extends RouteComponentProps<any> {
  goToPage: (args: string) => void
}

export default withRouter(SideBarSecondary)
