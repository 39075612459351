import React, { useState } from 'react'

import CopyToClipBoard from 'react-copy-to-clipboard'
import { Header, Button, Segment, Label } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'

const InviteTab = ({ project }: IProps) => {
  // States
  const [copied, setCopied] = useState(false)

  if (!project) return null

  const { projectUrl } = project

  const embedCode = `<embed src=${projectUrl} type="text/html" width="100%" height="1000">`

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Embed on your website
      </Header>
      <Segment textAlign="center" style={{ height: '200px' }}>
        <Header as="h3">
          <Header.Subheader>
            Copy this code snippet directly on your landing page or micro site to allow your audience to contribute
            their videos directly form your website.
          </Header.Subheader>
        </Header>

        <div style={{ margin: '2rem 0 1rem 0' }}>
          <CopyToClipBoard
            text={embedCode}
            options={{ format: 'text/plain' }}
            onCopy={() => {
              setCopied(true)
            }}
          >
            <Button as="div" labelPosition="left">
              <Label as="a" basic>
                {`${embedCode.slice(0, 20)}...`}
              </Label>
              <Button icon secondary size="mini">
                {copied ? 'Copied' : 'Copy link'}
              </Button>
            </Button>
          </CopyToClipBoard>
        </div>
      </Segment>
    </>
  )
}

interface IProps {
  project: IProject
}

export default InviteTab
