import React, { useContext } from 'react'

import { Popup, Header, Icon, Image, Dropdown } from 'semantic-ui-react'

import { UserContext } from '../../contexts/UserContext'

import avatarImage from '../../_images/avatar-default.jpg'

const UserCard = ({ username, logoAvatarUrl, isSmallSideBar }: IUserCardProps) => (
  <Header as="h4" inverted style={{ margin: '0', padding: '0.5rem  1rem ' }}>
    <Image avatar style={{ width: '35px' }} src={`${logoAvatarUrl}`} /> {isSmallSideBar ? '' : username}
  </Header>
)

const UserMenu = ({ username, logoAvatarUrl, goToPage, logout, isSmallSideBar }: IUserMenuProps) => {
  const { userProfile } = useContext(UserContext)

  const { email } = userProfile

  return (
    <Dropdown
      trigger={<UserCard username={username} logoAvatarUrl={logoAvatarUrl} isSmallSideBar={isSmallSideBar} />}
      icon={null}
    >
      <Dropdown.Menu pointing="top left">
        {isSmallSideBar ? (
          <>
            <Popup
              content="My Profile"
              trigger={<Dropdown.Item icon="user" onClick={() => goToPage('/me')} />}
              position="right center"
            />
            <Popup
              content="My Team"
              trigger={<Dropdown.Item icon="users" onClick={() => goToPage('/my-team')} />}
              position="right center"
            />
            <Popup
              content="Plans & Pricing"
              trigger={<Dropdown.Item icon="clipboard outline" onClick={() => goToPage('/my-account')} />}
              position="right center"
            />
            <Popup
              content="Settings"
              trigger={<Dropdown.Item icon="setting" onClick={() => goToPage('/my-settings')} />}
              position="right center"
            />
            <Popup
              content="Sign Out"
              trigger={<Dropdown.Item icon="log out" onClick={logout} />}
              position="right center"
            />
          </>
        ) : (
          <>
            <Dropdown.Header style={{ width: 200 }}>
              <Header as="h4">
                Hi, {username}
                <Header.Subheader style={{ margin: 0 }}>
                  {email}
                </Header.Subheader>
              </Header>
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item text="My Profile" image={{ avatar: true, src: avatarImage }} onClick={() => goToPage('/me')} />
            <Dropdown.Item text="My Team"  image={{ avatar: true, src: logoAvatarUrl }} onClick={() => goToPage('/my-team')} />
            <Dropdown.Item text="Plans & Pricing" icon={<Icon name="clipboard outline" style={{ width: 28 }} />} onClick={() => goToPage('/my-account')} />
            <Dropdown.Item text="Settings" icon={<Icon name="setting" style={{ width: 28 }} />} onClick={() => goToPage('/my-settings')} />
            <Dropdown.Divider />
            <Dropdown.Item text="Sign Out" onClick={logout} style={{ textAlign: 'right' }} />
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

interface IUserMenuProps {
  username: string
  logoAvatarUrl: string
  isSmallSideBar: boolean
  goToPage: (arg: string) => void
  logout: () => void
}

interface IUserCardProps {
  username: string
  logoAvatarUrl: string
  isSmallSideBar: boolean
}

export default UserMenu
