// moment.locale();         // en-au
// moment().format('LT');   // 5:33 PM
// moment().format('LTS');  // 5:33:47 PM
// moment().format('L');    // 23/02/2021
// moment().format('l');    // 23/2/2021
// moment().format('LL');   // 23 February 2021
// moment().format('ll');   // 23 Feb 2021
// moment().format('LLL');  // 23 February 2021 5:33 PM
// moment().format('lll');  // 23 Feb 2021 5:33 PM
// moment().format('LLLL'); // Tuesday, 23 February 2021 5:33 PM
// moment().format('llll'); // Tue, 23 Feb 2021 5:33 PM

const moment = require('moment/min/moment-with-locales')

moment.updateLocale('en-au', { parentLocale: 'en' }) // Define date formats ('LLL', 'lll'...)
moment.defaultFormat = 'YYYY-MM-DD HH:mm:ssZ' // Local time

export default moment
