import React, { useContext, useState, useEffect } from 'react'

import ReactDatePicker from 'react-datepicker'
import { Form, Segment, Dropdown, Header, DropdownProps, Message } from 'semantic-ui-react'

import Data, { IPlan } from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'
import Moment from '../../utils/Moment'
import moment from '../../utils/Moment'

const MyAccountDetailsAdmin = () => {
  const { isMobile } = useContext(GlobalContext)
  const { userProfile, dispatchUserContext } = useContext(UserContext)

  // States
  const [plans, setPlans] = useState<IPlan[] | null>(null)
  const [planSelected, setPlanSelected] = useState<any | null>(null)
  const [newPlanExpDateAdmin, setNewPlanExpDateAdmin] = useState<Date | null>(null)

  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    const fetchPlans = async () => {
      const { data, error } = await Data.getAvailablePlans()

      if (error) alert('An error occurred')

      setPlans(data)
    }

    fetchPlans()
  }, [])

  const handleSelectPlanAdmin = async (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value: planId } = data

    const newPlanSelected = plans!.find((plan) => plan.id === planId)

    setPlanSelected(newPlanSelected)
  }

  // Set filming date
  const handleChangeExpDateAdmin = (date: Date) => {
    // Here we set the time to 23:59:59 to make sure that the project stays open until the end of the newPlanExpDateAdmin day.
    const newExpDate = date ? Moment(date).set('hour', 23).set('minute', 59).set('second', 59).format() : null

    setNewPlanExpDateAdmin(newExpDate)
  }

  const submitChangePlanAdmin = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!planSelected) return alert('Please select a plan')

    const { data: newPlan, error } = await Data.changePlan({
      newPlanId: planSelected.id,
      newPlanExpDate: newPlanExpDateAdmin,
    })

    if (error) return setErrorMessage(error)

    setSuccessMessage('Plan has been updated successfully')

    dispatchUserContext({
      type: 'update user plan',
      payload: { plan: newPlan, subscriptionExpiryDate: newPlanExpDateAdmin },
    })
  }

  // Set filming date
  const onSetDate = (date: Date) => {
    const newDeadline = date ? moment(date).set('hour', 23).set('minute', 59).set('second', 59).format() : null

    handleChangeExpDateAdmin(newDeadline)
  }

  const { subscriptionExpiryDate } = userProfile

  const expirationDateFormatted: Date | null = newPlanExpDateAdmin ? Moment(newPlanExpDateAdmin).toDate() : null

  if (!plans) return null

  const planOpts = plans!.map((plan) => ({
    key: plan.id,
    text: plan.title,
    value: plan.id,
  }))

  console.log('Rendering Account Details Admin page', subscriptionExpiryDate, expirationDateFormatted)

  return (
    <>
      <Header as="h3">Admin section</Header>
      <Segment>
        <Form onSubmit={submitChangePlanAdmin}>
          <Form.Field>
            <label>Change user plan to:</label>
            <Dropdown placeholder="Select Plan" fluid selection options={planOpts} onChange={handleSelectPlanAdmin} />
          </Form.Field>

          <Form.Field>
            <label>Expiration date</label>
            <ReactDatePicker
              autoComplete="off"
              selected={expirationDateFormatted}
              placeholderText="No expiry date"
              onChange={onSetDate}
              dateFormat="MMMM d, yyyy"
              isClearable
            />
          </Form.Field>

          <Form.Button type="submit" primary fluid={isMobile}>
            Change
          </Form.Button>
        </Form>
        {successMessage && <Message success header="Success" content={successMessage} />}

        {errorMessage && <Message error header="Fail" content={errorMessage} />}
      </Segment>
    </>
  )
}

export default MyAccountDetailsAdmin
