import React from 'react'

import { Form, Header, InputOnChangeData, Segment, Dropdown } from 'semantic-ui-react'

import { IPlan, IProject } from '../../../api/Data'

import CustomLabel from '../../../components/common/custom-label/CustomLabel'
import UpgradeTag from '../../../components/common/UpgradeTag'

const MAX_NUMBER_OF_QUESTIONS = 8
const MAX_QUESTIONS_CHARACTER_LENGTH = 260

const PlanTabQuestionsPage = (props: IProps) => {
  // Props
  const { project, plan, handleChange } = props

  // Dropdown options should be 1 to 8
  const dropdownOptions = Array.from(Array(MAX_NUMBER_OF_QUESTIONS).keys()).map((i) => ({
    key: i + 1,
    text: i + 1,
    value: i + 1,
  }))

  const handleDropdownChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
    handleChange(e, {
      name: 'numberOfQuestions',
      value: data.value,
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { name, value } = data

    // Get the question number from the name
    const questionNumber = name.split('-')[1]

    // Copy the questions array
    const updatedQuestions = [...project.questions]

    // Update the specific question
    updatedQuestions[questionNumber - 1] = value

    // Update the project
    handleChange(e, {
      name: 'questions',
      values: [...updatedQuestions],
    })
  }

  console.log('Rendering Contributor Settings Form', project)

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Ask your contributors questions
        {!plan.isAllowedMultiClipUpload && (
          <UpgradeTag text="Upgrade your plan to ask your contributor multiple questions" />
        )}
      </Header>
      <Header as="h4">
        You can ask up to 8 questions. The contributor will be asked to answer each question with a video clip.
      </Header>

      <Segment>
        <Form>
          <Form.Field>
            <Dropdown
              fluid
              selection
              options={dropdownOptions}
              defaultValue={project.numberOfQuestions ?? 1}
              onChange={handleDropdownChange}
              disabled={!plan.isAllowedMultiClipUpload}
            />
          </Form.Field>
        </Form>
      </Segment>

      <Header as="h4" style={{ fontWeight: 'bold' }}>
        Question text
      </Header>

      <Form>
        {Array.from(Array(project.numberOfQuestions).keys()).map((i) => (
          <Form.Field key={i}>
            <CustomLabel>
              <CustomLabel.Text text={'Question ' + (i + 1)} />
              <CustomLabel.WordCount word={project.questions[i]} limit={MAX_QUESTIONS_CHARACTER_LENGTH} />
            </CustomLabel>
            <Form.Input
              placeholder="Type a question here..."
              name={'question-' + (i + 1)}
              maxLength={MAX_QUESTIONS_CHARACTER_LENGTH}
              value={project.questions[i] || ''}
              onChange={handleInputChange}
              disabled={!plan.isAllowedMultiClipUpload}
            />
          </Form.Field>
        ))}
      </Form>
    </>
  )
}

interface IProps {
  project: IProject
  plan: IPlan
  handleChange: (
    arg: React.SyntheticEvent,
    { name, value, checked, values }: { name: string; value?: string; checked?: boolean; values?: string[] }
  ) => void
}

export default PlanTabQuestionsPage
