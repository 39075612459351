import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import Auth from '../utils/Auth'

export const SecureUserRoute = (props: any) => {
  if (Auth.isAuthenticated()) return <Route {...props} />

  return <Redirect to="/login" />
}

export const SecureAdminRoute = (props: any) => {
  if (Auth.isAuthenticated() && Auth.getPayLoad().isAdmin === true) return <Route {...props} />

  return <Redirect to="/login" />
}
