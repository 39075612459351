import React from 'react'
import { useContext } from 'react'

import { Header, Grid, Container } from 'semantic-ui-react'

import { GlobalContext } from '../../contexts/GlobalContext'

const Title = ({ title, children: ActionBtns = null }: ITitle) => {
  const { isMobile } = useContext(GlobalContext)

  const buttonStack = {
    display: 'inline-block',
    gap: '1rem',
  }

  return (
    <Container>
      <div style={{ padding: isMobile ? '1rem 0' : '1rem 3rem ' }}>
        <Grid
          columns={ActionBtns ? 2 : 1}
          stackable
          verticalAlign="middle"
          style={{ height: isMobile ? 'auto' : '62px' }}
        >
          <Grid.Column width={6}>
            <Header as="h3">{title}</Header>
          </Grid.Column>
          <Grid.Column width={10} textAlign="right" style={buttonStack}>
            {ActionBtns}
          </Grid.Column>
          {/* {ActionBtns && <Grid.Column textAlign="right">{ActionBtns}</Grid.Column>} */}
        </Grid>
      </div>
    </Container>
  )
}

interface ITitle {
  title: string
  children?: JSX.Element | null
}

export default Title
