import React from 'react'

import { Button, Form, Segment, Header, InputOnChangeData } from 'semantic-ui-react'

const MyProfileTeamTab = ({ teamName, isMobile, handleChange, handleSubmit }: IProps) => (
  <>
    <Header as="h3">Team details</Header>

    <Segment>
      <Form>
        <Form.Field>
          <Form.Input type="text" label="Name" name="teamName" value={teamName} onChange={handleChange} />
        </Form.Field>
        <Button primary fluid={isMobile} onClick={handleSubmit}>
          Update
        </Button>
      </Form>
    </Segment>
  </>
)

interface IProps {
  teamName: string
  isMobile: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  handleSubmit: () => void
}

export default MyProfileTeamTab
