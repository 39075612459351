import React, { FC } from 'react'

import { Image, Dimmer, Header, Progress } from 'semantic-ui-react'

import VideoImagePlaceholder from '../../_images/video-upload-placeholder.jpg'

const ClipInProgressCard: FC<IProps> = ({ status }) => (
  <Dimmer.Dimmable blurring>
    <Image src={VideoImagePlaceholder} rounded style={{ border: '1px solid #e8e8e9' }} />
    <Dimmer active>
      <Header inverted as="h4">
        Your video is being produced
      </Header>
      <Header as="h5" inverted>
        <Progress size="small" color="violet" active percent={status - 100} />
      </Header>
    </Dimmer>
  </Dimmer.Dimmable>
)

interface IProps {
  status: number
}

export default ClipInProgressCard
