import React, { FC, useContext } from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Confirm } from 'semantic-ui-react'

import { GlobalContext } from '../../contexts/GlobalContext'

const ExitWithoutSavingChangeModal: FC<IProps> = ({ history }) => {
  const { showExitWithoutSavingModal, dispatchGlobalContext } = useContext(GlobalContext)

  const handleConfirm = () => {
    dispatchGlobalContext({ type: 'set user has made changes', payload: false })
    dispatchGlobalContext({ type: 'set exit without saving modal', payload: null })

    history.push(`${showExitWithoutSavingModal}`)
  }

  console.log('Rendering Exist Modal', showExitWithoutSavingModal)

  return (
    <Confirm
      open={!!showExitWithoutSavingModal}
      header="Exit without saving"
      content="Some changes have been made on this page. Do you really want to exit without saving your changes?"
      cancelButton="Cancel"
      confirmButton="Exit without saving"
      onCancel={() => dispatchGlobalContext({ type: 'set exit without saving modal', payload: null })}
      onConfirm={handleConfirm}
    />
  )
}

interface IProps extends RouteComponentProps<any> {}

export default withRouter(ExitWithoutSavingChangeModal)
