// This route should handle the oauth2 callback from Google redirect
// Pull the code from the query string and send it to the server

import React, { useEffect } from 'react'

import { RouteComponentProps } from 'react-router-dom'

import Data, { IntegrationProvider } from '../../api/Data'

const Callback = ({ location }: RouteComponentProps) => {
  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    // Log the URL
    console.log('location', location)

    // Set loading to true
    setIsLoading(true)

    // Get the code from the query string
    const urlParams = new URLSearchParams(location.search)
    const code = urlParams.get('code')

    console.log('code', code)

    if (!code) {
      console.error('No code found in query string')
      return
    }

    const createIntegration = async () => {
      const { data, error } = await Data.createIntegration(IntegrationProvider.GOOGLE, code)

      if (error) {
        console.error('Error integrating Google OAuth2', error)
      }

      // TODO: Check for an expiry value in the response to determine success?

      setIsLoading(false)

      // Redirect to the team integrations page
      window.location.href = '/my-team'
    }

    createIntegration()
  }, [location])

  return (
    // Show a full page loading spinner in the middle of the screen
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      {isLoading && <div>Finalising your OAuth2 connection...</div>}
    </div>
  )
}

export default Callback
