import React, { useContext } from 'react'

import { Card, Image, Grid, Dropdown, Icon } from 'semantic-ui-react'

import threeDotsIcon from '../../_images/3-dots.svg'
import { IProject } from '../../api/Data'
import { UserContext } from '../../contexts/UserContext'
import moment from '../../utils/Moment'

import UpgradeTag from '../../components/common/UpgradeTag'

const UploadsProjectCard = (props: IProps) => {
  // Props
  const { project } = props
  const { goToProjectShowPage, handleDuplicateProject, setShowDeletionConfModal } = props

  const { userProfile } = useContext(UserContext)

  const { name, slug, imageUrl, deadline, isProjectClosed } = project

  // Is user collaborator
  const isUserCollaborator = (): boolean => {
    return userProfile.userAccessRights === 'collaborator'
  }

  // To make sure the name fits on 1 line
  const shortTitle = name.length > 20 ? name.substring(0, 20).concat('...') : name

  return (
    <Card>
      <Image src={imageUrl} as="a" size="medium" onClick={() => goToProjectShowPage(slug)} />
      <Card.Content>
        <Card.Header>{shortTitle}</Card.Header>
        <Card.Meta>
          {isProjectClosed && <Icon color="red" name="exclamation circle" />}{' '}
          {deadline ? `End: ${moment(deadline).format('ll')}` : 'No expiry date'}
        </Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <Grid columns={2}>
          <Grid.Column>
            <p style={{ color: `${project.totalNumberOfTeamClips >= project.clipMax ? 'red' : ''}` }}>
              {project.clipCount === 1 ? '1 clip' : `${project.clipCount} clips`}
              {project.totalNumberOfTeamClips >= project.clipMax && (
                <UpgradeTag text="Upgrade your plan to collect more video clips" />
              )}
            </p>
          </Grid.Column>
          {!isUserCollaborator() && (
            <Grid.Column textAlign="right">
              <Dropdown
                trigger={<Image style={{ width: '1.5rem' }} src={threeDotsIcon} />}
                pointing="top left"
                icon={null}
              >
                <Dropdown.Menu>
                  <Dropdown.Item text="Duplicate project" onClick={() => handleDuplicateProject(slug)} />
                  <Dropdown.Item text="Delete project" onClick={() => setShowDeletionConfModal(slug)} />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
          )}
        </Grid>
      </Card.Content>
    </Card>
  )
}

interface IProps {
  project: IProject
  handleDuplicateProject: (arg: string) => void
  goToProjectShowPage: (arg: string) => void
  setShowDeletionConfModal: (arg: string) => void
}
export default UploadsProjectCard
