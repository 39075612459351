// RollbarErrorTracking.js
import Rollbar from 'rollbar'

import { HAS_ROLLBAR_SCRIPT, ROLLBAR_API_KEY } from '../config/environments'

export default new Rollbar({
  enabled: HAS_ROLLBAR_SCRIPT,
  environment: 'production',
  accessToken: ROLLBAR_API_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
})
