// This function add a no-index tag to the main html (only on staging)
export const addNoIndexTag = () => {
  const meta = document.createElement('meta')
  meta.setAttribute('name', 'robots')
  meta.setAttribute('content', 'noindex')

  const head = document.getElementsByTagName('head')[0]
  head.insertBefore(meta, head.childNodes[0])
}

export const addHotJarScript = () => {
  const hotjarTag = document.createElement('script')
  hotjarTag.innerText =
    "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:2308767,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"

  const head = document.getElementsByTagName('head')[0]
  head.insertBefore(hotjarTag, head.childNodes[0])
}

export const addGoSquaredScript = () => {
  const goSquaredTag = document.createElement('script')
  goSquaredTag.innerText = `!(function (g, s, q, r, d) {r = g[r] = g[r] || function () { ;(r.q = r.q || []).push(arguments)};d = s.createElement(q);d.src = '//d1l6p2sc9645hc.cloudfront.net/gosquared.js';q = s.getElementsByTagName(q)[0];q.parentNode.insertBefore(d, q);})(window, document, 'script', '_gs');_gs('GSN-371470-M', false);_gs('set', 'anonymizeIP', true);_gs('set', 'trackLocal', true)`

  const body = document.getElementsByTagName('body')[0]
  body.appendChild(goSquaredTag)
}

export const addFirstPromoterScript = () => {
  const firstPromoterTag1 = document.createElement('script')
  firstPromoterTag1.src = 'https://cdn.firstpromoter.com/fpr.js'
  firstPromoterTag1.async = true

  const firstPromoterTag2 = document.createElement('script')
  firstPromoterTag2.innerText = `(function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);fpr("init", {cid:"9m2g9lls"}); fpr("click");`

  const body = document.getElementsByTagName('body')[0]
  body.appendChild(firstPromoterTag1)
  body.appendChild(firstPromoterTag2)
}

export const addGetReditusScript = () => {
  const getReditusTag = document.createElement('script')
  getReditusTag.innerText = `(function(w, d, s, p, t) { w.gr = w.gr || function() { w.gr.q = w.gr.q || []; w.gr.q.push(arguments); }; p = d.getElementsByTagName(s)[0]; t = d.createElement(s); t.async = true; t.src = "https://app.getreditus.com/gr.js?_ce=60"; p.parentNode.insertBefore(t, p); })(window, document, "script"); gr("track", "pageview"); `

  const body = document.getElementsByTagName('body')[0]
  body.appendChild(getReditusTag)
}
