import React from 'react'

import { Initial } from 'react-initial'
import { Grid, Image, Feed } from 'semantic-ui-react'

import { IClipGroup } from '../../../api/Data'
import moment from '../../../utils/Moment'

// Interface

const GalleryClipGroupCard = (props: IProps) => {
  // Props
  const { clipGroup, handleClipGroupSelection } = props

  const { clips } = clipGroup
  
  const { createdAt, contributor, imageLowResUrl } = clips[0]

  const contributorName = contributor || 'unknown'
  
  return (
    <>
      <div style={{ position: 'relative', aspectRatio: '16/9', cursor: 'pointer' }} onClick={() => handleClipGroupSelection(clipGroup)}>
        <Image rounded src={imageLowResUrl} width="100%" onClick={() => {}} />
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: '#ffffff'
          }}
        >
          View all submissions
        </div>
      </div>

      <Grid verticalAlign="middle">
        <Grid.Column width={10}>
          <Feed style={{ margin: '0.5rem 0' }} size="small">
            <Feed.Event>
              <Feed.Label>
                <Initial charCount={2} fontSize={40} useWords name={contributorName} />
              </Feed.Label>
              <Feed.Content>
                <Feed.Date>{moment(createdAt).format('ll')}</Feed.Date>
                <Feed.Summary>
                  <strong>{contributorName}</strong>
                </Feed.Summary>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Grid.Column>
      </Grid>
      <div style={{ marginBottom: '0.5rem' }}>
        <strong>{clips.length === 1 ? '1 submission' : `${clips.length} submissions`}</strong>
      </div>
    </>
  )
}

interface IProps {
  clipGroup: IClipGroup
  handleClipGroupSelection: (arg: IClipGroup) => void
}

export default GalleryClipGroupCard
