import React, { useContext, useEffect, useState } from 'react'

import axios from 'axios'
import { Form, Grid, Image, Modal, Button, Dimmer, Loader } from 'semantic-ui-react'

import Data, { IPexelsVideo } from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'

const VideoGallery = ({
  videos,
  currentVideo,
  setCurrentVideo,
}: {
  videos: IPexelsVideo[] | null
  currentVideo: IPexelsVideo | null
  setCurrentVideo: (arg: IPexelsVideo) => void
}) => {
  if (!videos) return <></> // TODO

  if (videos.length === 0) {
    return <p>No video found, try another search.</p>
  }

  return (
    <>
      {videos.map((video) => (
        <Grid.Column key={video.id} onClick={() => setCurrentVideo(video)}>
          <Image
            rounded
            src={video.image}
            style={{ border: `${currentVideo?.id === video.id ? '2px solid red' : 'none'}` }}
          />
        </Grid.Column>
      ))}
    </>
  )
}

const PexelsLibraryModal = ({ projectSlug, setStep, setClipIds, setShowModal }: IProps) => {
  // Contexts
  const { isMobile, dispatchGlobalContext } = useContext(GlobalContext)

  // States
  const [search, setSearch] = useState<string | null>(null)
  const [videos, setVideos] = useState<IPexelsVideo[] | null>(null)
  const [currentVideo, setCurrentVideo] = useState<IPexelsVideo | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchVideos = async () => {
      setLoading(true)

      const { error, data } = await Data.getPexelsPopularVideos()

      if (error || !Array.isArray(data) || data.length === 0) {
        alert(`An error occurred ${error}`)
        return setLoading(false)
      }

      setLoading(false)

      setVideos(data)

      setCurrentVideo(data[0])
    }
    fetchVideos()
  }, [setVideos])

  const handleSearchChange = async () => {
    if (!search) return

    setLoading(true)

    const { error, data } = await Data.searchPexelsVideos(search)

    if (error || !Array.isArray(data) || data.length === 0) {
      alert(`An error occurred ${error}`)
      return setLoading(false)
    }

    setLoading(false)

    setVideos(data)

    setCurrentVideo(data[0])
  }

  const handleSubmit = async () => {
    setLoading(true)

    const { data: videoBlob } = await axios({
      method: 'get',
      url: `${currentVideo!.video_files[0].link}`,
      responseType: 'blob',
    })

    const formData = new FormData()

    formData.append('filepond', videoBlob)

    const { error, data } = await Data.postClip(projectSlug, formData)

    if (error) {
      setLoading(false)
      if (error.response.status === 403) return dispatchGlobalContext({ type: 'show update plan modal' })
      else return alert(`An error occurred ${error}`)
    }

    setLoading(false)

    setClipIds([data])

    setShowModal(false)

    return setStep(2)
  }

  console.log('Rendering Clip modal', videos, currentVideo)

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>From Pexels Library</Modal.Header>
      <Modal.Content>
        <Dimmer.Dimmable>
          <Dimmer active={loading} inverted>
            <Loader size="medium">Loading videos from Pexels...</Loader>
          </Dimmer>
          <Grid columns={2} style={{ height: '100%' }}>
            <Grid.Column verticalAlign="middle" style={{ backgroundColor: '#f5f5f6', borderRadius: '0.5rem' }}>
              {currentVideo && (
                //   eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  controls
                  autoPlay
                  tabIndex={-1}
                  style={{ width: '100%', height: '231px', borderRadius: '0.5rem' }}
                  src={currentVideo.video_files[0].link}
                />
              )}

              <div>
                <img
                  src="https://www.pexels.com/assets/_svg/logo-5ee7c1d7e25a1c5066520202eb5c41bd4c0a479e9a68911aa54d785c2e22d565.svg"
                  alt="Pexels icon"
                />
              </div>
              <small>Videos from www.pexels.com library</small>
            </Grid.Column>
            <Grid.Column>
              <Grid.Row>
                <Form onSubmit={handleSearchChange} style={{ marginBottom: '1rem' }} size="small">
                  <Form.Input
                    action={{
                      color: 'blue',
                      content: 'Search',
                      type: 'submit',
                    }}
                    placeholder="Type your search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form>
              </Grid.Row>
              <Grid.Row>
                <Grid columns={3} padded="vertically" style={{ height: '300px', overflowY: 'scroll' }}>
                  <VideoGallery videos={videos!} currentVideo={currentVideo} setCurrentVideo={setCurrentVideo} />
                </Grid>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Dimmer.Dimmable>
      </Modal.Content>

      <Modal.Actions>
        <Button fluid={isMobile} onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button fluid={isMobile} primary disabled={loading} onClick={handleSubmit}>
          Next
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  projectSlug: string
  setStep: (arg: number) => void
  setClipIds: (arg: number[]) => void
  setShowModal: (arg: boolean) => void
}
export default PexelsLibraryModal
