import React from 'react'

import { Popup, Icon } from 'semantic-ui-react'

const CustomLabelPopup = ({ popupContent }: { popupContent: any }): JSX.Element => (
  <Popup
    popper={{ style: { filter: 'none' } }} // Fix a semantic ui problem where popup will be blurred if triggered from a modal with blurred background.
    trigger={<Icon name="info circle" style={{ marginLeft: '0.5rem' }} />}
    content={popupContent}
    position="top left"
    hoverable={true}
  />
)

export default CustomLabelPopup
