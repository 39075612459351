import React, { useContext, useState } from 'react'

import { Message, Grid, Header, InputOnChangeData, CheckboxProps } from 'semantic-ui-react'

import Data, { IUser } from '../../api/Data'
import { MARKETING_WEBSITE_URL } from '../../config/environments'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'

import ExternalLink from '../../components/common/ExternalLink'
import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'

import MySettingsPassword from './MySettingsPassword'
import MySettingsStudioEmails from './MySettingsStudioEmails'

const initialPasswordFormState = {
  currentPassword: '',
  newPassword: '',
}

const MySettingsPage = () => {
  const { isMobile, dispatchGlobalContext } = useContext(GlobalContext)
  const { dispatchUserContext } = useContext(UserContext)
  
  const { userProfile } = useContext(UserContext)

  // States
  const [user, setUser] = useState<IUser>(userProfile)

  const [passwordForm, setPasswordForm] = useState<typeof initialPasswordFormState>(initialPasswordFormState)

  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // Reset form
  const resetForm = () => {
    if (successMessage) setSuccessMessage(null)
    if (errorMessage) setErrorMessage(null)
  }
  
  // Handle change password
  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    resetForm()

    const { name, value } = data

    setPasswordForm({ ...passwordForm, [name]: value })
  }

  // Submit password change
  const handleSubmitPassword = async () => {
    resetForm()

    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { error } = await Data.updatePassword(passwordForm)

    dispatchGlobalContext({ type: 'set loading', payload: false })

    if (error) {
      return setErrorMessage(error.response.data)
    }

    setSuccessMessage('Your password has been updated successfully')

    return setPasswordForm(initialPasswordFormState)
  }
  
  // Handle change studio emails
  const handleChangeSettingsStudioEmails = async (e: React.MouseEvent<HTMLInputElement, MouseEvent>, checkbox: CheckboxProps) => {
    resetForm()

    const { name, checked } = checkbox

    const newUserData = { ...user, [String(name)]: Boolean(checked) }
    
    // Handle change
    setUser(newUserData)
    
    // Auto save change
    dispatchGlobalContext({ type: 'set loading', payload: true })

    const { data, error } = await Data.updateMyProfile(newUserData)

    dispatchGlobalContext({ type: 'set loading', payload: false })

    if (error) {
      return setErrorMessage(error.response.data)
    }

    const { emailOptIn } = data

    dispatchUserContext({ type: 'update user profile', payload: { emailOptIn } })

    setSuccessMessage('Your preference has been updated successfully')
  }
  
  if (!user) return null

  console.log('Rendering Profile page', user)

  return (
    <Section>
      <>
        {/* title */}
        <Title title="Settings" />

        <SubSection>
          <>
            <Grid columns={2} divided="vertically">
              <Grid.Row stretched>
                <Grid.Column width={3}>
                  <Header as="h4">
                    Password
                  </Header>
                </Grid.Column>
                <Grid.Column width={13}>
                  {/* password reset */}
                  <MySettingsPassword
                    currentPassword={passwordForm.currentPassword}
                    newPassword={passwordForm.newPassword}
                    handleChange={handleChangePassword}
                    handleSubmit={handleSubmitPassword}
                    isMobile={isMobile}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column width={3}>
                  <Header as="h4">
                    Email
                  </Header>
                </Grid.Column>
                <Grid.Column width={13}>
                  <Header as="h4">
                    <span style={{ color: '#6b50ff' }}>{user.email}</span>
                    <Header.Subheader as="h6">
                      Account email addresses cannot be changed
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column width={3}>
                  <Header as="h4">
                    Notify me about
                  </Header>
                </Grid.Column>
                <Grid.Column width={13}>
                  <MySettingsStudioEmails
                    emailOptIn={user.emailOptIn}
                    handleChange={handleChangeSettingsStudioEmails}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  {/* User terms */}
                  <div>
                    <ExternalLink url={`${MARKETING_WEBSITE_URL}/user-terms`}>User terms</ExternalLink>
                  </div>
                  {/* Privacy policy */}
                  <div>
                    <ExternalLink url={`${MARKETING_WEBSITE_URL}/privacy`}>Privacy policy</ExternalLink>
                  </div>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  {/*
                  <div>
                    <ExternalLink url={'javascript:'}>Delete account</ExternalLink>
                  </div>
                  */}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {successMessage && <Message success header="Success" content={successMessage} />}
            {errorMessage && <Message error header="Fail" content={errorMessage} />}
            {/* Build number */}
            {/* <div style={{ color: 'white' }}>Build number:{APP_BUILD || 'no build number'}</div> */}
          </>
        </SubSection>
      </>
    </Section>
  )
}

export default MySettingsPage
