import React from 'react'

import { Image, Grid, Header, Button, Modal, Icon } from 'semantic-ui-react'

import ClipSelectedImage from '../../../_images/clip-selected.jpg'

const GalleryTabNotEnoughClipSelectedModal = (props: { setShowModal: (arg: boolean) => void }) => {
  const { setShowModal } = props

  return (
    <Modal dimmer="blurring" open size="small">
      <Modal.Header>
        <Icon name="warning sign" /> Select at least 1 clip
      </Modal.Header>

      <Modal.Content>
        <Grid columns={2} divided stackable>
          <Grid.Column>
            <Header as="h3">
              To add a clip to your story:
              <Header.Subheader>Click the top right corner icon to select a clip.</Header.Subheader>
              <Header.Subheader>
                Repeat this process until you have selected all the clips you need and then click &quot;Create video
                story&quot; to proceed to the next step.
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Image src={ClipSelectedImage} />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setShowModal(false)}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default GalleryTabNotEnoughClipSelectedModal
