import React, { useState } from 'react'

import { Modal, Form, Message, Button, InputOnChangeData, DropdownProps } from 'semantic-ui-react'

import TeamMember from './TeamMember'

const MyTeamTabAddUserModal = ({ projects, errorMessage, setShowModal, handleAddTeamMember }: IProps) => {
  // States
  const [newUser, setNewUser] = useState<TeamMember>({ username: '', email: '', role: '', projectIds: [] })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    setNewUser({ ...newUser, [name]: value })
  }

  const handleRoleChange = (e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setNewUser({ ...newUser, role: value as string })
  }

  const handleProjectsChange = (e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setNewUser({ ...newUser, projectIds: value as number[] })
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    console.log('New user', newUser)
    e.preventDefault()
    e.stopPropagation()
    handleAddTeamMember(newUser)
  }

  const projectOptions = projects.map((project) => ({
    key: project.id,
    text: project.name,
    value: project.id,
  }))

  console.log('Add user to team form')

  return (
    <Modal dimmer="blurring" open size="mini" onClose={() => setShowModal(false)}>
      <Modal.Header>Add member to your team </Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ width: '100%' }}>
          <Form id="add-user-form" error={!!errorMessage} onSubmit={handleSubmit}>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              type="text"
              name="username"
              value={newUser.username}
              placeholder="Name"
              onChange={handleChange}
              autoComplete="off"
            />
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              type="email"
              name="email"
              value={newUser.email}
              placeholder="E-mail address"
              onChange={handleChange}
              autoComplete="off"
            />
            <Form.Select
              fluid
              label="Role"
              name="role"
              options={[
                { key: 'admin', text: 'Admin', value: 'admin' },
                { key: 'member', text: 'Member', value: 'member' },
                { key: 'collaborator', text: 'Collaborator', value: 'collaborator' },
              ]}
              placeholder="Role"
              onChange={handleRoleChange}
            />
            {newUser.role === 'collaborator' && (
              <Form.Select
                fluid
                label="Projects"
                multiple
                name="projectIds"
                options={[...projectOptions]}
                placeholder="Select projects"
                onChange={handleProjectsChange}
              />
            )}

            {errorMessage && <Message error>{errorMessage}</Message>}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>Close</Button>
        <Button primary type="submit" form="add-user-form">
          Add
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  projects: any[]
  errorMessage: string | null
  setShowModal: (arg: boolean) => void
  handleAddTeamMember: (arg: TeamMember) => void
}

export default MyTeamTabAddUserModal
