import JWTDecode from 'jwt-decode'

class Auth {
  static setToken(token: string) {
    localStorage.setItem('token', token)
  }

  static getToken() {
    return localStorage.getItem('token')
  }

  static removeToken() {
    localStorage.removeItem('token')
  }

  static getPayLoad() {
    const token = this.getToken()
    if (!token) return false
    const parts = token.split('.')
    if (parts.length < 3) return false
    try {
      return JWTDecode(token)
    } catch (err) {
      console.error('error during decoding of the token', err)
      return false
    }
  }

  static isAuthenticated() {
    const payLoad = this.getPayLoad()
    if (!payLoad) return false
    const now = Math.floor(Date.now() / 1000)
    return now < payLoad.exp
  }
}

export default Auth
