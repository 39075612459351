import { FC, useEffect } from 'react'

import ReactGA from 'react-ga'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { HAS_GOOGLE_ANALYTICS } from '../config/environments'

const GoogleAnalytics: FC<IProps> = ({ history, location }) => {
  useEffect(() => {
    function logPageView() {
      // Logging page view for each history change
      console.log('G.A.: logging page view', location.pathname + location.search)
      ReactGA.set({ page: location.pathname + location.search })
      ReactGA.pageview(location.pathname + location.search)
    }

    if (HAS_GOOGLE_ANALYTICS) {
      // add your UA code
      ReactGA.initialize('UA-118075260-5')

      // Necessary to log the initial page the user lands on
      console.log('G.A.: initial page view', location.pathname + location.search)
      ReactGA.pageview(location.pathname)

      console.log('G.A.: create a new listener')
      const unListen = history.listen(logPageView)

      // Remove listener from history
      return () => {
        console.log('G.A.: remove history listener', unListen)
        unListen()
      }
    }
  }, [history, location.pathname, location.search])

  return null
}

interface IProps extends RouteComponentProps<any> {}

export default withRouter(GoogleAnalytics)
