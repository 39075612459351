import React, { useContext, useEffect, useState } from 'react'

import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Header, Grid, Button, Image, Confirm, Icon, Input, Select } from 'semantic-ui-react'

import CreateStoryIcon from '../../../_images/create-story-icon-purple.png'
import VideoImagePlaceholder from '../../../_images/video-upload-placeholder.jpg'
import Data, { IntegrationProvider } from '../../../api/Data'
import { IProject, IClipGroup, IClip } from '../../../api/Data'
import { clipsPerPage } from '../../../config/constants'
import { maxEpisodeDuration, maxClipsPerEpisode, minClipsPerEpisode } from '../../../config/constants'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { UserContext } from '../../../contexts/UserContext'
import { downloadFileFromBrowser, flattenClipsList, reshapeClipsList } from '../../../utils/sharedFunctions'
import UploadModal from '../../upload/UploadModal'

import SubSection from '../../../components/common/SubSection'
import Title from '../../../components/common/Title'
import VideoEncodingCard from '../../../components/common/VideoEncodingCard'
import VideoErrorCard from '../../../components/common/VideoErrorCard'

import GalleryClipCard from './ManageClipCard'
import GalleryClipGroupCard from './ManageClipGroupCard'
import GalleryClipModal from './ManageClipModal'
import GalleryControlsBar from './ManageControlsBar'
import GalleryTabNotEnoughClipSelectedModal from './ManageTabNotEnoughClipSelectedModal'

let autoFetchClips: any

const ManageTab = (props: IProps) => {
  const { dispatchGlobalContext } = useContext(GlobalContext)
  const { userProfile } = useContext(UserContext)

  // Props
  const { history, location, match, project, clips, clipsSelected, setClips, setClipsSelected, exitProject } = props
  const { projectTitle } = props

  // States
  const [activeTab, setActiveTab] = useState<string>('all')

  const [loading, setLoading] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const [totalEntries, setTotalEntries] = useState<null | number>(null)
  const [tags, setTags] = useState<string[]>([])
  const [searchPhrase, setSearchPhrase] = useState<string>('')

  const [selectedClipGroup, setSelectedClipGroup] = useState<IClipGroup | null>(null)
  const [showClipModal, setShowClipModal] = useState<IClip | null>(null)
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
  const [showNotEnoughClipModal, setShowNotEnoughClipModal] = useState<boolean>(false)
  const [showHasTooManyClipModal, setShowHasTooManyClipModal] = useState<boolean>(false)
  const [showEpisodeDurationExceeded, setShowEpisodeDurationExceeded] = useState<number | null>(null)
  const [showBulkTagModal, setShowBulkTagModal] = useState<boolean>(false)
  const [tagName, setTagName] = useState<string>('')

  const [isLoadingBulkDownloadRequest, setIsLoadingBulkDownloadRequest] = useState<boolean>(false)

  // Fetch clips
  useEffect(() => {
    fetchAllUploads()

    return () => clearTimeout(autoFetchClips)
  }, [searchPhrase, tags, match.params.projectSlug, page, setClips])

  // Check if the modal should be shown by default
  useEffect(() => {
    const shouldShowUploadModal = location.state?.uploadModal

    if (shouldShowUploadModal) {
      history.push(`/projects/${match.params.projectSlug}/manage`, { uploadModal: false })
      setShowUploadModal(true)
    }
  }, [history, location, match.params.projectSlug])

  const fetchAllUploads = async (autoFetching: boolean = false) => {
    const slug = match.params.projectSlug

    clearTimeout(autoFetchClips)

    const offset = (page - 1) * clipsPerPage
    const limit = clipsPerPage

    const { data, error } = await Data.getClips({ projectSlug: slug, offset, limit, searchPhrase, tags })

    if (error) return alert('An error occurred')

    const { rows, count } = data

    setTotalEntries(count)

    const fetchedClips = flattenClipsList(rows)

    setClips(fetchedClips)

    const isClipInProgress = fetchedClips.some((clip: IClip) => clip.encodingStatus === 'encoding')

    // Clear all selected clips on reload to prevent bulk actions on clips that are no longer shown in the current search and page criteria.
    if (!autoFetching) {
      setClipsSelected([])
    }

    // If clips are encoding, fetch clips every 5 seconds
    if (isClipInProgress) autoFetchClips = setTimeout(() => fetchAllUploads(true), 5000)
  }

  // Is user collaborator
  const isUserCollaborator = (): boolean => {
    return userProfile.userAccessRights === 'collaborator'
  }

  // Add tag to search
  const addFilterTag = (value: string) => {
    let newTagsArr: string[] = []

    if (tags.includes(value)) return

    newTagsArr = [...tags, value]

    setTags(newTagsArr)

    setPage(1)
  }

  // Remove tag from search
  const removeFilterTag = (value: string) => {
    let newTagsArr: string[] = []

    if (!tags.includes(value)) return

    newTagsArr = tags.filter((tag) => tag !== value)

    setTags(newTagsArr)

    setPage(1)
  }

  // Update clip
  const updateClip = async (clip: IClip) => {
    setLoading(true)

    const { error } = await Data.updateClip(clip)

    if (error) {
      alert('An error occurred')
      return setLoading(false)
    }

    // Trick to update the clip and show it in progress
    return setTimeout(() => {
      setLoading(false)

      setShowClipModal(null)

      window.location.reload()
    }, 2000)
  }

  // Delete clip
  const deleteClip = async (clipId: number) => {
    const { error } = await Data.deleteClip(clipId)

    if (error) return

    const newClips = clips.filter((item) => item.id !== clipId)

    setClips(newClips)

    setShowClipModal(null)
  }

  // Set clip as favorite
  const handleFavorite = async (clipId: number) => {
    const { data, error } = await Data.setClipAsFavorite(clipId)

    if (error) return

    const updatedClip: IClip = data

    const newClips1: IClip[] = clips.reduce((acc: IClip[], el: IClip) => {
      if (el.id === clipId) acc.push(updatedClip)
      else acc.push(el)
      return acc
    }, [])

    setClips(newClips1)
  }

  // handle clip selection
  const handleClipSelection = (clip: IClip) => {
    const clipIndex = clipsSelected.findIndex((clipSelected: IClip) => clipSelected.id === clip.id)

    if (clipIndex === -1) {
      // Add clip to clipSelected array
      return setClipsSelected([...clipsSelected, clip])
    }

    // Remove clip from clipSelected array
    return setClipsSelected(clipsSelected.filter((clipSelected, index) => index !== clipIndex))
  }

  // handle clip group selection
  const handleClipGroupSelection = (clipGroup: IClipGroup | null) => {
    setSelectedClipGroup(clipGroup)
  }

  // Download report
  const downloadCsvReport = async () => {
    const { data, error } = await Data.getCsvReport(project.slug)

    if (error) {
      if (error.response.status === 403) {
        return dispatchGlobalContext({ type: 'show update plan modal' })
      }
      return alert('An error occurred')
    }

    const { reportData, reportFileName } = data

    return downloadFileFromBrowser({ data: reportData, type: 'text/csv', fileName: reportFileName })
  }

  // Bulk download clips to zip
  const bulkDownloadClipsToZip = async () => {
    setIsLoadingBulkDownloadRequest(true)
    const clipIds = clipsSelected.map((clip: { id: number }) => clip.id)
    const projectSlug = match.params.projectSlug

    try {
      await Data.bulkDownloadClips(projectSlug, clipIds)
      setClipsSelected([])
      setIsLoadingBulkDownloadRequest(false)
      alert(
        `We're preparing your bulk download. Bulk download can take between 1 to 15 minutes to complete. Go to your projects "Exports" section to see your projects available bulk downloads.`
      )
    } catch (error) {
      alert('An error occurred')
      setIsLoadingBulkDownloadRequest(false)
    }
  }

  // Bulk export clips to Google Drive
  const bulkExportClipsToGoogleDrive = async () => {
    setClipsSelected([])

    // alert(`Sorry, we are unable to complete your bulk export to Google Drive at this time.`)

    setIsLoadingBulkDownloadRequest(true)
    const clipIds = clipsSelected.map((clip: { id: number }) => clip.id)
    const projectSlug = match.params.projectSlug

    try {
      await Data.bulkExportClips(projectSlug, clipIds, IntegrationProvider.GOOGLE)
      setClipsSelected([])
      setIsLoadingBulkDownloadRequest(false)
      alert(
        `We're preparing your bulk export. Bulk exports to Google Drive can take between 1 to 15 minutes to complete. Go to your projects "Exports" section to see your export history and progress.`
      )
    } catch (error) {
      alert('An error occurred')
      setIsLoadingBulkDownloadRequest(false)
    }
  }

  // Bulk export clips to Microsoft OneDrive
  const bulkExportClipsToMicrosoftOneDrive = async () => {
    setClipsSelected([])

    alert(`Sorry, we are unable to complete your bulk export to Microsoft OneDrive at this time.`)

    // setIsLoadingBulkDownloadRequest(true)
    // setIsLoadingBulkDownloadRequest(true)
    // const clipIds = clipsSelected.map((clip: { id: number }) => clip.id)
    // const projectSlug = match.params.projectSlug

    // try {
    //   await Data.bulkExportClips(projectSlug, clipIds, IntegrationProvider.MICROSOFT)
    //   setClipsSelected([])
    //   setIsLoadingBulkDownloadRequest(false)
    //   alert(
    //     `We're preparing your bulk export. Bulk exports to Microsoft OneDrive can take between 1 to 15 minutes to complete. Go to your projects "Exports" section to see your export history and progress.`
    //   )
    // } catch (error) {
    //   alert('An error occurred')
    //   setIsLoadingBulkDownloadRequest(false)
    // }
  }

  const handleKeyPressOnBulkTagInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleBulkUpdateClipsWithTag()
    }
  }

  const handleShowBulkTagModal = () => {
    if (clipsSelected.length === 0) {
      return alert('Please select at least one clip to bulk tag')
    }
    setShowBulkTagModal(true)
  }

  const handleBulkUpdateClipsWithTag = async () => {
    setShowBulkTagModal(false)
    const clipIds = clipsSelected.map((clip: { id: number }) => clip.id)
    try {
      await Data.bulkUpdateClipsWithTag(tagName, clipIds)
      fetchAllUploads()
      setClipsSelected([])
    } catch (error) {
      alert('An error occurred')
    }
  }

  // Create story
  const handleCreateVideoStory = () => {
    const hasNotEnoughClipSelected = !clipsSelected || clipsSelected.length < minClipsPerEpisode
    if (hasNotEnoughClipSelected) return setShowNotEnoughClipModal(true)

    const hasTooManyClipSelected = clipsSelected.length > maxClipsPerEpisode
    if (hasTooManyClipSelected) return setShowHasTooManyClipModal(true)

    const episodeDuration = Math.floor(
      clipsSelected.reduce((acc, clip) => (clip.duration ? acc + clip.duration : acc), 0)
    )
    if (episodeDuration > maxEpisodeDuration) return setShowEpisodeDurationExceeded(episodeDuration)

    return history.push(`/projects/${match.params.projectSlug}/merge`)
  }

  const filterByFavorite = () => {
    setActiveTab((prevState) => (prevState === 'all' ? 'favorites' : 'all'))
  }

  if (!clips || totalEntries === null) return null

  let clipsFiltered = activeTab === 'favorites' ? clips.filter((clip) => clip.isFavorite) : clips

  if (selectedClipGroup !== null) {
    clipsFiltered = clipsFiltered.filter((clip) => clip.submissionId === selectedClipGroup.submissionId)
  }

  const { plan } = userProfile!

  const { maxNbOfClips } = plan

  console.log('Rendering Clip Gallery Page', clipsFiltered)

  return (
    <>
      <Title title={projectTitle}>
        <>
          {!isUserCollaborator() && (
            <>
              <Button compact secondary onClick={downloadCsvReport}>
                <Icon name="file excel"></Icon>
                Project report
              </Button>
              <Select
                loading={isLoadingBulkDownloadRequest}
                placeholder="Bulk actions"
                disabled={clipsSelected.length <= 0}
                value={0}
                options={[
                  {
                    key: 'bulkDownloadToZip',
                    icon: 'zip',
                    text: 'Download archive',
                    value: 'bulkDownloadToZip',
                  },
                  {
                    key: 'bulkExportToGoogleDrive',
                    icon: 'google drive',
                    text: 'To Google Drive',
                    value: 'bulkExportToGoogleDrive',
                  },
                  {
                    key: 'bulkExportToMicrosoftOneDrive',
                    icon: 'microsoft',
                    text: 'To OneDrive',
                    value: 'bulkExportToMicrosoftOneDrive',
                  },
                ]}
                onChange={(e, { value }) => {
                  if (value === 'bulkDownloadToZip') return bulkDownloadClipsToZip()
                  if (value === 'bulkExportToGoogleDrive') return bulkExportClipsToGoogleDrive()
                  if (value === 'bulkExportToMicrosoftOneDrive') return bulkExportClipsToMicrosoftOneDrive()
                }}
              />
            </>
          )}
          <Button
            compact
            color="orange"
            onKeyDown={() => setShowUploadModal(true)}
            onClick={() => setShowUploadModal(true)}
          >
            <Icon name="upload"></Icon>
            Upload clip
          </Button>
          <Button compact onClick={exitProject}>
            Close
          </Button>
        </>
      </Title>

      <SubSection hasGreyBackground>
        <>
          <GalleryControlsBar
            totalTeamsClipCount={project.totalNumberOfTeamClips}
            projectEntryCount={totalEntries}
            projectClipCount={project.clipCount}
            clipMax={project.clipMax}
            tags={tags}
            removeFilterTag={removeFilterTag}
            setPage={setPage}
            page={page}
            clipsPerPage={clipsPerPage}
            showPagination={selectedClipGroup === null}
            maxNbOfClips={maxNbOfClips}
            activeTab={activeTab}
            filterByFavorite={filterByFavorite}
            onBulkTag={handleShowBulkTagModal}
            onSearch={setSearchPhrase}
            onSelectAll={() => {
              if (clipsSelected.length > 0) {
                return setClipsSelected([])
              }
              // Only select clips that are encoded
              const encodedClips = clipsFiltered.filter((clip) => clip.encodingStatus === 'encoded')
              return setClipsSelected(encodedClips)
            }}
            onBack={() => handleClipGroupSelection(null)}
            clipsSelected={clipsSelected}
          />
          <Grid columns={4} doubling stackable>
            {/* clips gallery */}
            {!isUserCollaborator() && (
              <Grid.Column textAlign="center">
                <div
                  style={{ position: 'relative', cursor: 'pointer' }}
                  tabIndex={-1}
                  role="button"
                  onClick={handleCreateVideoStory}
                >
                  <Image src={VideoImagePlaceholder} rounded style={{ border: '1px solid #6b50ff' }} />
                  <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
                    <Image src={CreateStoryIcon} style={{ margin: '1.5rem auto -1rem auto', width: '4rem' }} />
                    <Header as="h4" color="violet">
                      CREATE A STORY
                    </Header>
                  </div>
                </div>
              </Grid.Column>
            )}
            {clipsFiltered?.length > 0 &&
              (selectedClipGroup === null ? reshapeClipsList(clipsFiltered) : clipsFiltered).map((submission, i) => (
                ('id' in submission) ? (
                  <Grid.Column key={i}>
                    {submission.encodingStatus === 'encoded' && (
                      <GalleryClipCard
                        userId={userProfile.id}
                        handleClipSelection={handleClipSelection}
                        clip={submission}
                        clipsSelected={clipsSelected}
                        addFilterTag={addFilterTag}
                        setShowClipModal={setShowClipModal}
                        handleFavorite={handleFavorite}
                        votes={submission.votes}
                        handleVote={async (vote: boolean) => {
                          const { data, error } = await Data.setClipVote(submission.id, vote)

                          if (error) return alert('An error occurred')

                          const updatedClip: IClip = data

                          const newClips1: IClip[] = clips.reduce((acc: IClip[], el: IClip) => {
                            if (el.id === submission.id) acc.push(updatedClip)
                            else acc.push(el)
                            return acc
                          }, [])

                          setClips(newClips1)
                        }}
                      />
                    )}
                    {submission.encodingStatus === 'encoding' && (
                      /* If not encoded yet, display encoding message */
                      <VideoEncodingCard status={submission.status} />

                      // <GalleryClipInProgressCard isMobile={isMobile} clip={submission} />
                    )}
                    {submission.encodingStatus === 'error' && (
                      /* If encoding error, display error message */
                      <VideoErrorCard videoId={submission.id} handleDelete={deleteClip} />
                    )}
                  </Grid.Column>
                ) : (
                  <Grid.Column key={i}>
                    <GalleryClipGroupCard
                      clipGroup={submission}
                      handleClipGroupSelection={handleClipGroupSelection}
                    />
                  </Grid.Column>
                )
              ))}
          </Grid>
          {/* Bulk tag modal */}
          <Confirm
            open={showBulkTagModal}
            header="Input a tag name to bulk tag the selected clips"
            confirmButton="Save"
            content={
              <Input
                compact
                placeholder="Enter a tag"
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                onKeyPress={handleKeyPressOnBulkTagInput}
                style={{ width: '100%' }}
              />
            }
            cancelButton="Cancel"
            onCancel={() => setShowBulkTagModal(false)}
            onConfirm={handleBulkUpdateClipsWithTag}
          />
          {/* Project upload modal */}
          {showUploadModal && <UploadModal project={project} setShowModal={setShowUploadModal} />}
          {/* Clip edit modal */}
          {showClipModal && (
            <GalleryClipModal
              loading={loading}
              clip={showClipModal}
              setShowModal={setShowClipModal}
              updateClip={updateClip}
              deleteClip={deleteClip}
              displaySetting={project.displaySetting}
            />
          )}
          {/* Not enough clip modal alert */}
          {showNotEnoughClipModal && <GalleryTabNotEnoughClipSelectedModal setShowModal={setShowNotEnoughClipModal} />}
          {/* Too many clip modal alert */}
          <Confirm
            open={showHasTooManyClipModal}
            header="Too many clips selected"
            content={`Video stories can be made with ${minClipsPerEpisode} to ${maxClipsPerEpisode} clips maximum. Unselect some clips to create your video story.`}
            confirmButton="Ok"
            cancelButton={null}
            onConfirm={() => setShowHasTooManyClipModal(false)}
          />
          {/* Episode duration too long */}
          <Confirm
            open={!!showEpisodeDurationExceeded}
            header="Episode duration exceeded"
            content={`Video story cannot exceed ${maxEpisodeDuration}s (currently ${showEpisodeDurationExceeded}s). Unselect some clips to create your video story.`}
            confirmButton="Ok"
            cancelButton={null}
            onConfirm={() => setShowEpisodeDurationExceeded(null)}
          />
        </>
      </SubSection>
    </>
  )
}

type LocationState = {
  uploadModal: boolean
}

interface IProps extends RouteComponentProps<any, StaticContext, LocationState> {
  projectTitle: string
  project: IProject
  clips: IClip[]
  clipsSelected: IClip[]
  setClips: (arg: IClip[]) => void
  setClipsSelected: (arg: IClip[]) => void
  exitProject: () => void
}

export default withRouter(ManageTab)
