import axios, { Method } from 'axios'

import { API_URL } from '../config/environments'
import Auth from '../utils/Auth'
import rollbar from '../utils/Rollbar'

axios.defaults.baseURL = `${API_URL}/rest`
axios.defaults.timeout = 5 * 60000 // in ms (60 seconds)

async function resolve<T>(action: Method, path: string, body: any | null = null, headers: any | null = null) {
  let data: T | null = null
  let error: any = null

  try {
    const response = await axios({
      method: action,
      url: path,
      data: body,
      headers: { ...headers, Authorization: `Bearer ${Auth.getToken()}` },
    })

    data = response.data
  } catch (err: any) {
    rollbar.error(`Error during api call: ${action} ${path} => ${err.response?.data}`)
    error = err
  }

  return { data, error }
}

export default resolve
