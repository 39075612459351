import React, { useState } from 'react'

import { Button, Checkbox, Grid, Header } from 'semantic-ui-react'

import { S3_BUCKET_URL } from '../../config/environments'

import DesignerMusicSelectModal from './modals/DesignerMusicSelectModal'

const DesignerTabTransitions = ({ theme, updateTheme }) => {
  // States
  const [showSelectMusicModal, setShowSelectMusicModal] = useState(null)

  // Mute audio
  const muteAudio = (e, { checked }) => {
    const newTheme = { ...theme }
    newTheme.data.playlist.musicMuted = checked
    updateTheme(newTheme)
  }

  const { music, musicMuted } = theme.data.playlist

  const musicURL = `${S3_BUCKET_URL}/themes/assets/${music}`

  console.log('Rendering section page')

  return (
    <Grid>
      <Grid.Column width={1}></Grid.Column>
      <Grid.Column width={11}>
        <Grid divided="vertically" verticalAlign="middle">
          {/* music */}
          <Grid.Row>
            <Grid.Column width={10}>
              <Header as="h5" color="violet">
                <label>Music</label>
                <Header.Subheader>
                  Choose a music track to play along with your story. Click &quot;change&quot; to choose or upload your
                  own music (.mp3)
                </Header.Subheader>
              </Header>
              <Button basic size="small" onClick={() => setShowSelectMusicModal('music')}>
                Change
              </Button>
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              {music ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <audio autoPlay={false} controls>
                  <source src={musicURL} type="audio/mp3" />
                </audio>
              ) : (
                'No music selected'
              )}
            </Grid.Column>
          </Grid.Row>

          {/* music muted */}
          <Grid.Row>
            <Grid.Column width={10}>
              <Header as="h5" color="violet">
                <label>Mute original audio</label>
                <Header.Subheader>
                  You can mute the original audio of the clips contributed. This is really useful when you only want to
                  play your own music.
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              <Checkbox toggle checked={musicMuted} onClick={muteAudio} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      {/* music selection modal */}
      {showSelectMusicModal === 'music' && (
        <DesignerMusicSelectModal
          type={showSelectMusicModal}
          theme={theme}
          updateTheme={updateTheme}
          setShowModal={setShowSelectMusicModal}
        />
      )}
    </Grid>
  )
}

export default DesignerTabTransitions
