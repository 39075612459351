const globalReducer = (state: GlobalContextType, action: IAction) => {
  switch (action.type) {
    case 'initialized state': {
      return { ...state, ...action.payload }
    }
    case 'set mobile screen resolution': {
      return { ...state, ...action.payload }
    }
    case 'set loading': {
      return { ...state, isLoading: action.payload }
    }
    case 'show side bar': {
      return { ...state, isSideBarShown: true }
    }
    case 'hide side bar': {
      return { ...state, isSideBarShown: false }
    }
    case 'show update plan modal': {
      return { ...state, showUpdatePlanModal: true }
    }
    case 'hide update plan modal': {
      return { ...state, showUpdatePlanModal: false }
    }
    case 'set user has made changes': {
      return { ...state, hasUserMadeChange: action.payload }
    }
    case 'set exit without saving modal': {
      return { ...state, showExitWithoutSavingModal: action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

interface IAction {
  type:
    | 'initialized state'
    | 'set mobile screen resolution'
    | 'set loading'
    | 'show side bar'
    | 'hide side bar'
    | 'show update plan modal'
    | 'hide update plan modal'
    | 'set user has made changes'
    | 'set exit without saving modal'
    | 'log out'
  payload?: any
}

interface GlobalContextType {
  isLoading: boolean
  isWideScreen: boolean
  isMobile: boolean
  isSideBarShown: boolean
  showUpdatePlanModal: boolean
  showExitWithoutSavingModal: string | null // url to redirect the user if he confirms,
  hasUserMadeChange: boolean
  dispatchGlobalContext: (args: IAction) => void
}

export default globalReducer
