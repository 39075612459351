import React from 'react'

import { Confirm } from 'semantic-ui-react'

const MyAccountPaymentSuccessModal = ({ setShowModal }: IProps) => (
  <Confirm
    open
    header="Plan change successful"
    content="You request for a plan change has been successfully processed. You new plan should be active within 24hr. If you need more information please contact us via the chat bot at the bottom right of your screen."
    cancelButton={false}
    confirmButton="Ok"
    onConfirm={() => setShowModal(false)}
  />
)

interface IProps {
  setShowModal: (args: boolean) => void
}

export default MyAccountPaymentSuccessModal
