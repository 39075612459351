import React, { FC, useState } from 'react'

import { Form, InputOnChangeData } from 'semantic-ui-react'

const PasswordInput: FC<IProps> = ({ name, value, placeholder, error, handleChange }) => {
  // States
  const [showPassword, setShowPassword] = useState(true)

  // Hide / show password
  const togglePasswordView = () => {
    setShowPassword((prevState) => !prevState)
  }

  return (
    <Form.Input
      fluid
      icon="lock"
      iconPosition="left"
      type={showPassword ? 'password' : 'text'}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      autoComplete="off"
      action={{
        size: 'tiny',
        type: 'button',
        content: 'Reveal',
        icon: { name: `${showPassword ? 'eye' : 'eye slash'}` },
        onClick: togglePasswordView,
      }}
      error={error !== undefined && error}
    ></Form.Input>
  )
}

interface IProps {
  name: string
  value: string
  placeholder: string
  error?: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
}

export default PasswordInput
