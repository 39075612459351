import React, { FC } from 'react'

import { Font } from '@samuelmeuli/font-manager'
import FontPicker from 'font-picker-react'
import './FontPickerComponent.scss'

import { GOOGLE_FONT_PICKER_API_KEY } from '../../config/environments'

const FontPickerComponent: FC<IProps> = ({ pickerId = null, activeFontFamily, onChange }) => (
  <FontPicker
    limit={150}
    pickerId={pickerId || 'primary'}
    apiKey={GOOGLE_FONT_PICKER_API_KEY!}
    activeFontFamily={activeFontFamily}
    variants={['regular', 'italic', '700']}
    onChange={onChange}
  />
)

interface IProps {
  pickerId?: string | null
  activeFontFamily: string
  onChange: (font: Font) => void
}
export default FontPickerComponent
