import React, { useContext, useState, useEffect, useRef, FC } from 'react'

import { Button, Form, Grid, Modal, Rating } from 'semantic-ui-react'

import Data, { IClip } from '../../api/Data'
import { maxLength } from '../../config/constants'
import { S3_BUCKET_URL } from '../../config/environments'
import { GlobalContext } from '../../contexts/GlobalContext'

import CustomLabel from '../../components/common/custom-label/CustomLabel'
import UploadLocationField from '../../components/modals/LocationSearchField'

// Reducer helper
const reducer = (arrayToUpdate: IClip[], indexToUpdate: number, newFields: any) =>
  arrayToUpdate.reduce((newUploads: IClip[], upload, index) => {
    if (index === Number(indexToUpdate)) {
      newUploads.push({ ...upload, ...newFields })
    } else newUploads.push(upload)

    return newUploads
  }, [])

const UploadForm: FC<IProps> = ({ clipIds, displaySetting, uploads, setUploads, setShowModal }) => {
  const { isMobile } = useContext(GlobalContext)

  // States
  const [placeSuggestionsArr, setPlaceSuggestionsArr] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)

  // References
  const didFetchPlaces = useRef(false)

  // Fetch clips from the database
  useEffect(() => {
    const fetchUploads = async () => {
      try {
        const uploadPromises = clipIds.map((clipId) => Data.getClip(clipId))

        const results = await Promise.all(uploadPromises)

        if (results.some((result) => result.error)) return alert('An error occurred when loading clips from db')
        if (results.some((result) => result.data === null)) return alert('An error occurred when loading clips from db')

        const newUploads: IClip[] = results.reduce(
          (acc: IClip[], result) => (result.data ? [...acc, result.data] : acc),
          []
        )

        setUploads(newUploads)
      } catch (err) {
        console.error('Something wrong happened', err)
        alert('Something wrong happened')
      }
    }

    fetchUploads()
  }, [clipIds, setUploads])

  useEffect(() => {
    const fetchPlaceSuggestions = async () => {
      const promises = uploads.map((upload) => {
        return Data.getClipPlaceSuggestions(upload.id)
      })

      const promisesArr = (await Promise.all(promises)).map((promise) => promise?.data || null)

      return setPlaceSuggestionsArr([...promisesArr])
    }
    // Here we are using a trick to avoid this effect
    // to trigger each time "uploads" is updated
    if (didFetchPlaces.current || uploads.length === 0) return
    fetchPlaceSuggestions()
    didFetchPlaces.current = true
  }, [uploads, uploads.length])

  // Update state once the user picks up a place and location
  const setLocation = (
    indexToUpdate: number,
    suggestion: { place: string; location: string; lat: number; lng: number }
  ) => {
    const { place, location, lat, lng } = suggestion

    const fieldsToUpdate = { place, location, lat1: lat, lon1: lng }

    const newUploadArray = reducer(uploads, indexToUpdate, fieldsToUpdate)

    setUploads(newUploadArray)
  }

  // Reset location
  const resetLocation = (indexToUpdate: number) => {
    const fieldsToUpdate = { place: '', location: '', lat1: '', lon1: '' }
    const newUploadArray = reducer(uploads, indexToUpdate, fieldsToUpdate)

    setUploads(newUploadArray)
  }

  // Handle changes (caption1, ...)
  const handleChange = (name: string, value: any, indexToUpdate: number) => {
    const newUploadArray = reducer(uploads, indexToUpdate, { [name]: value })
    console.log(newUploadArray)
    setUploads(newUploadArray)
  }

  // Update clip with form metadata (caption, place, project tag...)
  const completeUpload = async () => {
    setIsLoading(true)

    const requests = uploads.map((upload) => {
      const { contributor, email, caption1, caption2, caption3, place, location, lat1, lon1, rating } = upload

      return Data.updateClip({
        id: upload.id,
        contributor,
        email,
        caption1,
        caption2,
        caption3,
        place,
        location,
        lat1,
        lon1,
        rating,
      })
    })

    try {
      // Send those requests
      await Promise.all(requests)

      // To avoid having the next screen appearing to fast
      await new Promise((resolve) => setTimeout(resolve, 2000))

      // Go back to clips gallery
      setShowModal(false)

      return window.location.reload()
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }

  console.log('Rendering Upload Form', clipIds, displaySetting, uploads)

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>Add clip information</Modal.Header>
      <Modal.Content>
        <Form error size="mini">
          {uploads.length > 0 &&
            uploads.map((upload, index) => (
              <Grid key={upload.id} stackable verticalAlign="middle" centered columns={2} divided>
                <Grid.Column key={upload.id} textAlign="center">
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video tabIndex={-1} controls style={{ width: '100%', height: '231px' }}>
                    <source src={`${S3_BUCKET_URL}/c/${upload.id}/0/0/1`} type="video/mp4" />
                    <source src={`${S3_BUCKET_URL}/c/${upload.id}/0/0/1`} type="video/quicktime" />
                  </video>
                </Grid.Column>
                <Grid.Column>
                  {/* Contributor name field */}
                  <Form.Field>
                    <CustomLabel>
                      <CustomLabel.Text text="Your name" />
                      <CustomLabel.WordCount
                        word={upload.contributor}
                        limit={maxLength.contributor}
                      ></CustomLabel.WordCount>
                    </CustomLabel>

                    <Form.Input
                      maxLength={maxLength.contributor}
                      name="contributor"
                      value={upload.contributor || ''}
                      onChange={(e, { name, value }) => handleChange(name, value, index)}
                      placeholder="i.e. Justin, Jeremy..."
                    />
                  </Form.Field>

                  {/* email field */}
                  {displaySetting.showEmail && (
                    <Form.Field>
                      <CustomLabel>
                        <CustomLabel.Text text="Your email" />
                        <CustomLabel.WordCount word={upload.email} limit={maxLength.email}></CustomLabel.WordCount>
                      </CustomLabel>

                      <Form.Input
                        type="email"
                        name="email"
                        value={upload.email || ''}
                        onChange={(e, { name, value }) => handleChange(name, value, index)}
                        placeholder="i.e. myemail@mydomain.com"
                      />
                    </Form.Field>
                  )}
                  {/* Caption fields */}
                  {displaySetting.showCaption1 && (
                    <Form.Field id="form-input-control-error-email">
                      <CustomLabel>
                        <CustomLabel.Text text={displaySetting.caption1 || 'Caption 1'} />
                        <CustomLabel.Popup popupContent="Add some words or comments to describe your clip to viewers"></CustomLabel.Popup>
                        <CustomLabel.WordCount
                          word={upload.caption1}
                          limit={maxLength.caption1}
                        ></CustomLabel.WordCount>
                      </CustomLabel>

                      <Form.Input
                        maxLength={maxLength.caption1}
                        name="caption1"
                        value={upload.caption1 || ''}
                        onChange={(e, { name, value }) => handleChange(name, value, index)}
                        placeholder="Type here..."
                      />
                    </Form.Field>
                  )}

                  {displaySetting.showCaption2 && (
                    <Form.Field id="form-input-control-error-email">
                      <CustomLabel>
                        <CustomLabel.Text text={displaySetting.caption2 || 'Caption 2'} />
                        <CustomLabel.Popup popupContent="Use this extra caption for more detail if needed"></CustomLabel.Popup>
                        <CustomLabel.WordCount
                          word={upload.caption2}
                          limit={maxLength.caption2}
                        ></CustomLabel.WordCount>
                      </CustomLabel>

                      <Form.Input
                        maxLength={maxLength.caption2}
                        name="caption2"
                        value={upload.caption2 || ''}
                        onChange={(e, { name, value }) => handleChange(name, value, index)}
                        placeholder="Type here..."
                      />
                    </Form.Field>
                  )}

                  {displaySetting.showCaption3 && (
                    <Form.Field id="form-input-control-error-email">
                      <CustomLabel>
                        <CustomLabel.Text text={displaySetting.caption3 || 'Caption 3'} />
                        <CustomLabel.Popup popupContent="Add some words or comments to describe your clip to viewers"></CustomLabel.Popup>
                        <CustomLabel.WordCount
                          word={upload.caption3}
                          limit={maxLength.caption3}
                        ></CustomLabel.WordCount>
                      </CustomLabel>

                      <Form.Input
                        maxLength={maxLength.caption3}
                        name="caption3"
                        value={upload.caption3 || ''}
                        onChange={(e, { name, value }) => handleChange(name, value, index)}
                        placeholder="Type here..."
                      />
                    </Form.Field>
                  )}

                  {displaySetting.showRating && (
                    <Form.Field>
                      <label>{displaySetting.rating || 'Leave a rating'} </label>
                      <Form.Input>
                        <Rating
                          icon="star"
                          defaultRating={0}
                          maxRating={5}
                          size="massive"
                          name="rating"
                          onRate={(e, { name, rating: value }) => handleChange(name, value, index)}
                        />
                      </Form.Input>
                    </Form.Field>
                  )}

                  {/* Location Field */}
                  {displaySetting.showLocation && (
                    <UploadLocationField
                      index={index}
                      upload={upload}
                      placeSuggestions={placeSuggestionsArr[index]}
                      setLocation={setLocation}
                      resetLocation={resetLocation}
                    />
                  )}
                </Grid.Column>
              </Grid>
            ))}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button fluid={isMobile} onClick={() => setShowModal(false)}>
          Cancel upload
        </Button>
        <Button fluid={isMobile} primary loading={isLoading} onClick={completeUpload}>
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps {
  clipIds: number[]
  displaySetting: any
  uploads: IClip[]
  setUploads: (arg: IClip[]) => void
  setShowModal: (arg: boolean) => void
}

export default UploadForm
