import React, { useContext } from 'react'

import { Icon, Image, Header, Button, Dimmer } from 'semantic-ui-react'

import QRCode from 'qrcode.react'

import { IProject } from '../../../api/Data'
import { UserContext } from '../../../contexts/UserContext'
import moment from '../../../utils/Moment'
import { convertTextAreaToHTML } from '../../../utils/sharedFunctions'

import ExternalLink from '../../../components/common/ExternalLink'
import UpgradeTag from '../../../components/common/UpgradeTag'
import VideoEncodingCard from '../../../components/common/VideoEncodingCard'

import PlanTabPhoneFrame from './PlanTabPhoneFrame'

const PlanTabLandingPagePreview = ({ project }: { project: IProject }) => {
  // Contexts
  const { userProfile } = useContext(UserContext)

  // Props
  const { title, description, questions, numberOfQuestions, deadline, incentive, rules, tips, teamTermsUrl, imageUrl, hasVideo, heroVideoEncodingStatus, projectUrl, allowRecordVideo, allowUploadFile, allowQrCode } = project

  const { teamName, logoAvatarUrl } = userProfile!

  const { hasBrandedUploadPage } = userProfile.plan

  const projectLinkForQRCode = `${projectUrl}?utm_source=qrcode` // utm is used to differentiate direct form qrcode scan

  return (
    <>
      <Header as="h3">
        Preview (<ExternalLink url={project.projectUrl}>view live</ExternalLink>){' '}
        {!hasBrandedUploadPage && (
          <UpgradeTag title="Upgrade to add your branding" text="Upgrade to the Collector plan to apply your branding to your landing page"></UpgradeTag>
        )}
      </Header>
      <PlanTabPhoneFrame project={project}>
        <>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Image avatar src={logoAvatarUrl} alt="crew" size="large" style={{ width: '30px', height: '30px' }} />
            <Header as="h6" style={{ flex: 1, margin: 0 }}>
              {teamName}
            </Header>
          
            {!!deadline && (
              <>
                {
                  moment().isAfter(moment(deadline)) ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Icon name="calendar times outline" color="black" size="large" />
                      <Header as="h6" style={{ margin: 0 }}>
                        Entries closed
                      </Header>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Icon name="hourglass half" color="black" size="large" />
                      <div>
                        <Header as="h6" style={{ margin: 0 }}>
                          Entries close
                        </Header>
                        <Header as="h6" className="primaryColor" style={{ margin: 0 }}>
                          {moment(moment(deadline)).format('D MMMM YYYY')}
                        </Header>
                      </div>
                    </div>
                  )
                }
              </>
            )}
          </div>

          {hasVideo && ![200, 199].includes(heroVideoEncodingStatus) ? (
            <VideoEncodingCard status={heroVideoEncodingStatus} />
          ) : (
            <>
              {/* image */}
              <Dimmer.Dimmable
                style={{
                  width: '100%',
                  height: '138px',
                  display: 'flex',
                  backgroundImage: `url(${imageUrl})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  borderRadius: '20px',
                  margin: '0.5rem 0'
                }}
              >
                <Dimmer
                  active={hasVideo}
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.45)',
                    borderRadius: '20px'
                  }}
                >
                  <Icon name="play circle outline" size="huge" />
                </Dimmer>
              </Dimmer.Dimmable>
            </>
          )}
        
          {(!!deadline && moment().isAfter(moment(deadline))) && (
            <Header as="h4" className="primaryColor">
              Project closed
            </Header>
          )}

          {/* project image and title */}
          <Header as="h3" style={{ margin: '1rem 0' }}>
            <strong>
              <div dangerouslySetInnerHTML={{ __html: convertTextAreaToHTML(title) }} />
            </strong>
          </Header>

          {/* brief */}
          <Header as="h5" style={{ margin: '0.5rem 0' }}>
            <div dangerouslySetInnerHTML={{ __html: convertTextAreaToHTML(description || '') }} />
          </Header>

          {!(!!deadline && moment().isAfter(moment(deadline))) && (
            <>
              {numberOfQuestions > 1 && (
                <Header as="h6">
                  Question 1 of {numberOfQuestions}
                  <Header as="h3" style={{ margin: '0.25rem 0' }}>
                    {questions[0]}
                  </Header>
                </Header>
              )}

              {allowUploadFile && (
                <div
                  style={{
                    margin: '1.5rem 0 0.25rem 0',
                    padding: '0.75rem',
                    border: '1px red solid',
                    borderRadius: '4px',
                    textAlign: 'center'
                  }}
                >
                  <strong>Drag &amp; drop or select from library</strong>
                </div>
              )}
              
              {allowRecordVideo && (
                <Button
                  icon
                  secondary
                  style={{
                    margin: '1.5rem 0 0.25rem 0',
                    padding: '1.5rem 1.25rem',
                    textTransform: 'none'
                  }}
                >
                  <span style={{ fontSize: '1rem' }}>Click here to record your video</span>
                </Button>
              )}

              {allowQrCode && (
                <div style={{ margin: '1.75rem 0', textAlign: 'center' }}>
                  <Header as="h4">
                    <strong>Scan QR code</strong> to open on another device
                  </Header>

                  <QRCode
                    value={projectLinkForQRCode}
                    size={96}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="L"
                  />
                </div>
              )}

              {/* tips */}
              {!!tips && (
                <Header as="h5">
                  <strong>Filming Tips</strong>
                  <Header as="h5" style={{ margin: '0.25rem 0' }}>
                    <div dangerouslySetInnerHTML={{ __html: convertTextAreaToHTML(tips) }} />
                  </Header>
                </Header>
              )}

              {!!incentive && (
                <Header as="h5">
                  <strong>Reward</strong>
                  <Header as="h5" style={{ margin: '0.25rem 0' }}>
                    <div dangerouslySetInnerHTML={{ __html: convertTextAreaToHTML(incentive) }} />
                  </Header>
                </Header>
              )}

              {!!rules && (
                <Header as="h5">
                  <strong>Project Rules</strong>
                  <Header as="h5" style={{ margin: '0.25rem 0' }}>
                    <div dangerouslySetInnerHTML={{ __html: convertTextAreaToHTML(rules) }} />
                  </Header>
                </Header>
              )}

              {!!teamTermsUrl && (
                <Header as="h5">
                  <strong>Terms &amp; Conditions</strong>
                  <Header as="h5" style={{ margin: '0.25rem 0' }}>
                    <u>Click here to read the terms and conditions</u>
                  </Header>
                </Header>
              )}

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '2rem 0 0 0' }}>
                <div style={{ flex: 1 }}>
                  <Header as="h6">
                    <strong className="primaryColor">We just need some details from you</strong>
                  </Header>
                </div>
                <Button
                  primary
                  style={{
                    padding: '0.75rem 1rem'
                  }}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </>
      </PlanTabPhoneFrame>
    </>
  )
}

export default PlanTabLandingPagePreview
