import React, { useRef, useEffect, useState } from 'react'

import hexToRgba from 'hex-to-rgba'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { Button, Input, Popup, Grid, Label, Menu, Icon, Checkbox, Header, Select, Divider } from 'semantic-ui-react'

import { getFontCSSFromFontFile, getFontFileFromFontCSS } from '../../utils/sharedFunctions'

import ColorPicker from '../../components/common/ColorPicker'

const MenuItem = (name, iconName, isActive, handleClick) => (
  <Menu.Item active={isActive} onClick={handleClick}>
    <Icon style={{ margin: 0 }} name={iconName} />
  </Menu.Item>
)

const DesignerTabSectionToolBox = (props) => {
  // Props
  const { section, selectedIndex, setSelectedIndex, defaultFonts, updateThemeSection } = props
  const { activeToolBox, setActiveToolBox } = props

  // Ref
  const staticTextRef = useRef(null)

  // States
  const [alreadyMounted, setAlreadyMounted] = useState(false)

  // Give focus to static text when element is selected
  useEffect(() => {
    if (!alreadyMounted && activeToolBox === 'font' && section.elements[selectedIndex].name === 'static') {
      staticTextRef.current.focus()
      setAlreadyMounted(true)
    }
  }, [activeToolBox, alreadyMounted, section.elements, selectedIndex])

  // Update theme object
  const updateThemeElement = (elementIndex, field, value) => {
    const newThemeSection = { ...section }
    newThemeSection.elements[elementIndex][field] = value

    updateThemeSection(newThemeSection)
  }

  // Move element backward or forward (up and down in the array)
  const moveElementBackFor = (direction) => {
    const newThemeSection = { ...section }

    const currentIndex = Number(selectedIndex)

    const indexLastPosition = newThemeSection.elements.length - 1

    // Return if element can't move anymore
    if (currentIndex === 0 && ['down', 'begining'].includes(direction)) return
    if (currentIndex === indexLastPosition && ['up', 'end'].includes(direction)) return

    let newIndex
    if (direction === 'up') newIndex = currentIndex + 1
    if (direction === 'down') newIndex = currentIndex - 1
    if (direction === 'end') newIndex = indexLastPosition
    if (direction === 'begining') newIndex = 0

    // Remove current element
    const [removedItem] = newThemeSection.elements.splice(currentIndex, 1)

    // Add it to its new selectedIndex
    newThemeSection.elements.splice(newIndex, 0, removedItem)

    setSelectedIndex(newIndex)
    updateThemeSection(newThemeSection)
  }

  // Update font
  const changeFontSize = (e) => {
    const newFontSize = Number(e.target.value)

    updateThemeElement(selectedIndex, 'fontsize', newFontSize)
  }

  // Update color
  const changeFontColor = (color) => {
    const newFontColor = [color.hex, color.rgb.a].join('@')

    updateThemeElement(selectedIndex, 'fontcolor', newFontColor)
  }

  // Toggle background
  const toggleBackground = () => {
    const newBackground = section.elements[selectedIndex].background ? null : '#000000@1.0'
    updateThemeElement(selectedIndex, 'background', newBackground)
  }

  // Update background color
  const changeBackgroundColor = (color) => {
    const newBackgroundColor = [color.hex, color.rgb.a].join('@')

    updateThemeElement(selectedIndex, 'background', newBackgroundColor)
  }

  // Toggle text casing uppercase/lowercase
  const toggleUppercase = () => {
    const isUppercase = Boolean(section.elements[selectedIndex].uppercase)
    updateThemeElement(selectedIndex, 'uppercase', !isUppercase)
  }

  // Change font family
  const changeFontFamily = (e, { value }) => {
    const { fontfile } = section.elements[selectedIndex]
    const { fontWeight, fontStyle } = getFontCSSFromFontFile(fontfile)

    const newFontFamily = value

    const newFontFile = getFontFileFromFontCSS(newFontFamily, fontWeight, fontStyle)

    updateThemeElement(selectedIndex, 'fontfile', newFontFile)
  }

  // Make font bold
  const toggleBold = () => {
    const { fontfile } = section.elements[selectedIndex]
    const { fontFamily, fontWeight, fontStyle } = getFontCSSFromFontFile(fontfile)

    const newFontWeight = fontWeight === 'bold' ? 'normal' : 'bold'

    const newFontFile = getFontFileFromFontCSS(fontFamily, newFontWeight, fontStyle)

    updateThemeElement(selectedIndex, 'fontfile', newFontFile)
  }

  // Make font italic
  const toggleItalic = () => {
    const { fontfile } = section.elements[selectedIndex]
    const { fontFamily, fontStyle, fontWeight } = getFontCSSFromFontFile(fontfile)

    const newFontType = fontStyle === 'italic' ? 'normal' : 'italic'

    const newFontFile = getFontFileFromFontCSS(fontFamily, fontWeight, newFontType)

    updateThemeElement(selectedIndex, 'fontfile', newFontFile)
  }

  // Change size
  const changePosition = (e, { name, value }) => {
    let newPosition
    // Limit the value to canva width
    if (name === 'x' && value > 1920) newPosition = 1920
    // Limit the value to canva height
    else if (name === 'y' && value > 1080) newPosition = 1080
    // Else set the value
    else newPosition = Number(value)

    updateThemeElement(selectedIndex, name, newPosition)
  }

  // Change position
  const changeSize = (e, { name, value }) => {
    let newSize = Number(value)

    if (name === 'width' && newSize > 1920) newSize = 1920

    if (name === 'height' && newSize > 1080) newSize = 1080

    updateThemeElement(selectedIndex, name, newSize)
  }

  // Center element on x axis
  const centerElement = () => {
    const elementWidth = section.elements[selectedIndex].width

    const newXPos = (1920 - elementWidth) / 2

    updateThemeElement(selectedIndex, 'x', newXPos)
  }

  // Change padding
  const changePadding = (e, { name, value }) => {
    const paddingY = section.elements[selectedIndex].padding?.split(',')[0] || 0
    const paddingX = section.elements[selectedIndex].padding?.split(',')[1] || 0

    let newPadding

    if (value === '') value = 0 // Fix pb when user hit backspace in padding field

    if (name === 'padX') newPadding = `${paddingY},${value}`
    if (name === 'padY') newPadding = `${value},${paddingX}`

    updateThemeElement(selectedIndex, 'padding', newPadding)
  }

  // Change fade
  const changeFadeIn = (e, { name, value }) => {
    const FadeInSt = section.elements[selectedIndex].fadeIn?.split(',')[0]
    const FadeInDur = section.elements[selectedIndex].fadeIn?.split(',')[1]

    let newFadeIn

    if (name === 'FadeInSt') newFadeIn = `${value},${FadeInDur}`
    if (name === 'FadeInDur') newFadeIn = `${FadeInSt},${value}`

    updateThemeElement(selectedIndex, 'fadeIn', newFadeIn)
  }

  // Add fade out
  const changeFadeOut = (e, { name, value }) => {
    const FadeOutSt = section.elements[selectedIndex].fadeOut?.split(',')[0] || 0
    const FadeOutDur = section.elements[selectedIndex].fadeOut?.split(',')[1] || 0

    let newFadeOut

    if (name === 'FadeOutSt') newFadeOut = `${value},${FadeOutDur}`
    if (name === 'FadeOutDur') newFadeOut = `${FadeOutSt},${value}`

    updateThemeElement(selectedIndex, 'fadeOut', newFadeOut)
  }

  // Toggle fade In
  const toggleFadeIn = () => {
    if (section.elements[selectedIndex].fadeIn) updateThemeElement(selectedIndex, 'fadeIn', null)
    else updateThemeElement(selectedIndex, 'fadeIn', '0,0')
  }

  // Toggle fade Out
  const toggleFadeOut = () => {
    if (section.elements[selectedIndex].fadeOut) updateThemeElement(selectedIndex, 'fadeOut', null)
    else updateThemeElement(selectedIndex, 'fadeOut', '0,0')
  }

  // Add image opacity
  const changeImageOpacity = ({ target: { value } }) => {
    const newOpacity = Number(value)

    if (newOpacity < 0 || newOpacity > 100) return

    updateThemeElement(selectedIndex, 'opacity', newOpacity)
  }

  // Change text alignment
  const alignText = (value) => {
    const [vAlign, align] = value.split('-')
    updateThemeElement(selectedIndex, 'valign', vAlign) // Vertical
    updateThemeElement(selectedIndex, 'align', align) // Horizontal
  }

  // Update text
  const updateText = (e, { value }) => {
    updateThemeElement(selectedIndex, 'text', value)
  }

  // Destructure selected element
  const { name, type, x, y, width, height, fadeIn, fadeOut, fontfile, padding } = section.elements[selectedIndex] || {}
  const { background, fontcolor, fontsize, opacity, text, uppercase } = section.elements[selectedIndex] || {}

  // Get padding
  const paddingY = padding?.split(',')[0] || 0
  const paddingX = padding?.split(',')[1] || 0

  // Get fade
  const FadeInSt = fadeIn?.split(',')[0] || ''
  const FadeInDur = fadeIn?.split(',')[1] || ''
  const FadeOutSt = fadeOut?.split(',')[0] || ''
  const FadeOutDur = fadeOut?.split(',')[1] || ''

  // Get background color and opacity
  const [colorHex, backgroundOpacity] = background?.split('@') || ['#FFF', '0']
  const rgbaColor = hexToRgba(colorHex, backgroundOpacity)

  // Get defaults fonts
  const [primaryFont, secondaryFont] = defaultFonts || ['Open Sans', 'Open Sans']

  // Get font family
  const { fontFamily, fontWeight, fontStyle } = fontfile ? getFontCSSFromFontFile(fontfile) : ''

  console.log('Rendering tool box page')

  return (
    <Grid id="designer-toolbox" padded="horizontally">
      <Grid.Row column={1}>
        <Grid.Column style={{ paddingTop: '0.5rem' }}>
          <Menu className="designer-menu" size="big" borderless>
            {type === 'text' && (
              <>
                <Popup
                  trigger={MenuItem('font', 'font', activeToolBox === 'font', () => setActiveToolBox('font'))}
                  content="Font settings"
                  position="top left"
                />

                <Popup
                  trigger={MenuItem('textAlignment', 'align center', activeToolBox === 'textAlignment', () =>
                    setActiveToolBox('textAlignment')
                  )}
                  content="Text alignment"
                  position="top left"
                />
              </>
            )}
            {['image', 'rating'].includes(type) && (
              <>
                <Popup
                  trigger={MenuItem('opacity', 'adjust', activeToolBox === 'opacity', () =>
                    setActiveToolBox('opacity')
                  )}
                  content="Image opacity"
                  position="top left"
                />
              </>
            )}
            {['text', 'image', 'rating'].includes(type) && (
              <>
                <Popup
                  trigger={MenuItem('position', 'arrows alternate', activeToolBox === 'position', () =>
                    setActiveToolBox('position')
                  )}
                  content="Position"
                  position="top left"
                />
                <Popup
                  trigger={MenuItem('fade', 'magic', activeToolBox === 'fade', () => setActiveToolBox('fade'))}
                  content="Special effects"
                  position="top left"
                />
              </>
            )}
          </Menu>
        </Grid.Column>
      </Grid.Row>

      {
        <>
          {/* position */}
          {activeToolBox === 'position' && (
            <>
              <Header as="h6" color="grey">
                Position
              </Header>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input size="mini" fluid type="number" label="x" name="x" value={x} onChange={changePosition} />
                </Grid.Column>
                <Grid.Column>
                  <Input size="mini" fluid type="number" label="y" name="y" value={y} onChange={changePosition} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input size="mini" fluid type="number" label="w" name="width" value={width} onChange={changeSize} />
                </Grid.Column>
                <Grid.Column>
                  <Input size="mini" fluid type="number" label="h" name="height" value={height} onChange={changeSize} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    size="mini"
                    fluid
                    type="number"
                    label="PadX"
                    name="padX"
                    value={paddingX}
                    onChange={changePadding}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    size="mini"
                    fluid
                    type="number"
                    label="PadY"
                    name="padY"
                    value={paddingY}
                    onChange={changePadding}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Button
                    basic
                    size="mini"
                    compact
                    fluid
                    onClick={() => centerElement(section.elements[selectedIndex].width)}
                  >
                    Center
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Button compact size="mini" basic onClick={() => moveElementBackFor('up')}>
                    Bring layer forward
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button compact size="mini" basic onClick={() => moveElementBackFor('down')}>
                    Send layer backward
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button compact size="mini" basic onClick={() => moveElementBackFor('end')}>
                    Send to front
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button compact size="mini" basic onClick={() => moveElementBackFor('beginning')}>
                    Send to back
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </>
          )}

          {/* fade */}
          {activeToolBox === 'fade' && (
            <>
              <Grid.Row columns={2}>
                <Grid.Column width={16} textAlign="left">
                  <Checkbox label="Add fade in" checked={!!fadeIn} onChange={toggleFadeIn} />
                </Grid.Column>
                {fadeIn && (
                  <>
                    <Grid.Column>
                      <Header as="h6" color="grey">
                        Start at
                      </Header>
                      <Input
                        fluid
                        type="number"
                        size="mini"
                        label={{ basic: false, content: 'sec' }}
                        labelPosition="right"
                        name="FadeInSt"
                        value={FadeInSt}
                        onChange={changeFadeIn}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Header as="h6" color="grey">
                        Duration
                      </Header>
                      <Input
                        fluid
                        size="mini"
                        type="number"
                        label={{ basic: false, content: 'sec' }}
                        labelPosition="right"
                        name="FadeInDur"
                        value={FadeInDur}
                        onChange={changeFadeIn}
                      />
                    </Grid.Column>
                  </>
                )}
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column width={16} textAlign="left">
                  <Checkbox label="Add fade out" size="mini" checked={!!fadeOut} onChange={toggleFadeOut} />
                </Grid.Column>
                {fadeOut && (
                  <>
                    <Grid.Column>
                      <Header as="h6" color="grey">
                        Start at
                      </Header>
                      <Input
                        fluid
                        type="number"
                        size="mini"
                        name="FadeOutSt"
                        value={FadeOutSt}
                        label={{ basic: false, content: 'sec' }}
                        labelPosition="right"
                        onChange={changeFadeOut}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Header as="h6" color="grey">
                        Duration
                      </Header>
                      <Input
                        fluid
                        type="number"
                        size="mini"
                        name="FadeOutDur"
                        value={FadeOutDur}
                        label={{ basic: false, content: 'sec' }}
                        labelPosition="right"
                        onChange={changeFadeOut}
                      />
                    </Grid.Column>
                  </>
                )}
              </Grid.Row>
            </>
          )}

          {/* Font */}
          {activeToolBox === 'font' && (
            <>
              <Grid.Row>
                <Grid.Column>
                  <Divider horizontal>
                    <span>
                      <small>Font</small>
                    </span>
                  </Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={11} verticalAlign="middle">
                  <p>{fontFamily}</p>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Input fluid type="number" min={12} max={200} onChange={changeFontSize} value={fontsize} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={4}>
                <Grid.Column>
                  <ColorPicker colorHex={fontcolor?.split('@')[0]} onChange={changeFontColor} />
                </Grid.Column>
                <Grid.Column>
                  <Button fluid basic toggle icon="bold" active={fontWeight === 'bold'} onClick={toggleBold} />
                </Grid.Column>
                <Grid.Column>
                  <Button fluid basic toggle icon="italic" active={fontStyle === 'italic'} onClick={toggleItalic} />
                </Grid.Column>
                <Grid.Column>
                  <Button fluid basic toggle icon="text height" active={uppercase} onClick={toggleUppercase} />
                </Grid.Column>
              </Grid.Row>

              {/* background color */}
              <Grid.Row column={1}>
                <Grid.Column>
                  <Divider horizontal>
                    <span>
                      <small>Background</small>
                    </span>
                  </Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <ColorPicker colorHex={rgbaColor?.split('@')[0]} onChange={changeBackgroundColor} />
                </Grid.Column>
                <Grid.Column>
                  <Checkbox size="mini" label="Transparent" checked={!background} onChange={toggleBackground} />
                </Grid.Column>
              </Grid.Row>
              {/* Text */}
              {name === 'static' && (
                <>
                  <Grid.Row column={1}>
                    <Grid.Column>
                      <Divider horizontal>
                        <span>
                          <small>Text</small>
                        </span>
                      </Divider>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Input ref={staticTextRef} fluid name="text" value={text} onChange={updateText} />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </>
          )}

          {/* text alignment */}
          {activeToolBox === 'textAlignment' &&
            ['top', 'middle', 'bottom'].map((alignType) => (
              <>
                <Grid.Row key={alignType} columns={1} textAlign="center">
                  <Grid.Column>
                    <Header as="h6" color="grey">
                      {[...alignType.charAt(0).toUpperCase(), ...alignType.slice(1)]}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3} textAlign="center">
                  <Grid.Column>
                    <Button icon="left align" onClick={() => alignText(`${alignType}-left`)} />
                  </Grid.Column>
                  <Grid.Column>
                    <Button icon="center align" onClick={() => alignText(`${alignType}-center`)} />
                  </Grid.Column>
                  <Grid.Column>
                    <Button icon="right align" onClick={() => alignText(`${alignType}-right`)} />
                  </Grid.Column>
                </Grid.Row>
              </>
            ))}

          {/* opacity */}
          {activeToolBox === 'opacity' && (
            <>
              <Header as="h6" color="grey">
                Change opacity
              </Header>
              <Grid.Row>
                <Grid.Column>
                  <Input>
                    <input type="range" min={0} max={1} step={0.1} onChange={changeImageOpacity} value={opacity} />
                  </Input>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Input size="mini" disabled type="number" onChange={changeImageOpacity} value={opacity * 100}>
                    <input />
                    <Label>%</Label>
                  </Input>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </>
      }
    </Grid>
  )
}

export default DesignerTabSectionToolBox
