import React, { FC, useContext } from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Button, Modal } from 'semantic-ui-react'

import { GlobalContext } from '../../contexts/GlobalContext'

const UpdatePlanModal: FC<IProps> = ({ history }) => {
  const { showUpdatePlanModal, dispatchGlobalContext } = useContext(GlobalContext)

  const goToMyAccountPage = () => {
    dispatchGlobalContext({ type: 'hide update plan modal' })
    history.push('/my-account')
  }

  return (
    <Modal dimmer="blurring" size="tiny" open={showUpdatePlanModal}>
      <Modal.Header>Upgrade your plan</Modal.Header>
      <Modal.Content>
        Your current plan does not allow you to perform this action. Upgrade your plan by visiting your
        &quot;Account&quot; page.
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => dispatchGlobalContext({ type: 'hide update plan modal' })}>Close</Button>
        <Button primary onClick={goToMyAccountPage}>
          Go to my plan
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IProps extends RouteComponentProps {}

export default withRouter(UpdatePlanModal)
