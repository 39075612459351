import React from 'react'

import { CheckboxProps, Form, Header, InputOnChangeData, Segment } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'
import { maxLength } from '../../../config/constants'

import CustomLabel from '../../../components/common/custom-label/CustomLabel'

const PlanTabCollectPage = (props: IProps) => {
  // Props
  const { project, handleChange } = props

  const handleCheckboxChange = (e: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps) => {
    const { name, checked } = data

    const newProjectOption = { ...project.displaySetting, [String(name)]: Boolean(checked) }

    handleChange(e, {
      name: 'displaySetting',
      value: { ...newProjectOption },
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { name, value } = data

    const newProjectOption = { ...project.displaySetting, [name]: value }

    handleChange(e, {
      name: 'displaySetting',
      value: { ...newProjectOption },
    })
  }

  const { displaySetting } = project

  console.log('Rendering Contributor Settings Form', project)

  return (
    <>
      <Header as="h1" style={{ fontWeight: 'bold' }}>
        Data Collection Settings
      </Header>

      <Segment>
        <Header as="h2" style={{ fontWeight: 'bold' }}>
          General information
          <span style={{ fontSize: '1.4rem', fontWeight: 'normal' }}>
            <CustomLabel.Popup popupContent="Collect important details from your contributors under General Information. This information will be requested once, regardless of the number of videos submitted." />
          </span>
          <br />
          <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
            Gather essential details from your contributors that will be asked only once.
          </span>
        </Header>

        <Form>
          <Form.Field>
            <CustomLabel>
              <CustomLabel.Text text="Name" />
              <CustomLabel.Popup popupContent="Contributor name is mandatory to ensure you have consent to use." />
            </CustomLabel>
          </Form.Field>
          <Form.Field>
            <CustomLabel>
              <CustomLabel.Text text="Organization" />
              <CustomLabel.Checkbox
                name="showOrganization"
                checked={displaySetting.showOrganization}
                onClick={handleCheckboxChange}
              ></CustomLabel.Checkbox>
            </CustomLabel>
          </Form.Field>
          <Form.Field>
            <CustomLabel>
              <CustomLabel.Text text="Email" />
              <CustomLabel.Checkbox
                name="showEmail"
                checked={displaySetting.showEmail}
                onClick={handleCheckboxChange}
              ></CustomLabel.Checkbox>
            </CustomLabel>
          </Form.Field>
          <Form.Field>
            <CustomLabel>
              <CustomLabel.Text text="Location" />
              <CustomLabel.Checkbox
                name="showLocation"
                checked={displaySetting.showLocation}
                onClick={handleCheckboxChange}
              ></CustomLabel.Checkbox>
            </CustomLabel>
          </Form.Field>
        </Form>
      </Segment>

      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Video-Specific Information
        <span style={{ fontSize: '1.4rem', fontWeight: 'normal' }}>
          <CustomLabel.Popup popupContent="For each video, you can specify additional questions under Video-Specific Information. This allows for tailored information to be collected with each clip in a multi-video submission" />
        </span>
        <br />
        <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
          Customize questions for each video within a multi-video submission.
        </span>
      </Header>

      <Form>
        {/* Caption 1 */}
        <Form.Field>
          <CustomLabel>
            <CustomLabel.Text text="Custom question 1" />
            <CustomLabel.Checkbox
              name="showCaption1"
              checked={displaySetting.showCaption1}
              onClick={handleCheckboxChange}
            ></CustomLabel.Checkbox>
            <CustomLabel.WordCount
              word={displaySetting.caption1}
              limit={maxLength.caption1Prompt}
            ></CustomLabel.WordCount>
          </CustomLabel>

          <Form.Input
            placeholder="i.e. Performance Name"
            name="caption1"
            maxLength={maxLength.caption1Prompt}
            value={displaySetting.caption1 || ''}
            onChange={handleInputChange}
            disabled={!displaySetting.showCaption1}
          />
        </Form.Field>
        {/* Caption 2 */}
        <Form.Field>
          <CustomLabel>
            <CustomLabel.Text text="Custom question 2" />
            <CustomLabel.Checkbox
              name="showCaption2"
              checked={displaySetting.showCaption2}
              onClick={handleCheckboxChange}
            ></CustomLabel.Checkbox>
            <CustomLabel.WordCount
              word={displaySetting.caption2}
              limit={maxLength.caption2Prompt}
            ></CustomLabel.WordCount>
          </CustomLabel>

          <Form.Input
            placeholder="i.e. Description"
            name="caption2"
            maxLength={maxLength.caption2Prompt}
            value={displaySetting.caption2 || ''}
            onChange={handleInputChange}
            disabled={!displaySetting.showCaption2}
          />
        </Form.Field>
        {/* Caption 3 */}
        <Form.Field>
          <CustomLabel>
            <CustomLabel.Text text="Custom question 3" />
            <CustomLabel.Checkbox
              name="showCaption3"
              checked={displaySetting.showCaption3}
              onClick={handleCheckboxChange}
            ></CustomLabel.Checkbox>
            <CustomLabel.WordCount
              word={displaySetting.caption3}
              limit={maxLength.caption3Prompt}
            ></CustomLabel.WordCount>
          </CustomLabel>

          <Form.Input
            placeholder="i.e. Venue name"
            name="caption3"
            maxLength={maxLength.caption3Prompt}
            value={displaySetting.caption3 || ''}
            onChange={handleInputChange}
            disabled={!displaySetting.showCaption3}
          />
        </Form.Field>
        {/* Rating */}
        <Form.Field>
          <CustomLabel>
            <CustomLabel.Text text="Ask your contributor for a rating" />
            <CustomLabel.Checkbox
              name="showRating"
              checked={displaySetting.showRating}
              onClick={handleCheckboxChange}
            ></CustomLabel.Checkbox>
            <CustomLabel.WordCount
              word={displaySetting.ratingPrompt}
              limit={maxLength.ratingPrompt}
            ></CustomLabel.WordCount>
          </CustomLabel>

          <Form.Input
            placeholder="i.e. Rate our product from 1 to 5"
            maxLength={maxLength.ratingPrompt}
            name="ratingPrompt"
            value={displaySetting.ratingPrompt || ''}
            onChange={handleInputChange}
            disabled={!displaySetting.showRating}
          />
        </Form.Field>
      </Form>
    </>
  )
}

interface IProps {
  project: IProject
  handleChange: (
    arg: React.SyntheticEvent,
    { name, value, checked }: { name: string; value: string; checked?: boolean }
  ) => void
}

export default PlanTabCollectPage
