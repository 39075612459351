const environment = process.env.REACT_APP_ENVIRONMENT || 'development'

const isProd = environment === 'production'

const config: any = {
  development: {
    S3_BUCKET_URL: 'https://vloggi-assets-development.s3-ap-southeast-2.amazonaws.com',
    API_URL: 'https://api.staging.vloggi.com',
    STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY_DEVELOPMENT,
    STRIPE_PRICING_TABLE_ID: 'prctbl_1NBBq0HoT90lJAMTqKn6UOGA',
  },

  staging: {
    S3_BUCKET_URL: 'https://vloggi-assets-staging.s3-ap-southeast-2.amazonaws.com',
    API_URL: 'https://api.staging.vloggi.com',
    STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY_DEVELOPMENT,
    STRIPE_PRICING_TABLE_ID: 'prctbl_1NBBq0HoT90lJAMTqKn6UOGA',
  },

  production: {
    S3_BUCKET_URL: 'https://vloggi-assets.s3-ap-southeast-2.amazonaws.com',
    API_URL: 'https://api.vloggi.com',
    STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY_PRODUCTION,
    STRIPE_PRICING_TABLE_ID: 'prctbl_1LzXKOHoT90lJAMTzirwcksa',
  },
}

// Common
export const MARKETING_WEBSITE_URL = 'https://vloggi.com'
export const HELPDOCS_URL = 'https://vloggi.helpdocs.io/'
export const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY
export const APP_BUILD = process.env.REACT_APP_BUILD
export const ROLLBAR_API_KEY = process.env.REACT_APP_ROLLBAR_API_KEY
export const GOOGLE_FONT_PICKER_API_KEY = process.env.REACT_APP_GOOGLE_FONT_PICKER_API_KEY

// Analytics / meta tags
export const HAS_NO_INDEX_TAG = isProd ? false : true // meta tag preventing bots to index the websites
export const HAS_GOOGLE_ANALYTICS = isProd ? true : false
export const HAS_GOSQUARED_ANALYTICS = isProd ? true : false
export const HAS_HOTJAR_SCRIPT = isProd ? true : false
export const HAS_ROLLBAR_SCRIPT = isProd ? true : false
export const HAS_FIRST_PROMOTER_SCRIPT = isProd ? true : false
export const HAS_GETREDITUS_SCRIPT = isProd ? true : false

// Env conf
export const S3_BUCKET_URL = config[environment].S3_BUCKET_URL
export const API_URL = config[environment].API_URL
export const STRIPE_API_KEY = config[environment].STRIPE_API_KEY
export const STRIPE_PRICING_TABLE_ID = config[environment].STRIPE_PRICING_TABLE_ID
