import React, { useState } from 'react'

import { Button, Grid, Header, Image } from 'semantic-ui-react'

import { S3_BUCKET_URL } from '../../config/environments'

import DesignerTransitionSelectModal from './modals/DesignerTransitionSelectModal'

const DesignerTabTransitions = ({ theme, updateTheme }) => {
  // States
  const [selectionModal, setSelectionModal] = useState(null)

  const { transitionFirstLast, transition } = theme.data.playlist
  const transitionFirstLastName = transitionFirstLast?.split(',')[0] || null
  const transitionName = transition?.split(',')[0] || null

  console.log('Rendering section page')

  return (
    <Grid>
      <Grid.Column width={1}></Grid.Column>
      <Grid.Column width={11}>
        <Grid divided="vertically" verticalAlign="middle">
          <Grid.Row>
            {/* transitions */}
            <Grid.Column width={10}>
              <Header as="h5" color="violet">
                <label>Default transitions:</label>
                <Header.Subheader>
                  {`Click the "Change" button and choose a custom transition between each contributor's
                  clip. From simple wipes, to exciting swirls, flips and fades.`}
                </Header.Subheader>
              </Header>
              <Button size="small" basic onClick={() => setSelectionModal('transition')}>
                Change
              </Button>
            </Grid.Column>

            <Grid.Column width={6} textAlign="right">
              {transitionName ? (
                <Image
                  width="200px"
                  floated="right"
                  rounded
                  src={`${S3_BUCKET_URL}/themes/transitions/${transitionName}.jpg`}
                />
              ) : (
                'No transition selected'
              )}
            </Grid.Column>
          </Grid.Row>

          {/* Intro outro transition */}
          <Grid.Row>
            <Grid.Column width={10}>
              <Header as="h5" color="violet">
                <label>Intro and outro transitions:</label>
                <Header.Subheader>
                  Click the &quot;Change&quot; button to choose a custom transition between your intro and the first
                  clip played, as well as between the last clip and your outro.
                </Header.Subheader>
              </Header>
              <Button basic onClick={() => setSelectionModal('transitionFirstLast')}>
                Change
              </Button>
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              {transitionFirstLastName ? (
                <Image
                  width="200px"
                  floated="right"
                  rounded
                  src={`${S3_BUCKET_URL}/themes/transitions/${transitionFirstLastName}.jpg`}
                />
              ) : (
                'No transition selected'
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>

      {['transitionFirstLast', 'transition'].includes(selectionModal) && (
        <DesignerTransitionSelectModal
          type={selectionModal}
          theme={theme}
          updateTheme={updateTheme}
          setShowModal={setSelectionModal}
        />
      )}
    </Grid>
  )
}

export default DesignerTabTransitions
