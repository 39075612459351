import React, { useState } from 'react'

import { Button, Form, Icon, InputOnChangeData } from 'semantic-ui-react'

import PasswordInput from '../../components/common/PasswordInput'

const MySettingsPassword = ({ currentPassword, newPassword, isMobile, handleChange, handleSubmit }: IProps) => {
  const [formShown, setFormShown] = useState<boolean>(false)

  return (
    <div>
      {
        !formShown ? (
          <Button primary onClick={() => setFormShown(true)}>
            Change password
          </Button>
        ) : (
          <Form>
            <Form.Field>
              <label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>Current password</div>
                <Icon name="close" link size="large" onClick={() => setFormShown(false)} style={{ marginTop: '-0.8rem' }} />
              </label>
              <PasswordInput
                name="currentPassword"
                value={currentPassword || ''}
                handleChange={handleChange}
                placeholder="Type here..."
              />
            </Form.Field>

            <Form.Field>
              <label>New password</label>
              <PasswordInput
                name="newPassword"
                value={newPassword || ''}
                handleChange={handleChange}
                placeholder="Type here..."
              />
            </Form.Field>

            <Button primary onClick={handleSubmit} fluid={isMobile}>
              Update
            </Button>
          </Form>
        )
      }
    </div>
  )
}

interface IProps {
  currentPassword: string
  newPassword: string
  isMobile: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  handleSubmit: () => void
}

export default MySettingsPassword
