import React, { useContext, useEffect, useState } from 'react'

import { Header } from 'semantic-ui-react'

import Data, { ITeam } from '../../api/Data'
import { UserContext } from '../../contexts/UserContext'
import Auth from '../../utils/Auth'

import Section from '../../components/common/Section'
import SubSection from '../../components/common/SubSection'
import Title from '../../components/common/Title'

import MyAccountDetails from './MyAccountDetails'
import MyAccountDetailsAdmin from './MyAccountDetailsAdmin'

const MyAccountPage = () => {
  const { userProfile } = useContext(UserContext)

  const [team, setTeam] = useState<ITeam | null>(null)

  useEffect(() => {
    const fetchMyTeam = async () => {
      const { data, error } = await Data.getMyTeam()

      if (error) return alert('An error occurred')

      return setTeam(data)
    }

    fetchMyTeam()
  }, [])

  const isUserTeamAdmin = (): boolean => {
    return team !== null && userProfile.userAccessRights === 'admin'
  }

  const isUserSystemAdmin = (): boolean => {
    const { isAdmin } = Auth.getPayLoad()
    return isAdmin
  }

  const findTeamAdminUsername = (team: ITeam): string => {
    const accountAdmin = team.users.find((user) => user.userAccessRights === 'admin')
    if (accountAdmin) return accountAdmin.username
    else return 'unknown'
  }

  if (!team) return null

  console.log('Rendering Account page', userProfile)

  return (
    <Section>
      <>
        <Title title="Plans & Pricing" />
        <SubSection>
          <>
            {isUserTeamAdmin() ? (
              <MyAccountDetails teamId={team.id} />
            ) : (
              <>
                <Header as="h2">Only admin member can manage team account.</Header>
                <Header as="h2">Admin of this account is {findTeamAdminUsername(team)}.</Header>
              </>
            )}
            {isUserSystemAdmin() && <MyAccountDetailsAdmin></MyAccountDetailsAdmin>}
          </>
        </SubSection>
      </>
    </Section>
  )
}

export default MyAccountPage
