/* eslint-disable react/no-unused-state */
import React, { useReducer, useEffect, useState } from 'react'

import globalContextReducer from '../reducers/GlobalReducer'

const initialState = {
  isLoading: false,
  isWideScreen: false,
  isMobile: false,
  isSideBarShown: true,
  showUpdatePlanModal: false,
  hasUserMadeChange: false,
  showExitWithoutSavingModal: null,
}

export const GlobalContext = React.createContext<any>(initialState)

export const GlobalProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(globalContextReducer, initialState)

  const [stateInitialized, setStateInitialized] = useState<boolean>(false)

  useEffect(() => {
    const initializedGlobalState = async () => {
      const { isWideScreen, isMobile, isSideBarShown } = getScreenResolution()

      const payload = { isWideScreen, isMobile, isSideBarShown }

      dispatch({ type: 'initialized state', payload })

      setStateInitialized(true)
    }

    initializedGlobalState()
  }, [dispatch])

  // Once the global state is initialized, we can add the window event handler
  useEffect(() => {
    const handleWindowResize = () => {
      let payload

      if (window.innerWidth <= 769) {
        payload = { isWideScreen: false, isMobile: true, isSideBarShown: false }
      } else if (window.innerWidth >= 1440) {
        payload = { isWideScreen: true, isMobile: false, isSideBarShown: true }
      } else {
        payload = { isWideScreen: false, isMobile: false, isSideBarShown: true }
      }

      dispatch({ type: 'set mobile screen resolution', payload })
    }

    if (stateInitialized) {
      window.addEventListener('resize', handleWindowResize)
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [dispatch, stateInitialized])

  const getScreenResolution = () => {
    const isWideScreen = window.innerWidth >= 1440
    const isMobile = window.innerWidth <= 769
    const isSideBarShown = !isMobile

    return { isWideScreen, isMobile, isSideBarShown }
  }

  if (!stateInitialized) return null

  console.log('Global Context State', state)

  const value = { ...state, dispatchGlobalContext: dispatch }

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

interface Props {
  children: JSX.Element
}
