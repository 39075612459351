import React from 'react'

import { Form, Grid, Header, InputOnChangeData } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'
import { maxLength } from '../../../config/constants'

import CustomLabel from '../../../components/common/custom-label/CustomLabel'

const PlanTabSuccessPage = ({ project, validationError, handleChange }: IProps) => {
  console.log('Rendering Success Page')

  const { successTextDescription, successCTAText, successCTALink } = project

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Thank your contributors
      </Header>

      <Form>
        <Grid stretched padded="vertically">
          <Grid.Column width={16}>
            {/* successTextDescription  */}
            <Form.Field required>
              <CustomLabel>
                <CustomLabel.Text text="Write a thank you message to you contributors" />
                <CustomLabel.Popup popupContent="Thanks your contributors and let them know what is the next step."></CustomLabel.Popup>
                <CustomLabel.WordCount
                  word={successTextDescription}
                  limit={maxLength.successTextDescription}
                ></CustomLabel.WordCount>
              </CustomLabel>

              <Form.Input
                placeholder="Type here..."
                name="successTextDescription"
                maxLength={maxLength.successTextDescription}
                value={successTextDescription || ''}
                onChange={handleChange}
                error={
                  validationError === 'successTextDescription' && {
                    content: 'A thank you message needs to be entered',
                    pointing: 'above',
                  }
                }
              />
            </Form.Field>
            {/* successCTAText  */}
            <Form.Field required>
              <CustomLabel>
                <CustomLabel.Text text="Add a call to action" />
                <CustomLabel.Popup popupContent="Redirect your contributors to your place of interest." />
                <CustomLabel.WordCount word={successCTAText} limit={maxLength.successCTAText}></CustomLabel.WordCount>
              </CustomLabel>

              <Form.Input
                placeholder="Type here..."
                name="successCTAText"
                maxLength={maxLength.successCTAText}
                value={successCTAText || ''}
                onChange={handleChange}
                error={
                  validationError === 'successCTAText' && {
                    content: 'A text needs to be entered for the CTA button',
                    pointing: 'above',
                  }
                }
              />
            </Form.Field>
            {/* successCTALink  */}
            <Form.Field required>
              <CustomLabel>
                <CustomLabel.Text text="Redirect link for your call to action" />
                <CustomLabel.Popup popupContent="Redirect your contributors to your place of interest." />
                <CustomLabel.WordCount word={successCTALink} limit={maxLength.successCTALink}></CustomLabel.WordCount>
              </CustomLabel>

              <Form.Input
                placeholder="https://www.mydomain.com"
                name="successCTALink"
                maxLength={maxLength.successCTALink}
                value={successCTALink || ''}
                onChange={handleChange}
                error={
                  validationError === 'successCTALink' && {
                    content: `A valid link needs to be entered`,
                    pointing: 'above',
                  }
                }
              />
            </Form.Field>
          </Grid.Column>
        </Grid>
      </Form>
    </>
  )
}

interface IProps {
  project: IProject
  validationError: string | null
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
}

export default PlanTabSuccessPage
