import React from 'react'

const ExternalLink = ({ url, children }: IProps) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

interface IProps {
  url: string
  children: string
}

export default ExternalLink
