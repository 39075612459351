import React, { useContext, useState } from 'react'

import { Link, RouteComponentProps } from 'react-router-dom'
import { Form, Button, Message, Grid, Image, Header, InputOnChangeData, FormProps } from 'semantic-ui-react'

import vloggiLogoWhite from '../../_images/vloggi-logo-white.png'
import Data from '../../api/Data'
import { GlobalContext } from '../../contexts/GlobalContext'

const ForgotPage = ({ history }: IProps) => {
  const { isMobile } = useContext(GlobalContext)

  // States
  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setErrorMessage(null)
    setEmail(data.value)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, data: FormProps) => {
    e.preventDefault()

    if (!email) return setErrorMessage('Please enter your email above')

    setLoading(true)

    const { error } = await Data.forgotPassword(email)

    if (error) {
      setLoading(false)
      return setErrorMessage(error.message)
    }

    return history.push('/login', { successMessage: `A password reset email has been sent to ${email}` })
  }

  return (
    <Grid stretched style={{ height: '100vh', margin: 0 }}>
      <Grid.Column only="computer" width={7} style={{ backgroundImage: 'linear-gradient(to bottom, #12165b, #2531a0)' }}>
        <Grid centered verticalAlign="middle">
          <Grid.Column width={14}>
            <Image width="200" src={vloggiLogoWhite} alt="vloggi" />
            <Header inverted as="h2">
              Collect videos from anyone, anywhere, at any time.
              <Header.Subheader>Login to your account</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column mobile={16} computer={9} style={{ backgroundImage: isMobile ? 'linear-gradient(to bottom, #12165b, #2531a0)' : undefined }}>
        <Grid padded>
          <Grid.Row></Grid.Row>
          <Grid.Row columns={1} centered verticalAlign="middle">
            <Grid.Column mobile={16} computer={10} widescreen={6}>
              {isMobile ? (
                <>
                  <Image src={vloggiLogoWhite} size="small" style={{ margin: 'auto' }} />
                  <Header inverted as="h4">
                    Forgot Password?
                  </Header>
                </>
              ) : (
                <Header as="h2" style={{ marginBottom: '2rem' }}>
                  <strong>Forgot Password?</strong>
                </Header>
              )}
              <Form error={!!errorMessage} onSubmit={handleSubmit} inverted={isMobile}>
                <p style={isMobile ? { color: 'white' } : undefined}>
                  Enter the email address you use to sign in, and we’ll send you a link to reset your password.
                </p>
                <Form.Field>
                  <label>Email</label>
                  <Form.Input
                    fluid
                    icon="mail"
                    iconPosition="left"
                    name="email"
                    value={email}
                    placeholder="Enter your email address"
                    type="email"
                    onChange={handleChange}
                  />
                </Form.Field>

                <Message error header="Fail" content={errorMessage} />

                <Button primary type="submit" loading={loading} style={{ marginTop: '0.5rem' }}>
                  Send link
                </Button>

                <Button as={Link} to="/login" style={{ marginTop: '0.5rem' }}>
                  Cancel
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

interface IProps extends RouteComponentProps<any> {}

export default ForgotPage
