import React from 'react'

import { Header, Grid, Segment, Form, InputOnChangeData } from 'semantic-ui-react'

import { IClip } from '../../../api/Data'

import CustomLabel from '../../../components/common/custom-label/CustomLabel'

import EpisodeBuilder from './MergeTabEpisodeBuilder'

const MergeTabStoryBuilder = (props: IProps) => {
  // Props
  const { validationError, episode, clipsSelected, maxLength, setClipsSelected, removeClip, handleChange } = props

  console.log('Story builder', clipsSelected)
  return (
    <Grid>
      <Grid.Column width={4}>
        <Header as="h3">Story Info</Header>

        <Form error={!!validationError}>
          <Form.Field>
            <CustomLabel>
              <CustomLabel.Text text=" Story name" />
              <CustomLabel.Popup
                popupContent={
                  "Use a catchy title and be clear on what the story is about to get people's attention and provide interest."
                }
              ></CustomLabel.Popup>
              <CustomLabel.WordCount word={episode.title} limit={maxLength.title}></CustomLabel.WordCount>
            </CustomLabel>

            <Form.Input
              maxLength={maxLength.title}
              type="text"
              placeholder="Type your title here..."
              name="title"
              value={episode.title || ''}
              onChange={handleChange}
              error={
                validationError === 'title' && {
                  content: `Please name this story (max ${maxLength.title} characters)`,
                  pointing: 'above',
                }
              }
            />
          </Form.Field>
        </Form>
      </Grid.Column>
      {/* episode builder */}
      <Grid.Column width={12}>
        <Header as="h3">Story Builder</Header>
        <Segment>
          <EpisodeBuilder clipsSelected={clipsSelected} setClipsSelected={setClipsSelected} removeClip={removeClip} />
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

interface IProps {
  maxLength: { title: number; description: number }
  validationError: string | null
  episode: { title: string; description: string }
  clipsSelected: IClip[]
  handleChange: (arg1: React.ChangeEvent<HTMLInputElement>, arg2: InputOnChangeData) => void
  setClipsSelected: (arg: IClip[]) => void
  removeClip: (arg: IClip) => void
}

export default MergeTabStoryBuilder
