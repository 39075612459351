import React from 'react'

import ReactMapboxGl, { ZoomControl, Layer, Feature } from 'react-mapbox-gl'
import { withRouter, RouteComponentProps } from 'react-router'
import { Form, Grid, Segment, Header, Popup, Button, Checkbox, Item, Image, Statistic } from 'semantic-ui-react'

import 'mapbox-gl/dist/mapbox-gl.css' // Issue with mapbox version > 2.0 => https://github.com/mapbox/underreact/issues/108. Need to run mapbox-gl version  <2.0. Also another issue with react-mapbox-gl version > 5.0 https://github.com/alex3165/react-mapbox-gl/issues/920. Need to run version < 5.0

import { IClip, IProject } from '../../../api/Data'
import { maxLength } from '../../../config/constants'
import { MAPBOX_API_KEY } from '../../../config/environments'
import moment from '../../../utils/Moment'
import { getBbox } from '../../../utils/sharedFunctions'

import CustomLabel from '../../../components/common/custom-label/CustomLabel'
import ExternalLink from '../../../components/common/ExternalLink'
import 'react-datepicker/dist/react-datepicker.css'

const Map = ReactMapboxGl({
  accessToken: MAPBOX_API_KEY!,
  touchZoomRotate: true,
  // scrollZoom: false,
})

const PlanTabOverviewPage = (props: IProps) => {
  // Props
  const { history, project, clips, handleChange, validationError } = props

  const getLatestEntries = () => {
    return clips.slice(0, 2)
  }

  const goToClipGalleryPage = () => {
    history.push(`/projects/${project.slug}/manage`)
  }

  const uploadMyFirstVideo = () => {
    history.push(`/projects/${project.slug}/manage`, { uploadModal: true })
  }

  const getClipsWithLocation = () => {
    return clips.filter((clip: IClip) => clip.lat1 && clip.lon1)
  }

  const { name, projectUrl } = project

  const bbox: any = getBbox(clips)

  const latestEntries: IClip[] = getLatestEntries()

  const clipsWithLocation: IClip[] = getClipsWithLocation()

  const { pageViews, clipCount, episodeCount } = project

  console.log('Rendering Plan Tab 1 page', project)

  return (
    <Grid stackable stretched>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as="h2" style={{ fontWeight: 'bold' }}>
            Status
          </Header>

          <Segment>
            <Form>
              {/* name */}
              <Form.Field>
                <CustomLabel>
                  <CustomLabel.Text text=" Project name" />
                  <CustomLabel.Popup popupContent="Name your project"></CustomLabel.Popup>
                  <CustomLabel.WordCount word={name} limit={maxLength.name}></CustomLabel.WordCount>
                </CustomLabel>

                <Form.Input
                  placeholder="Type here..."
                  name="name"
                  maxLength={maxLength.name}
                  value={name}
                  onChange={handleChange}
                  error={
                    validationError === 'name' && {
                      content: `Please give a name to your project (max ${maxLength.name} characters)`,
                      pointing: 'above',
                    }
                  }
                />
              </Form.Field>
              <Grid>
                <Grid.Column width={12}>
                  {/* link */}
                  <Form.Field>
                    <label>Project link</label>
                    <div>
                      <ExternalLink url={projectUrl}>{projectUrl.split('//')[1]}</ExternalLink>
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  {/* live */}
                  <Form.Field>
                    <label style={{ marginRight: '1rem' }}>Live</label>
                    <div>
                      <Checkbox toggle name="shared" checked={project.shared} onClick={handleChange} />
                    </div>
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </Form>
          </Segment>
        </Grid.Column>

        <Grid.Column width={9}>
          <Popup
            trigger={
              <Header as="h2" style={{ fontWeight: 'bold' }}>
                Insights
              </Header>
            }
            content="This section gives you some statistics like nb of clip contributed, number of user views for your upload page etc..."
            position="top left"
          />
          <Segment basic>
            <Grid columns={3} verticalAlign="middle" stackable>
              <Grid.Column>
                <Segment circular style={{ width: '130px', height: '130px' }}>
                  <Statistic size="mini">
                    <Statistic.Value>{pageViews}</Statistic.Value>
                  </Statistic>
                  <p>
                    Page
                    <br />
                    views
                  </p>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment circular style={{ width: '130px', height: '130px' }}>
                  <Statistic size="mini">
                    <Statistic.Value>{clipCount}</Statistic.Value>
                  </Statistic>
                  <p>Clips contributed</p>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment circular style={{ width: '130px', height: '130px' }}>
                  <Statistic size="mini">
                    <Statistic.Value>{episodeCount}</Statistic.Value>
                  </Statistic>
                  <p>Stories produced</p>
                </Segment>
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={9}>
          <Header as="h2" style={{ fontWeight: 'bold' }}>
            Clip Locations
          </Header>

          <Segment style={{ width: '100%', height: '300px' }}>
            <Map
              // eslint-disable-next-line react/style-prop-object
              style="mapbox://styles/mapbox/streets-v8"
              fitBounds={bbox}
              fitBoundsOptions={{ padding: 20, maxZoom: 9 }}
              containerStyle={{
                height: '100%',
                width: '100%',
              }}
            >
              <>
                <ZoomControl />

                {clipsWithLocation.map((clip) => (
                  <Layer key={clip.id} type="symbol" id={`marker-${clip.lat1}`} layout={{ 'icon-image': 'marker-15' }}>
                    <Feature coordinates={[clip.lon1!, clip.lat1!]} />
                  </Layer>
                ))}
              </>
            </Map>
          </Segment>
        </Grid.Column>

        <Grid.Column width={7}>
          <Header as="h2" style={{ fontWeight: 'bold' }}>
            Latest Entries
          </Header>

          <Segment style={{ width: '100%', height: '300px' }}>
            <Grid column={1} verticalAlign="middle" textAlign="center" style={{ height: '300px' }}>
              <Grid.Column>
                {!clips.length ? (
                  <>
                    <Header>You have not received any contributions yet.</Header>

                    <Button secondary onClick={uploadMyFirstVideo}>
                      Upload your first clip
                    </Button>
                  </>
                ) : (
                  <Item.Group unstackable relaxed>
                    {latestEntries.map((clip) => (
                      <Item key={clip.id} onClick={goToClipGalleryPage} as="a">
                        <Image src={clip.imageLowResUrl} rounded style={{ width: '190px', height: '107px' }} />

                        <Item.Content verticalAlign="middle">
                          <Item.Meta>
                            <span className="price">{moment(clip.createdAt).fromNow()}</span>
                          </Item.Meta>
                          <Item.Description>By: {clip.contributor || 'Unknown'}</Item.Description>
                        </Item.Content>
                      </Item>
                    ))}
                  </Item.Group>
                )}
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

interface IProps extends RouteComponentProps<any> {
  project: IProject
  clips: IClip[]
  validationError: string | null
  handleChange: (arg: any, data: any) => void
}

export default withRouter(PlanTabOverviewPage)
