import React from 'react'

import { Button, Grid, Header, Image, Modal } from 'semantic-ui-react'

import { ITeam, IBrand } from '../../api/Data'

import ColorPicker from '../../components/common/ColorPicker'
import FontPickerComponent from '../../components/common/FontPickerComponent'

import imageOnboardingBranding from '../../_images/onboarding-branding.png'

const StyleModal = (props: IStyleModalProps) => {
  // Props
  const { loading, team, brand, setShowLogoUploadModal, setShowWatermarkUploadModal, handleChangeFont, handleChangePrimaryColor, handleChangeSecondaryColor, stepBack, handleSaveStyle, handleEndOnboarding } = props

  const { teamName, organisationDescription, organisationWebsite, logoAvatarUrl, logoWatermarkUrl, primaryFont, primaryColor, secondaryColor } = team

  return (
    <Modal open style={{ width: '100%', height: '100%', marginTop: '-14px' }}>
      <Modal.Content style={{ padding: 0 }}>
        <Grid style={{ height: '100vh', minHeight: '700px', margin: 0 }}>
          <Grid.Column only="computer" width={7} style={{ backgroundImage: 'linear-gradient(to bottom, #3121a0, #1f248e)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Header inverted as="h1">
                Almost done - let's tailor Vloggi to your needs
              </Header>
              <Image src={imageOnboardingBranding} width={350} alt="vloggi" />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} computer={9}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button disabled={loading} onClick={handleEndOnboarding}>
                  Skip
                </Button>
              </div>
              <div style={{ padding: '0 100px' }}>
                <Header as="h2">
                  Customize your branding
                </Header>
                <div>
                  <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{brand?.organisationName || teamName}</p>
                  {!!organisationDescription && (
                    <p>{organisationDescription}</p>
                  )}
                  {!!organisationWebsite && (
                    <p><a href={'http://' + organisationWebsite} target="_blank" rel="noopener noreferrer">{organisationWebsite}</a></p>
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                  <div>
                    <p style={{ margin: '20px 0 5px 0', fontSize: '13px' }}>Team Logo</p>
                    <Image size="small" src={`${logoAvatarUrl}?${Date.now()}`} style={{ width: '120px', height: '80px', border: '1px solid #6c58fb', borderRadius: '5px', objectFit: 'cover', cursor: 'pointer' }} onClick={() => setShowLogoUploadModal(true)} />
                  </div>
                  <div>
                    <p style={{ margin: '20px 0 5px 0', fontSize: '13px' }}>Video Logo</p>
                    <Image src={`${logoWatermarkUrl}?${Date.now()}`} style={{ width: '120px', height: '80px', border: '1px solid #6c58fb', borderRadius: '5px', objectFit: 'cover', cursor: 'pointer' }} onClick={() => setShowWatermarkUploadModal(true)} />
                  </div>
                </div>
                <p style={{ margin: '20px 0 5px 0', fontSize: '13px' }}>Colors</p>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                  <div style={{ width: '50px', position: 'relative' }}>
                    <ColorPicker colorHex={primaryColor} onChange={handleChangePrimaryColor} />
                  </div>
                  <div style={{ width: '50px', position: 'relative' }}>
                    <ColorPicker colorHex={secondaryColor} onChange={handleChangeSecondaryColor} />
                  </div>
                </div>
                <p style={{ margin: '20px 0 5px 0', fontSize: '13px' }}>Font</p>
                <div style={{ width: '300px', margin: '0 0 10px 0' }}>
                  <FontPickerComponent activeFontFamily={primaryFont} onChange={handleChangeFont} />
                  {!!brand && brand.primaryFont !== primaryFont && (
                    <p style={{ margin: '10px 0 0 0' }}>{brand.primaryFont} not available, select substitution</p>
                  )}
                </div>
                <Button primary disabled={loading} loading={loading} style={{ marginTop: '20px' }} onClick={handleSaveStyle}>
                  Next
                </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ margin: '12px 20px 0 0' }}>
                  3 / 6
                </div>
                <Button disabled={loading} onClick={stepBack}>
                  Back
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

interface IStyleModalProps {
  loading: boolean
  team: ITeam
  brand: IBrand | null
  setShowLogoUploadModal: (arg: boolean) => void
  setShowWatermarkUploadModal: (arg: boolean) => void
  handleChangeFont: (arg: { family: string }) => void
  handleChangePrimaryColor: (arg: { hex: string }) => void
  handleChangeSecondaryColor: (arg: { hex: string }) => void
  stepBack: () => void
  handleSaveStyle: () => void
  handleEndOnboarding: () => void
}

export default StyleModal
