export const teamAvatarSize = {
  imageIsAvatar: true,
  width: 225,
  height: 225,
  inputImageType: 'image/*',
  outputImageType: 'image/png',
}

export const teamWatermarkSize = {
  imageIsAvatar: false,
  width: 400,
  height: 225,
  inputImageType: 'image/png',
  outputImageType: 'image/png',
}

export const projectImageSize = {
  imageIsAvatar: false,
  width: 400,
  height: 225,
  inputImageType: 'image/*',
  outputImageType: 'image/jpeg',
}

export const themeThumbnailSize = {
  imageIsAvatar: false,
  width: 400,
  height: 225,
  inputImageType: 'image/*',
  outputImageType: 'image/jpeg',
}

export const onboardingThemes = [584, 585, 599, 604, 1261, 1263]

export const clipsPerPage = 39

export const maxEpisodeDuration = 2.5 * 60 // 2mins 30sec
export const maxClipsPerEpisode = 10
export const minClipsPerEpisode = 1

export const maxLength = {
  // Clip
  contributor: 75,
  email: 75,
  organization: 75,
  caption1: 75,
  caption2: 75,
  caption3: 75,

  // Project / Episode
  name: 75,
  title: 75,
  description: 500,
  incentive: 75,
  rules: 500,
  tips: 500,
  teamTermsUrl: 250,
  caption1Prompt: 75,
  caption2Prompt: 75,
  caption3Prompt: 75,
  ratingPrompt: 75,
  successTextDescription: 500,
  successCTAText: 75,
  successCTALink: 250,
  question: 250,
}

export const urlsWithoutSideBar = [
  /^\/$/,
  /^\/style$/,
  /^\/login$/,
  /^\/register$/,
  /^\/forgot$/,
  /^\/recover.+$/,
  /^\/404$/,
]

export const urlsWithSecondarySideBar = [/^\/projects\/.+$/]

export const vloggiSuccessCallUrl = 'https://calendly.com/jwastnage/vloggi-demo'
