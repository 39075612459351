import React from 'react'

import { Button, Form, Grid, Header, Image, Modal, InputOnChangeData } from 'semantic-ui-react'

import CustomLabel from '../../components/common/custom-label/CustomLabel'

import imageOnboardingGetStarted from '../../_images/onboarding-get-started.png'

const ProjectNameModal = (props: IProjectNameModalProps) => {
  // Props
  const { loading, projectName, handleChangeProjectName, stepBack, handleSaveProject, handleEndOnboarding } = props
  
  return (
    <Modal open style={{ width: '100%', height: '100%', marginTop: '-14px' }}>
      <Modal.Content style={{ padding: 0 }}>
        <Grid style={{ height: '100vh', minHeight: '700px', margin: 0 }}>
          <Grid.Column only="computer" width={7} style={{ backgroundImage: 'linear-gradient(to bottom, #f1eff6, #f2f0f6)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Header as="h1">
                Last steps - you're nearly all set up
              </Header>
              <Image src={imageOnboardingGetStarted} width={350} alt="vloggi" />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} computer={9}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button disabled={loading} onClick={handleEndOnboarding}>
                  Skip
                </Button>
              </div>
              <div style={{ padding: '0 100px' }}>
                <Header as="h2">
                  Let's give your first project name
                </Header>
                <Form style={{ margin: '20px 0 10px 0' }}>
                  <Form.Field>
                    <CustomLabel>
                      <CustomLabel.Text text="You can change this later" />
                      <CustomLabel.WordCount word={projectName} limit={75}></CustomLabel.WordCount>
                    </CustomLabel>
                    <Form.Input type="text" name="teamName" maxLength={75} value={projectName} onChange={handleChangeProjectName} />
                  </Form.Field>
                </Form>
                <Button primary disabled={projectName.trim() === '' || loading} loading={loading} style={{ marginTop: '20px' }} onClick={handleSaveProject}>
                  Next
                </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ margin: '12px 20px 0 0' }}>
                  5 / 6
                </div>
                <Button disabled={loading} onClick={stepBack}>
                  Back
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

interface IProjectNameModalProps {
  loading: boolean
  projectName: string
  handleChangeProjectName: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  stepBack: () => void
  handleSaveProject: () => void
  handleEndOnboarding: () => void
}

export default ProjectNameModal
