import React, { SyntheticEvent } from 'react'

import { Header, Grid, Pagination, Icon, Label, PaginationProps, Button, Form, Container } from 'semantic-ui-react'

import { IClip } from '../../../api/Data'

import UpgradeTag from '../../../components/common/UpgradeTag'

const ManageControlsBar = (props: IProps) => {
  const {
    activeTab,
    tags,
    page,
    totalTeamsClipCount,
    projectEntryCount,
    projectClipCount,
    clipMax,
    clipsPerPage,
    showPagination,
    onBulkTag,
    onSearch,
    onSelectAll,
    onBack,
    clipsSelected,
  } = props
  const { removeFilterTag, setPage, filterByFavorite } = props

  const goToPage = (_e: SyntheticEvent, data: PaginationProps) => {
    const page = Number(data.activePage)
    setPage(page)
  }

  const onSearchEvent = (e: SyntheticEvent, data: any) => {
    console.log('onSearch', data.value)
    onSearch(data.value)
  }

  const buttonStack = {
    display: 'inline-block',
    gap: '1rem',
  }

  return (
    <Grid verticalAlign="middle" stackable>
      <Grid.Column width={4}>
        <Form.Input icon="search" placeholder="Search..." fluid inverted name="search" onChange={onSearchEvent} />
      </Grid.Column>

      <Grid.Column width={8} style={buttonStack}>
        <Button compact basic size="mini" onClick={onSelectAll}>
          <Icon name="list" />
          {clipsSelected.length > 0 ? `Deselect (${clipsSelected.length})` : 'Select all'}
        </Button>
        <Button compact basic size="mini" onClick={filterByFavorite}>
          <Icon name="heart" color={activeTab === 'favorites' ? 'red' : 'grey'} />
          Favorites
        </Button>
        <Button compact primary size="mini" onClick={onBulkTag} disabled={clipsSelected.length <= 0}>
          <Icon name="tag" />
          Bulk tag
        </Button>
      </Grid.Column>
      
      {showPagination ? (
        <>
          <Grid.Column width={4} textAlign="right">
            <span style={{ marginRight: '1rem', color: `${totalTeamsClipCount >= clipMax ? 'red' : 'grey'}` }}>
              {projectClipCount === 1 ? '1 clip' : `${projectClipCount} clips`}
              {totalTeamsClipCount >= clipMax && <UpgradeTag text="Upgrade your plan to collect more video clips" />}
            </span>
            
            {/* Pagination */}
            <Pagination
              size="mini"
              boundaryRange={0}
              defaultActivePage={page}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              onPageChange={goToPage}
              totalPages={Math.ceil(projectEntryCount! / clipsPerPage)}
            />
          </Grid.Column>
        </>
      ) : (
        <Grid.Column width={4} textAlign="right" style={buttonStack}>
          <Button compact secondary basic size="mini" onClick={onBack}>
            <Icon name="arrow left" />
            Back
          </Button>
        </Grid.Column>
      )}

      {tags.length > 0 && (
        <Grid.Column width={16}>
          <div>
            <p style={{ marginRight: '1rem', display: 'inline' }}>Search by tags:</p>
            <Label.Group size="large" style={{ display: 'inline' }}>
              {tags.map((tag) => (
                <Label basic color="violet" key={tag}>
                  {tag}
                  <Icon name="delete" onClick={() => removeFilterTag(tag)} />
                </Label>
              ))}
            </Label.Group>
          </div>
        </Grid.Column>
      )}
    </Grid>
  )
}

interface IProps {
  activeTab: string
  tags: string[]
  totalTeamsClipCount: number
  projectEntryCount: number
  projectClipCount: number
  clipMax: number
  page: number
  clipsPerPage: number
  showPagination: boolean
  maxNbOfClips: number
  removeFilterTag: (arg: string) => void
  setPage: (arg: number) => void
  filterByFavorite: () => void
  onBulkTag: () => void
  onSearch: (arg: string) => void
  onSelectAll: () => void
  onBack?: () => void
  clipsSelected: IClip[]
}

export default ManageControlsBar
