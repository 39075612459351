import React, { useContext, useState } from 'react'

import CopyToClipBoard from 'react-copy-to-clipboard'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share'
import { Grid, Header, Button, Segment, Popup, Icon, Label } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'
import { UserContext } from '../../../contexts/UserContext'

import ExternalLink from '../../../components/common/ExternalLink'

const InviteTab = ({ project }: IProps) => {
  const { userProfile } = useContext(UserContext)

  // States
  const [copied, setCopied] = useState(false)

  const { title, description, projectUrl } = project
  const { teamName } = userProfile!

  const socialMediaTitle = `Vloggi is a crowd sourcing video platform. You have been invited to contribute video clips to the Vloggi project "${title}" posted by ${teamName} `
  const socialMediaDescription = `Upload your video clips here`

  if (!project) return null

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'bold' }}>
        Invite your community
      </Header>
      <Segment textAlign="center">
        <Header as="h4">
          <Header.Subheader>
            Copy this link below and share it with your community to get them to contribute to your video project.
            <Popup
              hoverable
              content={
                <p>
                  Share as a facebook / instagram post or include it into you EDM to get your target audience to upload
                  their videos (
                  <ExternalLink url="https://vloggi.helpdocs.io/article/t29pnqjn3c-where-to-share-your-video-project">
                    read more
                  </ExternalLink>
                  )
                </p>
              }
              trigger={<Icon name="info circle" style={{ marginLeft: '0.5rem' }} />}
            />
          </Header.Subheader>
        </Header>

        <div style={{ margin: '2rem 0 1rem 0' }}>
          <CopyToClipBoard
            text={projectUrl}
            options={{ format: 'text/plain' }}
            onCopy={() => {
              setCopied(true)
            }}
          >
            <Button as="div" labelPosition="left">
              <Label as="a" basic>
                {projectUrl.split('//')[1]}
              </Label>
              <Button icon primary>
                {copied ? 'Copied' : 'Copy link'}
              </Button>
            </Button>
          </CopyToClipBoard>
        </div>

        {/* Share Social Media */}
        <Grid textAlign="center" padded stackable>
          <Grid.Column width={3}>
            <EmailShareButton url={projectUrl} subject={socialMediaTitle} body={description}>
              <EmailIcon size={44} round />
            </EmailShareButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FacebookShareButton url={projectUrl} quote={`${socialMediaTitle} \n ${socialMediaDescription}:`}>
              <FacebookIcon size={44} round />
            </FacebookShareButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <WhatsappShareButton url={projectUrl} title={`${socialMediaTitle} \n ${socialMediaDescription}: \n`}>
              <WhatsappIcon size={44} round />
            </WhatsappShareButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <LinkedinShareButton
              url={projectUrl}
              title={socialMediaTitle}
              summary={socialMediaDescription}
              source="www.vloggi.com"
            >
              <LinkedinIcon size={44} round />
            </LinkedinShareButton>
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  )
}

interface IProps {
  project: IProject
}

export default InviteTab
