import React from 'react'

import {
  renderClipBackgroundImageHTML,
  renderClipBackgroundVideoHTML,
  renderTextElementHTML,
  renderImageElementHTML,
} from '../../components/common/ThemeElement'

const ThemePreview = (props: IProps) => {
  // Props
  const { section, themeData, ratio, backgroundAsset = null, isBackgroundVideo = null, placeholderTextObj } = props

  const [width, height] = [1920 * ratio, 1080 * ratio]

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
        backgroundColor: 'black',
        padding: '0',
      }}
    >
      {isBackgroundVideo
        ? renderClipBackgroundVideoHTML(backgroundAsset)
        : renderClipBackgroundImageHTML(backgroundAsset)}

      {/* Display each element */}
      {themeData[section] &&
        themeData[section].elements.map((element: any, index: number) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              display: 'table',
              left: element.x * ratio,
              top: element.y * ratio,
              width: element.width * ratio,
              height: element.height * ratio,
            }}
          >
            {element.type === 'text' && renderTextElementHTML(section, element, index, ratio, placeholderTextObj)}
            {element.type === 'image' && renderImageElementHTML(element, index, ratio)}
          </div>
        ))}
    </div>
  )
}

interface IProps {
  section: string
  themeData: any
  ratio: number
  backgroundAsset?: string
  isBackgroundVideo?: boolean
  placeholderTextObj?: any
}

export default ThemePreview
