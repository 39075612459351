import React, { useState } from 'react'

import { Modal, Form, Button } from 'semantic-ui-react'

const DesignerStaticTextPromptModal = ({ handleSubmit, setShowModal }) => {
  // States
  const [text, setText] = useState('')

  const submitForm = (e) => handleSubmit(e, { value: 'static' }, text)

  return (
    <Modal dimmer="blurring" open size="mini" onClose={() => setShowModal(false)}>
      <Modal.Header>Enter text</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form onSubmit={submitForm}>
            <Form.Input
              autoFocus
              fluid
              type="text"
              label="Type the text you want to overlay on your template"
              value={text}
              onChange={(e, { value }) => setText(value)}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>Close</Button>
        <Button primary onClick={submitForm}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
export default DesignerStaticTextPromptModal
