import React, { useState } from 'react'

import { Button, Header, Image, Dimmer, Grid } from 'semantic-ui-react'

const MyBrandingLogos = (props: IMyBrandingLogosProps) => {
  // Props
  const { logoAvatarUrl, logoWatermarkUrl, setShowLogoUploadModal, setShowWatermarkUploadModal } = props

  // States
  const [dimmedLogo, setDimmedLogo] = useState(false)
  const [dimmedWatermark, setDimmedWatermark] = useState(false)

  return (
    <>
      <Grid.Row>
        <Grid.Column width={10}>
          <Header as="h3">
            Team logo
            <Header.Subheader>
              Your team logo will be used on your landing pages and as your profile picture (recommend 225 x 225px).
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Dimmer.Dimmable
            onMouseEnter={() => setDimmedLogo(true)}
            onMouseLeave={() => setDimmedLogo(false)}
            style={{ width: '100px', margin: 'auto' }}
          >
            {/* Add background color in case of white png images */}
            <Image avatar size="small" src={`${logoAvatarUrl}`} />
            <Dimmer active={dimmedLogo} style={{ borderRadius: '50%' }}>
              <Button size="mini" fluid inverted compact basic onClick={() => setShowLogoUploadModal(true)}>
                Change
              </Button>
            </Dimmer>
          </Dimmer.Dimmable>
        </Grid.Column>
      </Grid.Row>
      {/* Video logo */}
      <Grid.Row>
        <Grid.Column width={10}>
          <Header as="h3">
            Video logo
            <Header.Subheader>
              Your video logo is the core part of your video. It will be displayed throughout your video stories and
              should be defined as a transparent png, and white in color (recommend 400 x 225px).
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={4} textAlign="right">
          <Dimmer.Dimmable
            onMouseEnter={() => setDimmedWatermark(true)}
            onMouseLeave={() => setDimmedWatermark(false)}
            style={{ width: '150px', margin: 'auto' }}
          >
            {/* Add background color in case of white png images */}
            <Image src={`${logoWatermarkUrl}`} style={{ backgroundColor: 'hsl(0, 0%, 90%)', margin: 'auto' }} />
            <Dimmer active={dimmedWatermark}>
              <Button size="mini" fluid inverted compact basic onClick={() => setShowWatermarkUploadModal(true)}>
                Change
              </Button>
            </Dimmer>
          </Dimmer.Dimmable>
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

interface IMyBrandingLogosProps {
  logoAvatarUrl: string
  logoWatermarkUrl: string
  setShowLogoUploadModal: (arg: boolean) => void
  setShowWatermarkUploadModal: (arg: boolean) => void
}

export default MyBrandingLogos
