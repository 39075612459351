import TeamMember from '../pages/my-team/TeamMember'

import resolve from './base'

export default class Data {
  // ----------------------------------Users------------------------------------------------
  static login = async (body: any) => resolve<any>('post', '/users/login', { ...body })

  static register = async (body: any) => resolve<any>('post', '/users/register', { ...body })

  static getMyProfile = async () => resolve<any>('get', '/users/myProfile')

  static updateMyProfile = async (newProfile: any) => resolve<any>('put', '/users/updateMyProfile', { ...newProfile })

  static updatePassword = async (newPassword: any) => resolve<any>('put', `/users/changePassword`, newPassword)

  static forgotPassword = async (email: string) => resolve<any>('post', 'users/forgotPassword', { email })

  static recoverPassword = async (recoveryCode: string, newPassword: string) =>
    resolve<any>('put', `users/recoverPassword/${recoveryCode}`, { newPassword })

  static impersonateUser = async (userId: number) => resolve<any>('post', `users/${userId}/impersonate`)

  static searchUser = async (value: string) => resolve<any>('get', `users?search=${value}`)

  // ----------------------------------Teams------------------------------------------------

  static getMyTeam = async () => resolve<any>('get', `/teams/myTeam`)

  static getTeamPlan = async () => resolve<any>('get', `/teams/getPlan`)

  static updateTeam = async (team: any) => resolve<any>('put', `/teams/updateMyTeam`, { ...team })

  static updateTeamAvatar = async (data: any) =>
    resolve<any>('put', `teams/updateAvatar`, data, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData',
    })

  static updateTeamWatermark = async (data: any) =>
    resolve<any>('put', `teams/updateWatermark`, data, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData',
    })

  static addUser = async (user: TeamMember) => resolve<any>('put', `/teams/addUser`, { ...user })

  static updateUser = async (user: TeamMember) => resolve<any>('put', `/teams/updateUser`, { ...user })

  static revokeUserAccess = async (userId: number) => resolve<any>('put', `/teams/revokeUserAccess`, { userId })

  static reactivateUser = async (userId: number) => resolve<any>('put', `/teams/reactivateUser`, { userId })

  static searchTeam = async (value: string) => resolve<any>('get', `teams?search=${value}`)

  static changePlan = async ({ newPlanId, newPlanExpDate }: { newPlanId: number; newPlanExpDate: Date | null }) =>
    resolve<any>('put', '/teams/changePlan', { newPlanId, newPlanExpDate })

  static getIntegrations = async () => resolve<IIntegration[]>('get', `/teams/integrations`)

  static createIntegration = async (provider: IntegrationProvider, code: string) =>
    resolve<any>('post', `/teams/integrations`, { provider, code })

  static deleteIntegration = async (integrationId: number) =>
    resolve<any>('delete', `/teams/integrations/${integrationId}`)

  static getIntegrationUrl = async (provider: IntegrationProvider) =>
    resolve<any>('get', `/teams/integrations/url?provider=${provider}`)

  // ----------------------------------Brands--------------------------------------------------
  static getBrand = async (domain: string) => resolve<any>('get', `brand-fetch/fetch-brand-assets/?brand=${domain}`)

  // ----------------------------------Projects------------------------------------------------
  static getProjects = async () => resolve<any>('get', `/projects`)

  static getProject = async (projectSlug: string) => resolve<any>('get', `/projects/${projectSlug}`)

  static createProject = async (body: any) => resolve<any>('post', '/projects', { ...body })

  static updateProject = async (project: any) => resolve<any>('put', `/projects/${project.slug}`, { ...project })

  static duplicateProject = async (slug: string) => resolve<any>('post', `/projects/${slug}/duplicate`)

  static deleteProject = async (projectSlug: string) => resolve<any>('delete', `/projects/${projectSlug}`)

  static cloneProject = async (projectSlug: string, newThemeId: number) =>
    resolve<any>('post', `projects/${projectSlug}/clone`, { newThemeId })

  static updateProjectImage = async (projectSlug: string, data: any) =>
    resolve<any>('put', `/projects/${projectSlug}/updateImage`, data, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData',
    })

  static getCsvReport = async (projectSlug: string) => resolve<any>('post', `projects/${projectSlug}/getCsvReport`)

  static getBulkDownloads = async (projectSlug: string) => resolve<any>('get', `projects/${projectSlug}/bulkDownloads`)

  static getExports = async (projectSlug: string) => resolve<any>('get', `projects/${projectSlug}/exports`)

  // ----------------------------------Clips------------------------------------------------
  static getClips = async (params: {
    projectSlug: string
    offset: number
    limit: number
    searchPhrase: string
    tags: string[]
  }) => {
    const { projectSlug, offset, limit, searchPhrase, tags } = params

    return resolve<any>(
      'get',
      `clips?project=${projectSlug}&groupSubmissions=true&offset=${offset}&limit=${limit}${
        searchPhrase.length > 0 ? `&search=${searchPhrase}` : ''
      }${tags ? `&tags=${tags.join(',')}` : ''}`
    )
  }
  static getClip = async (clipId: number) => resolve<IClip>('get', `/clips/${clipId}`)

  static setClipAsFavorite = async (clipId: number) => resolve<any>('put', `/clips/${clipId}/favorite`)

  static setClipVote = async (clipId: number, vote: boolean) => resolve<any>('put', `/clips/${clipId}/vote`, { vote })

  static postClip = async (projectSlug: string, data: any) =>
    resolve<any>('post', `/clips?projectSlug=${projectSlug}`, data, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData',
    })

  static getClipPlaceSuggestions = async (clipId: number) => resolve<any>('get', `/clips/${clipId}/placeSuggestions`)

  static updateClip = async (clip: any) => resolve<any>('put', `/clips/${clip.id}`, { ...clip })

  static downloadClip = async (clipId: number, type: string) =>
    resolve<any>('post', `/clips/${clipId}/downloadClip/${type}`)

  static deleteClip = async (clipId: number) => resolve<any>('delete', `/clips/${clipId}`)

  static bulkUpdateClipsWithTag = async (tagName: string, clipIds: number[]) =>
    resolve<any>('put', `/clips/bulkUpdateClipsWithTag`, { tagName: tagName, clipIds: clipIds })

  static bulkDownloadClips = async (projectSlug: string, clipIds: number[]) =>
    resolve<any>('post', `/clips/bulkDownloadClips`, { projectSlug, clipIds })

  static bulkExportClips = async (projectSlug: string, clipIds: number[], provider: IntegrationProvider) =>
    resolve<any>('post', `/clips/bulkExportClips`, { projectSlug, clipIds, provider })

  // ----------------------------------Episodes------------------------------------------------
  static getEpisodes = async (projectSlug: string) => resolve<any>('get', `/episodes?projectSlug=${projectSlug}`)

  static getEpisode = async (episodeId: number) => resolve<any>('get', `/episodes/${episodeId}`)

  static createEpisode = async (episode: any) => resolve<any>('post', '/episodes', { ...episode })

  static putEpisode = async (body: any) => resolve<any>('put', '/episodes', { ...body })

  static downloadEpisode = async (id: number, type: string) =>
    resolve<any>('post', `/episodes/${id}/downloadEpisode/${type}`)

  static duplicateTheme = async (themeId: number, title: string) =>
    resolve<any>('post', `themes/${themeId}/duplicate`, { title })

  static deleteEpisode = async (episodeId: number) => resolve<any>('delete', `/episodes/${episodeId}`)

  // ----------------------------------Themes------------------------------------------------
  static getThemes = async () => resolve<any>('get', '/themes')

  static getTheme = async (themeId: number) => resolve<any>('get', `/themes/${themeId}`)

  static postTheme = async (body: any) => resolve<any>('post', '/themes', { ...body })

  static updateTheme = async (theme: any) => resolve<any>('put', `/themes/${theme.id}`, { ...theme })

  static updateThemeThumbnail = async (themeId: number, data: any) =>
    resolve<any>('put', `/themes/${themeId}/updateThumbnail`, data, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData',
    })

  static deleteTheme = async (episodeId: number) => resolve<any>('delete', `/themes/${episodeId}`)

  static encodeThemePreview = async (themeId: number) => resolve<any>('post', `themes/${themeId}/createPreview`)

  // ----------------------------------Theme assets------------------------------------------------
  static getThemeAssets = async (type: string, offset: number, limit: number) =>
    resolve<any>('get', `themeAssets?type=${type}&offset=${offset}&limit=${limit}`)

  static deleteThemeAsset = async (themeAssetId: number) => resolve<any>('delete', `/themeAssets/${themeAssetId}`)

  // ----------------------------------Plan------------------------------------------------
  static getAvailablePlans = async () => resolve<any>('get', '/plans')

  // ------------------------------------------------Pexels------------------------------------------------
  static getPexelsPopularVideos = async () => resolve<IPexelsVideo[]>('get', '/pexels/popular')

  static searchPexelsVideos = async (value: string) => resolve<IPexelsVideo[]>('get', `/pexels/search?query=${value}`)

  // ------------------------------------------------Payment------------------------------------------------
  static getStripePlans = async () => resolve<IStripePlan[]>('get', '/payments/getPlans')

  static createStripeCheckoutSession = async (priceId: string) =>
    resolve<{ sessionId: string }>('post', '/payments/createCheckoutSession', { priceId })

  static createStripeCustomerPortalSession = async () =>
    resolve<{ url: string }>('post', '/payments/createCustomerPortalSession')
}

export interface IProject {
  id: number
  slug: string
  name: string
  title: string
  description: string
  quantity: number
  deadline: Date
  incentive: string
  rules: string
  tips: string
  teamTermsUrl: string
  hasImage: boolean
  hasVideo: boolean
  heroVideoEncodingStatus: number
  displaySetting: any
  numberOfQuestions: number
  questions: string[]
  successTextDescription: string
  successCTAText: string
  successCTALink: string
  shared: boolean
  showRecentContributions: boolean
  pageViews: number
  imageUrl: string
  videoUrl: string
  isProjectClosed: boolean
  projectUrl: string
  totalNumberOfTeamClips: number
  clipCount: number
  clipMax: number
  episodeCount: number
  createdAt: Date
  updatedAt: Date
  allowRecordVideo: boolean
  allowUploadFile: boolean
  allowQrCode: boolean
  shouldEmailEverySubmission: boolean
  customTrackingCode: string
}

export interface ITheme {
  id: number
  title: string
  description: string
  category: string
  isMaster: boolean
  isPublished: boolean
  placeholderText: string
  data: any
  videoPreviewUrl: string
  videoSampleUrl: string
  createdAt: Date
  updatedAt: Date
  projectSlug: string
  imageUrl: string
}

type Comment = {
  id: string
  comment: string
}

export interface IClip {
  id: number
  submissionId: string | null
  contributor: string
  email: string
  organization: string
  caption1: string
  caption2: string
  caption3: string
  place: string
  location: string
  rating: number
  lat1: number | null
  lon1: number | null
  filmingDate: Date
  filmingUTCOffset: number
  status: number
  encodingTimestamp: Date
  isPublished: boolean
  isFavorite: boolean
  isFeatured: boolean
  duration: number
  trim: number[]
  tags: string[]
  contentOwnership: boolean
  is1080: boolean
  trimmedVideoDuration: number
  filmingLocalTime: Date
  videoUploadUrl: string
  videoCleanUrl: string
  videoHighResUrl: string
  videoUrl: string
  videoLowResUrl: string
  imageUrl: string
  imageLowResUrl: string
  encodingStatus: string
  createdAt: Date
  updatedAt: Date
  votes: number[]
  comments: Comment[]
  question: string | null
}

export interface IClipGroup {
  submissionId: string,
  clips: IClip[]
}

export interface IEpisode {
  id: number
  title: string
  description: string
  encodeTimestamp: Date
  status: number
  videoHighResUrl: string
  videoUrl: string
  videoLowResUrl: string
  imageUrl: string
  imageLowResUrl: string
  encodingStatus: string
  createdAt: Date
  updatedAt: Date
}

export interface ITeam {
  id: number
  teamName: string
  organisationWebsite: string
  organisationDescription: string
  hasImage: boolean
  watermarkId: number
  primaryFont: string
  primaryColor: string
  secondaryColor: string
  buttonTextColor: string
  logoAvatarUrl: string
  logoWatermarkUrl: string
  users: IUser[]
  subscription: any
  projects: IProject[]
}

export interface IBrand {
  organisationName: string
  organisationWebsite: string
  organisationDescription: string
  logoAvatarUrl: string
  logoWatermarkUrl: string
  primaryFont: string
  primaryColor: string
  secondaryColor: string
}

export interface IUser {
  id: number
  email: string
  username: string
  hasImage: boolean
  userAccessRights: 'admin' | 'member' | 'collaborator' | 'revoke'
  imageUrl: string
  bio: string
  emailOptIn: boolean
  plan: IPlan
  collaboratorProjects: number[]
}

export interface IPlan {
  id: number
  title: string
  description: string
  contentOwnership: boolean
  maxNbOfUserPerTeam: number
  maxNbOfClips: number
  videoResolution: number
  downloadReport: boolean
  cleanClip: boolean
  hasMadeWithVloggiOutro: boolean
  projectHeroVideo: boolean
  isAllowedMultiClipUpload: boolean
}

export interface IUserProfile {
  username: string
  email: string
  userAccessRights: 'admin' | 'member' | 'collaborator' | 'revoke'
  teamName: string
  logoWatermarkUrl: string
  logoAvatarUrl: string
  watermarkId: number
  subscriptionExpiryDate: Date
  subscriptionDaysLeft: number
  isCustomer: boolean
  plan: IPlan
}

export interface IStripePlan {
  priceDescription: string
  priceId: string
}

export interface IPexelsVideo {
  avg_color: any
  duration: number
  full_res: any
  height: number
  id: number
  image: string
  tags: string[]
  url: string
  user: { id: number; name: string; url: string }
  video_files: { id: number; quality: string; file_type: string; width: number; height: number; link: string }[]
  video_pictures: { id: number; nr: number; picture: string }[]
}

export enum IntegrationProvider {
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}

export interface IIntegration {
  id: number
  teamId: number
  provider: IntegrationProvider
  isActive: boolean
  createdAt: Date
  updatedAt: Date
}

export enum ExportDestination {
  ZIP = 'zip',
  GOOGLE_DRIVE = 'google_drive',
}

export interface IExport {
  id: number
  destination: ExportDestination
  exportedBy: string
  requestedAt: Date
  projectId: number
  clipIds: number[]
  isActive: boolean
}
