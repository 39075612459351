import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Grid, Button } from 'semantic-ui-react'

import { IProject } from '../../../api/Data'

import SubSection from '../../../components/common/SubSection'
import Title from '../../../components/common/Title'

import InviteTabEmbed from './InviteTabEmbed'
import InviteTabHelp from './InviteTabHelp'
import InviteTabQRCode from './InviteTabQRCode'
import InviteTabShareLinks from './InviteTabShareLinks'

const InviteTab = ({ projectTitle, project, history, exitProject }: IProps) => {
  const uploadMyFirstVideo = () => {
    history.push(`/projects/${project.slug}/manage`, { uploadModal: true })
  }

  return (
    <>
      <Title title={projectTitle}>
        <>
          <Button compact color="orange" onClick={uploadMyFirstVideo}>
            Upload your first video
          </Button>
          <Button compact onClick={exitProject}>
            Close
          </Button>
        </>
      </Title>

      <SubSection hasGreyBackground>
        <Grid stackable>
          <Grid.Column width={11}>
            <Grid stackable>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <InviteTabShareLinks project={project} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column>
                  <InviteTabQRCode project={project} />
                </Grid.Column>

                <Grid.Column>
                  <InviteTabEmbed project={project} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>

          <Grid.Column width={5}>
            <InviteTabHelp />
          </Grid.Column>
        </Grid>
      </SubSection>
    </>
  )
}

interface IProps extends RouteComponentProps<any> {
  projectTitle: string
  project: IProject
  exitProject: () => void
}

export default withRouter(InviteTab)
