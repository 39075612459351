import React from 'react'

import { Modal } from 'semantic-ui-react'

const ThemeCustomisationModal = ({ setShowModal }: IProps) => {
  const loadActiveCampaignForm = () => {
    const script = document.createElement('script')

    script.src = 'https://vloggi.activehosted.com/f/embed.php?id=3&email=%EMAIL%'
    script.async = true

    document.body.appendChild(script)
  }
  return (
    <Modal
      dimmer="blurring"
      size="small"
      open
      basic
      closeIcon
      onMount={loadActiveCampaignForm}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        <div style={{ width: '500px', height: '500px', margin: '0 auto' }}>
          <div className="_form_3" />
        </div>
      </Modal.Content>
    </Modal>
  )
}

interface IProps {
  setShowModal: (arg: boolean) => void
}

export default ThemeCustomisationModal
