import React, { FC } from 'react'

import { STRIPE_API_KEY, STRIPE_PRICING_TABLE_ID } from '../../config/environments'

const StripePricingTableComponent: FC<Props> = ({ clientReferenceId }) => {
  return React.createElement('stripe-pricing-table', {
    'client-reference-id': clientReferenceId,
    'pricing-table-id': STRIPE_PRICING_TABLE_ID,
    'publishable-key': STRIPE_API_KEY,
  })
}

type Props = {
  clientReferenceId: number
}

export default StripePricingTableComponent
