import React, { FC, useState } from 'react'

import { map } from 'lodash'

const LocationSearchMapBox: FC<IProps> = (props) => {
  const [state, setState] = useState({
    error: false,
    errorMsg: '',
    query: props.query ? props.query : '',
    queryResults: [],
    publicKey: props.publicKey,
    resetSearch: props.resetSearch ? props.resetSearch : false,
  })

  const _updateQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    console.log(event)
    setState((prevState) => ({ ...prevState, query: event.target.value }))

    const header = { 'Content-Type': 'application/json' }

    let path = `https://api.mapbox.com/geocoding/v5/mapbox.places/${state.query}.json?access_token=${state.publicKey}`

    if (props.country) {
      path = `https://api.mapbox.com/geocoding/v5/mapbox.places/${state.query}.json?access_token=${state.publicKey}&country=${props.country}`
    }

    if (state.query.length > 2) {
      return fetch(path, {
        headers: header,
      })
        .then((res) => {
          if (!res.ok) throw Error(res.statusText)
          return res.json()
        })
        .then((json) => {
          setState((prevState) => ({
            ...prevState,
            error: false,
            queryResults: json.features,
          }))
        })
        .catch((err) => {
          setState((prevState) => ({
            ...prevState,
            error: true,
            errorMsg: 'There was a problem retrieving data from mapbox',
            queryResults: [],
          }))
        })
    }
    setState((prevState) => ({
      ...prevState,
      error: false,
      queryResults: [],
    }))
  }

  const _resetSearch = () => {
    if (state.resetSearch) {
      setState((prevState) => ({
        ...prevState,
        query: '',
        queryResults: [],
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        queryResults: [],
      }))
    }
  }

  const _onSuggestionSelect = (event: any) => {
    if (state.resetSearch === false) {
      setState((prevState) => ({ ...prevState, query: event.target.getAttribute('data-suggestion') }))
    }

    props.onSuggestionSelect({
      place: event.target.getAttribute('data-text'),
      location: event.target.getAttribute('data-suggestion'),
      lat: event.target.getAttribute('data-lat'),
      lng: event.target.getAttribute('data-lng'),
    })
  }

  // This function return a shorter location that will be used to display on the video
  const _getDataLocation = (place: any) => {
    console.log('Place', place)

    const placeType = place.place_type[0]

    const placeFormatted = {
      text: place.text,
      neighborhood: place.context?.find((el: any) => el.id.match(/neighborhood/))?.text || '',
      locality: place.context?.find((el: any) => el.id.match(/locality/))?.text || '', // i.e. Bondi
      placeContext: place.context?.find((el: any) => el.id.match(/place/))?.text || '', // i.e. Sydney
      region: place.context?.find((el: any) => el.id.match(/region/)) || '', // i.e. NSW
      country: place.context?.find((el: any) => el.id.match(/country/))?.text || '', // i.e. NSW
    }

    const { text, neighborhood, locality, placeContext, region, country } = placeFormatted

    if (['Australia', 'Belgium', 'Brazil', 'Canada', 'Germany', 'United States'].includes(country) === true) {
      const countryRegion = region.short_code?.replace(/.+-/, '') || ''

      switch (placeType) {
        case 'address':
          return [neighborhood, locality || placeContext, countryRegion].filter(Boolean).join(', ') // i.e. Curtin street

        case 'poi':
          return [neighborhood, locality || placeContext, countryRegion].filter(Boolean).join(', ') // i.e. Tour Eiffel

        case 'place':
          return [text, neighborhood, locality || placeContext, countryRegion].filter(Boolean).join(', ') // i.e. Tour Eiffel

        case 'locality':
          return [text, countryRegion].filter(Boolean).join(', ') // i.e. Bondi beach

        case 'region':
          return [text].filter(Boolean).join(', ') // i.e. Massa

        default:
          return text
      }
    }

    if (country !== 'Australia') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const worldRegion = region.text || ''

      switch (placeType) {
        case 'address':
          return [neighborhood, placeContext || locality, country].filter(Boolean).join(', ') // i.e. Curtin street

        case 'poi':
          return [placeContext || locality, country].filter(Boolean).join(', ') // i.e. Tour Eiffel

        case 'place':
          return [text, neighborhood, placeContext || locality, country].filter(Boolean).join(', ') // i.e. Tour Eiffel

        case 'locality':
          return [text, country].filter(Boolean).join(', ') // i.e. Bondi beach

        case 'region':
          return [text, country].filter(Boolean).join(', ') // i.e. Massa

        default:
          return [text, country].filter(Boolean).join(', ')
      }
    }
  }

  // Return a place only if there is a place of interest returned
  const _getPlaceOfInterest = (place: any) => {
    if (place.place_type[0] === 'poi') return place.text // return the poi ('Eiffel tower')
    if (place.place_type[0] === 'address') return place.text // return only the street ('Curtin street')
    return ''
  }

  return (
    <div>
      <input
        autoFocus
        placeholder={props.placeholder || 'Search'}
        className={props.inputClass ? `${props.inputClass} react-mapbox-ac-input` : 'react-mapbox-ac-input'}
        onChange={_updateQuery}
        value={state.query}
        type="text"
      />
      <span>
        <div
          className="react-mapbox-ac-menu"
          style={state.queryResults.length > 0 || state.error ? { display: 'block' } : { display: 'none' }}
          onClick={_resetSearch}
        >
          {map(state.queryResults, (place: any, i) => {
            return (
              <div
                className="react-mapbox-ac-suggestion"
                onClick={_onSuggestionSelect}
                key={i}
                data-suggestion={_getDataLocation(place)}
                data-lng={place.center[0]}
                data-lat={place.center[1]}
                data-text={_getPlaceOfInterest(place)}
              >
                {place.place_name}
              </div>
            )
          })}

          {state.error && <div className="react-mapbox-ac-suggestion">{state.errorMsg}</div>}
        </div>
      </span>
    </div>
  )
}

interface IProps {
  inputClass: string
  publicKey: string
  placeholder?: string
  country?: string
  query?: string
  resetSearch: boolean
  onSuggestionSelect: (arg: any) => void
}

export default LocationSearchMapBox
