// Download a file (clip, episode)

import { IClipGroup, IClip } from '../api/Data'

import { GOOGLE_FONT_PICKER_API_KEY } from '../config/environments'

// A CORS configuration is set on the AWS to allow browser to access the relevant S3 bucket
export const downloadFileFromBrowser = ({ data, type, fileName }: { data: string; type: string; fileName: string }) => {
  const target = document.createElement('a')
  const blob = new Blob([data], { type })
  const url = window.URL.createObjectURL(blob)
  target.hidden = true
  target.href = url
  target.download = fileName
  document.body.appendChild(target)
  target.click()
  document.body.removeChild(target)
}

export const getFontCSSFromFontFile = (fontfile: string = '') => {
  let matchResults
  let fontFamilySlugified = ''
  let fontType = ''

  matchResults = fontfile.match(/^.+\/(.*)-.*.ttf$/)
  if (!matchResults) throw Error('Invalid fontfile')
  fontFamilySlugified = matchResults[1]

  matchResults = fontfile.match(/^.+\/.*-(.*).ttf$/)
  if (!matchResults) throw Error('Invalid fontfile')
  fontType = matchResults[1]

  const fontFamily = fontFamilySlugified.replace(/_/g, ' ')
  const fontWeight = fontType.match(/Bold/) ? 'bold' : 'normal'
  const fontStyle = fontType.match(/Italic/) ? 'italic' : 'normal'

  return { fontFamily, fontWeight, fontStyle }
}

export const getFontFileFromFontCSS = (fontFamily: string, fontWeight: string, fontStyle: string) => {
  const fontFamilyFile = fontFamily.replace(/ /g, '_')

  let fontTypeFile

  if (fontWeight === 'normal' && fontStyle === 'normal') fontTypeFile = 'Regular'
  if (fontWeight === 'normal' && fontStyle === 'italic') fontTypeFile = 'Italic'
  if (fontWeight === 'bold' && fontStyle === 'normal') fontTypeFile = 'Bold'
  if (fontWeight === 'bold' && fontStyle === 'italic') fontTypeFile = 'BoldItalic'

  return `google/${fontFamilyFile}-${fontTypeFile}.ttf`
}

// Allow to handle line breaks in textArea in order to create HTML list
export const convertTextAreaToHTML = (text: string) => {
  const lines: string[] = text.split('\n')
  const getFirstListItem = (line: string) => `<ul><li>${line.replace('-', '')}</li>`
  const getListItem = (line: string) => `<li>${line.replace('-', '')}</li>`
  const getLastListItem = (line: string) => `<li>${line.replace('-', '')}</li></ul>`
  const getNormalTextLine = (line: string) => `<span>${line}</span><br/>`

  return lines
    .map((line, index) => {
      if (line.startsWith('-') && !lines[index - 1]?.startsWith('-')) return getFirstListItem(line)
      if (line.startsWith('-') && !lines[index + 1]?.startsWith('-')) return getLastListItem(line)
      if (line.startsWith('-')) return getListItem(line)
      return getNormalTextLine(line)
    })
    .join('')
}

export const getBbox = (clips: IClip[]) => {
  const clipsLat = clips.reduce((acc: number[], clip) => (clip.lat1 ? [...acc, clip.lat1] : acc), [])
  const clipsLon = clips.reduce((acc: number[], clip) => (clip.lon1 ? [...acc, clip.lon1] : acc), [])

  // If no clip with location return Australia Bbox
  if (clipsLat.length === 0 || clipsLon.length === 0) {
    const bboxWorld = [
      [-161.174927, -53.560705],
      [176.325073, 72.332048],
    ]

    return bboxWorld
  }

  /* tslint:disable-next-line */
  const maxWest = Math.min(...clipsLon)
  const maxEast = Math.max(...clipsLon)

  const maxNorth = Math.min(...clipsLat)
  const maxSouth = Math.max(...clipsLat)

  return [
    [maxWest, maxSouth],
    [maxEast, maxNorth],
  ]
}

export const extractDomain = (string: string) => string.replace(/^.+(:\/\/)/, '').replace(/(\/).*/, '').replace(/(\:).*/, '')

export const wrapBrand = (data: any) => {
  if (!!data) {
    return {
      organisationName: data?.name || null,
      organisationWebsite: data?.domain || null,
      organisationDescription: data?.description || null,
      logoAvatarUrl: data?.logos?.find((l: any) => l.type === 'icon')?.formats?.find((l: any) => ['jpeg', 'jpg', 'png'].includes(l.format))?.src || null,
      logoWatermarkUrl: data?.logos?.find((l: any) => l.type === 'logo')?.formats?.find((l: any) => ['png'].includes(l.format) && l.background === 'transparent')?.src || null,
      primaryFont: data?.fonts?.find((f: any) => f.origin === 'google')?.name || data?.fonts[0]?.name || null,
      primaryColor: data?.colors?.find((c: any) => ['brand', 'dark'].includes(c.type))?.hex || null,
      secondaryColor: data?.colors?.find((c: any) => c.type === 'accent')?.hex || null
    }
  }

  return data
}

export const getAvailableFonts = async () => {
  try {
    const response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=' + GOOGLE_FONT_PICKER_API_KEY)
    const data = await response.json()
    
    return data.items.filter(
      (f: any) => f.variants.includes('regular') === true && f.variants.includes('italic') === true && f.variants.includes('700') === true
    ).slice(0, 150).sort(
      (f1: any, f2: any) => f1.family.toLowerCase() > f2.family.toLowerCase() ? 1 : -1
    )
  } catch (err: any) {
    return []
  }
}

export const flattenClipsList = (clips: any) => {
  return Object.keys(clips).reduce((result: IClip[], submissionId: string) => {
    return [...result, ...clips[submissionId].clips]
  }, [])
}

export const reshapeClipsList = (clips: IClip[]) => {
  return Array.from(new Set(clips.map((clip: IClip) => clip.submissionId))).reduce((result: Array<IClip | IClipGroup>, submissionId: string | null) => {
    const submissionClips = clips.filter((clip: IClip) => clip.submissionId === submissionId)

    if (submissionId === null) {
      result = [...result, ...submissionClips]
    } else if (submissionClips.length === 1) {
      result = [...result, ...submissionClips]
    } else {
      result.push({
        submissionId: submissionId,
        clips: submissionClips
      })
    }

    return result
  }, [])
}