import React from 'react'

import { Card, Image } from 'semantic-ui-react'

import { ITheme } from '../../api/Data'

const ThemeCard = (props: IProps) => {
  // Props
  const { theme, selected, onClickSelect } = props
  
  const { title, description, imageUrl } = theme
  
  // To make sure the title fits on 1 line
  const shortTitle = title.length > 20 ? title.substring(0, 20).concat('...') : title

  const applicableDescription = description.indexOf('1.') !== -1 && description.indexOf('2.') !== -1 ? description.substring(description.indexOf('1.') + 2, description.indexOf('2.')).trim() : description
  const shortDescription = applicableDescription.length > 50 ? applicableDescription.substring(0, 50).concat('...') : applicableDescription

  return (
    <div style={{ border: (selected ? '4px solid #6c58fb' : '4px solid transparent'), borderRadius: '10px' }} onClick={() => onClickSelect(theme)}>
      <Card style={{ width: '176px', height: '214px', cursor: 'pointer' }}>
        <Image src={imageUrl} as="a" size="medium" />
        <Card.Content textAlign="left">
          <Card.Header>{shortTitle}</Card.Header>
          <Card.Description>
            {shortDescription}
          </Card.Description>
        </Card.Content>
      </Card>
    </div>
  )
}

interface IProps {
  theme: ITheme
  selected: boolean
  onClickSelect: (t: ITheme) => void
}

export default ThemeCard
