import React from 'react'

import { Button, Grid, Header, Image, Modal } from 'semantic-ui-react'

import imageOnboardingWelcome from '../../_images/onboarding-welcome.png'

const WelcomeModal = (props: IWelcomeModalProps) => {
  // Props
  const { stepForward } = props

  return (
    <Modal open style={{ width: '100%', height: '100%', marginTop: '-14px' }}>
      <Modal.Content style={{ padding: 0 }}>
        <Grid style={{ height: '100vh', minHeight: '700px', margin: 0 }}>
          <Grid.Column only="computer" width={7} style={{ backgroundImage: 'linear-gradient(to bottom, #12165b, #121360)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Header inverted as="h1">
                Welcome to Vloggi!
              </Header>
              <Image src={imageOnboardingWelcome} width={350} alt="vloggi" />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} computer={9}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
              <div style={{ height: '30px' }}>
                
              </div>
              <div style={{ padding: '0 100px' }}>
                <Header as="h2">
                  Let's get you set up
                </Header>
                <Header as="h4">
                  It only takes a moment. And it'll make your video collecting with Vloggi even better.
                </Header>
                <Button color="orange" style={{ marginTop: '20px' }} onClick={stepForward}>
                  Get started
                </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ height: '30px', margin: '12px 20px 0 0' }}>
                  1 / 6
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

interface IWelcomeModalProps {
  stepForward: () => void
}

export default WelcomeModal
