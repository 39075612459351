import React from 'react'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { IClip } from '../../../api/Data'

import SelectedClipCard from './MergeTabSelectedClipCard'

const dndListStyle = {
  width: '100%',
}

const dndItemStyle = {
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: '1rem auto',
}

const MergeTabEpisodeBuilder = ({ clipsSelected, setClipsSelected, removeClip }: IProps) => {
  // a little function to help us with reordering the result
  const reorder = (list: IClip[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) return

    const newClipsSelected = reorder(clipsSelected, result.source.index, result.destination.index)

    setClipsSelected(newClipsSelected)
  }

  console.log('drag and drop', clipsSelected)

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: any, snapshot: any) => (
          <div
            ref={provided.innerRef}
            className="dnd-list-style"
            style={{
              background: snapshot.isDraggingOver ? 'lightblue' : 'rgba(34, 36, 38, 0)',
              ...dndListStyle,
            }}
            {...provided.droppableProps}
          >
            {clipsSelected.map((clip, index) => (
              <Draggable key={clip.id} draggableId={String(clip.id)} index={index}>
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="dnd-item-style"
                    style={{
                      background: snapshot.isDragging ? 'lightgreen' : '',
                      marginTop: '0.5rem',
                      ...provided.draggableProps.style,
                      ...dndItemStyle,
                    }}
                  >
                    <SelectedClipCard clip={clip} removeClip={removeClip} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

interface IProps {
  clipsSelected: IClip[]
  setClipsSelected: (args: IClip[]) => void
  removeClip: (arg: IClip) => void
}

export default MergeTabEpisodeBuilder
